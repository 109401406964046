import { DRIVER_LISTING } from './types'
import { post ,logoutOnExpiry} from './../../../services/requests'

export const getDrivers = () => {
        return (dispatch) => {
            post('user/getUsers', { userType : "driver"})
                .then(response => {
                    const driverLists = response.data
                    dispatch(getDriverListing(driverLists))
                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        } 
    }


export const getDriverListing = (driverLists) => {
    return {
        type: DRIVER_LISTING,
        payload: driverLists
    }
}