/* eslint-disable */
import React from 'react';
import $ from 'jquery'
import { useEffect, useState } from 'react'
import { Draggable, Droppable } from 'react-drag-and-drop'
import { getConstraints } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import HandleInputOutput from './Modal/HandleInputOutput'
import Solutions from './Modal/Solutions'
import SolutionTables from './Modal/SolutionTables'
import axios from 'axios'
import history from '../../services/history'

const LoadOptimizer = () => {
    const dispatch = useDispatch()
    const [constraintsArray, setConstraintsArray] = useState([])
    const [constraintsOutput, setConstraintsOutput] = useState([])
    const inputlisting = useSelector((state) => state.getConstraintsRed.constrainstInputList)
    const outputlisting = useSelector((state) => state.getConstraintsRed.constrainstOutputList)
    const [constraintState, setConstraintState] = useState({
        value: [],
        type: ''
    })
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const [draggableComponents, setDraggableComponents] = useState([
        {
            label: 'Dispatch Plan',
            type: 'input',
            dragType: 'dispatchplan',
            disable: false
        },
        {
            label: 'Product Dimensions and Volume',
            type: 'input',
            dragType: 'productdimvol',
            disable: false
        },
        {
            label: 'Vehicle Dimensions and Volume',
            type: 'input',
            dragType: 'vehdimvol',
            disable: false
        },
        {
            label: 'Freight Rates',
            type: 'input',
            dragType: 'freightrates',
            disable: false
        },
        {
            label: 'Delivery locations',
            type: 'input',
            dragType: 'deliverylocations',
            disable: false
        },
        {
            label: 'Wastage of space in volume',
            type: 'input',
            dragType: 'wastageofspace',
            disable: false
        },
        {
            label: 'Optimal Number vehicles',
            type: 'output',
            dragType: 'optimalnumvehicles',
            disable: false
        },
        {
            label: 'Delivery time',
            type: 'output',
            dragType: 'delivertime',
            disable: false
        },



    ])
    const onDrop = (data) => {
        // getting different things in data so looping around it 
        let compName = Object.keys(data);
        let index = 0
        let key = ''
        compName.forEach((ind) => {
            if (data[ind].length > 0) {
                index = data[ind].split(",")[1]
                key = data[ind].split(",")[2]
            }
        });
        let local = [...draggableComponents]
        local[index].disable = true
        setDraggableComponents(local)

        if (data.productdimvol.split(",")[0] === 'productdimvol') {
            setConstraintsArray([...constraintsArray, {
                component: 'productdimvol',
                label: 'Product Dimensions and Volume',
                ind: index,
                type: 'input'
            }])
        }
        else if (data.vehdimvol.split(",")[0] === 'vehdimvol') {
            setConstraintsArray([...constraintsArray, {
                component: 'vehdimvol',
                label: 'Vehicle Dimensions and Volume',
                ind: index,
                type: 'input'
            }])
        }
        else if (data.freightrates.split(",")[0] === 'freightrates') {
            setConstraintsArray([...constraintsArray, {
                component: 'freightrates',
                label: 'Freight Rates',
                ind: index,
                type: 'input'
            }])
        }
        else if (data.dispatchplan.split(",")[0] === 'dispatchplan') {
            setConstraintsArray([...constraintsArray, {
                component: 'dispatchplan',
                label: 'Dispatch Plan',
                ind: index,
                type: 'input'
            }])
        }
        else if (data.deliverylocations.split(",")[0] === 'deliverylocations') {
            setConstraintsArray([...constraintsArray, {
                component: 'deliverylocations',
                label: 'Delivery locations',
                ind: index,
                type: 'input'
            }])
        }
        else if (data.wastageofspace.split(",")[0] === 'wastageofspace') {
            setConstraintsArray([...constraintsArray, {
                component: 'deliverylocations',
                label: 'Wastage of space in volume',
                ind: index,
                type: 'input'
            }])
        }
        // setting the state for API
        let temp = { ...constraintState }
        temp.value.push(key)
        temp.type = 'input'
        dispatch(getConstraints(temp))
    }
    const onOutputDrop = (data) => {
        // getting different things in data so looping around it 
        let compName = Object.keys(data);
        let index = 0
        let key = ''
        let type = ''
        compName.forEach((ind) => {
            if (data[ind].length > 0) {
                index = data[ind].split(",")[1]
                key = data[ind].split(",")[2]
                type = data[ind].split(",")[3]
            }
        });
        let local = [...draggableComponents]
        local[index].disable = true
        setDraggableComponents(local)

        if (data.optimalnumvehicles.split(",")[0] === 'optimalnumvehicles') {
            setConstraintsOutput([...constraintsOutput, {
                component: 'optimalnumvehicles',
                label: 'Optimal Number vehicles',
                ind: index,
                type: 'output'
            }])
        }
        else if (data.delivertime.split(",")[0] === 'delivertime') {
            setConstraintsOutput([...constraintsOutput, {
                component: 'delivertime',
                label: 'Delivery time',
                ind: index,
                type: 'output'
            }])
        }

        // setting the state for API
        let temp = { ...constraintState }
        temp.value.push(key)
        temp.type = 'output'
        dispatch(getConstraints(temp))
    }
    const removeConstraint = (item, index) => {
        let local = [...constraintsArray]
        let ind = local[index].ind
        let temp = [...draggableComponents]
        temp[ind].disable = false

        let apiState = { ...constraintState }
        apiState.value.splice(index, 1)
        setConstraintState(apiState)
        local.splice(index, 1)
        setConstraintsArray(local)
        setDraggableComponents(temp)
        dispatch(getConstraints({ ...constraintState, type: 'input' }))
    }
    const removeOutPutConstraint = (item, index) => {
        let local = [...constraintsOutput]
        let ind = local[index].ind
        let temp = [...draggableComponents]
        temp[ind].disable = false

        let apiState = { ...constraintState }
        apiState.value.splice(index, 1)
        setConstraintState(apiState)
        local.splice(index, 1)
        setConstraintsOutput(local)
        setDraggableComponents(temp)
        dispatch(getConstraints({ ...constraintState, type: 'output' }))
    }
  useEffect(()=>{
    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
    $("#SolutionTable").removeClass("custom-modal-is-visible")
  },[])
    return (
        <>
            <div className="contentpanel">
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-5'>
                        <h1 className="title">
                            Load Optimizer
                        </h1>
                    </div>
                    <div className='cust-col-5 flex-jc-end'>
                        <h1 className="title font-bold orange_color">
                        </h1>
                    </div>
                </div>
                <hr className='bgg' />
                <div className="cust-container">
                    <div class="cust-row">
                        <div class="cust-col-10">
                            {/* <div className='bdaOuter'>
                                <div className='searcBox'>
                                </div>
                                <div className='mapView'>
                                    <button className='myMapBtnGrey' onClick={() => { _handleClick('HandleInputOutputModal') }}> Create Trip2</button>
                                </div>
                            </div> */}
                            <div className='bdaList'>
                                <div className="custom-modal-body" style={{ height: '75vh' }}>
                                    <div className='inputDivNew'>
                                        <div className='inputElmOuter'>
                                            <div className='inputHead'>
                                                <span className='io'>Choose Your Constraints :</span>
                                                {/* <div className='colorCodes'>
                                    <div className='colorRow'>
                                        <div className='round'></div>
                                        <span className='colorNameGreen'>Input</span>
                                    </div>
                                    <div className='colorRow'>
                                        <div className='roundColor'></div>
                                        <span className='colorNameGrey'>Output</span>
                                    </div>

                                </div> */}
                                            </div>
                                            <div className='inputElements'>
                                                {draggableComponents.map((item, index) => {
                                                    return (
                                                        item.disable !== true ?
                                                            <Draggable type={item.dragType} data={[item.dragType, index, item.label, item.type]}>
                                                                <div key={index} className='inputComponent'>
                                                                    <span>{item.label}</span>
                                                                </div>
                                                            </Draggable>
                                                            :
                                                            <div key={index} className={item.type === 'input' ? 'undraggableItemgreen' : 'undraggableItemgrey'}>
                                                                <span>{item.label}</span>
                                                            </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='draggableIoOuter'>
                                        <div className='dragHead'>
                                            <div className='dragHeadings' style={{ borderRight: '1px solid silver', borderBottom: 'none' }}>Input</div>
                                            <div className='dragHeadings' style={{ borderRight: 'none', borderBottom: 'none' }}>Output</div>
                                        </div>
                                        <div className='droppable' style={{ borderTop: 'none' }}>
                                            {constraintsOutput && constraintsOutput.length === 0 ?
                                                <Droppable
                                                    className='dropZone'
                                                    types={['productdimvol', 'dispatchplan', 'vehdimvol', 'freightrates', 'deliverylocations', 'wastageofspace']}
                                                    onDrop={(data) => onDrop(data)}>
                                                    {constraintsArray.length !== 0 ?
                                                        constraintsArray.map((item, index) => {
                                                            return (
                                                                item.component === 'productdimvol' || 'vehdimvol' || 'freightrates' || 'dispatchplan' ?
                                                                    <div key={index} className='inputComponentDragged'>
                                                                        <i className="fa fa-times groupCrossComp" onClick={() => removeConstraint(item, index)} ></i>
                                                                        <span>{item.label}</span>
                                                                    </div>
                                                                    : ''
                                                            )
                                                        })
                                                        :
                                                        <div className='dragDotted'>Drop Input Constraint..</div>
                                                    }
                                                </Droppable>
                                                :
                                                inputlisting[0] && inputlisting[0].input !== '' ?
                                                    <div className='droppable dropZone'>
                                                        {inputlisting[0] && inputlisting[0].input.map((item, index) => {
                                                            return (
                                                                <div key={index} className='inputComponentDragged '>
                                                                    <span>{item.id}</span>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    :
                                                    <div className='dropZone dropZoneCentre '>
                                                        <span>No Input Constarint Found.</span>
                                                    </div>
                                            }

                                            {constraintsArray && constraintsArray.length === 0 ?
                                                <Droppable
                                                    className='dropZone'
                                                    types={['optimalnumvehicles', 'delivertime']}
                                                    onDrop={(data) => onOutputDrop(data)}>
                                                    {constraintsOutput.length !== 0 ?
                                                        constraintsOutput.map((item, index) => {
                                                            return (
                                                                item.component === 'optimalnumvehicles' || 'delivertime' ?
                                                                    <div key={index} className='inputComponentDraggedOutPut'>
                                                                        <i className="fa fa-times groupCrossComp" onClick={() => removeOutPutConstraint(item, index)} ></i>
                                                                        <span>{item.label}</span>
                                                                    </div>

                                                                    : ''
                                                            )
                                                        })
                                                        :
                                                        <div className='dragDotted'>Drop Output Constraint..</div>
                                                    }
                                                </Droppable>
                                                :
                                                outputlisting[0] && outputlisting[0].output !== '' ?
                                                    <div className='droppable dropZone'>
                                                        {outputlisting[0] && outputlisting[0].output.map((item, index) => {
                                                            return (
                                                                <div key={index} className='inputComponentDraggedOutPut '>
                                                                    <span>{item.id}</span>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    :
                                                    <div className='dropZone dropZoneCentre '>
                                                        <span>No Output Constarint Found.</span>
                                                    </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='customBtnElem'>
                                        <button className='customBtn' onClick={() => { _handleClick('SolModal') }}>Next</button>
                                        {/* <button onClick={customerPage}>Customer Data</button> */}
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <HandleInputOutput closeModal={_closeModal} />
                <Solutions closeModal={_closeModal} />
                <SolutionTables closeModal={_closeModal} />
            </div>
        </>

    );
};

export default LoadOptimizer;