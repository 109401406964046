import { combineReducers } from 'redux';
import bdaListingRed from './bdaListingRed'; 
import getOrdersRed from './getOrdersRed'; 
import optimisedRed from './optimisedRed'; 
import getVehiclesRed from './getVehiclesRed'; 
import createOptiRouteRed from './createOptiRouteRed';
import addressListRed from './addressRed'; 
import getRequestRed from './requestReducer';
import getVehicleRed from './vehiclesRed';
import getSetRed from './settingsRed';   
import tripListingRed from './tripListRed';
import driverListRed from './driverListingRed'; 
import changeVehicleReducer from './changeVehRed'; 
import getSlotRed from './slotRed'
import getConstraintsRed from './constrainstRed'  
import getSolRed from './getSols'
import getplotRed from './plotBoxRed'
import getUserRed from './usersRed'   
import getAreasRed from './areaListRed'   
import getCustRed from './customerRed'
import getCylinderRed from './cylinderRed'
import cashMemoListingRed from './cashMemoRed'  
import distributorListingReducer from './distributorAdminRed'
import getFeedbackRed from './feedbackRed';
import getDistributorActivityRed from './distributorActivityRed';

export default combineReducers({
    bdaListingRed,
    getOrdersRed,
    optimisedRed,
    getVehiclesRed,
    createOptiRouteRed,
    addressListRed,
    getRequestRed,
    getVehicleRed,
    tripListingRed,
    driverListRed,
    getSetRed,
    changeVehicleReducer,
    getSlotRed,
    getConstraintsRed,
    getSolRed,
    getplotRed,
    getUserRed,
    getAreasRed,
    getCustRed,
    getCylinderRed,
    getFeedbackRed,
    getDistributorActivityRed,
    cashMemoListingRed,
    distributorListingReducer
});