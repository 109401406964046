import { ADDRESS_LISTING ,  SHOW_ADD_LOADER , HIDE_ADD_LOADER,ADDRESS_MSSG , ADDRESS_COUNT} from "../actions/types";

const initialState={
    addressListing: [],
    loading: true,
    message: '',
    count :''
} 

const addressListRed =(state=initialState,{type,payload})=>{
    switch(type){
        case ADDRESS_LISTING : return{
            ...state,
            addressListing : payload
        }
        case SHOW_ADD_LOADER : return{ 
            ...state,
            loading:true 
        }
        case HIDE_ADD_LOADER : return{ 
            ...state,
            loading:false 
        }
        case ADDRESS_MSSG : return{ 
            ...state,
            message: payload 
        }
        case ADDRESS_COUNT : return{ 
            ...state,
            count: payload 
        }
        default: return state;  
    }
}
export default addressListRed ;