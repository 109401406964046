import { CHANGE_VEHICLE, VEHICLE_LOADER_TRUE, VEHICLE_LOADER_FALSE ,RESET_CLUSTER} from './types'
import { post , logoutOnExpiry } from './../../../services/requests'

export const changeVehiclefun = (object) => {
    return (dispatch) => {
        dispatch(showLoader())
        dispatch(resetCluster([]))
        post('trip/changeVehicle', object)
            .then(response => {
                const data = response.data
                const status = response.success
                const message = response.message
                if(status === true){
                    dispatch(getNewCluster(data))
                    dispatch(hideLoader())
                }
                else{
                    dispatch(hideLoader())
                    window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
                
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }
}


export const getNewCluster = (data) => {
    return {
        type: CHANGE_VEHICLE,
        payload: data
    }
}
export const resetCluster = (data) => {
    return {
        type: RESET_CLUSTER,
        payload: []
    }
}
export const showLoader = () => {
    return {
        type: VEHICLE_LOADER_TRUE,
    }
}
export const hideLoader = () => {
    return {
        type: VEHICLE_LOADER_FALSE,
    }
}

