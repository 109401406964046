import { PLOT_BOXES, POINTS_SHOW , POINTS_HIDE , EMPTY_PLOT } from "../actions/types";

const initialState={
    plotLists : [],
    loading : ''
} 

const getplotRed =(state=initialState,{type,payload})=>{
    switch(type){
        case PLOT_BOXES : return{
            ...state,
            plotLists : payload
        }
        case POINTS_SHOW : return{
            ...state,
            loading : true
        }
        case POINTS_HIDE : return{
            ...state,
            loading : false
        }
        case EMPTY_PLOT : return{
            ...state,
            plotLists : []
        }
        default: return state;  
    }
}
export default getplotRed ;