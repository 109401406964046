import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { getSettings } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { logoutOnExpiry, url } from './../../services/requests'
import Select from 'react-select';
import { colourStyles } from '../../services/functions'

const ProductSettings = () => {
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.getSetRed.settingslisting)
    const [productSettings, setProductSettings] = useState(
        {
            unitType: "Number"
        }
    )

    const settingsTypes = [
        { label: 'Quantity (Number)', value: 'Number' },
        { label: 'Kg', value: 'Kg' },
    ]

    const handleSettings = (item) => {
        let temp = { ...productSettings }
        temp.unitType = item.value
        setProductSettings(temp)
    }


    const addSettings = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post(`${url}/setting/addSetting`, { ...productSettings }, { headers: config.headers })
            .then(response => {
                const addressRes = response.data
                const status = response.data.success
                const message = response.data.message
                if (status === true) {
                    localStorage.setItem('unit', productSettings.unitType)
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                    setTimeout(() => {
                        dispatch(getSettings())
                    }, 1000)
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })

    }
    useEffect(()=>{
        let temp = {...productSettings}
        const unit = localStorage.getItem('unit') 
        temp.unitType = unit
        setProductSettings(temp)
    },[])
    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className='cust-col-5'>
                    <h1 className="title">
                        Product Settings
                    </h1>
                </div>
                <div className='cust-col-5 flex-jc-end'>
                    <h1 className="title font-bold orange_color">
                    </h1>
                </div>
            </div>
            <hr className='bgg' />
            <div className="cust-container">
                <div class="cust-row">
                    <div class="cust-col-10">
                        <div className='bdaList'>
                            <div className='productSetDiv productSetDivWidth'>
                                <span className='product60span'>Please Select Unit : </span>
                                <Select
                                    onChange={(value) => handleSettings(value)}
                                    options={settingsTypes}
                                    styles={colourStyles}
                                    value={{label: productSettings.unitType, value:  productSettings.unitType}}
                                />
                                <button className='productSave' onClick={addSettings}> Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductSettings;