import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import jwt_decode from "jwt-decode";
import { Route, Redirect } from 'react-router-dom';
import version from '../../package.json'
export default function PrivateRoute({
  component: Component,
  isPrivate,
  ...rest
}) {
  const token = localStorage.getItem("secretkey");
  const versionName = version.version

  useEffect(() => {
    const reloadAndClear = () => {
      if ("version" in localStorage) {
        const currentVersion = localStorage.getItem('version')
        if (currentVersion === versionName) {
          return null
        }
        else {
          localStorage.setItem("version", versionName);
          setTimeout(() => {
            // window.$.fn.show_notification({ message: `New Updates available. Do you want to Reload ?`, title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
            //   if (result.type === 'confirm') {
                window.location.reload(true)
              // }
            // })
          }, 2000)
        }
      }
      else {
        localStorage.setItem("version", versionName);
        setTimeout(() => {
          // window.$.fn.show_notification({ message: `New Updates available. Do you want to Reload ?`, title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
          //   if (result.type === 'confirm') {
              window.location.reload(true)
          //   }
          // })
        }, 2000)
      }
    }
    reloadAndClear()
  }, [])

  setInterval(() => {
    if ("version" in localStorage) {
      const currentVersion = localStorage.getItem('version')
      if (currentVersion === versionName) {
        return null
      }
      else {
        localStorage.setItem("version", versionName);
        setTimeout(() => {
          // window.$.fn.show_notification({ message: `New Updates available. Do you want to Reload ?`, title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
          //   if (result.type === 'confirm') {
              window.location.reload(true)
          //   }
          // })
        }, 2000)
      }
    }
    else {
      localStorage.setItem("version", versionName);
      setTimeout(() => {
        // window.$.fn.show_notification({ message: `New Updates available. Do you want to Reload ?`, title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
        //   if (result.type === 'confirm') {
            window.location.reload(true)
        //   }
        // })
      }, 2000)
    }

  }, 600000)
  

  if (token) {
    const decoded = jwt_decode(token);
    const dateNow = new Date();
    if (decoded.exp * 1000 < dateNow.getTime()) {
      localStorage.removeItem('secretkey')
      window.$.fn.show_notification({ message: 'Session Expired: Please Login Again', title: 'Message!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
        if (result.type === 'confirm') {
        }
      })
      return <Redirect to="/" />;
    }
  }

  if (isPrivate && token === null) {
    return <Redirect to="/" />;
  }
  if (!isPrivate && token) {
    return <Redirect to="/dashboard" />;
  }
  return <Route {...rest} component={Component} />;
}


































//   var signed = false;
//   if(localStorage.getItem('secretkey')){
//       signed=true
//   }
//   /**
//    * Redirect user to SignIn page if he tries to access a private route
//    * without authentication.
//    */
//   if (isPrivate && !signed) {
//     return <Redirect to="/" />;
//   }
//   /**
//    * Redirect user to Main page if he tries to access a non private route
//    * (SignIn or SignUp) after being authenticated.
//    */
//   if (!isPrivate && signed) {
//     return <Redirect to="/dashboard" />;
//   }
//   /**
//    * If not included on both previous cases, redirect user to the desired route.
//    */
//   return <Route {...rest} component={Component} />;
// }
// RouteWrapper.propTypes = {
//   isPrivate: PropTypes.bool,
//   component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
//     .isRequired,
// };
// RouteWrapper.defaultProps = {
//   isPrivate: false,
// };