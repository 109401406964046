/* eslint-disable */
import { selectInit } from './../../../services/functions'
import { useEffect, useState } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import $ from 'jquery'
import chatMrker from './../../../static/images/icon/sidebar/updated/location.png'


const LocationModal = (props) => {
    const lat = props && props.geo && props.geo.val && props.geo.val.deliveryLocation ? props.geo.val.deliveryLocation.latitude : ''
    const lng = props && props.geo && props.geo.val && props.geo.val.deliveryLocation ? props.geo.val.deliveryLocation.longitude : ''
    const mid = { lat: lat, lng: lng }

    const actualLat = props && props.geo && props.geo.val && props.geo.val.locationDetails ? props.geo.val.locationDetails.latitude : ''
    const actualLng = props && props.geo && props.geo.val && props.geo.val.locationDetails ? props.geo.val.locationDetails.longitude : ''
    const actualMid = { lat: actualLat, lng: actualLng }

    const locations = [
        {lat: mid.lat, lng : mid.lng},
        {lat: actualMid.lat, lng : actualMid.lng},
    ]





    useEffect(() => {
        if(locations && locations.length){
            var lat = mid && mid.lat ? mid.lat : ''
            var lng = mid && mid.lng ? mid.lng : ''
            var markers;
            var myLatLng = { lat: +lat, lng: +lng };
            var map = new google.maps.Map(document.getElementById("mapDimensions"), {
                zoom: 12,
                center: myLatLng,
            });
            for (let i = 0; i < locations.length; i++) {  
                markers = new google.maps.Marker({
                  position: new google.maps.LatLng(locations[i].lat, locations[i].lng),
                  map: map,
                  icon: {
                    url: chatMrker,
                    scaledSize: new google.maps.Size(40 , 40),
                    labelOrigin: new google.maps.Point(22, 31)
                },
                label: {
                    text: i === 0 ? 'Delivered Location' : 'Actual location',
                    color: "blue",
                    fontSize: "14px",
                    fontWeight: '800',
                }
                });
        }
    }
    }, [mid])




    return (
        <section id="LocationModal" className="custom-modal toggle_width" style={{ width: "60%" }}>
            <div className="custom-modal-header">
                <h1>Location</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ maxHeight: '70vh', height: '70vh' }} >
                <div className='mapOuter' style={{ height: '100%' }}>

                    <div id='mapDimensions'>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default LocationModal;