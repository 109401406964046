import { USERS , USERS_SHOW , USERS_HIDE , USERS_MSSG , USERS_COUNT  } from "../actions/types";

const initialState={
    usersList: [],
    loading: true, 
    message : "",
    count:""
} 

const getUserRed =(state=initialState,{type,payload})=>{
    switch(type){
        case USERS : return{
            ...state,
            usersList : payload
        }
        case USERS_SHOW : return{ 
            ...state,
            loading: true
        }
        case USERS_HIDE : return{ 
            ...state,
            loading:false
        }  
        case USERS_MSSG : return{ 
            ...state,
            message:payload
        }
        case USERS_COUNT : return{ 
            ...state,
            count:payload
        }
        default: return state;  
    }
}
export default getUserRed ;