import React, { useState, useEffect } from 'react';
import history from '../../../services/history'
import $ from 'jquery'
import { selectInit } from './../../../services/functions'
import { getAddress, getVehiclesList , getTrips } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import Select from 'react-select';
import { logoutOnExpiry , url} from './../../../services/requests'
import { colourStyles } from './../../../services/functions'

const EditTrip = (props) => {
    const dispatch = useDispatch()
    const data = props.sharedData.data
    const status = props.sharedData.status
    const listing = useSelector((state) => state.addressListRed.addressListing)
    const vehListing = useSelector((state) => state.getVehicleRed.vehicleListing)
    const [editState, setEditState] = useState({})
    const pageNumber = props && props.paginationState && props.paginationState && props.paginationState.pageNumber ? props.paginationState.pageNumber : ''
    const entries = props && props.paginationState && props.paginationState && props.paginationState.entries ? props.paginationState.entries : '' 
    const [finalObj, setFinalObj] = useState({
        tripId: "",
        updateDetails: {
            locations: [],
            vehicle: "",
            dateOfTrip: "",
            origin: {
                address: '',
                latitude: '',
                longitude: ''
            }
        }
    })

    const [newLocations, setNewLocations] = useState([
        {
            _id: "",
            userId: "",
            address: "",
            userName: "",
            geofenceId: "",
            status: '',
            base: "",
            latitude: "",
            longitude: "",
            quantity: ""
        }
    ])

    useEffect(() => {
        setTimeout(() => {
            selectInit(_handleDrop)
        }, 100);
        let dummy = { ...data }
        setEditState(dummy)
        dispatch(getAddress())
        // dispatch(getVehiclesList())
    }, [data, dispatch])

    const deleteTrip = (index) => {
        let temp = { ...editState }
        temp.locations.splice(index, 1)
        setEditState(temp)
    }
    const deleteNewTrip = (index) => {
        let temp = [...newLocations]
        temp.splice(index, 1)
        setNewLocations(temp)
    }
    const addTrip = () => {
        let temp = [...newLocations]
        temp.push(
            {
                _id: "",
                userId: "",
                address: "",
                userName: "",
                geofenceId: "",
                status: '',
                base: "",
                latitude: "",
                longitude: "",
                quantity: ""
            }
        )
        setNewLocations(temp)
    }
    const _handleDrop = (e, ind) => {
        const id = e.target.value
        const innerInd = ind
        if (newLocations.length < 11) {
            let dummy = [...newLocations]
            dummy[innerInd]._id = listing[id]._id
            dummy[innerInd].userId = listing[id].userId
            dummy[innerInd].address = listing[id].geofence[0].address
            dummy[innerInd].userName = listing[id].userName
            dummy[innerInd].geofenceId = listing[id].geofenceId
            dummy[innerInd].status = listing[id].status
            dummy[innerInd].base = listing[id].base
            dummy[innerInd].latitude = listing[id].latitude
            dummy[innerInd].longitude = listing[id].longitude
            setNewLocations(dummy)
        }
        else {
            window.$.fn.show_notification({ message: `Only 11 locations allowed..`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
    }
    const _handleQtyChange = (e, index) => {
        let temp = [...newLocations]
        temp[index].quantity = e.target.value
        setNewLocations(temp)
    }
    const _handleVehicle = (e) => {
        let id = e.target.value
        let dummy = { ...finalObj }
        dummy.updateDetails.vehicle = vehListing[id]._id
        setFinalObj(dummy)
    }
    const _handleDate = (e) => {
        let dummy = { ...finalObj }
        dummy.updateDetails.dateOfTrip = e.target.value
        setFinalObj(dummy)
    }
    const editTripFun = () => {
        finalObj.tripId = data.tripId
        finalObj.updateDetails.dateOfTrip = editState.dateOfTrip
        finalObj.updateDetails.origin.address = editState.origin.address
        finalObj.updateDetails.origin.latitude = editState.origin.latitude
        finalObj.updateDetails.origin.longitude = editState.origin.longitude
        if (newLocations[0]._id === '') {
            const concated = [...editState.locations]
            finalObj.updateDetails.locations = concated
        }
        else {
            const concated = [...editState.locations, ...newLocations]
            finalObj.updateDetails.locations = concated
        }


        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post(`${url}/trip/updateTrip`, { ...finalObj }, { headers: config.headers })
            .then(response => {
                const status = response.data.success
                const message = response.data.message
                if (status === true) {
                    dispatch(getTrips({
                        page: pageNumber,
                        limit: entries,
                        date: ''
                    }))
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })   
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
        // console.log('finalObj', finalObj)
    }
    const _onDateChange = (e) => {
        let dummy = { ...editState }
        dummy.dateOfTrip = e.target.value
        setEditState(dummy)
    }
  
    const options = []
    vehListing && vehListing.map((item) => {
        options.push({ value: item.vehicleNo, label: item.vehicleNo });
    });
    const handleVeh = (item) => {
        // let dummy = { ...tripState }
        // dummy.vehicle.push({
        //     vehicleType: item.label,
        //     volume: item.volume,
        //     freightRate: item.freightRate,
        //     quantity: 1,
        //     disabled: true
        // })
        // setTripState(dummy)
    }
    return (
        <section id="EditTripModal" className="custom-modal" style={{ width: '62%' }}>
            <div className="custom-modal-header">
                <h1 >Edit Trip</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ overflow: 'auto', maxHeight: '70vh', height: '69vh' }}>
                <div className='editTrip100'>
                    <div className='editHead'>
                        <span className='EditSpan'>Date :</span>
                        {status !== true ?
                            <input className='EditInp' name='dateOfTrip' value={finalObj.dateOfTrip} onChange={(e) => _handleDate(e)} type='date' />
                            :
                            <span className='driverSpan2 calenderInpCont' style={{ width: '25%', marginLeft: '12px', color: '#007BC9', fontSize: '0.75rem', fontWeight:'800' }}>
                                {moment(editState.dateOfTrip).format('DD/MM/YYYY')}
                                <div className='hubUploadIcon' style={{ width: '20%', border: 'none' }}>
                                    <i className="fa fa-calendar updateCalender"></i>
                                    <input className='hideCalend' min={new Date().toISOString().split('T')[0]} name='StartDate' value={data.dateOfTrip} onChange={(e) => _onDateChange(e)} type="date" />
                                </div>
                            </span>

                        }

                    </div>
                    <div className='editBodyy'>
                    
                        <span className='EditSpan underFont'>Origin :</span>

                        <div className='locationsList'>
                            <input type='text' className='locationInput' disabled={true} value={editState && editState.origin && editState.origin.address ? editState.origin.address : '-'} />
                        </div>
                        <div className='boxDiv'>

                            <span className='EditSpan underFont'>Locations :</span>
                            {editState && editState.locations && editState.locations.map((item, index) => {
                                return (
                                    <div className='locationsList'>
                                        <input key={index} type='text' className='locationInput' disabled={true}
                                            value={"locationDetails" in item ? item && item.locationDetails && item.locationDetails.address ? item.locationDetails.address : '-' : 'startpoint'} />
                                        <div className='tripIcons'>
                                            <i className="fa fa-trash trashIcon" onClick={() => deleteTrip(index)} ></i>
                                        </div>
                                    </div>
                                )
                            })}
                            {newLocations.map((item, ind) => {
                                return (
                                    <div key={ind} className='locationsList1'>
                                        <select className="customDropDown" style={{ cursor: 'pointer' }} defaultValue="select" id="searchType" onChange={(e) => _handleDrop(e, ind)}>
                                            <option value="select">Select Address</option>
                                            {listing && listing.map((item, index) => {
                                                return (
                                                    <option value={index}>{item.address}</option>
                                                )
                                            })}
                                        </select>
                                        <div className='grouplist3'>
                                            <span className='tripSpan' style={{ fontSize: '0.8rem' }}>Quantity :</span>
                                            <input name='quantity' onChange={(e) => _handleQtyChange(e, ind)} className='tripInput' type='number' placeholder='Quantity' />
                                        </div>
                                        <div className='tripIcons1'>
                                            <i className="fa fa-plus" onClick={addTrip} style={{ fontSize: '0.9rem', color: 'green', marginRight: '8px' }} ></i>
                                            {ind !== 0 ?
                                                <i className="fa fa-trash trashIcon" onClick={() => deleteNewTrip(ind)} ></i>
                                                : ''}
                                        </div>

                                    </div>

                                )
                            })}
                        </div>
                        <div className='allotedVehicle'>
                            <span className='EditSpan underFont'>Alloted Vehicle : <span className='vehNum'>{editState && editState.vehicle && editState.vehicle.vehicleNo}</span></span>
                            <div className='allotedDrop'>
                                <select className="customDropDown alterDrop" defaultValue="select" id="searchType" onChange={(e) => _handleVehicle(e)}>
                                    <option value="select">Select Vehicle</option>
                                    {vehListing && vehListing.map((item, index) => {
                                        return (
                                            <option value={index}>{item.vehicleNo}</option>
                                        )
                                    })}
                                </select>
                                {/* <Select
                                    onChange={(value) => handleVeh(value)}
                                    options={options}
                                    styles={colourStyles}
                                   
                                /> */}
                            </div>
                        </div>
                        <div className='customBtnElem' style={{ height: '70px' }}>
                            <button className='customBtn' onClick={editTripFun}>Update Trip</button>
                        </div>
                    </div>
                </div>

                {/* <div className='createRouteBtn'>
                    <button >Update Trip</button>
                </div> */}
            </div>
        </section>
    )
}

export default EditTrip;