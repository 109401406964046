import React, { useEffect, useState } from 'react';
import history from '../../services/history'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers } from '../../state/redux/actions/index';
import AddUser from './Modal/AddUser'
import $ from 'jquery'
import axios from 'axios'
import { logoutOnExpiry , url } from './../../services/requests'

const UserManagement = () => {
    const dispatch = useDispatch()
    const [activeInd, setActiveInd] = useState('0')
    const listing = useSelector((state) => state.getUserRed.usersList)

    useEffect(() => {
        dispatch(getUsers({
            page: 1,
            limit: 10,
            searchType: "",
            searchValue: "",
            userType: "distributor",
            type: 'admin'
        }))
    }, [])
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const CustomizeBtn = () => {
        history.push({
            state: "item",
            pathname: '/dashboard/customization'
        })
    }
    const changeUserType = (index) => {
        setActiveInd(index)
        if (index === '0') {
            dispatch(getUsers({
                page: 1,
                limit: 10,
                searchType: "",
                searchValue: "",
                userType: "distributor",
                type: 'admin'
            }))
        }
        else {
            dispatch(getUsers({
                page: 1,
                limit: 10,
                searchType: "",
                searchValue: "",
                userType: "driver",
                type: 'admin'
            }))
        }

    }
    const edit = (_id) => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post(`${url}/user/editUser`, { _id }, { headers: config.headers })
            .then(response => {
                const status = response.data.success
                const message = response.data.message
                if (status === true) {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                    window.location.reload()
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }

    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className='cust-col-5'>
                    <h1 className="title">
                        User Management
                    </h1>
                </div>
                <div className='cust-col-5 flex-jc-end'>
                    <h1 className="title font-bold orange_color">
                    </h1>
                </div>
            </div>
            <hr className='bgg' />
            <div className="cust-container">
                <div class="cust-row">
                    <div class="cust-col-10">
                        <div className='bdaOuter' >
                            <div className='searcBox alignCen alignCentNew' style={{ width: '100%' }} >
                                <div className='userTypeDivv'>
                                    <button onClick={() => changeUserType('0')} className={activeInd === '0' ? 'userTypeStatusActive' : 'userTypeStatusinActive'}>Distributor</button>
                                    <button onClick={() => changeUserType('1')} className={activeInd === '1' ? 'userTypeStatusActive' : 'userTypeStatusinActive'}>Driver</button>
                                </div>
                            </div>
                            <div className='mapView'>
                                <button className='myMapBtnGrey' onClick={() => { _handleClick('AddUser') }}> Add User</button>
                            </div>
                        </div>
                        <div className='bdaList'>
                            {listing && listing.length !== 0 ?
                                <div className='bdaTableNew'>
                                    <div className='bdaHeader'>
                                        <div className='bdaH00 wid5'>
                                            <span>S NO.</span>
                                        </div>
                                        <div className='bdaH2 wid30'>
                                            <span>Name</span>
                                        </div>
                                        <div className='bdaH2 wid30'>
                                            <span>Company Name</span>
                                        </div>
                                        <div className='bdaH2 wid30'>
                                            <span>Mobile Number</span>
                                        </div>
                                        <div className='bdaH2 wid30'>
                                            <span>User Type</span>
                                        </div>
                                        <div className='bdaH2 wid30'>
                                            <span>Lat , Lng</span>
                                        </div>
                                        <div className='bdaH2 wid10'>
                                            <span>Action</span>
                                        </div>
                                    </div>
                                    {listing && listing.map((item, index) => {
                                        return (
                                            <div className='bdaContent'>
                                                <div className='bdaH00 wid5 no_top' style={{ height: 'auto', padding: "3px" }}>
                                                    <span className='bdaSpan orangeColor'>{index + 1}</span>
                                                </div>
                                                <div className='bdaH2 wid30 no_top modifyHgt'>
                                                    <span className='bdaSpan'>{item.name}</span>
                                                </div>
                                                <div className='bdaH2 wid30 no_top modifyHgt'>
                                                    <span className='bdaSpan'>{item.company}</span>
                                                </div>
                                                <div className='bdaH2 wid30 no_top modifyHgt'>
                                                    <span className='bdaSpan'>{item.mobile}</span>
                                                </div>
                                                <div className='bdaH2 wid30 no_top modifyHgt'>
                                                    <span className='bdaSpan'>{item.userType}</span>
                                                </div>
                                                <div className='bdaH2 wid30 no_top modifyHgt'>
                                                    <span className='bdaSpan'>{item.latitude} ,  {item.longitude}</span>
                                                </div>
                                                <div className='bdaH2 wid10 no_top modifyHgt' style={{ height: 'auto', padding: "7px" }}>
                                                    {/* <button className='customizeBtn' onClick={()=>CustomizeBtn()}>Customize</button> */}
                                                    {item.approved ? <i class="fa fa-check slotStatusDisableIcon" onClick={()=>edit(item._id)}></i>:<i class="fa fa-times slotStatusEnableIcon" onClick={()=>edit(item._id)}></i>}
                                                </div>


                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <div className='noData'>
                                    <p>No Data found..</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <AddUser closeModal={_closeModal} />
        </div>
    )
}
export default UserManagement;