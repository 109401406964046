import { useEffect, useState } from "react";
import Select from 'react-select';
import $ from 'jquery';
import axios from 'axios'
import { logoutOnExpiry , url } from './../../../services/requests'
import { colourStyles } from './../../../services/functions'
import { getUsers } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'

const TripSettingsModal = (props) => {
    const dispatch = useDispatch()
    const [listingData, setListingData] = useState({})
    const pageNumber = props && props.dataState && props.dataState.pagination && props.dataState.pagination.pageNumber ? props.dataState.pagination.pageNumber : ''
    const entries = props && props.dataState && props.dataState.pagination && props.dataState.pagination.entries ? props.dataState.pagination.entries : '' 
    const [userState, setUserState] = useState(
        {
            name: '',
            password: "",
            confirmPassword: "",
            email: "",
            mobile: "",
            company: "",
            userType: "driver",
            maxTripDuration: "",
            earliestTripTime: "",
            latestTripTime: "",
            waitingTime: "",
            driverRestTime: "",
            areaId: "",
            eligibleForVehicle: ""

        }
    )
  
    const timeSlots = [
        { label: '00', value: '00' },
        { label: '01', value: '01' },
        { label: '02', value: '02' },
        { label: '03', value: '03' },
        { label: '04', value: '04' },
        { label: '05', value: '05' },
        { label: '06', value: '06' },
        { label: '07', value: '07' },
        { label: '08', value: '08' },
        { label: '09', value: '09' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
        { label: '16', value: '16' },
        { label: '17', value: '17' },
        { label: '18', value: '18' },
        { label: '19', value: '19' },
        { label: '20', value: '20' },
        { label: '21', value: '21' },
        { label: '22', value: '22' },
        { label: '23', value: '23' },
        { label: '24', value: '24' },
    ]
    const handleStartChange = (item, val) => {
        let temp = { ...userState }
        if (val === 'maxTrip') {
            temp.maxTripDuration = item.value
            setUserState(temp)
        }
        else if (val === 'earliestStart') {
            temp.earliestTripTime = item.value
            setUserState(temp)
        }
        else {
            temp.latestTripTime = item.value
            setUserState(temp)
        }
    }
    const handleChange = (e) => {
        setUserState({ ...userState, [e.target.name]: e.target.value })
    }

    const editSettings = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        let temp = {
            _id: listingData && listingData.driverId ? listingData.driverId : '',
            updateDetails: userState
        }
        if (temp.updateDetails.maxTripDuration === '') {
            window.$.fn.show_notification({ message: 'Enter maximum trip duration', title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(temp.updateDetails.earliestTripTime === ''){
            window.$.fn.show_notification({ message: 'Enter maximum trip duration', title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(temp.updateDetails.latestTripTime === ''){
            window.$.fn.show_notification({ message: 'Enter Latest Trip Time', title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(temp.updateDetails.waitingTime === ''){
            window.$.fn.show_notification({ message: 'Enter Waiting Time', title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(temp.updateDetails.driverRestTime === ''){
            window.$.fn.show_notification({ message: 'Enter Delivery Man Rest Time', title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else{
            axios.post(`${url}/user/editUser`, { ...temp }, { headers: config.headers })
            .then(response => {
                let status = response.data.success
                let message = response.data.message
    
                if(status === true){
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    }) 
                    dispatch(getUsers({
                        page: entries,
                        limit: pageNumber,
                        searchType: "",
                        searchValue: "",
                        userType: "driver",
                        type: 'driver'
                    }))
                    setUserState(
                        {
                            name: '',
                            password: "",
                            confirmPassword: "",
                            email: "",
                            mobile: "",
                            company: "",
                            userType: "driver",
                            maxTripDuration: "",
                            earliestTripTime: "",
                            latestTripTime: "",
                            waitingTime: "",
                            driverRestTime: "",
                            areaId: "",
                            eligibleForVehicle: ""
    
                        }
                    )
                }
                else{
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
        }
       
    }

    useEffect(() => {
        if (props) {
            let temp = { ...userState }
            let data = props && props.settingsState ? props.settingsState : {}
            setListingData(data)

            temp.name = data && data.name ? data.name : ''
            temp.email = data && data.email ? data.email : ''
            temp.mobile = data && data.mobile ? data.mobile : ''
            temp.company = data && data.company ? data.company : ''
            temp.maxTripDuration = data && data.settings && data.settings.maxTripDuration ? data.settings.maxTripDuration : ''
            temp.earliestTripTime = data && data.settings && data.settings.earliestTripTime ? data.settings.earliestTripTime : ''
            temp.latestTripTime = data && data.settings && data.settings.latestTripTime ? data.settings.latestTripTime : ''
            temp.waitingTime = data && data.settings && data.settings.waitingTime ? data.settings.waitingTime : ''
            temp.driverRestTime = data && data.settings && data.settings.driverRestTime ? data.settings.driverRestTime : ''
            temp.areaId = data && data.settings && data.settings.areaId ? data.settings.areaId : ''
            temp.eligibleForVehicle = data && data.settings && data.settings.eligibleForVehicle ? data.settings.eligibleForVehicle : ''
            setUserState(temp)
        }
    }, [props])

    return (
        <section id="TripSettingsModal" className="custom-modal toggle_width" style={{ width: "50%" }}>
            <div className="custom-modal-header">
                <h1>Delivery Man Settings</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '64vh' }} >
                {/* <div className="btaTTop">
                        <button className="cBtn">Edit Settings</button>
                </div> */}

                <div className='bdaTableNew'>
                    <div className='bdaHeader'>
                        <div className='bdaH2 wid30' style={{ background: '#FFE000' }}>
                            <span>Max Trip Duration</span>
                        </div>
                        <div className='bdaH2 wid30' style={{ background: '#FFE000' }}>
                            <span>Earliest Trip Time</span>
                        </div>
                        <div className='bdaH2 wid30' style={{ background: '#FFE000' }}>
                            <span>Latest Trip Time</span>
                        </div>
                        <div className='bdaH2 wid30' style={{ background: '#FFE000' }}>
                            <span>Waiting Time</span>
                        </div>
                        <div className='bdaH2 wid30' style={{ background: '#FFE000' }}>
                            <span>Delivery Man Rest Time</span>
                        </div>
                    </div>

                    <div className='bdaContent'>
                        <div className='bdaH2 wid30 no_top modifyHgt'>
                            <span className='bdaSpan'>{listingData && listingData.settings ? listingData.settings.maxTripDuration : '-'}</span>
                        </div>
                        <div className='bdaH2 wid30 no_top modifyHgt'>
                            <span className='bdaSpan'>{listingData && listingData.settings ? listingData.settings.earliestTripTime : '-'}</span>
                        </div>
                        <div className='bdaH2 wid30 no_top modifyHgt'>
                            <span className='bdaSpan'>{listingData && listingData.settings ? listingData.settings.latestTripTime : '-'}</span>
                        </div>
                        <div className='bdaH2 wid30 no_top modifyHgt'>
                            <span className='bdaSpan'>{listingData && listingData.settings ? listingData.settings.waitingTime : '-'}</span>
                        </div>
                        <div className='bdaH2 wid30 no_top modifyHgt'>
                            <span className='bdaSpan'>{listingData && listingData.settings ? listingData.settings.driverRestTime : '-'}</span>
                        </div>

                    </div>

                </div>
                <div className="bdaSettingsUI">
                    <div className="bdaSr bdasrHead" style={{ background: '#fff', border: 'none' }}>
                       <span>Delivery Man Settings</span>
                    </div>
                    <div className="bdaSr">
                        <div className="bdaSr30">
                            <span className="bdasrspan">Max Trip Duration</span>
                            <Select
                                onChange={(value) => handleStartChange(value, 'maxTrip')}
                                options={timeSlots}
                                styles={colourStyles}
                                value={{ label: userState.maxTripDuration === '' ? '-' : userState.maxTripDuration, value: userState.maxTripDuration === '' ? '-' : userState.maxTripDuration }}
                            />
                        </div>
                        <div className="bdaSr30">
                            <span className="bdasrspan">Earliest Trip Time</span>
                            <Select
                                onChange={(value) => handleStartChange(value, 'earliestStart')}
                                options={timeSlots}
                                styles={colourStyles}
                                value={{ label: userState.earliestTripTime === '' ? '-' : userState.earliestTripTime, value: userState.earliestTripTime === '' ? '-' : userState.earliestTripTime }}
                            />
                        </div>
                        <div className="bdaSr30">
                            <span className="bdasrspan">Latest Trip Time</span>
                            <Select
                                onChange={(value) => handleStartChange(value, 'latestTrip')}
                                options={timeSlots}
                                styles={colourStyles}
                                value={{ label: userState.latestTripTime === '' ? '-' : userState.latestTripTime, value: userState.latestTripTime === '' ? '-' : userState.latestTripTime }}
                            />
                        </div>
                    </div>
                    <div className="bdaSr">
                        <div className="bdaSr30">
                            <span className="bdasrspan">Waiting Time</span>
                            <input className="bdSRInput" name='waitingTime' onChange={(e) => handleChange(e)} value={userState.waitingTime} type='number' placeholder="Enter Waiting Time" />
                        </div>
                        <div className="bdaSr30">
                            <span className="bdasrspan">Delivery Man Rest Time</span>
                            <input className="bdSRInput" name='driverRestTime' onChange={(e) => handleChange(e)} type='number' value={userState.driverRestTime} placeholder="Enter Driver Rest Time" />
                        </div>
                        <div className="bdaSr30">

                        </div>

                    </div>
                    <div className="bdaSr alignEndBtn" style={{ background: '#fff', border: 'none' }}>
                        <button className="cBtn" onClick={() => editSettings()}>Edit Settings</button>
                    </div>


                </div>
            </div>
        </section>
    )
}

export default TripSettingsModal;