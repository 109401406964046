/* eslint-disable */
import React, { useEffect, useState } from 'react'
import history from '../../services/history';
import solid from './../../static/images/icon/sidebar/updated/solidmarker.png'
import solidblue from './../../static/images/icon/sidebar/updated/solidblue.png'
import solidbrown from './../../static/images/icon/sidebar/updated/solidbrown.png'
import solidpurple from './../../static/images/icon/sidebar/updated/solidpurple.png'
import solidred from './../../static/images/icon/sidebar/updated/solidred.png'
import $ from 'jquery'
import Select from 'react-select';
import { changeVehiclefun } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { logoutOnExpiry , url } from './../../services/requests'
import { colourStyles } from '../../services/functions'

const Preview = (props) => {
    const dispatch = useDispatch()
    const sharedData = props.location.state.clusterArr.data
    const clusterArr = Array.isArray(sharedData) ? props.location.state.clusterArr.data : [props.location.state.clusterArr.data]
    const previewData = props.location.state.previewPayload
    const [completeData, setCompletData] = useState([])
    const [previewState, setPreviewState] = useState({})
    const [keyState, setKeyState] = useState('')
    const changedData = useSelector((state) => state.changeVehicleReducer)
    const [etaDetails, setEtaDetails] = useState([])
    const [indexes, setIndexes] = useState({
        innerIndex: "",
        outerIndex: ''
    })
    let colorCodes = ['#ff0054', '#191970', 'purple', 'brown', 'red']
    let markersArray = [solid, solidblue, solidbrown, solidpurple, solidred]
    const _moveBck = () => {
        history.goBack()
    }
    const openCluster = (outerInd, innerInd, key) => {
        setKeyState(key)
        if ($(".clusterList").hasClass('slideOut')) {
            $(".clusterList").removeClass('slideOut')
            $('.clusterList').addClass('slideIn')
        }
        else {
            $('.clusterList').addClass('slideOut')
            $(".clusterList").removeClass('slideIn')
        }
        let local = { ...indexes }
        local.outerIndex = outerInd
        local.innerIndex = innerInd
        setIndexes(local)
        toogleHubView(outerInd)
    }
    useEffect(() => {
        setCompletData(clusterArr)
        setPreviewState(previewData)
    }, [])
    useEffect(() => {
        if (completeData.length !== 0) {
            etaDetails.length = 0
            let local = [...completeData]

            let temp = [...etaDetails]
            for (let i = 0; i < local.length; i++) {
                temp.push({
                    locations: [],
                    vehicleNumber: local[i].vehicle.vehicleNo,
                    minTime: local[i].minimumSlot
                }
                )
                // let sec = local[i].minimumSlot * 60 * 60

                for (let j = 0; j < local[i].locations.length; j++) {

                    let duration = local && local[i] && local[i].locations[j] && local[i].locations[j].locationDetails && local[i].locations[j].locationDetails.eta ? local[i].locations[j].locationDetails.eta : null
                    let address = local && local[i] && local[i].locations[j] && local[i].locations[j].locationDetails && local[i].locations[j].locationDetails.address ? local[i].locations[j].locationDetails.address : '-'
                    let start = local && local[i] && local[i].locations[j] && local[i].locations[j].locationDetails && local[i].locations[j].locationDetails.startSlotTime ? local[i].locations[j].locationDetails.startSlotTime : '-'
                    let end = local && local[i] && local[i].locations[j] && local[i].locations[j].locationDetails && local[i].locations[j].locationDetails.endSlotTime ? local[i].locations[j].locationDetails.endSlotTime : '-'
                    let res = duration

                    // let minutes = Math.floor((res / (60)) % 60)
                    // let hours = Math.floor((res / (60 * 60)) % 24);
                    // hours = (hours < 10) ? "0" + hours : hours;
                    // minutes = (minutes < 10) ? "0" + minutes : minutes;
                    // let dum = `${hours} : ${minutes}`
                    // temp[i].locations.push({
                    //     address: address, 
                    //     startSlotTime: start,
                    //     endSlotTime: end,
                    //     eta: dum
                    // })

                    let finalTime = ""
                    let time = res / 60
                    if (time > 60) {
                        let t = time / 60
                        let minute = (t - Math.floor(t)) * 60
                        if (minute > 0) {
                            minute = 1
                        }
                        finalTime = finalTime + Math.floor(t) + " hours " + minute + " minutes"
                    }
                    else {
                        finalTime = finalTime + Math.trunc(time) + " minutes";
                    }  
                    temp[i].locations.push({
                        address: address,
                        startSlotTime: start,
                        endSlotTime: end,
                        eta: finalTime
                        // eta: start + ' ' + ' hours' + ' : ' + finalTime 
                    })


                }
                setEtaDetails(temp)


            }


        }
    }, [completeData])

    useEffect(() => {
        if (completeData) {
            let sortedLatLng = completeData
            let coords = []
            var markers = [];
            var mapOptions = {
                center: new google.maps.LatLng(28.5503314, 77.2501893),
                zoom: 7
            }
            var map = new google.maps.Map(myMap, mapOptions)
            var marker, startMarker;
            var infowindow = new google.maps.InfoWindow({ map: map });
            const directionsService = new google.maps.DirectionsService();
            for (let i = 0; i < sortedLatLng.length; i++) {
                let waypoints = [];
                let dummy = sortedLatLng[i]
                let temp = []
                let char = 'A'
                var currentCode = 0;
                dummy.locations.forEach(ele => {
                    temp.push({
                        lat: ele && ele.location && ele.location[1] ? ele.location[1] : '',
                        lng: ele && ele.location && ele.location[0] ? ele.location[0] : '',
                        address: ele.locationDetails && ele.locationDetails.address ? ele.locationDetails.address : '',
                        company: ele.locationDetails && ele.locationDetails.company ? ele.locationDetails.company : '',
                        mobile:  'mobile' in ele.locationDetails ? ele.locationDetails && ele.locationDetails.mobile ? ele.locationDetails.mobile : '' : '',
                        username: 'personName' in ele.locationDetails ? ele.locationDetails && ele.locationDetails.personName ? ele.locationDetails.personName : '' :'',
                        userId: ele.locationDetails && ele.locationDetails.userId ? ele.locationDetails.userId : '',
                        id: ele.locationDetails && ele.locationDetails.id ? ele.locationDetails.id : '',
                        label: String.fromCharCode(char.charCodeAt(0) + currentCode),
                        turfArr: [ele && ele.location && ele.location[0] ? ele.location[0] : '', ele && ele.location && ele.location[1] ? ele.location[1] : '']
                    })
                    currentCode++;
                })
                coords.push(temp)
                let start = { lat: coords[i][0].lat, lng: coords[i][0].lng };
                let end = {
                    lat: coords[i][coords[i].length - 1].lat,
                    lng: coords[i][coords[i].length - 1].lng,
                };

                for (let j = 1; j < coords[i].length - 1; j++) {
                    waypoints.push({
                        location: { lat: coords[i][j].lat, lng: coords[i][j].lng },
                        stopover: true,
                    });

                    let request = {
                        origin: start,
                        waypoints: waypoints,
                        destination: end, 
                        travelMode: "DRIVING",
                    };
                    directionsService.route(request, function (result, status) {
                        if (status == "OK") {
                            var directionsDisplay = new google.maps.DirectionsRenderer()
                            directionsDisplay.setMap(map)
                            directionsDisplay.setOptions({ suppressMarkers: true, polylineOptions: { strokeColor: colorCodes[i] } });
                            directionsDisplay.setDirections(result);
                        
                        }
                    });
                    // var marker;
                    if (i == 0) {
                        startMarker = new google.maps.Marker({
                            position: new google.maps.LatLng(coords[i][0].lat, coords[i][0].lng),
                            map: map,
                            icon: {
                                url: coords[i][0].id === '' ? solid : solid,
                                scaledSize: new google.maps.Size(40, 40),
                                labelOrigin: new google.maps.Point(20, 14)
                            },
                            index: i,
                            label: {
                                text: coords[i][0].label,
                                color: "#fff",
                                fontSize: "12px",
                                fontWeight: '400',
                            }
                        });
                    }
                    else if (i == coords[i].length - 1) {

                        startMarker = new google.maps.Marker({
                            position: new google.maps.LatLng(coords[i][coords[i].length - 1].lat, coords[i][coords[i].length - 1].lng),
                            map: map,
                            icon: {
                                url: coords[i][coords[i].length - 1].id === '' ? solid : solid,
                                scaledSize: new google.maps.Size(40, 40),
                                labelOrigin: new google.maps.Point(20, 14)
                            },
                            index: i,
                            label: {
                                text: coords[i][0].label,
                                color: "#fff",
                                fontSize: "12px",
                                fontWeight: '800',
                            }
                        });
                    }
                    if (j != 0) {
                        if (coords[i][j].lat === coords[i][j + 1].lat && coords[i][j].lng === coords[i][j + 1].lng) {
                            marker = new google.maps.Marker({
                                position: new google.maps.LatLng(coords[i][j].lat, coords[i][j].lng),
                                map: map,
                                icon: {
                                    url: markersArray[i],
                                    scaledSize: new google.maps.Size(57, 57),
                                    labelOrigin: new google.maps.Point(28, 14)
                                },
                                index: i,
                                label: {
                                    text: coords[i][j].label,
                                    color: "#fff",
                                    fontSize: "12px",
                                    fontWeight: '800',
                                }
                            });
                        }
                        else {
                            marker = new google.maps.Marker({
                                position: new google.maps.LatLng(coords[i][j].lat, coords[i][j].lng),
                                map: map,
                                icon: {
                                    url: coords[i][j].id === '' ? markersArray[i] : markersArray[i],
                                    scaledSize: new google.maps.Size(40, 40),
                                    labelOrigin: new google.maps.Point(20, 15)
                                },
                                index: i,
                                label: {
                                    text: coords[i][j].label,
                                    color: "#fff",
                                    fontSize: "12px",
                                    fontWeight: '800',
                                }
                            });
                        }

                    }

                    var data = coords[i][j];
                    var startMarkerData = coords[i][0]
                    google.maps.event.addListener(marker, 'click', (function (data, marker, outerInd, innerInd) {
                        return function () {
                            if (document.querySelector('.infoWindow')) {
                                handlePopupAndDropdownClose()
                            }
                            infowindow.setContent('<div id="content" class="infoWindow" >' +
                                '<div id="siteNotice">' +
                                "</div>" +
                                '<h5 id="firstHeading" class="firstHeading">Details</h5>' +
                                '<div id="bodyContent">' +
                                `<p id="infoSpan">${data.address}</p>` +
                                "</div>" +
                                '<div id="bodyContent">' +
                                `<p id="infoSpan">${data.username} , ${data.mobile}</p>` +
                                "</div>" +
                                '<div id="bodyContent">' +
                                "</div>" +
                                `${coords.length !== 1 ?
                                    '<button class="clusterBtn" id="clusterBtn" >Change Vehicle</button>'
                                    : ''}`
                                +
                                `<button class="removeBtnFun" id="ltnRemoveBtn" >Remove </button>` +
                                "</div>");
                            infowindow.open(map, marker);
                            // for (var j = 0; j < markers.length; j++) {
                            //     markers[j].setIcon({
                            //         url: solid,
                            //         scaledSize: new google.maps.Size(35, 35),
                            //         labelOrigin: new google.maps.Point(17, 13)

                            //     });
                            // }
                            // marker.setIcon({
                            //     url: blackPin,
                            //     scaledSize: new google.maps.Size(28, 28),
                            //     labelOrigin: new google.maps.Point(47, 43),
                            //     fillOpacity: 1,
                            //     scale: 2,
                            //     strokeWeight: 10
                            // })
                            google.maps.event.addListener(infowindow, 'closeclick', () => {
                                handlePopupAndDropdownClose()
                            })
                            setTimeout(() => {
                                let elements = document.getElementsByClassName('clusterBtn')
                                for (var i = 0; i < elements.length; i++) {
                                    elements[i].addEventListener('click', changeCluster, false);
                                }
                            }, 300)
                            setTimeout(() => {
                                let elements = document.getElementsByClassName('removeBtnFun')
                                for (var i = 0; i < elements.length; i++) {
                                    elements[i].addEventListener('click', removeLtn, false);
                                }
                            }, 300)
                            function removeLtn() {
                                removeLocation(outerInd, innerInd, 'change')
                            }
                            function changeCluster() {
                                openCluster(outerInd, innerInd, 'change')
                            }
                        }

                    })(data, marker, i, j));

                    google.maps.event.addListener(startMarker, 'click', (function (data, startMarkerData, marker, outerInd, innerInd) {
                        return function () {
                            if (document.querySelector('.infoWindow')) {
                                handlePopupAndDropdownClose()
                            }
                            infowindow.setContent('<div id="content" class="infoWindow" >' +
                                '<div id="siteNotice">' +
                                "</div>" +
                                '<h5 id="firstHeading" class="firstHeading">Details</h5>' +
                                '<div id="bodyContent">' +
                                `<p id="infoSpan">${startMarkerData.address}</p>` +
                                "</div>" +
                                '<div id="bodyContent">' +
                                `<p id="infoSpan">${startMarkerData.username}  ${startMarkerData.mobile}</p>` +
                                "</div>" +
                                '<div id="bodyContent">' +
                                "</div>" +
                                // '<button class="clusterBtn" id="clusterBtn" >Change Vehicle</button>' +
                                // `${data.userId === "" ? "" : `<button class="removeBtnFun" id="ltnRemoveBtn" >Remove </button>`}` +
                                "</div>");
                            infowindow.open(map, marker);
                            // for (var j = 0; j < markers.length; j++) {
                            //     markers[j].setIcon({
                            //         url: solid,
                            //         scaledSize: new google.maps.Size(35, 35),
                            //         labelOrigin: new google.maps.Point(17, 13)

                            //     });
                            // }
                            // marker.setIcon({
                            //     url: blackPin,
                            //     scaledSize: new google.maps.Size(28, 28),
                            //     labelOrigin: new google.maps.Point(47, 43),
                            //     fillOpacity: 1,
                            //     scale: 2,
                            //     strokeWeight: 10
                            // })
                            google.maps.event.addListener(infowindow, 'closeclick', () => {
                                handlePopupAndDropdownClose()
                            })
                            setTimeout(() => {
                                let elements = document.getElementsByClassName('clusterBtn')
                                for (var i = 0; i < elements.length; i++) {
                                    elements[i].addEventListener('click', changeCluster, false);
                                }
                            }, 300)
                            setTimeout(() => {
                                let elements = document.getElementsByClassName('removeBtnFun')
                                for (var i = 0; i < elements.length; i++) {
                                    elements[i].addEventListener('click', removeLtn, false);
                                }
                            }, 300)
                            function removeLtn() {
                                removeLocation(data)
                            }
                            function changeCluster() {
                                openCluster(outerInd, innerInd)
                            }
                        }

                    })(data, startMarkerData, startMarker, i, j));

                    markers.push(marker);
                }
            }
        }
    }, [completeData])

    const toogleHubView = (index) => {
        if ($(".collapse-view").eq(index).hasClass("open")) {
            $(".collapse-view").eq(index).removeClass("open");
            $(".collapse-view").eq(index).animate({ height: 35 }, 500);
        } else {
            let height =
                Number($(".collapse-view").eq(index).children("table")[0].clientHeight);
            $(".collapse-view")
                .eq(index)
                .animate({ height }, 500, () => {
                    $(".collapse-view").eq(index).addClass("open");
                });
        }
    }
    
    const options = clusterArr && clusterArr.map(function (item, index) {
        return { label: index + 1, value: index + 1, isDisabled: indexes.outerIndex === index ? true : false }
    })
    const handleChange = (item, index, innerIndex, val) => {
        let local = [...completeData]
        local[val.value - 1].locations.push(item)
        local[index].locations.splice(innerIndex, 1)
        dispatch(changeVehiclefun(local))
    }
    const removeLocation = (outerInd, innerInd, key) => {
        setKeyState(key)
        let local = [...completeData]
        local[outerInd].locations.splice(innerInd, 1)
        setCompletData(local)
        // dispatch(changeVehiclefun(local))
    }
    useEffect(() => {
        if (changedData.changedCluster.length !== 0) {
            $('.clusterList').addClass('slideOut')
            $(".clusterList").removeClass('slideIn')
            $('.positionsElem').addClass('slide-out')
            $(".positionsElem").removeClass('slide-in')
            setCompletData(changedData.changedCluster)
        }
    }, [changedData])

    const toggleView = () => {
        if ($(".positionsElem").hasClass('slide-out')) {
            $(".positionsElem").removeClass('slide-out')
            $('.positionsElem').addClass('slide-in')
        }
        else {
            $('.positionsElem').addClass('slide-out')
            $(".positionsElem").removeClass('slide-in')
        }
    }
    const closeSideBar = () => {
        $(".positionsElem").removeClass('slide-in')
        $('.positionsElem').addClass('slide-out')
    }
    const toggleEtaElem = (index) => {
        if ($(".newcollapse-view").eq(index).hasClass("open")) {
            $(".newcollapse-view").eq(index).removeClass("open");
            $(".newcollapse-view").eq(index).animate({ height: 35 }, 500);
        } else {
            let height =
                Number($(".newcollapse-view").eq(index).children("table")[0].clientHeight);
            $(".newcollapse-view")
                .eq(index)
                .animate({ height }, 500, () => {
                    $(".newcollapse-view").eq(index).addClass("open");
                });
        }
    }
    // ***************POLYLINE VIEW*****************
    // useEffect(() => {
    //     if (completeData) {
    //         let sortedLatLng = completeData
    //         let coords = []
    //         // var markers = [];
    //         var mapDiv = document.getElementById("myMap")
    //         var mapOptions = {
    //             center: new google.maps.LatLng(28.5503314, 77.2501893),
    //             zoom: 12,
    //             mapTypeId: google.maps.MapTypeId.ROADMAP
    //         }
    //         var map = new google.maps.Map(mapDiv, mapOptions)
    //         var infowindow = new google.maps.InfoWindow({ map: map });

    //         for (let i = 0; i < sortedLatLng.length; i++) {
    //             let dummy = sortedLatLng[i]
    //             let temp = []
    //             dummy.locations.forEach(ele => {
    //                 temp.push({
    //                     lat: ele.location[1] ? ele.location[1] : '',
    //                     lng: ele.location[0] ? ele.location[0] : '',
    //                     address: ele.locationDetails && ele.locationDetails.address ? ele.locationDetails.address : '',
    //                     company: ele.locationDetails && ele.locationDetails.company ? ele.locationDetails.company : '',
    //                     mobile: ele.locationDetails && ele.locationDetails.mobile ? ele.locationDetails.mobile : '',
    //                     username: ele.locationDetails && ele.locationDetails.userName ? ele.locationDetails.userName : '',
    //                     userId: ele.locationDetails && ele.locationDetails.userId ? ele.locationDetails.userId : '',
    //                     id: ele.locationDetails && ele.locationDetails.id ? ele.locationDetails.id : '',
    //                 })
    //             })
    //             coords.push(temp)
    //             for (let j = 0; j < coords[i].length; j++) {
    //                 const flightPath = new google.maps.Polyline({
    //                     path: [coords[i][j], coords[i][j + 1]],
    //                     geodesic: true,
    //                     strokeColor: colorCodes[i],
    //                     strokeOpacity: 1.0,
    //                     strokeWeight: 2,
    //                 });
    //                 flightPath.setMap(map);
    //                 var marker;
    //                 marker = new google.maps.Marker({
    //                     position: new google.maps.LatLng(coords[i][j].lat, coords[i][j].lng),
    //                     map: map,
    //                     icon: coords[i][j].id === '' ? gps : customMarker,
    //                     index: i,
    //                 });
    //                 var data = coords[i][j];
    //                 google.maps.event.addListener(marker, 'click', (function (data, marker, outer, inner) {
    //                     return function () {
    //                         setOuterIndex(outer)
    //                         setInnerIndex(inner)
    //                         infowindow.setContent('<div id="content">' +
    //                             '<div id="siteNotice">' +
    //                             "</div>" +
    //                             '<h5 id="firstHeading" class="firstHeading">Details</h5>' +
    //                             '<div id="bodyContent">' +
    //                             `<p id="infoSpan">${data.address}</p>` +
    //                             "</div>" +
    //                             '<div id="bodyContent">' +
    //                             `<p id="infoSpan">${data.company} , ${data.mobile}</p>` +
    //                             "</div>" +
    //                             '<div id="bodyContent">' +
    //                             "</div>" +
    //                             '<button class="clusterBtn" id="clusterBtn" >Change Vehicle</button>' +
    //                             `${data.userId === "" ? "" : `<button class="removeBtnFun" id="ltnRemoveBtn" >Remove </button>`}` +
    //                             "</div>");
    //                         infowindow.open(map, marker);
    //                         setTimeout(() => {
    //                             let elements = document.getElementsByClassName('clusterBtn')
    //                             for (var i = 0; i < elements.length; i++) {
    //                                 elements[i].addEventListener('click', changeCluster, false);
    //                             }
    //                         }, 300)
    //                         setTimeout(() => {
    //                             let elements = document.getElementsByClassName('removeBtnFun')
    //                             for (var i = 0; i < elements.length; i++) {
    //                                 elements[i].addEventListener('click', removeLtn, false);
    //                             }
    //                         }, 300)
    //                         function removeLtn() {
    //                             removeLocation(coords[i], coords[i][j])
    //                         }
    //                         function changeCluster() {
    //                             openCluster()
    //                         }
    //                     }

    //                 })(data, marker, i, j));

    //             }
    //         }

    //     }
    // }, [completeData])

    // useEffect(() => {
    //     setTimeout(()=>{
    //         toogleHubView(outerIndex)
    //     },500)
    // }, [outerIndex])

    const saveTrip = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post(`${url}/trip/saveTrip`, { data: completeData }, { headers: config.headers })
            .then(response => {
                let message = response.data.message
                let status = response.data.success
                if (status === true) {
                    history.push('/dashboard')
                    window.$.fn.show_notification({ message: `${message}`, title: 'Success!!',  autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }



    return (
        <>
            <div className="contentpanel">
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-5'>
                        <h1 className="title">
                            <button className='button bg-orange mr-2 wd10 flex-inline backBtn' onClick={_moveBck}>
                                <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                            </button>
                            Preview Map
                        </h1>
                    </div>
                </div>
                <hr className='bgg' />
                <div className="cust-container pad-0">
                    <div className='mapHeader'>
                        <div className="cust-row flex-algn-cent">
                            <div className='mapHeader' style={{ justifyContent: 'space-between' }}>
                                <div className='searcBoxCenter'>
                                    <button className='myMapOrange' style={{ width: '20%' }} disabled={completeData[0] && completeData[0]?.locations?.length === 2 ? true : false} onClick={() => saveTrip()}>Save Trip </button>
                                </div>
                                <div className='wd60'>
                                    <button className='myMapBtnGrey' style={{ width: ' 30%' }} disabled={completeData[0] && completeData[0]?.locations?.length === 2 ? true : false} onClick={() => toggleView()}>List View </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mapOuter'>
                        <div className='positionsElem slide-out'>
                            <div className='postHeader postFixed'>
                                <div className='wd90' style={{ display: 'flex', justifyContent: 'center', width: '95%' }}>
                                    <i className="fa fa-truck truckFa"></i>
                                    <span className='postDetails' style={{ paddingLeft: '26px' }}>Estimated Time Of Arrival (ETA)
                                    </span>
                                </div>
                                <div className='wd10' style={{ width: '5%' }} onClick={closeSideBar}>
                                    {/* <i className="fa fa-angle-left leftOpenArr" ></i> */}
                                    <i className="fa fa-times-circle leftOpenArr"></i>
                                </div>
                            </div>

                            <div className='overflowDiv' style={{ position: 'relative', top: '13px' }}>
                                {etaDetails && etaDetails.map((item, index) => {
                                    return (
                                        <div key={index} className="table-responsive newcollapse-view" style={{ margin: '0px', marginBottom: '20px' }}>
                                            <div className="collapsable_icon" onClick={() => { toggleEtaElem(index) }}></div>
                                            <table className='cctbl popUpTable' style={{ background: colorCodes[index] }}>

                                                <thead>
                                                    <tr className="bg_black" style={{ background: colorCodes[index] }}>
                                                        <th colSpan="2">Vehicle No - {item.vehicleNumber} </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={{ background: colorCodes[index] }}>
                                                        <td colSpan={2} style={{ background: colorCodes[index] }}>
                                                            <div className="dd_sec orange" style={{ background: '#fff', padding: '10px', margin: '0px' }}>
                                                                <div>
                                                                    <div className="btblock" style={{ margin: '0px', display: 'flex', flexDirection: 'column' }}>
                                                                        <div className='tripLists' style={{ marginBottom: '0px', borderRadius: '3px', padding: '0px', boxShadow: 'none' }}>
                                                                            <div className='timeHeadings' style={{ background: '#303030' }}>
                                                                                <div className='timeHead1' style={{ borderRight: '1px solid white' }}>
                                                                                    <span className='timeHeadspanNew' >Address</span>
                                                                                </div>
                                                                                <div className='timeHead2' style={{ borderRight: '1px solid white' }}>
                                                                                    <span className='timeHeadspanNew' style={{ paddingLeft: '7px' }} >Preferred Slot</span>
                                                                                </div>
                                                                                <div className='timeHead3'>
                                                                                    <span className='timeHeadspanNew' >ETA</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {item && item.locations && item.locations.map((data, ind) => {
                                                                            return (
                                                                                <div key={ind} className='tripResponse' style={{ borderRadius: '3px', padding: '0px' }}>
                                                                                    <div className='timeHeadings'>
                                                                                        <div className='timeHead1'>
                                                                                            <span className='timeHeadspan'>
                                                                                                {ind === 0 ? 'Start' : data.address}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='timeHead2'>
                                                                                            <span className='timeHeadspan'>
                                                                                                {data.startSlotTime !== '-' && data.endSlotTime !== '-' ?

                                                                                                    data && data.startSlotTime ? data.startSlotTime + ':00 ' + '- ' : ''
                                                                                                    :
                                                                                                    ''
                                                                                                }

                                                                                                {data.endSlotTime !== '-' && data.endSlotTime !== '-' ?
                                                                                                    data && data.endSlotTime ? data.endSlotTime + ':00' : ''
                                                                                                    :
                                                                                                    '-'
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='timeHead3'>
                                                                                            <span className='timeHeadspan'>
                                                                                                {data.startSlotTime !== '-' && data.endSlotTime !== '-' ?
                                                                                                    data.eta : '-'


                                                                                                }

                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div id="myMap" style={{height:'100vh'}}>
                        </div>
                        <div className='clusterList slideOut'>
                            <div className='clusterFixed'>
                                <span>Vehicles</span>
                                <div className='wd10 clusterClose' >
                                    <i onClick={openCluster} className="fa fa-times-circle circleClose"></i>
                                </div>
                            </div>
                            <div className='overflowDiv'>
                                {completeData && completeData.map((item, index) => {
                                    return (
                                        <div key={index} className="table-responsive collapse-view" style={{ margin: '0px', marginBottom: '20px' }}>
                                            <div className="collapsable_icon" onClick={() => { toogleHubView(index) }}></div>
                                            <table className='cctbl popUpTable' style={{ background: colorCodes[index] }}>

                                                <thead>
                                                    <tr className="bg_black" style={{ background: colorCodes[index] }}>
                                                        <th colSpan="2">Vehicle No - {item.vehicle && item.vehicle.vehicleNo ? item.vehicle.vehicleNo : ''} </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={{ background: colorCodes[index] }}>
                                                        <td colSpan={2} style={{ background: colorCodes[index] }}>
                                                            <div className="dd_sec orange" style={{ background: '#fff', padding: '10px', margin: '0px' }}>
                                                                <div>
                                                                    <div className="btblock" style={{ margin: '0px', display: 'flex', flexDirection: 'column' }}>
                                                                        {item && item.locations.map((innerItem, innerIndex) => {
                                                                            return (
                                                                                <div key={innerIndex} className={indexes.innerIndex === innerIndex ? 'activeTripLists' : 'tripLists'}>
                                                                                    <div className='tripDetails'>
                                                                                        <span className={innerIndex === 0 ? 'startSpan' : 'otherspan' && indexes.innerIndex === innerIndex ? 'activespan' : 'otherspan'}>{innerIndex === 0 ? 'Start Point' : innerItem && innerItem.locationDetails && innerItem.locationDetails.address ? innerItem.locationDetails.address : ''}</span>
                                                                                    </div>
                                                                                    <div className='tripDetails1'>
                                                                                        <Select
                                                                                            onChange={(value) => handleChange(innerItem, index, innerIndex, value)}
                                                                                            options={options}
                                                                                            styles={colourStyles}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {changedData.loading === true ?
                <div className='overlayModal' style={{ zIndex: '999999999' }}>
                    <div className='overlayContent'>
                        <span className='routePreview'>Re-routing.....</span>
                        <i className="fa fa-spinner fa-spin loadingSpinner"></i>
                    </div>
                </div>
                : ''
            }
        </>
    );
};

export default Preview;


function handlePopupAndDropdownClose() {
    //remove collapse from all dropdowns
    const collapseContainer = document.querySelector('.overflowDiv').children;
    for (let i = 0; i < collapseContainer.length; i++) {
        collapseContainer[i].classList.remove("open");
        collapseContainer[i].style.height = '35px';
    }

    //close pop up
    $('.clusterList').addClass('slideOut')
    $(".clusterList").removeClass('slideIn')
}