import { GET_SOLUTIONS , SOL_SHOW , SOL_HIDE } from "../actions/types";

const initialState={
    solsListing : [],  
    loading : ''
} 

const getSolRed =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_SOLUTIONS : return{
            ...state,
            solsListing : payload
        }
        case SOL_SHOW : return{
            ...state,
            loading : true
        }
        case SOL_HIDE : return{
            ...state,
            loading : false
        }
        default: return state;  
    }
}
export default getSolRed ;