/* eslint-disable */
import { selectInit } from './../../../services/functions'
import { useEffect, useState } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import $ from 'jquery'

// eslint-disable-next-line no-undef

const Geofence = (props) => {
  const mid = { lat: props.geo.lat, lng: props.geo.lng }
  const cordinates = props.geo && props.geo.geo && props.geo.geo.geofence[0] && props.geo.geo.geofence[0].geofence.coordinates ? props.geo.geo.geofence[0].geofence.coordinates : ''

  useEffect(() => {
    var objs = cordinates && cordinates.map(x => ({
      lat: x[1],
      lng: x[0]
    }));
    var lat = objs && objs[1].lat ? parseFloat(objs[1].lat)  : ''
    var lng = objs && objs[1].lng ? parseFloat(objs[1].lng)  : ''
    const map = new google.maps.Map(
      document.getElementById("mapDimensions"),
      {
        zoom: 17,
        center: { lat: +lat, lng: +lng },
        mapTypeId: "terrain",
      }
    );

    const geofence = new google.maps.Polygon({
      paths: objs,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: "#FF0000",
      fillOpacity: 0.34,
    });

    var bounds = new google.maps.LatLngBounds();
    var marker;
    for (var i = 0; i < objs.length; i++) {
      bounds.extend(objs[i]);
    }

    marker = new google.maps.Marker({
      
      position: new google.maps.LatLng(bounds.getCenter().lat(), bounds.getCenter().lng()),
      map: map,
      // icon: customMarker2
    });
    geofence.setMap(map);
  }, [cordinates, mid])


  return (
    <section id="GeofenceModal" className="custom-modal toggle_width" style={{ width: "60%" }}>
      <div className="custom-modal-header">
        <h1>Geofence</h1>
        <span className="ct-close" onClick={props.closeModal}></span>
      </div>
      <div className="custom-modal-body" style={{ maxHeight: '70vh', height: '70vh' }} >
        <div className='mapOuter' style={{ height: '100%' }}>

          <div id='mapDimensions'>

          </div>
        </div>
      </div>
    </section>
  )
}

export default Geofence;