/* eslint-disable */

import { useEffect, useState } from 'react'



const LocationPlot = (props) => {
    const lat = props && props.geo ? props.geo.lat  : ''
    const lng = props && props.geo ? props.geo.lng : ''
    const mid = { lat: lat, lng: lng }

    useEffect(() => {
        let lat = mid && mid.lat ? mid.lat : ''
        let lng = mid && mid.lng ? mid.lng : ''
        const map = new google.maps.Map(document.getElementById("mapDimensions"), {
            zoom: 17,
            center: {lat : +lat , lng : +lng},
        });

        new google.maps.Marker({
            position: {lat : +lat , lng : +lng},
            map,
            title: "",
        });
        var circle = new google.maps.Circle({
            map: map,
            radius: 100,   
            strokeOpacity: 0.8,
            strokeWeight: 0.7,
            fillColor: "#add8e6",
            fillOpacity: 0.45,
            zIndex: 1,
            setDraggable: false
        });
        var dummyMarker = new google.maps.Marker({
            position: new google.maps.LatLng(mid.lat, mid.lng),
            visible: false,
            map: map,
        });
        circle.bindTo('center', dummyMarker, 'position');
  }, [mid])

  const _closeModal = () => {
    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
    $(".custom-modal").removeClass("custom-modal-is-visible")
  }


return (
    <section id="LocationPlot" className="custom-modal toggle_width" style={{ width: "60%" }}>
        <div className="custom-modal-header">
            <h1>Location</h1>
            <span className="ct-close" onClick={_closeModal}></span>
        </div>
        <div className="custom-modal-body" style={{ maxHeight: '70vh', height: '70vh' }} >
            <div className='mapOuter' style={{ height: '100%' }}>

                <div id='mapDimensions'>

                </div>
            </div>
        </div>
    </section>
)
}

export default LocationPlot;