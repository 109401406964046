import React, { useState } from 'react';
import axios from 'axios';
import $ from 'jquery'
import history from './../../../services/history'
import crypto from 'crypto-js';
import { logoutOnExpiry , url } from './../../../services/requests'

const ChangePassword = (props) => {
    const [oldEye, setOldEye] = useState('password')
    const [newEye, setNewEye] = useState('password')
    const [confirmEye, setConfrimEye] = useState('password')
    const [changePass, setChangePass] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        time: ""
    })
    const handleChange = (e) => {
        setChangePass({ ...changePass, [e.target.name]: e.target.value })
    }
    const otpTogglePassType = () => {
        if (oldEye === 'password') {
            setOldEye('text')
        }
        else {
            setOldEye('password')
        }
    }
    const otpNewPassType = () => {
        if (newEye === 'password') {
            setNewEye('text')
        }
        else {
            setNewEye('password')
        }
    }
    const otpConfirmPassType = () => {
        if (confirmEye === 'password') {
            setConfrimEye('text')
        }
        else {
            setConfrimEye('password')
        }
    }
    const changePassword = () => {

        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        if (changePass.oldPassword === '') {
            window.$.fn.show_notification({ message: 'Enter old Password', title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if (changePass.newPassword === '') {
            window.$.fn.show_notification({ message: 'Enter New Password', title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if (changePass.confirmPassword === '') {
            window.$.fn.show_notification({ message: 'Enter Confirm Password', title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else {
            if (changePass.newPassword.match(/[a-z]/g) && changePass.newPassword.length >= 8 && changePass.newPassword.match(/[A-Z]/g) && changePass.newPassword.match(/[0-9]/g) && changePass.newPassword.match(/[!@#$%^&*]/g)) {
                if (changePass.newPassword === changePass.confirmPassword) {
                    let dateTime = new Date().getTime();
                    let encryptedBase64Key = `ven/hrtvdi+6442+G23${dateTime}`;
                    let parsedBase64Key = crypto.enc.Base64.parse(encryptedBase64Key);
                    let encryptedSharingLink1 = crypto.AES.encrypt(`${changePass.oldPassword}`, parsedBase64Key, {
                        mode: crypto.mode.ECB,
                        padding: crypto.pad.Pkcs7
                    });
                    let encryptedSharingLink2 = crypto.AES.encrypt(`${changePass.newPassword}`, parsedBase64Key, {
                        mode: crypto.mode.ECB,
                        padding: crypto.pad.Pkcs7
                    });
                    let temp = { 
                        oldPassword : '',
                        newPassword : '',
                        time : ''
                     }
                    temp.oldPassword = encryptedSharingLink1.toString();
                    temp.newPassword = encryptedSharingLink2.toString();
                    temp.time = dateTime
                    axios.post(`${url}/user/changePassword`, { ...temp }, { headers: config.headers })
                        .then(response => {
                            let status = response.data.success
                            let mssg = response.data.message
                            if (status === true) {
                                setChangePass(
                                    {
                                        oldPassword: "",
                                        newPassword: "",
                                        confirmPassword: ""
                                    }
                                )
                                $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                                $(".custom-modal").removeClass("custom-modal-is-visible")
                                window.$.fn.show_notification({ message: 'Password changed successfully.', title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                                    if (result.type === 'confirm') {
                                    }
                                })
                                localStorage.removeItem('secretkey')
                                localStorage.removeItem('token')
                                localStorage.removeItem('userType')
                                history.push("/")
                            }
                            else {
                                window.$.fn.show_notification({ message: `${mssg}`, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                                    if (result.type === 'confirm') {
                                    }
                                })
                            }
                        })
                        .catch(error => {
                            console.log('err',error)
                            if (error.error.status === 401) {
                                logoutOnExpiry()
                            }
                        })
                }
                else {
                    window.$.fn.show_notification({ message: 'Password does not match..', title: 'Alert!!',  autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            }
            else {
                window.$.fn.show_notification({ message: 'New Password should contain min 8 letters with 1 lower case , 1 upper case , 1 number and 1 special character.', title: 'Alert!!',  autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }

        }
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
        setChangePass({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            time: ""
        })
    }
    return (
        <section id="changePasswordModal" className="custom-modal" style={{ width: "40%" }}>
            <div className="custom-modal-header">
                <h1>Change Password</h1>
                <span className="ct-close" onClick={_closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '80vh' }}>
                <div className="cust-row mb-2">
                    <div className="cust-col-10">
                        <label className="black_color font-bold text-left wdt100">Old Password</label>
                        <input className="cust-input" type='text' name="oldPassword" value={changePass.oldPassword} onChange={(e) => handleChange(e)} placeholder="Enter Old Password" />
                        {/* {oldEye === 'password' ?
                            <i className="fa fa-eye-slash iconEye otpEyes" onClick={otpTogglePassType}></i>
                            :
                            <i onClick={otpTogglePassType} className="fa fa-eye iconEye otpEyes" ></i>
                        } */}
                    </div>
                </div>
                <div className="cust-row mb-2">
                    <div className="cust-col-10">
                        <label className="black_color font-bold text-left wdt100">New Password</label>
                        <input className="cust-input" type={newEye} name="newPassword" value={changePass.newPassword} onChange={(e) => handleChange(e)} placeholder="Enter New Password" />
                        {newEye === 'password' ?
                            <i className="fa fa-eye-slash iconEye otpEyes" onClick={otpNewPassType}></i>
                            :
                            <i onClick={otpNewPassType} className="fa fa-eye iconEye otpEyes" ></i>
                        }
                    </div>
                </div>
                <div className="cust-row mb-2">
                    <div className="cust-col-10">
                        <label className="black_color font-bold text-left wdt100">Confirm Password</label>
                        <input className="cust-input" type={confirmEye} name="confirmPassword" value={changePass.confirmPassword} onChange={(e) => handleChange(e)} placeholder="Re-enter New Password" />
                        {confirmEye === 'password' ?
                            <i className="fa fa-eye-slash iconEye otpEyes" onClick={otpConfirmPassType}></i>
                            :
                            <i onClick={otpConfirmPassType} className="fa fa-eye iconEye otpEyes" ></i>
                        }
                    </div>
                </div>
                <div className="cust-row">
                    <div className="cust-col-3">

                    </div>
                    <div className="cust-col-5">
                        <button className='fBtn' onClick={changePassword}>Change Password</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ChangePassword