import { AREA_LIST , AREA_SHOW , AREA_HIDE , AREA_MSSG } from './types'
import { post, logoutOnExpiry } from './../../../services/requests'

export const getAreasList = (object) => {
        return (dispatch) => {
            dispatch(showLoader())
            post('user/getAreaIds', {...object })
                .then(response => {
                    const areaList = response.data
                    const status = response.success
                    const message = response && response.message ? response.message : ''
                    if(status === true && areaList !== undefined){
                        dispatch(getAreaList(areaList))
                        dispatch(hideLoader())
                        dispatch(showMessage(''))
                    }
                    else{
                        dispatch(getAreaList())
                        dispatch(hideLoader())
                        dispatch(showMessage(message))
                    }
                    
                })
                .catch(error => {
                    dispatch(hideLoader())
                    dispatch(showMessage('Some Error Occured.'))
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        } 
    }


export const getAreaList = (areaList) => {
    return {
        type: AREA_LIST,
        payload: areaList
    }
}
export const showLoader = () =>{
    return{
        type : AREA_SHOW,
    }
}
export const hideLoader = () =>{
    return{
        type : AREA_HIDE,
    }
}
export const showMessage = (message) =>{
    return{
        type : AREA_MSSG,
        payload : message
    }
}