import { SETTINGS, SETTINGS_SHOW, SETTINGS_HIDE, SETTINGS_MSSG } from './types'
import { post , logoutOnExpiry } from './../../../services/requests'

export const getSettings = () => {
    return (dispatch) => {
        dispatch(showLoader())
        post('setting/getSetting', {})
            .then(response => {
                const settingsList = response.data
                const status = response.success
                const message = response && response.message ? response.message : ''
                if (status === true && settingsList !== undefined) {
                    dispatch(getSetList(settingsList))
                    dispatch(hideLoader())
                    dispatch(settingMessage(''))
                }
                else{
                    dispatch(getSetList())
                    dispatch(hideLoader())
                    dispatch(settingMessage(message))
                }
            })
            .catch(error => {
                dispatch(hideLoader())
                dispatch(settingMessage('Some Error Occured.'))
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
                console.log('error', error);
            })
    }
}


export const getSetList = (settingsList) => {
    return {
        type: SETTINGS,
        payload: settingsList
    }
}
export const showLoader = () =>{
    return{
        type : SETTINGS_SHOW,
    }
}
export const hideLoader = () =>{
    return{
        type : SETTINGS_HIDE,
    }
}
export const settingMessage = (message) =>{
    return{
        type : SETTINGS_MSSG,
        payload: message
    }
}