import { selectInit } from './../../../services/functions'
import { useEffect, useState } from 'react'
import $ from 'jquery'
import { getAddress } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { getSlotList } from './../../../state/redux/actions/index';
import Select from 'react-select';
import { logoutOnExpiry, url } from './../../../services/requests'
import { colourStyles } from './../../../services/functions'

const AddSlot = (props) => {
    const dispatch = useDispatch()
    const sharedData = props && props.data && props.data.detail ? props.data.detail : ''
    const status = props && props.data && props.data.update ? props.data.update : ''
    const [warningstate, setWarningState] = useState(null)
    const pageNumber = props && props.data && props.data.pagination && props.data.pagination.pageNumber ? props.data.pagination.pageNumber : ''
    const entries = props && props.data && props.data.pagination && props.data.pagination.entries ? props.data.pagination.entries : ''
    const [slotTime, setSlotTime] = useState({  
        startTime: '',
        endTime: ''
    })
    const [storeData, setStoreData] = useState({
        startTime: '',
        endTime: ''
    })
    const [filteredSlots, setFilteredslots] = useState([])

    const addSlot = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        if(slotTime.startTime !== '' && slotTime.endTime !== ''){
            axios.post(`${url}/setting/addTimeSettings`, { ...slotTime }, { headers: config.headers })
                .then(response => {
                    const status = response.data.success
                    const message = response.data.message
                    dispatch(getSlotList({
                        page: 1,
                        limit: 10,
                        searchType: '',
                        searchValue: ''
                    }))
                    if (status === true) {
                        setSlotTime({
                            startTime: '',
                            endTime: ''
                        })
                        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                        $(".custom-modal").removeClass("custom-modal-is-visible")
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                        // window.location.reload()
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        }
        else{
            window.$.fn.show_notification({ message: `All Fields necessary.`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
    }
    useEffect(() => {
        setStoreData(sharedData)
    }, [sharedData])

    const empty = () => {
        setFilteredslots([])
        setSlotTime({
            startTime: '',
            endTime: ''
        })
    }
    const updateSlot = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        if(storeData.startTime !== '' && storeData.endTime !== ''){
            axios.post(`${url}/setting/editSlotTime`, { ...storeData }, { headers: config.headers })
                .then(response => {
                    const status = response.data.success
                    const message = response.data.message
                    if (status === true) {
                        setStoreData({
                            startTime: '',
                            endTime: ''
                        })
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                        $(".custom-modal").removeClass("custom-modal-is-visible")
                        dispatch(getSlotList({
                            page: pageNumber,
                            limit: entries,
                            searchType: '',
                            searchValue: ''
                        }))
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        }
        else{
            window.$.fn.show_notification({ message: `All Fields necessary.`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
    }

    const handleStartChange = (item) => {
        filteredSlots.length = 0
        let local = { ...slotTime }
        local.startTime = Number(item.label)
        local.endTime = ''
        setSlotTime(local)

        let num = Number(item.label)
        for (let i = num + 1; i <= 24; i++) {
            filteredSlots.push({
                label: i <= 9 ? `0` + i : i, value: i
            })
        }
    }

    const handleEndChange = (item) => {
        let local = { ...slotTime }
        local.endTime = Number(item.label)
        setSlotTime(local)
    }
    const startSlots = [
        { label: '00', value: '00' },
        { label: '01', value: '01' },
        { label: '02', value: '02' },
        { label: '03', value: '03' },
        { label: '04', value: '04' },
        { label: '05', value: '05' },
        { label: '06', value: '06' },
        { label: '07', value: '07' },
        { label: '08', value: '08' },
        { label: '09', value: '09' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
        { label: '16', value: '16' },
        { label: '17', value: '17' },
        { label: '18', value: '18' },
        { label: '19', value: '19' },
        { label: '20', value: '20' },
        { label: '21', value: '21' },
        { label: '22', value: '22' },
        { label: '23', value: '23' },
        { label: '24', value: '24' },
    ]
  
    const _onUpdateDrop = (value, item) => {
        if (item === 'updateStart') {
            let local = { ...storeData }
            local.startTime = Number(value.label)
            local.endTime = 'select'
            if (local.startTime >= local.endTime) {
                setWarningState('End Slot cannot be smaller or equal to Start Slot.')
                setStoreData(local)
            }
            else {
                setWarningState(null)
                setStoreData(local)
            }
        }
        else {
            let local = { ...storeData }
            local.endTime = value.label
            if (local.startTime >= local.endTime) {
                setWarningState('End Slot cannot be smaller or equal to Start Slot.')
                setStoreData(local)
            }
            else {
                setWarningState(null)
                setStoreData(local)
            }

        }
    }


    return (
        <section id="AddSlot" className="custom-modal toggle_width" style={{ width: "50%" }}>
            <div className="custom-modal-header">
                <h1>{status !== true ? 'Add Slot' : 'Edit Slot'}</h1>
                <span className="ct-close" onClick={() => { props.closeModal(); props.callBack(); empty() }}></span>
            </div>

            <div className="custom-modal-body" >
                <div className='requestBody'>
                    <div className='reqbod3' style={{ marginTop: '20px' }}>
                        <div className='customRow addLightBg'>
                            <div className='custom47'>
                                <div className='custom40'>
                                    <span className='custSpan'>Start Time Slot <span className='asterick'> * </span> :</span>
                                </div>
                                <div className='custom60'>
                                    {status !== true ?
                                        <Select
                                            value={{ label: slotTime.startTime === '' ? 'Select' : slotTime.startTime, value: slotTime.startTime === '' ? 'Select' : slotTime.startTime }}
                                            onChange={(value) => handleStartChange(value)}
                                            options={startSlots}
                                            styles={colourStyles}
                                        />
                                        :
                                        <Select
                                            onChange={(value) => _onUpdateDrop(value, 'updateStart')}
                                            options={startSlots}
                                            styles={colourStyles}
                                            value={{ label: storeData.startTime, value: storeData.startTime }}
                                        />
                                    }
                                </div>
                            </div>
                            <div className='custom47' >
                                <div className='custom40'>
                                    <span className='custSpan'>End Time Slot <span className='asterick'> * </span> :</span>
                                </div>
                                <div className='custom60' style={{ position: 'relative' }}>
                                    {status !== true ?
                                        <Select
                                            value={{ label: slotTime.endTime === '' ? 'Select' : slotTime.endTime, value: slotTime.endTime === '' ? 'Select' : slotTime.startTime }}
                                            onChange={(value) => handleEndChange(value)}
                                            options={filteredSlots}
                                            styles={colourStyles}
                                        />
                                        :
                                        <Select
                                            onChange={(value) => _onUpdateDrop(value)}
                                            options={startSlots}
                                            styles={colourStyles}
                                            value={{ label: storeData.endTime, value: storeData.endTime }}
                                        />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='customBtnElem'>
                        {status !== true ?
                            <button className='customBtn' onClick={()=>{addSlot(); props.returnFun();}}>Add Slot</button>
                            :
                            <button className='newCustBtn' disabled={warningstate === null ? false : true} onClick={()=>{updateSlot(); props.returnFun();}}>Update Slot
                            {warningstate !== null ?
                                <div className='warningDiv'>
                                    <span className='warning'>{warningstate}</span>
                                </div>
                                :""}
                            </button>
                        }

                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddSlot;