import { FEEDBACK_DATA, FEEDBACK_SHOW, FEEDBACK_HIDE , FEEDBACK_MSSG } from "../actions/types";
const initialState={
    feedbackData: {},
    loading: true, 
    message : ""
} 

const getFeedbackRed =(state=initialState,{type,payload})=>{
    switch(type){
        case FEEDBACK_DATA : return{
            ...state,
            feedbackData : payload
        }
        case FEEDBACK_SHOW : return{ 
            ...state,
            loading: true
        }
        case FEEDBACK_HIDE : return{ 
            ...state,
            loading:false
        }  
        case FEEDBACK_MSSG : return{ 
            ...state,
            message:payload
        }
        default: return state;  
    }
}
export default getFeedbackRed ;