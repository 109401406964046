import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import history from './../../services/history';
import $ from 'jquery'
//InActive
import management from './../../static/images/icon/sidebar/updated/management.png'
import route from './../../static/images/icon/sidebar/updated/distance.png'
import request from './../../static/images/icon/sidebar/updated/interview.png'
import address from './../../static/images/icon/sidebar/updated/address.png'
import settings from './../../static/images/icon/sidebar/updated/settings.png'
import destination from './../../static/images/icon/sidebar/updated/destination.png'
import boxesInactive from './../../static/images/icon/sidebar/updated/boxes.png'
import pickupInactive from './../../static/images/icon/sidebar/updated/pickupInactive.png'
import areaInactive from './../../static/images/icon/sidebar/updated/locationInactive.png'
import custInActive from './../../static/images/icon/sidebar/updated/custInactive.png'
import cashInactive1 from './../../static/images/icon/sidebar/updated/cashInactive1.png'
//Active
import managementActive from './../../static/images/icon/sidebar/updated/mangementActive.png'
import routeActive from './../../static/images/icon/sidebar/updated/distanceActive.png'
import requestActive from './../../static/images/icon/sidebar/updated/interviewActive.png'
import addressactive from './../../static/images/icon/sidebar/updated/addressactive.png'
import settingActive from './../../static/images/icon/sidebar/updated/settingsActive.png'
import destinationActive from './../../static/images/icon/sidebar/updated/destinationActive.png'
import pickupActive from './../../static/images/icon/sidebar/updated/pickupActive.png'
import areaActive from './../../static/images/icon/sidebar/updated/locationActive.png'
import custActive from './../../static/images/icon/sidebar/updated/custActive.png'
import cashActive1 from './../../static/images/icon/sidebar/updated/cashActive1.png'
// blue
import counterBlue from './../../static/images/icon/sidebar/updated/counterBlue.png'
import areaBlue from './../../static/images/icon/sidebar/updated/areaBlue.png'
import requestBlue from './../../static/images/icon/sidebar/updated/requestBlue.png'

import addBlue from './../../static/images/icon/sidebar/updated/addBlue.png'
import tripBlue from './../../static/images/icon/sidebar/updated/tripBlue.png'
import settingsBlue from './../../static/images/icon/sidebar/updated/settingsBlue.png'

class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            url: `${props.location.pathname}${props.location.search}${props.location.hash}`,
            userType: localStorage.getItem("userType") === 'driver' ? 'driver' : localStorage.getItem("userType") === 'distributor' ? 'distributor' : localStorage.getItem("userType") === 'admin' ? 'admin' : localStorage.getItem("userType") === 'plant' ? "plant" : "",
            closeSidebar: this.closeSidebar.bind(this)
            // icon: BookingIcon
        }
    }
    componentDidMount() {
        this.unlisten = history.listen((location, action) => {
            this.setState({
                url: `${location.pathname}${location.search}${location.hash}`
            })
        })
    }
    componentWillUnmount() {
        this.unlisten();
    }
    closeSidebar() {
        // $("#myBar").on("click", function () {
        $(".sidebar.open.mobile").removeClass('mobile')
        //   })
    }

    render() {
        return (
            <div className="sidebar open">
                <div className="tab-content">
                    <div className="tab-pane" id="mainmenu">
                        <ul className="menu-nav">
                            {(this.state.userType === 'admin' || this.state.userType === 'plant') &&
                                <li className={(localStorage.getItem('userType') === 'plant' && this.state.url === "/dashboard") || (localStorage.getItem('userType') !== 'plant' && this.state.url === "/dashboard/analytics") ? "active" : ""} onClick={() => this.closeSidebar()}>
                                    <Link to={localStorage.getItem('userType') === 'plant' ? "/dashboard" : "/dashboard/analytics"}>
                                        <GraphIcon color={(localStorage.getItem('userType') === 'plant' && this.state.url === "/dashboard") || (localStorage.getItem('userType') !== 'plant' && this.state.url === "/dashboard/analytics") ? '#007BC9' : "currentColor"} />&nbsp;
                                        {/* <img className='siconUpdated' style={{ width: '35px' }} src={this.state.url === "/dashboard/analytics" ? GraphIcon : GraphIcon} alt='' /> */}
                                        <span className='txt'>Analytics </span>
                                    </Link>
                                </li>}

                            {this.state.userType !== 'admin' ?
                                <li className={(localStorage.getItem('userType') === 'plant' && this.state.url === "/dashboard/trip-management") || (localStorage.getItem('userType') !== 'plant' && this.state.url === "/dashboard") ? "active" : ""} onClick={() => this.closeSidebar()}>
                                    <Link to={localStorage.getItem('userType') === 'plant' ?  "/dashboard/trip-management" : "/dashboard"}>
                                        <img className='siconUpdated' style={{ width: '35px' }} src={(localStorage.getItem('userType') === 'plant' && this.state.url === "/dashboard/trip-management") || (localStorage.getItem('userType') !== 'plant' && this.state.url === "/dashboard") ? tripBlue : destination} alt='' />
                                        <span className='txt'>Trip Management</span>
                                    </Link>
                                </li>
                                :
                                <li className={this.state.url.includes("/dashboard") ? "active" : ""} onClick={() => this.closeSidebar()}>
                                    <Link to="/dashboard">
                                        <img className='siconUpdated' src={this.state.url.includes("/dashboard") ? addBlue : address} alt='' />
                                        <span className='txt'>User Management</span>
                                    </Link>
                                </li>
                            }

                            {this.state.userType === 'distributor' ?
                                <li className={this.state.url.includes("/dashboard/request") ? "active" : ""} onClick={() => this.closeSidebar()}>
                                    <Link to="/dashboard/request">
                                        <img className='sicon smallIcon' src={this.state.url.includes("/dashboard/request") ? requestBlue : request} alt='' />
                                        <span className='txt'>REQUEST</span>
                                    </Link>
                                </li>
                                : ""}
                            {this.state.userType === 'distributor' ?
                                <li className={this.state.url.includes("/dashboard/address-management") ? "active" : ""} onClick={() => this.closeSidebar()}>
                                    <Link to="/dashboard/address-management">
                                        <img className='siconUpdated' style={{ width: '23%', height: '24px' }} src={this.state.url.includes("/dashboard/address-management") ? addBlue : address} alt='' />
                                        <span className='txt'>Address Management</span>
                                    </Link>
                                </li>
                                : ""}
                            {this.state.userType === 'distributor' ?
                                <li className={this.state.url.includes("/dashboard/consumer-management") ? "active" : ""} onClick={() => this.closeSidebar()}>
                                    <Link to="/dashboard/consumer-management">
                                        <img className='siconUpdated' src={this.state.url.includes("/dashboard/consumer-management") ? counterBlue : custActive} alt='' />
                                        <span className='txt'>Consumer Management</span>
                                    </Link>
                                </li>
                                : ""}
                            {this.state.userType === 'distributor' ?
                                <li className={this.state.url.includes("/dashboard/cash-memo") ? "active" : ""} onClick={() => this.closeSidebar()}>
                                    <Link to="/dashboard/cash-memo">
                                        <img className='siconUpdated' src={this.state.url.includes("/dashboard/cash-memo") ? cashActive1 : cashInactive1} alt='' />
                                        <span className='txt'>Cash Memo</span>
                                    </Link>
                                </li>
                                : ""}
                            {this.state.userType === 'distributor' ?
                                <li className={this.state.url.includes("/dashboard/area-management") ? "active" : ""} onClick={() => this.closeSidebar()}>
                                    <Link to="/dashboard/area-management">
                                        <img className='siconUpdated' src={this.state.url.includes("/dashboard/area-management") ? areaBlue : areaInactive} alt='' />
                                        <span className='txt'>Area Management</span>
                                    </Link>
                                </li>
                                : ""}


                            {this.state.userType === 'distributor' ?
                                <li className={this.state.url.includes("/dashboard/settings") ? "nav-parent active" : "nav-parent"} >
                                    <Link to="/dashboard/settings/vehicle-settings" >
                                        <img className='sicon ' style={{ width: '37px' }} src={this.state.url.includes("/dashboard/settings") ? settingsBlue : settings} alt='' />
                                        <span className='txt'>Settings</span>
                                    </Link>
                                    <li className={this.state.url.includes("/dashboard/settings/vehicle-settings") ? "active" : ""}>
                                        <ul className="children ">
                                            <li className={this.state.url.includes("/dashboard/settings/vehicle-settings") ? "active" : ""} onClick={() => this.closeSidebar()}>
                                                <Link to="/dashboard/settings">Vehicle Settings</Link>
                                            </li>
                                            <li className={this.state.url.includes("/dashboard/settings/delivery-man-settings") ? "active" : ""} onClick={() => this.closeSidebar()}>
                                                <Link to="/dashboard/settings/delivery-man-settings">Delivery Man Settings</Link>
                                            </li>
                                            <li className={this.state.url.includes("/dashboard/settings/product-settings") ? "active" : ""} onClick={() => this.closeSidebar()}>
                                                <Link to="/dashboard/settings/product-settings">Product Settings</Link>
                                            </li>
                                        </ul>
                                    </li>



                                </li>
                                : ""}






                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}


const GraphIcon = ({ color = 'currentColor' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24">
            <path fill={color} d="M22 21H2V3h2v16h2v-9h4v9h2V6h4v13h2v-5h4z" />
        </svg>
    )
}

export default Sidebar;