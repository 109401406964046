import { TRIP_LISTING ,SHOW_LOADER, HIDE_LOADER, TRIP_MSSG , TRIP_COUNT } from "../actions/types";

const initialState={
    tripListing: [],
    loading: true,
    message:'',
    count : ''
} 

const tripListingRed =(state=initialState,{type,payload})=>{
    switch(type){
        case TRIP_LISTING : return{
            ...state,
            tripListing : payload
        }
        case SHOW_LOADER : return{ 
            ...state,
            loading: true
        }
        case HIDE_LOADER : return{ 
            ...state,
            loading:false
        }
        case TRIP_MSSG : return{ 
            ...state,
            message: payload
        }
        case TRIP_COUNT : return{ 
            ...state,
            count: payload
        }
        default: return state;  
    }
}
export default tripListingRed ;