import { REQUEST_LIST, SHOW_REQ_LOADER, HIDE_REQ_LOADER,REQUEST_MSSG, REQUEST_COUNT} from './types' 
import { post , logoutOnExpiry } from './../../../services/requests'

export const getRequestList = (object) => { 
        return (dispatch) => {
            dispatch(showLoader())
            post('request/getRequest', {...object})
                .then(response => {
                    const requestList = response.data
                    const status =response.success
                    const count = response.count
                    const message = response && response.message ? response.message : ''
                    if(status === true && requestList !== undefined){
                        dispatch(getReqList(requestList))
                        dispatch(hideLoader())
                        dispatch(getCount(count))
                        dispatch(reqMessage(''))
                    }
                    else{
                        dispatch(getReqList())
                        dispatch(hideLoader())
                        dispatch(reqMessage(message))
                    }
                }) 
                .catch(error => {
                    dispatch(reqMessage('Some Error Occured'))
                    dispatch(hideLoader())
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        } 
    }


export const getReqList = (requestList) => {
    return {
        type: REQUEST_LIST,
        payload: requestList
    }
}
export const showLoader = () =>{
    return{
        type : SHOW_REQ_LOADER,
    }
}
export const hideLoader = () =>{
    return{
        type : HIDE_REQ_LOADER,
    }
}
export const reqMessage = (message) =>{
    return{
        type : REQUEST_MSSG,
        payload : message
    }
}
export const getCount = (count) =>{
    return{
        type : REQUEST_COUNT,
        payload : count
    }
}
