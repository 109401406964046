import agent from 'superagent';
import history from './../services/history';
// const domain = 'https://dpp2df0jd1e4b.cloudfront.net/v1/'
// const domain = 'http://localhost:3022/v1/'
// const domain = 'https://betabpcl.lynkit.in/v1/'

const domain = '/v1/' 
// export const domain = 'https://devbpcl.lynkit.in/v1/'

// const domain = 'http://192.168.2.49:3022/v1/'

export const post = (uri, payload) => new Promise((resolve, reject) => {
  agent
    .post(domain+uri)
    .send(payload)
    .set('Accept', 'application/json')
    .set('Authorization', localStorage.getItem('secretkey'))
    .end(withPromiseCallback(resolve, reject));
});

export const get = uri => new Promise((resolve, reject) => {
  agent
    .get(domain+uri)
    .set('Accept', 'application/json')
    .set('Authorization', localStorage.getItem('secretkey'))
    .end(withPromiseCallback(resolve, reject));
});

export const put = (uri, payload) => new Promise((resolve, reject) => {
  agent
    .put(domain+uri)
    .send(payload)
    .set('Accept', 'application/json')
    .set('Authorization', localStorage.getItem('secretkey'))
    .end(withPromiseCallback(resolve, reject));
});

export const deleteRequest = (uri, payload) => new Promise((resolve, reject) => {
  agent
    .delete(domain+uri)
    .send(payload)
    .set('Accept', 'application/json')
    .set('Authorization', localStorage.getItem('secretkey'))
    .end(withPromiseCallback(resolve, reject));
});

export const withPromiseCallback = (resolve, reject) => (error, response) => {
  if (error) {
    console.error(error);
    reject({ error });
  } else {
    resolve(response.body);
  }
};
export const logoutOnExpiry = () => {
  localStorage.removeItem('secretkey')
  localStorage.removeItem('token')
  localStorage.removeItem('userType')
  history.push('/')
  setTimeout(() => {
    window.$.fn.show_notification({ message: 'Session Expired: Please Login Again', title: 'Message!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
      if (result.type === 'confirm') {
      }
    })
  }, 500);
}
export const url = '/v1'  

// export const url = 'https://betabpcl.lynkit.in/v1'
// export const socektDomain = 'https://devbpcl.lynkit.in'
// export const url = 'https://dpp2df0jd1e4b.cloudfront.net/v1'

// export const url = 'http://localhost:3022/v1/'






