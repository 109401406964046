import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getUsers } from '../../state/redux/actions/index';
import AddDriver from './Modal/AddDriver'
import TripSettingsModal from './Modal/TripSettingsModal'
import $ from 'jquery'
import axios from 'axios'
import { logoutOnExpiry, url } from './../../services/requests'
import Select from 'react-select';
import { colourStyles } from './../../services/functions'
import {Table} from "react-bootstrap"
const DriverSettings = () => {
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.getUserRed.usersList)
    const loader = useSelector((state) => state.getUserRed.loading)
    const message = useSelector((state) => state.getUserRed.message)
    const count = useSelector((state) => state.getUserRed.count)
    const [settingsState, setSettingsState] = useState([])
    const [dataState, setDataState] = useState({ data: '', update: '' , pagination: {pageNumber:'', entries:''} })
    const [pageCount, setPageCount] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [searchObj, setSearchObject] = useState({
        page: pageCount,
        limit: pageLimit,
        searchType: '',
        searchValue: ''
    })
    useEffect(() => {
        dispatch(getUsers({
            page: pageCount,
            limit: pageLimit,
            searchType: "",
            searchValue: "",
            userType: "driver",
            type: 'driver'
        }))
    }, [dispatch, pageCount])

    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const returnFun = () => {
        // setPageCount(1)
    }
    const nextPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount += 1);
        });
    }
    const prevPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount -= 1);
        });
    }
    const setData = (item) => {
        let concated = { ...dataState }
        concated.data = item
        concated.update = true
        concated.pagination.pageNumber = pageCount
        concated.pagination.entries = pageLimit
        setDataState(concated)
    }
    const callBack = () => {
        let concated = { ...dataState }
        concated.data = ''
        concated.update = false
        concated.pagination.entries = ''
        concated.pagination.pageNumber = ''
        setDataState(concated)
    }
    const setSettings = (item) => {
        setSettingsState(item)
        let concated = { ...dataState }
        concated.pagination.entries =  pageCount
        concated.pagination.pageNumber = pageLimit
        setDataState(concated)
    }
    const deleteDriver = (id) => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        let temp = {
            _id: id
        }
        window.$.fn.show_notification({ message: 'Delete this Driver ?', title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
            if (result.type === 'confirm') {

                axios.post(`${url}/user/deleteUser`, { ...temp }, { headers: config.headers })
                    .then(response => {
                        const status = response.data.success
                        const message = response.data.message
                        if (status === true) {
                            setPageCount(1)
                            dispatch(getUsers({
                                page: pageCount,
                                limit: pageLimit,
                                searchType: "",
                                searchValue: "",
                                userType: "driver",
                                type: 'driver'
                            }))
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                        if (error.error.status === 401) {
                            logoutOnExpiry()
                        }
                    })
            }
        })

    }
    const entries = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: 40, value: 40 },
        { label: 50, value: 50 }
    ]

    const handleChange = (item) => {
        let count = item.value
        setPageCount(1)
        setPageLimit(count)
        dispatch(getUsers({
            page: 1,
            limit: item.value,
            searchType: '',
            searchValue: '',
            userType: "driver",
            type: 'driver'
        }))
    }
    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className='cust-col-5'>
                    <h1 className="title">
                    Delivery Man Management
                    </h1>
                </div>
                <div className='cust-col-5 flex-jc-end'>
                    <h1 className="title font-bold orange_color">
                    </h1>
                </div>
            </div>
            <hr className='bgg' />
            <div className="cust-container">
                <div class="cust-row">
                    <div class="cust-col-10">
                        <div className='bdaOuter' >
                            <div className='searcBox alignCen alignCentNew' style={{ width: '50%' }} >
                            </div>
                            <div className='mapView deliveryMan'>
                                <button className='myMapBtnGrey ' style={{paddingLeft:'14px', paddingRight:'14px'}} onClick={() => { _handleClick('AddDriver') }}> Add Delivery Man</button>
                            </div>
                        </div>
                        {loader === false ?
                            listing && listing.length !== 0 ?
                                <div className="generic-table-wrapper mt20">
                                    <Table className="generic-table">
                                        <thead className="bg-accent">
                                            <tr>
                                                <th>S. No</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Mobile Number</th>
                                                <th>User Type</th>
                                                <th>Allocated</th>
                                                <th>Primary Area ID</th>
                                                <th>Secondary Area ID</th>
                                                <th>Vehicle Type</th>
                                                <th>Action</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            {listing && listing.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ color: '#007BC9' }}>
                                                            {((pageCount - 1) * (pageLimit)) + index + 1}
                                                        </td>
                                                        <td>{item && item.name ? item.name : '-'}</td>
                                                        <td>{item && item.email ? item.email : '-'}</td>
                                                        <td>{item && item.mobile ? item.mobile : "-"}</td>

                                                        <td>{item && item.userType && item.userType === "driver" ? "Delivery Man" : ""}</td>
                                                        <td> {item.isAllocated === true ?
                                                            <span className='bdaSpangreen' >True</span>
                                                            :
                                                            <span className='bdaSpanred'>False</span>
                                                        }</td>
                                                        <td>{'settings' in item ? item && item.settings && item.settings.areaId ? item.settings.areaId.join(" , ") : '-' : "-"}</td>


                                                        <td>{'settings' in item ? item && item.settings && item.settings.secondaryAreaId ? item.settings.secondaryAreaId.join(" , ") : '-' : "-"} </td>
                                                        <td>{'settings' in item ? item && item.settings && item.settings.eligibleForVehicle ? item.settings.eligibleForVehicle : '-' : "-"}</td>

                                                        <td><p></p>
                                                            <>
                                                            <i className="fa fa-cog" onClick={() => { _handleClick('TripSettingsModal'); setSettings(item) }} style={{ fontSize: '14px', marginRight: '12px', cursor: 'pointer' }} ></i>
                                                        <i className="fa fa-edit" onClick={() => { _handleClick('AddDriver'); setData(item); }} style={{ fontSize: '14px', marginRight: '12px', cursor: 'pointer' }}></i>
                                                        <i className="fa fa-trash trashIcon" onClick={() => deleteDriver(item._id)} ></i>
                                                            </>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                                :
                                <div className='noData'>
                                    <p>{message}</p>
                                </div>
                            :
                            <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
                            </div>

                        }
                        {listing && listing.length !== 0 ?
                                <div className='paginationDiv'>
                                    <div className='entries'>
                                        <span className='entrieSpan'>Show Entries :</span>
                                        <Select
                                            onChange={(value) => handleChange(value)}
                                            options={entries}
                                            styles={colourStyles}
                                        />
                                    </div>
                                    <button disabled={pageCount === 1 ? true : false} className='paginationBtn' onClick={prevPage}>Previous</button>
                                    <button disabled={Math.floor((count + pageLimit - 1) / pageLimit) === pageCount ? true : false} className='paginationBtn' onClick={nextPage}>Next</button>
                                </div>
                                : ''}
                    </div>
                </div>
            </div>
            <AddDriver closeModal={_closeModal} returnFun={returnFun} dataState={dataState} callBack={callBack} />
            <TripSettingsModal closeModal={_closeModal} dataState={dataState} settingsState={settingsState} />
        </div>
    )
}
export default DriverSettings;