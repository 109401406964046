import { CUSTOMER_LIST , CUST_SHOW , CUST_HIDE , CUST_MSSG , CUST_COUNT } from './types'
import { post , logoutOnExpiry } from './../../../services/requests'

export const getCustomerLists = (object) => {
        return (dispatch) => {
            dispatch(showLoader())
            post('user/getConsumers', {...object })
                .then(response => {
                    const customerList = response.data
                    const status = response.success
                    const message = response && response.message ? response.message : ''
                    const count = response.count
                    if(status === true && customerList !== undefined){
                        dispatch(getCustomers(customerList))
                        dispatch(getCount(count))
                        dispatch(hideLoader())
                        dispatch(showMessage(message))
                    }
                    else{
                        dispatch(getCustomers())
                        dispatch(hideLoader())
                        dispatch(showMessage(message))
                    }
                    
                })
                .catch(error => {
                    dispatch(hideLoader())
                    dispatch(showMessage('Some Error Found..'))
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        } 
    }


export const getCustomers = (customerList) => {
    return {
        type: CUSTOMER_LIST,
        payload: customerList
    }
}
export const showLoader = () =>{
    return{
        type : CUST_SHOW,
    }
}
export const hideLoader = () =>{
    return{
        type : CUST_HIDE,
    }
}
export const showMessage = (message) =>{
    return{
        type : CUST_MSSG,
        payload : message
    }
}
export const getCount = (count) =>{
    return{
        type : CUST_COUNT,
        payload : count
    }
}