import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { getSolutions, getPlotPoints , getVehiclesList } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import $ from 'jquery'
import VehicleLoads from '../Modal/VehLoad'
import { colourStyles } from './../../../services/functions'

const Solutions = (props) => {
    const dispatch = useDispatch()
    const solList = useSelector((state) => state.getSolRed.solsListing)
    const solListLoader = useSelector((state) => state.getSolRed.loading)
    const listing = useSelector((state) => state.getVehicleRed.vehicleListing)
    const [vehType, setvehType] = useState('select')
    const [coordinates, setCoordinates] = useState({})
    const [tripState, setTripState] = useState(
        {
            vehicle: [],
            dispatchPlan: [
                {
                    shapeType: 'select',
                    quantity: '',
                    length: '',
                    width: '',
                    height: '',
                    radius: '',
                    weight: '',
                    volume: ''
                }
            ],
        }
    )
    const [dimCheck, setDimCheck] = useState([
        {
            lengthCheck: false,
            widthCheck: false,
            heightCheck: false,
            radiusCheck: false,
        }
    ])
  
    // const VehicleTypes = [
    //     {
    //         label: 'Express Transporter ',
    //         value: 'Express Transporter ',
    //         vehType: 'Truck close (17 feet)',
    //         freightRate: 24,
    //         volume: 22000000,
    //         isDisabled: false
    //     },
    //     {
    //         label: 'Fast Movers',
    //         value: 'Fast Movers',
    //         vehType: 'Truck close (20 feet)',
    //         freightRate: 22,
    //         volume: 35960841,
    //         isDisabled: false

    //     },
    //     {
    //         label: 'Quality Transporter Services',
    //         value: 'Quality Transporter Services',
    //         vehType: 'Truck close (22 feet)',
    //         freightRate: 20,
    //         volume: 35000000,
    //         isDisabled: false
    //     }
    // ]
    const VehicleTypes = []
 
    listing && listing.map((item) => {
        VehicleTypes.push({ value: item.vehicleType , label: ` ${item.vehicleNo} (${item.vehicleType})` , vehType : item.vehicleType,  vehNo : item.vehicleNo , freight :  item.freightRate , vol : item.capacityInNumber , length: item.length , width: item.width , height : item.height });
    });
    const handleVehType = (item) => {
        let dummy = { ...tripState }
        dummy.vehicle.push({
            volume: item.vol,
            freightRate: item.freight,
            quantity: 1,
            vehicleType: item.vehType,
            vehNo : item.vehNo,
            length : item.length,
            width : item.width,
            height: item.height

        })
        setvehType(item.label)
        setTripState(dummy)
    }
    const _handleQty = (key, index) => {
        if (key === 'sub') {
            let dummy = { ...tripState }
            if (dummy.vehicle[index].quantity > 1) {
                let res = dummy.vehicle[index].quantity - 1
                dummy.vehicle[index].quantity = res
                setTripState(dummy)
            }
        }
        else {
            let dummy = { ...tripState }
            let res = dummy.vehicle[index].quantity + 1
            dummy.vehicle[index].quantity = res
            setTripState(dummy)
        }
    }
    const removeVehicle = (index) => {
        let dummy = { ...tripState }
        dummy.vehicle.splice(index, 1)
        setTripState(dummy)
    }
    const shapes = [
        { value: 'Select', label: 'Select' },
        { value: 'Box', label: 'Box' },
        { value: 'Cylinder', label: 'Cylinder' }
    ];
    const append = () => {
        let temp = { ...tripState }
        temp.dispatchPlan.push({
            shapeType: 'select',
            quantity: '',
            length: '',
            width: '',
            height: '',
            radius: '',
            volume: ''
        })
        setTripState(temp)

        let dummy = [...dimCheck]
        dummy.push({
            lengthCheck: false,
            widthCheck: false,
            heightCheck: false,
            radiusCheck: false,
        })
        setDimCheck(dummy)
    }
    const remove = (index) => {
        let local = { ...tripState }
        local.dispatchPlan.splice(index, 1)
        setTripState(local)

        let dummy = [...dimCheck]
        dummy.splice(index,1)
        setDimCheck(dummy)
    }
    const _handleShapeType = (item, index) => {
        let local = { ...tripState }
        if (item.label === 'Box') {
            local.dispatchPlan[index].shapeType = 'Box'
            setTripState(local)
        }
        else if (item.label === 'Cylinder') {
            local.dispatchPlan[index].shapeType = 'Cylinder'
            setTripState(local)
        }
    }
    const _handleAddInput = (e, index, item) => {
        let temp = { ...tripState }
        if (item === 'qty') {
            temp.dispatchPlan[index].quantity = e.target.value
        }
        else if (item === 'length') {
            if (e.target.value > 240) {
                dimCheck[index].lengthCheck = true
            }
            else {
                dimCheck[index].lengthCheck = false
                temp.dispatchPlan[index].length = e.target.value
            }
        }
        else if (item === 'width') {
            if (e.target.value > 609) {
                dimCheck[index].widthCheck = true
            }
            else {
                dimCheck[index].widthCheck = false
                temp.dispatchPlan[index].width = e.target.value
            }
        }
        else if (item === 'height') {
            if (e.target.value > 240) {
                dimCheck[index].heightCheck = true
            }
            else {
                dimCheck[index].heightCheck = false
                temp.dispatchPlan[index].height = e.target.value
            }
        }
        else if (item === 'radius') {
            if (e.target.value > 120) {
                dimCheck[index].radiusCheck = true
            }
            else {
                dimCheck[index].radiusCheck = false
                temp.dispatchPlan[index].radius = e.target.value
            }
        }
        else if (item === 'weight') {
            temp.dispatchPlan[index].weight = e.target.value
        }
        setTripState(temp)
    }
    const calculation = (index) => {
        let temp = { ...tripState }
        if (temp.dispatchPlan[index].shapeType === 'Box') {
            let res = Math.ceil(temp.dispatchPlan[index].length * temp.dispatchPlan[index].width * temp.dispatchPlan[index].height)

            temp.dispatchPlan[index].volume = res
            setTripState(temp)
        }
        else if (temp.dispatchPlan[index].shapeType === 'Cylinder') {
            let res = Math.ceil(3.145 * temp.dispatchPlan[index].radius * temp.dispatchPlan[index].radius * temp.dispatchPlan[index].height)
            temp.dispatchPlan[index].volume = res
            setTripState(temp)
        }

    }
    const solssss = () => {
        dispatch(getSolutions(tripState))
        dispatch(getPlotPoints(tripState))
    }
    useEffect(() => {
        if(solList !== undefined){
            if (Object.keys(solList).length !== 0) {
                $(".custom-modal-overlay").addClass("custom-modal-is-visible")
                $(`#SolutionTable`).addClass("custom-modal-is-visible")

                // $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                $("#SolModal").removeClass("custom-modal-is-visible")
                setvehType('select')
                setTripState(
                    {
                        vehicle: [],
                        dispatchPlan: [
                            {
                                shapeType: 'select',
                                quantity: '',
                                length: '',
                                width: '',
                                height: '',
                                radius: '',
                                weight: '',
                                volume: ''
                            }
                        ],
                    })
            }
        }
     
        else { 
            $(".custom-modal-overlay").addClass("custom-modal-is-visible")
            $(`#SolutionTable`).addClass("custom-modal-is-visible")

        // $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $("#SolModal").removeClass("custom-modal-is-visible")
     }
    }, [solList])

    const _closeModal = () => {
        setvehType('select')
        setTripState(
            {
                vehicle: [],
                dispatchPlan: [
                    {
                        shapeType: 'select',
                        quantity: '',
                        length: '',
                        width: '',
                        height: '',
                        radius: '',
                        weight: '',
                        volume: ''
                    }
                ],
            })
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $("#SolModal").removeClass("custom-modal-is-visible")
    }
    useEffect(() => {
        dispatch(getVehiclesList())
    }, [])




    return (
        <>
            <section id="SolModal" className="custom-modal toggle_width" style={{ width: "72%", }}>
                <div className="custom-modal-header">
                    <h1>Choose Inputs</h1>
                    <span className="ct-close" onClick={_closeModal}></span>
                </div>

                <div className="custom-modal-body" style={{ maxHeight: '78vh', overflow: 'auto', position: 'relative' }}>
                    <div className='trip100'>
                        <div className='trip48'>
                            <div className='trip40'>
                                <span className='tripSpan'>Select Vehicle :</span>
                            </div>
                            <div className='trip60 reactSelect'>
                                <Select
                                    onChange={(value) => handleVehType(value)}
                                    options={VehicleTypes}
                                    styles={colourStyles}   
                                    value={{ label: vehType, value: vehType }}
                                />
                            </div>
                        </div>
                        <div className='trip48New'>
                            {tripState && tripState.vehicle.length !== 0 ?
                                tripState && tripState.vehicle.map((item, index) => {
                                    return (
                                        <div key={index} className='group' style={{ position: 'relative' }}>
                                            <i className="fa fa-times groupCross" onClick={() => removeVehicle(index)} ></i>
                                            <div className='typeContainerOuter'>
                                                <div className='typeContainer'>
                                                    <span className='tHead'>Vehicle :</span>
                                                    <span className='typeName'>{item.vehNo} ({item.vehicleType})</span>
                                                </div>
                                                <div className='qtyContainer'>
                                                    <div className='qtyOper'>
                                                        <i className="fa fa-minus-circle qtySub" onClick={() => _handleQty('sub', index)}></i>
                                                    </div>
                                                    <div className='qtyOper addBdr'>
                                                        <span className='qtyNum'>{item.quantity}</span>
                                                    </div>
                                                    <div className='qtyOper'>
                                                        <i className="fa fa-plus-circle qtyAdd" onClick={() => _handleQty('add', index)}></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='typeContainerOuter'>
                                                <div className='tCont50'>
                                                    <span className='tHead'>Vehicle Volume :</span>
                                                    <span className='tSpan'>{item.volume} cm<sup>3</sup></span>
                                                </div>
                                                <div className='tCont50' style={{ justifyContent: 'flex-end', marginRight: '14px' }}>
                                                    <span className='tHead'>Freight Rate :</span>
                                                    <span className='tSpan'>{item.freightRate} / KM</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className='notFound'>
                                    <span>Choose Vehicle...</span>
                                </div>
                            }

                        </div>
                    </div>
                    <div className='trip100'>
                        <div className='trip48' style={{ width: '20%' }}>
                            <div className='trip40' style={{ width: '100%' }}>
                                <span className='tripSpan'>Dispatch Plan :</span>
                            </div>
                        </div>

                        <div className='trip48New' style={{ width: '85%' }}>
                            {tripState && tripState.dispatchPlan.map((item, index) => {
                                return (
                                    <div key={index} className='groupListView asignFlex' style={{ marginBottom: '22px' }}>
                                        <span className='sNumber'>{index + 1}</span>
                                        {tripState.dispatchPlan.length === 1 ? '' :
                                            <button className="groupremove" onClick={() => remove(index)}>Remove</button>
                                        }
                                        {tripState.dispatchPlan.length - 1 === index ?
                                            <button className="groupadd" onClick={() => append()}>Add</button>
                                            : ""}


                                        <div className=' alterWidthsNew'>
                                            <div className='shapeTypeDiv alterWidths'>
                                                <span className=' newtripSpan' style={{ fontSize: '0.74rem' }}>Type - </span>
                                                <Select
                                                    onChange={(value) => _handleShapeType(value, index)}
                                                    options={shapes}
                                                    styles={colourStyles}
                                                    value={{ label: tripState.dispatchPlan[index].shapeType, label: tripState.dispatchPlan[index].shapeType }}
                                                />
                                            </div>
                                            <div className='shapeTypeDiv alterWidths2'>
                                                <span className='tripSpan' style={{ fontSize: '0.74rem' }}>Qty- </span>
                                                <input name='quantity' type="number" value={item.quantity} onChange={(e) => _handleAddInput(e, index, 'qty')} className='tripInput' style={{ width: '53%', marginLeft: '10px', height: '33px', border: '1px solid hsl(0, 0%, 80%)' }} placeholder='quantity' />
                                            </div>
                                            <div className='shapeTypeDiv alterWidths2'>
                                                <span className='tripSpan' style={{ fontSize: '0.74rem' }}>Weight- </span>
                                                <input name='weight' value={item.weight} onChange={(e) => _handleAddInput(e, index, 'weight')} className='tripInput' style={{ width: '53%', marginLeft: '10px', height: '33px', border: '1px solid hsl(0, 0%, 80%)' }} type='number' placeholder='weight (kg)' />
                                                <span className='qtyslash'>/ Qty</span>
                                            </div>
                                            <div className='shapeTypeDiv alterWidths2'>
                                            </div>
                                        </div>
                                        {tripState.dispatchPlan[index].shapeType === 'select' ? '' :
                                            <div className='groupListViewIn'>

                                                {tripState.dispatchPlan[index].shapeType === 'Box' ?
                                                    <div className='grouplist2 toggleWidth'>
                                                        <span className='tripSpan' style={{ fontSize: '0.74rem' }}>Length - </span>
                                                        <input type='number' 
                                                        name='length'
                                                            onChange={(e) => _handleAddInput(e, index, 'length')}
                                                            onKeyUp={() => calculation(index)}
                                                            value={item.length}
                                                            placeholder='length (cm)' className='custInputProd' />
                                                        {dimCheck[index].lengthCheck === true ?
                                                            <span className='lengthCheck'>Length cannot be greater than 240 cm.</span>
                                                            : ''}
                                                    </div>
                                                    : ""}

                                                {tripState.dispatchPlan[index].shapeType === 'Box' ?
                                                    <div className='grouplist2 toggleWidth'>
                                                        <span className='tripSpan' style={{ fontSize: '0.74rem' }}>Width - </span>
                                                        <input type='number' name='width'
                                                            onChange={(e) => _handleAddInput(e, index, 'width')}
                                                            onKeyUp={() => calculation(index)}
                                                            value={item.width}
                                                            placeholder='width (cm)' className='custInputProd' />
                                                        {dimCheck[index].widthCheck === true ?
                                                            <span className='lengthCheck'>Width cannot be greater than 609 cm.</span>
                                                            : ''}
                                                    </div>
                                                    : ""}
                                                {tripState.dispatchPlan[index].shapeType === 'Cylinder' ?
                                                    <div className='grouplist2 toggleWidth'>
                                                        <span className='tripSpan' style={{ fontSize: '0.74rem' }}>Pi - </span>
                                                        <input type='number' name='radius'
                                                            value="3.145"
                                                            className='custInputProd'
                                                            style={{ position: 'relative', left: '14%' }}
                                                        />
                                                    </div>
                                                    : ""}
                                                {tripState.dispatchPlan[index].shapeType === 'Cylinder' ?
                                                    <div className='grouplist2 toggleWidth'>
                                                        <span className='tripSpan' style={{ fontSize: '0.74rem' }}>Radius - </span>
                                                        <input type='number' name='radius'
                                                            onChange={(e) => _handleAddInput(e, index, 'radius')}
                                                            onKeyUp={() => calculation(index)}
                                                            value={item.radius}
                                                            placeholder='radius (cm)' className='custInputProd' />
                                                        {dimCheck[index].radiusCheck === true ?
                                                            <span className='lengthCheck'>Radius cannot be greater than 120 cm.</span>
                                                            : ""}
                                                    </div>
                                                    : ""}
                                                <div className='grouplist2 toggleWidth'>
                                                    <span className='tripSpan' style={{ fontSize: '0.74rem' }}>Height - </span>
                                                    <input type='number' name='height'
                                                        onChange={(e) => _handleAddInput(e, index, 'height')}
                                                        onKeyUp={() => calculation(index)}
                                                        value={item.height}
                                                        placeholder='height (cm)' className='custInputProd' />
                                                    {dimCheck[index].heightCheck === true ?
                                                        <span className='lengthCheck'>Height cannot be greater than 240 cm.</span>
                                                        : ""}
                                                </div>
                                                <div className='grouplist2 toggleWidthh'>
                                                    <span className='tripSpan' style={{ fontSize: '0.8rem', marginRight: '18px' }}>Volume-</span>
                                                    <span className='tripInput toggleWidthNew' style={{ width: '66%', background: '#fff', color: '#ff7200', fontWeight: '800' }}>{tripState.dispatchPlan[index].volume} cm<sup>3</sup> </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='customBtnElem'>
                        <button className='customBtn'
                            disabled={tripState.vehicle.length === 0
                                || tripState.dispatchPlan.map((item) => { return (item.quantity === '' ? 'false' : 'true') }).includes('false') ? true : false
                                    || tripState.dispatchPlan.map((item) => { return (item.weight === '' ? 'false' : 'true') }).includes('false') ? true : false
                                        || tripState.dispatchPlan.map((item) => { return (item.volume === '' ? 'false' : 'true') }).includes('false') ? true : false
                                            || tripState.dispatchPlan.map((item) => { return (item.volume === 0 ? 'false' : 'true') }).includes('false') ? true : false
                                                && tripState.dispatchPlan.map((item) => { return (item.radius === '' ? 'false' : 'true') }).includes('false') ? true : false
                            }

                            // disabled={tripState.vehicle.length === 0 ||  tripState.dispatchPlan.forEach((item)=>{return(item.quantity === '' )? true : false})}

                            onClick={solssss}>Get Solutions</button>
                        {solListLoader === true ?
                            <i className="fa fa-spinner fa-spin pointLoader" style={{ color: '#007BC9', fontSize: '1rem', fontWeight: '800' }}></i>
                            : ''}

                    </div>

                </div>

            </section>


            <VehicleLoads coordinates={coordinates} />
        </>


    )
}

export default Solutions;