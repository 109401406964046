import { DIST_ADMIN_LISTING , DIST_ADMIN_LOADER , DIST_HIDE_ADMIN_LOADER , DIST_MSSG, DIST_COUNT} from './types'
import { post , logoutOnExpiry} from './../../../services/requests'

export const getDistributorAdminList = (object) => {
        return (dispatch) => {
            dispatch(showLoader())
            post('user/getAllDistributorAdmin', {...object})
                .then(response => { 
                    const distAdminLists = response.data
                    const status =response.success
                    const count = response.count
                    const message = response && response.message ? response.message : ''
                    if(status === true && distAdminLists !== undefined){
                        dispatch(getDistLists(distAdminLists))
                        dispatch(getCount(count))
                        dispatch(hideLoader())
                        dispatch(distMssg())
                    }
                    else{
                        dispatch(getDistLists())
                        dispatch(hideLoader())
                        dispatch(distMssg(message))
                    }   
                })
                .catch(error => {
                    dispatch(distMssg('Some Error Occured.'))
                    dispatch(hideLoader())
                    console.log('error', error.error.status);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        } 
    }


export const getDistLists = (distAdminLists) => {
    return {
        type: DIST_ADMIN_LISTING,
        payload: distAdminLists
    }
}
export const showLoader = () =>{
    return{
        type : DIST_ADMIN_LOADER,
    }
}
export const hideLoader = () =>{
    return{
        type : DIST_HIDE_ADMIN_LOADER,
    }
}
export const distMssg = (message) =>{
    return{
        type : DIST_MSSG,
        payload : message
    }
}
export const getCount = (count) =>{
    return{
        type : DIST_COUNT,
        payload : count
    }
}