import {  DISTRIBUTORACTIVITY_COUNT, DISTRIBUTORACTIVITY_DATA, DISTRIBUTORACTIVITY_SHOW, DISTRIBUTORACTIVITY_HIDE , DISTRIBUTORACTIVITY_MSSG } from "../actions/types";
const initialState={
    distributorActivityListing: [],
    loading: true, 
    message : ""
} 

const getDistributorActivityRed =(state=initialState,{type,payload})=>{
    switch(type){
        case DISTRIBUTORACTIVITY_DATA : return{
            ...state,
            distributorActivityListing : payload
        }
        case DISTRIBUTORACTIVITY_SHOW : return{ 
            ...state,
            loading: true
        }
        case DISTRIBUTORACTIVITY_HIDE : return{ 
            ...state,
            loading:false
        }  
        case DISTRIBUTORACTIVITY_MSSG : return{ 
            ...state,
            message:payload
        }
        case DISTRIBUTORACTIVITY_COUNT : return{
            ...state,
            count:payload
        }
        default: return state;  
    }
}
export default getDistributorActivityRed ;