import {  CYLINDER_DATA, CYLINDER_SHOW, CYLINDER_HIDE , CYLINDER_MSSG } from "../actions/types";

const initialState={
    cylinderData: {},
    loading: true, 
    message : ""
} 

const getCylinderRed =(state=initialState,{type,payload})=>{
    switch(type){
        case CYLINDER_DATA : return{
            ...state,
            cylinderData : payload
        }
        case CYLINDER_SHOW : return{ 
            ...state,
            loading: true
        }
        case CYLINDER_HIDE : return{ 
            ...state,
            loading:false
        }  
        case CYLINDER_MSSG : return{ 
            ...state,
            message:payload
        }
        default: return state;  
    }
}
export default getCylinderRed ;
