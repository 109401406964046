
import { CHANGE_VEHICLE , VEHICLE_LOADER_TRUE , VEHICLE_LOADER_FALSE, RESET_CLUSTER } from "../actions/types";

const initialState={
    changedCluster: [],
    loading: undefined
} 

const changeVehicleReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case CHANGE_VEHICLE : return{
            ...state,
            changedCluster : payload 
        }
        case VEHICLE_LOADER_TRUE : return{ 
            ...state,
            loading:true
        }
        case VEHICLE_LOADER_FALSE : return{ 
            ...state,
            loading:false
        }
        case RESET_CLUSTER : return{ 
            ...state,
            changedCluster : payload 
        }
        default: return state;  
    }
}
export default changeVehicleReducer ;