import { SLOT_LISTING ,SHOW_SLOT_LOADER , HIDE_SLOT_LOADER, SLOT_MESSAGE , SLOT_COUNT  } from "../actions/types";

const initialState={
    slotListing: [],
    loading: true,
    message: "",
    count : ""
} 

const getSlotRed =(state=initialState,{type,payload})=>{
    switch(type){
        case SLOT_LISTING : return{
            ...state,
            slotListing : payload
        }
        case SHOW_SLOT_LOADER : return{ 
            ...state,
            loading: true
        }
        case HIDE_SLOT_LOADER : return{ 
            ...state,
            loading:false
        }
        case SLOT_MESSAGE : return{ 
            ...state,
            message : payload
        }
        case SLOT_COUNT : return{ 
            ...state,
            count : payload
        }
        default: return state;  
    }
}
export default getSlotRed ;