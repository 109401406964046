import { PLOT_BOXES, EMPTY_PLOT, POINTS_SHOW, POINTS_HIDE } from './types'
import { post , logoutOnExpiry } from './../../../services/requests'


export const getPlotPoints = (data) => {
    return (dispatch) => {
        dispatch(showPointLoader())
        dispatch(emptyPlot())
        post('load/getStacking', { ...data })
            .then(response => {
                let status = response.success
                if (status === true) {
                    const pointsLists = response.data.data
                    dispatch(getPlot(pointsLists))
                    dispatch(hidePointLoader())
                }
                else{
                    dispatch(getPlot())
                    dispatch(hidePointLoader())
                }
            })
            .catch(error => {
                dispatch(hidePointLoader())
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }
}


export const getPlot = (pointsLists) => {
    return {
        type: PLOT_BOXES,
        payload: pointsLists
    }
}
export const showPointLoader = () => {
    return {
        type: POINTS_SHOW,
        payload: true
    }
}
export const hidePointLoader = () => {
    return {
        type: POINTS_HIDE,
        payload: false
    }
}
export const emptyPlot = () => {
    return {
        type: EMPTY_PLOT,
        payload: []
    }
}