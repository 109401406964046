import { CONSTRAINTS, CONSTRAINTS_OUTPUT } from './types'
import { post , logoutOnExpiry} from './../../../services/requests'

export const getConstraints = (object) => {
    return (dispatch) => {
        post('trip/constraints', { ...object })
            .then(response => {
                const constraintList = response.data || []
                if (object.type === 'input') {
                    dispatch(getOutput(constraintList))
                }
                else {
                    dispatch(getConstraint(constraintList))  
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }
}


export const getConstraint = (constraintList) => {
    return {
        type: CONSTRAINTS,
        payload: constraintList
    }
}
export const getOutput = (constraintList) => {
    return {
        type: CONSTRAINTS_OUTPUT,
        payload: constraintList
    }
}



