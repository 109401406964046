import React, { useEffect, useState, useRef } from 'react'
import HandleInputOutput from './Modal/HandleInputOutput'
import Solutions from './Modal/Solutions'
import VehicleLoads from './Modal/VehLoad'
import HandleOtp from './Modal/HandleOtp'
import $, { data } from 'jquery'
import axios from 'axios'
import { Table } from "react-bootstrap";
import { getTrips, getDistributorAdminList } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import history from '../../services/history'
import DeliveryDetails from './Modal/DeliveryDetails'
import LocationModal from './Modal/LocationModal'
import { logoutOnExpiry, url } from './../../services/requests'
import Select from 'react-select';
import { colourStyles } from './../../services/functions'
import { fontSize } from '@material-ui/system'

const PlantDashboard = () => {
    const dispatch = useDispatch()
    const tripLists = useSelector((state) => state.tripListingRed.tripListing)
    const dist = useSelector((state) => state.distributorListingReducer.distributorListing)
    const loader = useSelector((state) => state.tripListingRed.loading)
    const message = useSelector((state) => state.tripListingRed.message)
    const counts = useSelector((state) => state.tripListingRed.count)
    let user = localStorage.getItem("userType")
    const [pageCount, setPageCount] = useState(1)
    const [count, setCount] = useState(10)
    const [date, setDate] = useState('')
    const [sharedData, setSharedData] = useState({})
    const [sharedState, setSharedState] = useState({})
    const [geo, setGeo] = useState({ val: '' })
    const [distData, setDistData] = useState({
        name: "",
        id: ""
    })
    const [deliveryState, setdeliveryState] = useState({ data: {}, item: {} })
    const [pageState, setPageState] = useState({ pageNumber: '', entries: '' })

    const [otpData, setOtpData] = useState({
        tripId: '',
        type: ''
    })


    const [searchObj, setSearchObject] = useState({
        page: pageCount,
        limit: count,
        searchType: '',
        searchValue: ''
    })
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const returnFun = () => {
        // setPageCount(1)
    }
    const sharefun = (item) => {
        setSharedState(item)
    }

    const openMap = (item) => {
        history.push({
            pathname: '/dashboard/trip-map',
            state: item
        })
    }
    const nextPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount += 1);
        });
    }
    const prevPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount -= 1);
        });
    }


    useEffect(() => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
        dispatch(getDistributorAdminList())
    }, [pageCount])

    const _handleChange = (e) => {
        setSearchObject({ ...searchObj, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(getTrips({
            page: pageCount,
            limit: count,
            distributor: distData?.id,
            searchType: searchObj.searchType,
            searchValue: searchObj.searchValue
        }))
    }
    const slide = (index) => {
        const buttonRight = document.getElementById(`slideRight-${index}`);
        const buttonLeft = document.getElementById(`slideLeft-${index}`);

        buttonRight.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft += 150;
        };
        buttonLeft.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft -= 150;
        };
    }

    const _handleClick1 = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const shareDeliveryData = (data, item) => {
        let temp = { ...deliveryState }
        temp.data = data
        temp.item = item
        setdeliveryState(temp)
    }
    const shareData1 = (data) => {
        let temp = { ...geo }
        temp.val = data
        setGeo(temp)
    }
    const allTrips = () => {
        history.push({
            pathname: '/dashboard/all-trips',
            // state: item
        })
    }
    const deleteTrip = (id) => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        window.$.fn.show_notification({ message: 'Delete this Trip ?', title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
            if (result.type === 'confirm') {
                axios.post(`${url}/trip/deleteTrip`, { tripId: id }, { headers: config.headers })
                    .then(response => {
                        const status = response.data.success
                        const message = response.data.message
                        if (status === true) {
                            setPageCount(1)
                            dispatch(getTrips({
                                page: pageCount,
                                limit: count,
                                distributor: distData?.id
                            }))
                            window.$.fn.show_notification({ message: 'Trip deleted successfully.', title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                        else {
                            window.$.fn.show_notification({ message: 'Some Error Occured.', title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                        if (error.error.status === 401) {
                            logoutOnExpiry()
                        }
                    })
            }
        })

    }

    const entries = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: 40, value: 40 },
        { label: 50, value: 50 }
    ]
    const handleChange = (item) => {
        let counts = item.value
        setCount(counts)
        setPageCount(1)
        dispatch(getTrips({
            page: 1,
            limit: item.value,
            searchType: '',
            searchValue: '',
            distributor: distData?.id,
            date: date
        }))
    }


    const distributorArray = [];
    dist && dist.map((item) => {
        distributorArray.push({
            value: item._id,
            label: item.name,
            address: item.address
        });
    });

    const handleDropChange = (item) => {
        let temp = { ...distData }
        temp.id = item.value
        temp.name = item.label
        setDistData(temp)
        dispatch(getTrips({
            page: pageCount,
            limit: count,
            distributor: item.value
        }))
    }

    useEffect(() => {
        if (dist.length) {
            let temp = { ...distData }
            temp.id = dist[0]?._id
            temp.name = dist[0]?.name
            setDistData(temp)
            dispatch(getTrips({
                page: pageCount,
                limit: count,
                distributor: dist[0]?._id
            }))
        }
    }, [dist])

    const typeArray = [
        { label: "Trip ID", value: "tripId" },
        { label: "Vehicle Number", value: "vehicle.vehicleNo" },

    ]
    const handleSelectChange = (item) => {
        let temp = { ...searchObj }
        temp.searchType = item.value
        setSearchObject(temp)
    }




    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className='cust-col-5'>
                    <h1 className="title">
                        Trips
                    </h1>

                </div>
                <div className='cust-col-5 flex-jc-end'>
                    {/* {distTripShow === true ?
                        <button className='distBack' onClick={backme}>Back</button>
                        : ''} */}
                </div>
            </div>
            <hr className='bgg' />
            <div className="cust-container">
                <div class="cust-row">
                    <div class="cust-col-10">
                        <div style={{ display: 'flex', alignItems: 'center' }}>


                            <div className='header30' style={{ width: '350px', margin: '8px', alignItems: 'center' }}>
                                <span style={{ color: '#000', fontSize: '14px', fontWeight: '600', marginRight: '22px' }}>Distributors : </span>
                                <div className="myCs" style={{ width: '60%', height: '37px', zIndex: '10' }}>
                                    <Select
                                        onChange={(value) => handleDropChange(value)}
                                        options={distributorArray}
                                        styles={colourStyles}
                                    value={{ label: distData?.name, value: distData?.name }}
                                    />
                                </div>
                            </div>

                            <div className='searcBox alignCen' style={{ justifyContent: 'space-between' }} >
                                <div className='cust-col-4-new'>
                                    <div className="input-group">
                                        <div className="input-group-prepend" id="myycs" style={{ width: '30%', zIndex: '10', marginRight: '15px' }}>
                                            <Select
                                                onChange={(value) => handleSelectChange(value)}
                                                options={typeArray}
                                                styles={colourStyles}
                                            />
                                        </div>
                                        <input type="text" name='searchValue' value={searchObj.searchValue} onChange={(e) => _handleChange(e)} className="form-control" placeholder="Search here..." style={{ zIndex: '10' }} />
                                        <div className="input-group-append" onClick={() => triggerSearch()}>
                                            <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>



                            </div>


                            <div className='mapView' style={{ width: '30%' }}>

                            </div>
                        </div>

                        <div className='bdaList '>
                            {loader === false ?
                                tripLists && tripLists.length !== 0 ?
                                    <div className="generic-table-wrapper mt20">
                                        <Table className="generic-table">
                                            <thead className="bg-accent">
                                                <tr>
                                                    <th>S. No</th>
                                                    <th>Trip ID</th>
                                                    <th>Date</th>
                                                    <th>Vehicle</th>
                                                    <th>Estimated Duration</th>
                                                    <th>Trip Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-gray-100">
                                                {tripLists && tripLists.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr key={index}>
                                                                <td style={{ color: '#007BC9' }}>
                                                                    {((pageCount - 1) * (count)) + index + 1}
                                                                </td>
                                                                <td>{item.tripId ? item.tripId : '-'}</td>
                                                                <td>{item.dateOfTrip ? moment(item.dateOfTrip).format("DD-MM-YYYY") : '-'}</td>
                                                                <td>
                                                                    {item && item.vehicle && item.vehicle.vehicleNo ? item.vehicle.vehicleNo : '-'}
                                                                    {user === 'plant' ?
                                                                        <div className='tripButtons' style={{ paddingTop: '12px' }}>
                                                                            <div className='customDriverDetails'>
                                                                                <i className="fa fa-info-circle customeInfo" ></i>
                                                                                <div className='driverModalCustom'>
                                                                                    <div className='driverR'>
                                                                                        <div className='d100'>
                                                                                            <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-start' }}>
                                                                                                <span className='driverhead'>Name :</span>
                                                                                            </div>
                                                                                            <div style={{ width: '70%', display: 'flex', justifyContent: 'flex-start' }}>
                                                                                                <span className='driverValue'>{item && item.vehicle && item.vehicle.driverDetails && item.vehicle.driverDetails.name ? item.vehicle.driverDetails.name : '-'}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='d100' >
                                                                                            <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-start' }}>
                                                                                                <span className='driverhead'> Email :</span>
                                                                                            </div>
                                                                                            <div style={{ width: '70%', display: 'flex', justifyContent: 'flex-start' }}>
                                                                                                <span className='driverValue'>{item && item.vehicle && item.vehicle.driverDetails && item.vehicle.driverDetails.name ? item.vehicle.driverDetails.email : '-'}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='d100'>
                                                                                            <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-start' }}>
                                                                                                <span className='driverhead'> Mobile  :</span>
                                                                                            </div>
                                                                                            <div style={{ width: '70%', display: 'flex', justifyContent: 'flex-start' }}>
                                                                                                <span className='driverValue'>{item && item.vehicle && item.vehicle.driverDetails && item.vehicle.driverDetails.name ? item.vehicle.driverDetails.mobile : '-'}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : ""}
                                                                </td>
                                                                <td>
                                                                    <>
                                                                        {item.eta ? Math.floor((item.eta / (60 * 60)) % 24) < 10 ? '0' + Math.floor((item.eta / (60 * 60)) % 24) + ' hours : ' : Math.floor((item.eta / (60 * 60)) % 24) + ' hours : ' : '- '}

                                                                        {item.eta ? Math.floor((item.eta / (60)) % 60) < 10 ? "0" + Math.floor((item.eta / (60)) % 60) + ' mins' : Math.floor((item.eta / (60)) % 60) + ' mins' : '-'}
                                                                    </>
                                                                </td>
                                                                <td>
                                                                    <>
                                                                        {item.tripStatus && item.tripStatus === 'initiated' ?
                                                                            <span className='bdaSpanOrange'>Initiated</span>
                                                                            : ""}
                                                                        {item.tripStatus && item.tripStatus === 'in_transit' ?
                                                                            <span className='bdaSpanOrange'>In-Transit</span>
                                                                            : ""}
                                                                        {item.tripStatus && item.tripStatus === 'completed' ?
                                                                            <span className='bdaSpanOrange'>Completed</span>
                                                                            : ""}
                                                                    </>
                                                                </td>
                                                                <td>
                                                                    <>
                                                                        {localStorage.getItem('userType') !== 'driver' ?
                                                                            item.tripStatus === 'initiated' ?

                                                                                <div className='tripIcElem'>
                                                                                    <i className="fa fa-trash trashIcon" onClick={() => deleteTrip(item._id)} ></i>
                                                                                </div>
                                                                                :
                                                                                ''
                                                                            : ''
                                                                        }
                                                                        <span className='viewMap' onClick={() => openMap(item)}>View on Map</span>
                                                                    </>
                                                                </td>
                                                            </tr>
                                                            <tr className='trBorder'>
                                                                <td colspan="7" style={{ padding: '0px' }}>
                                                                    <div className='tripLocationListing'>
                                                                        <div className=' carettDiv' style={{ position: 'relative' }}>
                                                                            <i id={`slideLeft-${index}`} className="fa fa-caret-left carett" onClick={() => slide(index)}></i>
                                                                        </div>
                                                                        <div className='wid95' id={`container-${index}`} >
                                                                            <div className='totalLocatuu'>
                                                                                <span className='totalLocat'> Total Locations - {item && item.locations ? item.locations.length - 1 : 0}</span>
                                                                            </div>
                                                                            {item && item.locations && item.locations.map((data, ind) => {
                                                                                return (
                                                                                    ind !== 0 ?
                                                                                        <div className="tripRow">
                                                                                            <div id={item && item.currentWaypoint === data.waypoint_index ? `scroll-${ind}` : null} className={item && item.currentWaypoint === ind && item.tripStatus !== 'completed' ? 'tripAddress tripAddressActive' : 'tripAddress '}>
                                                                                                {/* {data.isReached === true ?
                                                                                                <span className='reached'><b>Reached at {moment(data.time).format("HH:mm")}</b></span>
                                                                                                : ''} */}
                                                                                                {data.isDelivered === true ?
                                                                                                    <span style={{ zIndex: 0 }} className='reached'><b>Arrived at {moment(data.time).format("DD-MM-YYYY hh:mm ")}</b></span>
                                                                                                    : ''}
                                                                                                {data.isReached === true ?
                                                                                                    <span style={{ zIndex: 0 }} className='slotTime'><b>Time Slot ({data.locationDetails && data.locationDetails.startSlotTime ? data.locationDetails.startSlotTime : '-'} - {data.locationDetails && data.locationDetails.endSlotTime ? data.locationDetails.endSlotTime : '-'})</b></span>
                                                                                                    : ''}
                                                                                                {data.isRescheduleForOtherDay === true || data.isRescheduleForToday === true ?
                                                                                                    <span style={{ zIndex: 0 }} className='skipped'><b>Skipped</b></span>
                                                                                                    : ''}
                                                                                                {data.isReached === true && data.isDelivered === true ?
                                                                                                    data.deliveryViolation === true ?
                                                                                                        <span className='violated'><b>Slot Violated</b>   </span>
                                                                                                        :
                                                                                                        <span className='timely'><b>Delivered Timely</b></span>
                                                                                                    : ''
                                                                                                }
                                                                                                <span className='bpclBlue'>
                                                                                                    {"locationDetails" in data ? data.locationDetails && data.locationDetails.address ? data.locationDetails.address : data && data.origin && data.origin.address === '' ? data.origin.address : '-' : ''}
                                                                                                    <br />
                                                                                                    <span style={item.currentWaypoint === ind ? { color: '#000' } : { color: '#000' }}>{"locationDetails" in data ? data.locationDetails && data.locationDetails.personName ? ` ${data.locationDetails.personName}` : '' : ''} </span>
                                                                                                    <span style={item.currentWaypoint === ind ? { color: '#000' } : { color: '#000' }}>{"locationDetails" in data ? data.locationDetails && data.locationDetails.mobile ? ` , ${data.locationDetails.mobile}` : '' : ''}</span>
                                                                                                    {data.isDelivered === true ?
                                                                                                        <p onClick={() => { _handleClick1('LocationModal'); shareData1(data) }} className={item.currentWaypoint === ind ? 'activeLat' : 'inactiveLat'}><span className='deliveryPoint'>{data && data.deliveryLocation ? "Delivered Point - " : ""} </span>{data && data.deliveryLocation ? `${data.deliveryLocation.latitude} , ${data.deliveryLocation.longitude}` : ''}</p>
                                                                                                        : ''}
                                                                                                    {ind !== 0 && data.isDelivered === true ?
                                                                                                        <span><i className={item.currentWaypoint === ind ? "fa fa-info-circle isDeIcWhite" : "fa fa-info-circle isDeIc"} onClick={() => { _handleClick1('DeliveryDetails'); shareDeliveryData(data, item); }} ></i></span>
                                                                                                        :
                                                                                                        ''
                                                                                                    }
                                                                                                </span>

                                                                                            </div>

                                                                                            {item.locations.length - 1 !== ind ?
                                                                                                <div className='tripArrow'>
                                                                                                    {item.currentWaypoint === data.waypoint_index ?
                                                                                                        <>
                                                                                                            <i className="fa fa-arrow-right faRights " ></i>
                                                                                                        </>
                                                                                                        :
                                                                                                        <i className="fa fa-arrow-right faRights " ></i>
                                                                                                    }
                                                                                                </div>
                                                                                                : ''}
                                                                                        </div>
                                                                                        : ''
                                                                                )
                                                                            })}


                                                                        </div>
                                                                        <div className='carettDiv'>
                                                                            <i id={`slideRight-${index}`} className="fa fa-caret-right carett" onClick={() => slide(index)}></i>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    :
                                    <div className='noData'>
                                        <p>{message}</p>
                                    </div>
                                :
                                <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                    <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
                                </div>

                            }

                            {tripLists && tripLists.length !== 0 ?
                                <div className='paginationDiv'>
                                    <div className='entries'>
                                        <span className='entrieSpan'>Show Entries :</span>
                                        <Select
                                            onChange={(value) => handleChange(value)}
                                            options={entries}
                                            styles={colourStyles}
                                        />
                                    </div>
                                    <button disabled={pageCount === 1 ? true : false} className='paginationBtn' onClick={prevPage}>Previous</button>
                                    <button disabled={Math.floor((counts + count - 1) / count) === pageCount ? true : false} className='paginationBtn' onClick={nextPage}>Next</button>
                                </div>
                                : ''}
                        </div>




                    </div>



                </div>
            </div>




            {user !== 'driver' ?
                <>
                    <HandleInputOutput closeModal={_closeModal} />
                    <Solutions closeModal={_closeModal} />
                    <VehicleLoads closeModal={_closeModal} />
                    <HandleOtp closeModal={_closeModal} otpData={otpData} />

                </>
                : ''
            }

            <DeliveryDetails closeModal={_closeModal} deliveryState={deliveryState} />
            <LocationModal closeModal={_closeModal} geo={geo} />
        </div>
    )
}
export default PlantDashboard;
