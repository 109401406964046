import React from 'react';
import logo from './../../static/images/logo3.png'

const Footer = (props) => (
    <div className="footer-sec">
        <img className='footerLogo' src={logo} alt='' />
        <span>Powered By Lynkit. </span>
    </div>
);

export default Footer