import React from 'react';
import Footer from "../../views/Landing/Footer"
import Navbar from "../../views/Landing/Navbar"
import Login from "../../views/Landing/Login"
import './../../static/css/style.css'
import logo from './../../static/images/logo.png'

const App = () => {

  return ( 
    <div>
      <Navbar logo={logo} />
      <Login  />
      <Footer />
    </div>
  ) 
}

export default App;