import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectInit } from './../../services/functions'
import { getCashMemoListing } from '../../state/redux/actions/index';
import Select from 'react-select';
import { colourStyles } from '../../services/functions'
import moment from 'moment'
import axios from 'axios'
import { url } from './../../services/requests'
import { Table } from 'react-bootstrap';
const CashMemoC = () => {
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.cashMemoListingRed.cashListing)
    const loader = useSelector((state) => state.cashMemoListingRed.loading)
    const message = useSelector((state) => state.cashMemoListingRed.message)
    const count = useSelector((state) => state.cashMemoListingRed.count)
    const [pageLimit, setPageLimit] = useState(10)
    const [pageCount, setPageCount] = useState(1)
    const [searchObj, setSearchObject] = useState({
        page: pageCount,
        limit: pageLimit,
        searchType: '',
        searchValue: '',
        startDate: '',
        endDate: ''
    })

    useEffect(() => {
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 50)
        dispatch(getCashMemoListing({
            page: pageCount,
            limit: pageLimit,
            searchType: '',
            searchValue: '',
            startDate: '',
            endDate: ''
        }))
    }, [dispatch, pageCount])

    const _searchDrop = (id, value) => {
        let temp = { ...searchObj }
        temp.searchType = id
        setSearchObject(temp)

    }

    const _handleChange = (e) => {
        setSearchObject({ ...searchObj, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(getCashMemoListing({
            page: '',
            limit: pageLimit,
            searchType: searchObj.searchType,
            searchValue: searchObj.searchValue,
            startDate: '',
            endDate: ''
        }))
    }
    const nextPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount += 1);
        });
    }
    const prevPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount -= 1);
        });
    }
    const entries = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: 40, value: 40 },
        { label: 50, value: 50 }
    ]
    const handleChange = (item) => {
        let count = item.value
        setPageLimit(count)
        setPageCount(1)
        dispatch(getCashMemoListing({
            page: 1,
            limit: item.value,
            searchType: '',
            searchValue: '',
            startDate: '',
            endDate: ''
        }))
    }
    const cashMemoFunction = (item) => {
        let data = { fileName: item.filepath }
        axios.post(`${url}/general/getImageToBlob`, { ...data })
            .then(response => {
                let base = response.data.data
                let status = response.data.success
                let message = response.data.message
                if(status === true){
                    let blob = b64toBlob(base, 'application/pdf');
                    let ur = URL.createObjectURL(blob);
                    setTimeout(() => {
                        window.open(ur, "_blank")
                    }, 100)
                }
                else{
                    window.$.fn.show_notification({ message: `${message}`, title: 'Oops!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
                
            })
            .catch(error => {
                console.log('error', error);
            })
    }
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    const _handleDateChange = (e,item) => {
        let temp = {...searchObj}
        if(item === 'start'){
            temp.startDate = e.target.value
            setSearchObject(temp)
        }
        else{
            temp.endDate = e.target.value
            setSearchObject(temp)
        }
    }
    const search = () => {
        dispatch(getCashMemoListing({
            page: 1,
            limit: pageLimit,
            searchType: '',
            searchValue: '',
            startDate: searchObj.startDate,
            endDate: searchObj.endDate
        }))
    }
    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className='cust-col-5'>
                    <h1 className="title">
                        Cash Memo
                    </h1>
                </div>
                <div className='cust-col-5 flex-jc-end'>
                    <h1 className="title font-bold orange_color">
                    </h1>
                </div>
            </div>
            <hr className='bgg' />
            <div className="cust-container">
                <div class="cust-row">
                    <div class="cust-col-10">
                        <div className='bdaOuter' >
                            <div className='searcBox alignCen' style={{width:'auto'}}>
                                <div className='cust-col-4-new'>
                                    <div className="input-group">
                                        <div className="input-group-prepend" style={{ width: '30%', zIndex: '10', marginRight: '15px' }}>
                                            <select className="select2-single" defaultValue="select" id="cashMemoSearch">
                                                <option value="select" disabled>Select</option>
                                                <option value="consumerName">Consumer Name</option>
                                                <option value="bdaCode">BDA code</option>
                                            </select>
                                        </div>
                                        <input type="text" name='searchValue' value={searchObj.searchValue} onChange={(e) => _handleChange(e)} className="form-control" placeholder="Search here..." style={{ zIndex: '10' }} />
                                        <div className="input-group-append" onClick={() => triggerSearch()}>
                                            <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='twoCalend'>
                                <div className='firstCalenBox' style={{marginRight:'3px'}}>
                                    <span className='calenSpan'>Start Date</span>
                                    <input className='twodateInput' type='date' onChange={(e) => _handleDateChange(e,'start')} />
                                </div>
                                <div className='firstCalenBox'>
                                    <span className='calenSpan'>End Date</span>
                                    <input className='twodateInput' type='date' onChange={(e) => _handleDateChange(e,'end')} />
                                </div>

                                <div className="input-group-append" >
                                    <button type="submit" onClick={() => search()} className="btn btn-orange p-1 pl-3 pr-3 pt-2"  disabled={searchObj.startDate === '' || searchObj.endDate === '' ? true : false}>
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        {loader === false ?
                            listing && listing.length !== 0 ?
                                <div className="generic-table-wrapper mt20">
                                    <Table className="generic-table">
                                        <thead className="bg-accent">
                                            <tr>
                                                <th>S. No</th>
                                                <th>BDA Code</th>
                                                <th>Consumer Name</th>
                                                <th>Mobile Number</th>
                                                <th>Cash Memo Date & Time</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            {listing && listing.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ color: '#007BC9' }}>
                                                            {((pageCount - 1) * (pageLimit)) + index + 1}
                                                        </td>
                                                        <td>{item?.bdaCode|| '-'}</td>
                                                        <td>{item?.consumerName || '-'}</td>
                                                        <td >{item?.consumerMobileno || "-"}</td>
                                                      
                                                      
                                                       
                                                        <td>{item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY") : '-'} , {item.createdAt ? moment(item.createdAt).format("h:mm a") : '-'}
                                                        </td>

                                                        <td> <button className='cashMemoBtn' onClick={() => cashMemoFunction(item)}>
                                                            Cash Memo <i className="fa fa-download ml-1" aria-hidden="true"></i>
                                                        </button></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                                :
                                <div className='noData'>
                                    <p>{message}</p>
                                </div>
                            :
                            <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
                            </div>

                        }


                        {listing && listing.length !== 0 ?
                            <div className='paginationDiv'>
                                <div className='entries'>
                                    <span className='entrieSpan'>Show Entries :</span>
                                    <Select
                                        onChange={(value) => handleChange(value)}
                                        options={entries}
                                        styles={colourStyles}
                                    />
                                </div>
                                <button disabled={pageCount === 1 ? true : false} className='paginationBtn' onClick={prevPage}>Previous</button>
                                <button disabled={Math.floor((count + pageLimit - 1) / pageLimit) === pageCount ? true : false} className='paginationBtn' onClick={nextPage}>Next</button>
                            </div>
                            : ''}
                    </div>
                </div>
            </div>

            {/* <Geofence closeModal={_closeModal} geo={geo} /> */}
        </div>
    );
};

export default CashMemoC;