import React, { useState, useEffect } from 'react';
import history from '../../../services/history'
import $ from 'jquery'
import Select from 'react-select';
import { colourStyles } from './../../../services/functions'

const AddressRoute = (props) => {
    const listing = props.listing
    const [latlngState, setLatLng] = useState([
        {
            lat: '',
            lng: '',
            index: '',
            address: '',
        }
    ])
    const _handleChange = (e, index) => {
        let dummyArr = [...latlngState]
        if (e.target.name === 'lat') {
            dummyArr[index].lat = parseFloat(e.target.value)
        }
        else if (e.target.name === 'lng') {
            dummyArr[index].lng = parseFloat(e.target.value)
        }
        setLatLng(dummyArr)
    }
    const addField = () => {
        let tempArr = [...latlngState]
        tempArr.push({
            lat: '',
            lng: '',
            address: '',
        })
        setLatLng(tempArr)
    }
    const removeField = (index) => {
        let removeArr = [...latlngState]
        removeArr.splice(index, 1)
        setLatLng(removeArr)
    }
    const openMap = () => {
        if (latlngState.length === 1) {
            window.$.fn.show_notification({ message: `Minimum 2 address required! `, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else {
            history.push({
                pathname: '/dashboard/map',
                state: latlngState
            })
        }
    }
    const options = listing && listing.map(function (item, index) {
        return { label: item.address, value: item.address, id: index, lat: item.latitude, lng: item.longitude }
    })
  
    const _onSelect = (value, index) => {
        let tempArr = [...latlngState]
        tempArr[index].lat = value.lat
        tempArr[index].lng = value.lng 
        tempArr[index].address = value.label
        tempArr[index].index = index
        setLatLng(tempArr)
    }
    return (
        <section id="AddressRouteModal" className="custom-modal" style={{ width: '50%' }}>
            <div className="custom-modal-header">
                <h1 >Create Route</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ maxHeight: '70vh' }}>
                <div className='latlngElem'>
                    <span className='latlngElemSpan'>Please Choose your Address.</span>
                </div> 
                <div className='latlngInpElem'>
                    {latlngState.map((item, index) => {
                        return (
                            <div key={index} className='latlngInpInn'>
                                <div className='sNo'>{index + 1}</div>
                                <div className='addressDropElem'>
                                    <Select
                                        options={options}
                                        className="css-1oy5eth-ReactDropdownSelect wd-100"
                                        onChange={(value) => _onSelect(value, index)}
                                        color={"#007BC9"}
                                        styles={colourStyles}
                                    />

                                </div>
                                <div className='operators'>
                                    {latlngState.length - 1 === index ?
                                        <i className="fa fa-plus faAdd" onClick={() => { addField(index) }} ></i>
                                        : ''}
                                    {latlngState.length !== 1 ?
                                        <i className="fa fa-minus faMinus" onClick={() => { removeField(index) }}></i>
                                        : ''}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='createRouteBtn'>
                    <button onClick={() => openMap()}>Create Route</button>
                </div>
            </div>
        </section>
    )
}

export default AddressRoute;