import {  CUSTOMER_LIST , CUST_SHOW , CUST_HIDE , CUST_MSSG , CUST_COUNT } from "../actions/types";

const initialState={
    customerList: [],
    loading: false, 
    message : "",
    count : ''
}  

const getCustRed =(state=initialState,{type,payload})=>{
    switch(type){
        case CUSTOMER_LIST : return{
            ...state,
            customerList : payload
        }
        case CUST_SHOW : return{ 
            ...state,
            loading: true
        }
        case CUST_HIDE : return{ 
            ...state,
            loading:false
        }  
        case CUST_MSSG : return{ 
            ...state,
            message:payload
        }
        case CUST_COUNT : return{ 
            ...state,
            count:payload
        }
        default: return state;  
    }
}
export default getCustRed ;