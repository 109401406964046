import React, { useState, useEffect } from 'react';
import history from '../../../services/history'
import $ from 'jquery'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';


const CreateRouteModal = (props) => {
    const [lat, setLat] = useState('')
    const [lng, setLng] = useState('') 
    const [address, setAddress] = useState('')
    const [latlngState, setLatLng] = useState([
        {
            lat: 28.525456226830496,
            lng: 77.2754886108826,
            index: 0,
            address: '',
            field: 'num'
        }
    ])
    const _handleChange = (e, index) => {
        let dummyArr = [...latlngState]
        if (e.target.name === 'lat') { 
            dummyArr[index].lat = parseFloat(e.target.value)
        }
        else if (e.target.name === 'lng') {
            dummyArr[index].lng = parseFloat(e.target.value)
        }
        setLatLng(dummyArr)
    }
    const addField = () => {
        let tempArr = [...latlngState]
        tempArr.push({
            lat: '',
            lng: '',
            address: '',
            field: ''
        })
        setLatLng(tempArr)
    }
    const removeField = (index) => {
        let removeArr = [...latlngState]
        removeArr.splice(index, 1)
        setLatLng(removeArr)
    }
    const openMap = () => {
        history.push({
            pathname: '/dashboard/map',
            state: latlngState
        })
    }
    const showAddInp = (index) => {
        if ($(".addressRow").eq(index).hasClass("close")) {
            $(".addressRow").eq(index).removeClass("close");
            $(".addressRow").eq(index).addClass("open");
            $(".ct100").eq(index).removeClass("open");
            $(".ct100").eq(index).addClass("close");

        } else {
            $(".addressRow").eq(index).removeClass("open");
            $(".addressRow").eq(index).addClass("close")
            $(".ct100").eq(index).removeClass("close");
            $(".ct100").eq(index).addClass("open");
        }
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        })
        geocodeByAddress(latlngState.address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                setLat(latLng.lat)
                setLng(latLng.lng)
            })
            .catch(error => console.error('Error', error));
    }, [latlngState.field])
    
    const handleChange = (item, index) => {
        // setLatLng({...latlngState , [e.target.name]:e.target})
        latlngState[index].address = ''
        setAddress(item)
    };
    const handleSelect = (item, index) => {
        latlngState[index].address = item
        geocodeByAddress(item)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                setLat(latLng.lat)
                setLng(latLng.lng)
                latlngState[index].lat = latLng.lat
                latlngState[index].lng = latLng.lng
            })
            .catch(error => console.error('Error', error));
    };
   
  

    return (
        <section id="createroutemodal" className="custom-modal" style={{ width: '65%' }}>
            <div className="custom-modal-header">
                <h1 >Create Route</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                <div className='latlngElem'>
                    <span className='latlngElemSpan'>Please Enter Lat and Lng or Address to create Route.</span>
                </div>
                <div className='latlngInpElem'>
                    {latlngState.map((item, index) => {
                        return (
                            <div key={index} className='latlngInpInn'>
                                <div className='sNo'>{index + 1}</div>
                                <div className='ct100'>
                                    <div className='latElem'>
                                        <span className='latElemSpan'>Latitude :</span>
                                        <input type='number' name='lat' value={item.lat} className='latlngField' onChange={(e) => _handleChange(e, index)} placeholder='Please Enter Latitude' />
                                    </div>
                                    <div className='latElem'>
                                        <span className='latElemSpan'>Longitude :</span>
                                        <input type='number' name='lng' value={item.lng} className='latlngField' onChange={(e) => _handleChange(e, index)} placeholder='Please Enter Longitude' />
                                    </div>
                                </div>

                                <div className='addressRow close'>
                                    <span className='latElemSpan'>Address :</span>
                                    <div className='latlngFieldNew'>
                                        <PlacesAutocomplete
                                            name='address'
                                            value={latlngState[index].address === '' ? address : latlngState[index].address}
                                            onChange={(e) => handleChange(e, index)}
                                            onSelect={(e) => handleSelect(e, index)}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div className='placeHolderWidth'>
                                                    <input
                                                        id='widthh'
                                                        value={latlngState[index].address}
                                                        {...getInputProps({
                                                            placeholder: 'Search Places ...',
                                                            className: 'location-search-input',


                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#007BC9', cursor: 'pointer', color: '#fff', fontSize: '0.8rem', width: '100%', borderRadius: '3px', padding: '3px' }
                                                                : {
                                                                    backgroundColor: '#ffffff', cursor: 'pointer', fontSize: '0.8rem', border: '1px solid rgb(0 0 0 / 21%)', width: '100%',
                                                                    padding: '3px', borderRadius: '3px'
                                                                };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>

                                    <button className='addreddBtn2' onClick={() => showAddInp(index)}>Enter Lat /Lng</button>
                                </div>


                                <button className='addreddBtn1' onClick={() => showAddInp(index)}>Enter Address</button>


                                <div className='operators'>
                                    {latlngState.length - 1 === index ?
                                        <i className="fa fa-plus faAdd" onClick={() => { addField(index) }} ></i>
                                        : ''}
                                    {latlngState.length !== 1 ?
                                        <i className="fa fa-minus faMinus" onClick={() => { removeField(index) }}></i>
                                        : ''}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='createRouteBtn'>
                    <button onClick={() => openMap()}>Create Route</button>
                </div>
            </div>
        </section>
    )
}

export default CreateRouteModal;