import { CREATE_OPTI_ROUTE } from "../actions/types";

const initialState={
    createdOptiRoutes: [],
} 

const createOptiRouteRed =(state=initialState,{type,payload})=>{
    switch(type){
        case CREATE_OPTI_ROUTE : return{
            ...state,
            createdOptiRoutes : payload
        }
        default: return state;  
    }
}
export default createOptiRouteRed ; 