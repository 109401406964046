import { TRIP_LISTING, SHOW_LOADER, HIDE_LOADER , TRIP_MSSG , TRIP_COUNT} from './types'
import { post , logoutOnExpiry} from './../../../services/requests'

export const getTrips = (object) => {
    return (dispatch) => {
        dispatch(showLoader())
        post('trip/getTrip', { ...object })
            .then(response => {
                const tripLists = response.data !== undefined ? response.data : []
                const status = response.success
                const count = response.count
                const message = response && response.message ? response.message : ''
                if (status === true && tripLists !== undefined) {
                    const points = tripLists.map((a, b) => { return (a) })
                    // for (let num = 0; num < points.length; num++) {
                    //     points[num].locations.sort((a, b) => {
                    //         return a.waypoint_index - b.waypoint_index;
                    //     });
                    // }
                    dispatch(tripsMessage(''))
                    dispatch(getTripLists(points))
                    dispatch(getCount(count))
                    dispatch(hideLoader())
                }
                else{
                    dispatch(tripsMessage(message))
                    dispatch(getTripLists())
                    dispatch(hideLoader())
                }
            })
            .catch(error => {
                dispatch(tripsMessage('Some Error Occured.'))
                dispatch(hideLoader())
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
                console.log('error', error);
            })
    }
}


export const getTripLists = (points) => {
    return {
        type: TRIP_LISTING,
        payload: points
    }
}
export const showLoader = () =>{
    return{
        type : SHOW_LOADER,
    }
}
export const hideLoader = () =>{
    return{
        type : HIDE_LOADER,
    }
}
export const tripsMessage = (mssg) =>{
    return{
        type : TRIP_MSSG,
        payload : mssg
    }
}
export const getCount = (count) =>{
    return{
        type : TRIP_COUNT,
        payload : count
    }
}