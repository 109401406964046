import { VEHICLES_LIST ,  SHOW_VEH_LOADER , HIDE_VEH_LOADER , VEH_MSSG , VEH_COUNT } from "../actions/types";

const initialState={
    vehicleListing: [], 
    loading: true,
    message: '',
    count :''
} 

const getVehicleRed =(state=initialState,{type,payload})=>{
    switch(type){
        case VEHICLES_LIST : return{
            ...state,
            vehicleListing : payload
        }
        case SHOW_VEH_LOADER : return{ 
            ...state,
            loading: true
        }
        case HIDE_VEH_LOADER : return{ 
            ...state,
            loading:false
        }
        case VEH_MSSG : return{ 
            ...state,
            message : payload
        }
        case VEH_COUNT : return{ 
            ...state,
            count : payload
        }
        default: return state;  
    }
}
export default getVehicleRed ;