import { ADDRESS_LISTING , SHOW_ADD_LOADER , HIDE_ADD_LOADER , ADDRESS_MSSG, ADDRESS_COUNT} from './types'
import { post , logoutOnExpiry} from './../../../services/requests'

export const getAddress = (object) => {
        return (dispatch) => {
            dispatch(showLoader())
            post('route/getAddress', {...object})
                .then(response => { 
                    const addressLists = response.data
                    const status =response.success
                    const count = response.count
                    const message = response && response.message ? response.message : ''
                    if(status === true && addressLists !== undefined){
                        dispatch(getAddressLists(addressLists))
                        dispatch(getCount(count))
                        dispatch(hideLoader())
                        dispatch(addressmssg())
                    }
                    else{
                        dispatch(getAddressLists())
                        dispatch(hideLoader())
                        dispatch(addressmssg(message))
                    }   
                })
                .catch(error => {
                    dispatch(addressmssg('Some Error Occured.'))
                    dispatch(hideLoader())
                    console.log('error', error.error.status);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        } 
    }


export const getAddressLists = (addressLists) => {
    return {
        type: ADDRESS_LISTING,
        payload: addressLists
    }
}
export const showLoader = () =>{
    return{
        type : SHOW_ADD_LOADER,
    }
}
export const hideLoader = () =>{
    return{
        type : HIDE_ADD_LOADER,
    }
}
export const addressmssg = (message) =>{
    return{
        type : ADDRESS_MSSG,
        payload : message
    }
}
export const getCount = (count) =>{
    return{
        type : ADDRESS_COUNT,
        payload : count
    }
}