import { selectInit } from './../../../services/functions'
import { useEffect, useState } from 'react'
import $ from 'jquery'
import { getDrivers, getTrips } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import Select from 'react-select';
import { logoutOnExpiry , url } from './../../../services/requests'
import { colourStyles } from './../../../services/functions'

const AllocateDriver = (props) => {
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.driverListRed.driverListing)
    const [allocateDriverState, setAllDriver] = useState({
        driverDetails: {
            _id: "",
            email: "",
            mobile: "",
            name: ""
        }
    }
    )
    const data = props.sharedState && props.sharedState || ''

    useEffect(() => {
        dispatch(getDrivers())
    }, [dispatch])

  
    const driverListing = []
    listing && listing.map((item) => {
        driverListing.push({ value: item.name, label: item.name, id: item._id, email: item.email, mobile: item.mobile });
    });
    const handleDriver = (value) => {
        let local = { ...allocateDriverState }
        local.driverDetails._id = value.id
        local.driverDetails.name = value.label
        local.driverDetails.email = value.email
        local.driverDetails.mobile = value.mobile
        setAllDriver(local)
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const allocateDriver = (val) => {
        if(allocateDriverState.driverDetails._id !== '' && allocateDriverState.driverDetails.name !== '' ){
            const token = localStorage.getItem('secretkey')
            const config = {
                headers: { Authorization: token }
            };
            const tripId = { tripId: data.tripId }
            const concate = { ...tripId, ...allocateDriverState }
            axios.post(`${url}/trip/assignTripToDriver`, { ...concate }, { headers: config.headers })
                .then(response => {
                    const status = response.data.success
                    const message = response.data.message
                    dispatch(getDrivers())
                    if (status === true) {
                        dispatch(getTrips({
                            page: 1,
                            limit: 10,
                            date: ''
                        }))
                        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                        $(".custom-modal").removeClass("custom-modal-is-visible")
                        window.$.fn.show_notification({ message:  `${message} `, title: 'Success!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
    
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message} `, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
    
        }
      
else{
   
}
    }
    const handleTransporter = (value) => {
    }

    const transporterlisting = [
        { label: 'Yonex Transporter', value: 'Yonex Transporter' },
        { label: 'B2B Logistics', value: 'B2B Logistics' },
    ]

    return (
        <section id="AllocateDriverModal" className="custom-modal" style={{ width: "55%" }}>
            <div className="custom-modal-header">
                <h5>Allocate Delivery Man </h5>
                <span className="ct-close" onClick={_closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ maxHeight: '79vh' }}>
                <div className='rowSpan' style={{ background: 'none' }}>
                    <div className='rowInner' style={{ width: '100%', background: 'none' }}>
                        <div >
                            <span className='keyValue' style={{ fontSize: '0.86rem' }}>Trip Id :</span>
                        </div>
                        <div >
                            <span className='tripSpana' >{data.tripId} </span>
                        </div>
                    </div>

                </div>
                <div className='rowSpan'>
                    <div className='rowInner' >
                        <div className='key'>
                            <span className='keyValue'>Select Delivery Man :</span>
                        </div>
                        <div className='value'>
                            <Select
                                onChange={(value) => handleDriver(value,'driver')}
                                options={driverListing}
                                styles={colourStyles}
                            />
                        </div>
                    </div>
                    <div style={{width:'50px'}}>
                    <span className='spanOr'>OR</span>

                    </div>

                    <div className='rowInner'  >
                        <div className='key'>
                            <span className='keyValue'>Select Transporter :</span>
                        </div>
                        <div className='value'>
                            <Select
                                onChange={(value) => handleTransporter(value)}
                                options={transporterlisting}
                                styles={colourStyles}
                            />
                        </div>
                    </div>
                </div>

                <div className='createRouteBtn'>
                    <button onClick={() => allocateDriver()}>Allocate Delivery Man</button>
                </div>
            </div>
        </section>
    )
}

export default AllocateDriver;