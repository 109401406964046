import React, { useEffect, useState } from 'react';
import history from '../../services/history'
import { getVehiclesList } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import AddVehicle from './Modal/AddVehicle';
import $ from 'jquery'
import { selectInit } from './../../services/functions'
import axios from 'axios'
import { logoutOnExpiry, url } from './../../services/requests'
import Select from 'react-select';
import { colourStyles } from './../../services/functions'
import { Table } from 'react-bootstrap';
const VehicleSettings = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState({
        detail: '',
        update: '',
        pagination: {pageNumber:'', entries:''}
    })
    const [pageCount, setPageCount] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [searchObj, setSearchObject] = useState({
        page: pageCount,
        limit: pageLimit,
        searchType: '',
        searchValue: ''
    })
    const listing = useSelector((state) => state.getVehicleRed.vehicleListing)
    const loader = useSelector((state) => state.getVehicleRed.loading)
    const message = useSelector((state) => state.getVehicleRed.message)
    const count = useSelector((state) => state.getVehicleRed.count)

    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const _shareData = (item) => {
        let concated = { ...data }
        concated.detail = item
        concated.update = true
        concated.pagination.pageNumber = pageCount
        concated.pagination.entries = pageLimit
        setData(concated)
    }
    const callBack = () => {
        let concated = { ...data }
        concated.detail = ''
        concated.update = false
        concated.pagination.entries = ''
        concated.pagination.pageNumber = ''
        setData(concated)
    }
    const nextPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount += 1);
        });
    }
    const prevPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount -= 1);
        });
    }
    useEffect(() => {
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 50)
        dispatch(getVehiclesList({
            page: pageCount,
            limit: pageLimit,
            searchType: '',
            searchValue: ''
        }))
    }, [dispatch, pageCount])

    const _searchDrop = (id, value) => {
        let temp = { ...searchObj }
        temp.searchType = id
        setSearchObject(temp)

    }
    const _handleChange = (e) => {
        setSearchObject({ ...searchObj, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(getVehiclesList({
            page: '',
            limit: pageLimit,
            searchType: searchObj.searchType,
            searchValue: searchObj.searchValue
        }))
    }
    const deleteVehicle = (id) => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        window.$.fn.show_notification({ message: 'Delete this Vehicle ?', title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
            if (result.type === 'confirm') {
                axios.post(`${url}/vehicle/deleteVehicle`, { _id: id }, { headers: config.headers })
                    .then(response => {
                        const status = response.data.success
                        const message = response.data.message
                        if (status === true) {
                            setPageCount(1)
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                            dispatch(getVehiclesList({
                                page: pageCount,
                                limit: pageLimit,
                                searchType: '',
                                searchValue: ''
                            }))
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                        window.$.fn.show_notification({ message: 'Some Error Occured.', title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                        if (error.error.status === 401) {
                            logoutOnExpiry()
                        }
                    })
            }
        })
    }
    const returnFunction = () => {
        // setPageCount(1)
    }
    const entries = [
        {label: 10, value : 10},
        {label: 20, value : 20},
        {label: 30, value : 30},
        {label: 40, value : 40},
        {label: 50, value : 50}
    ]
    
    const handleChange = (item) => {
        let count = item.value
        setPageCount(1)
        setPageLimit(count)
        dispatch(getVehiclesList({
            page: 1,
            limit: item.value,
            searchType: '',
            searchValue: ''
        }))
    }
    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className='cust-col-5'>
                    <h1 className="title">
                        Vehicle Settings
                    </h1>
                </div>
                <div className='cust-col-5 flex-jc-end'>
                    <h1 className="title font-bold orange_color">
                    </h1>
                </div>
            </div>
            <hr className='bgg' />
            <div className="cust-container">
                <div class="cust-row">
                    <div class="cust-col-10">
                        <div className='bdaOuter'>
                            <div className='searcBox alignCen' >
                                <div className='cust-col-4-new'>
                                    <div className="input-group">
                                        <div className="input-group-prepend" style={{ width: '30%', zIndex: '10', marginRight: '15px' }}>
                                            <select className="select2-single" defaultValue="select" id="vehicleSearch">
                                                <option value="select" disabled>Select</option>
                                                <option value="vehicleNo">Vehicle Number</option>
                                            </select>
                                        </div>
                                        <input type="text" name='searchValue' value={searchObj.searchValue} onChange={(e) => _handleChange(e)} className="form-control" placeholder="Search here..." style={{ zIndex: '10' }} />
                                        <div className="input-group-append" onClick={() => triggerSearch()}>
                                            <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='cust-col-2'>
                                </div>
                            </div>
                            <div className='mapView'>
                                <button className='myMapBtnGrey' onClick={() => { _handleClick('AddVehicle') }}> Add Vehicle</button>
                            </div>
                        </div>
                        {loader === false ?
                            listing && listing.length !== 0 ?
                                <div className="generic-table-wrapper mt20">
                                    <Table className="generic-table">
                                        <thead className="bg-accent">
                                            <tr>
                                                <th>S. No</th>
                                                <th>Vehicle Number</th>
                                                <th>Vehicle Type</th>
                                                <th>Quantity</th>
                                                <th>Vehicle Volume (cm <sup> 3 </sup>)</th>
                                                <th>Freight Rate</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            {listing && listing.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ color: '#007BC9' }}>
                                                            {((pageCount - 1) * (pageLimit)) + index + 1}
                                                        </td>
                                                        <td>{item.vehicleNo ? item.vehicleNo : '-'}</td>
                                                        <td>{item.vehicleType ? item.vehicleType : '-'}</td>
                                                        <td>{item.capacityInNumber !== null ? item.capacityInNumber : '-'}</td>
                                                        <td>{item.capacityInKg !== null ? item.capacityInKg : '-'}</td>
                                                        <td>{item.freightRate ? item.freightRate : '-'}</td>
                                                        <td>
                                                            <>
                                                            <i className="fa fa-pencil-square-o editIcon" style={{ marginRight: '10px' }} onClick={() => { _handleClick('AddVehicle'); _shareData(item) }}></i>
                                                        <i className="fa fa-trash trashIcon" onClick={() => deleteVehicle(item._id)} ></i>
                                                            </>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                                :
                                <div className='noData'>
                                    <p>{message}</p>
                                </div>
                            :
                            <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
                            </div>

                        }
                        {listing && listing.length !== 0 ?
                                <div className='paginationDiv'>
                                <div className='entries'>
                                        <span className='entrieSpan'>Show Entries :</span>
                                        <Select
                                            onChange={(value) => handleChange(value)}
                                            options={entries}
                                            styles={colourStyles}
                                        />
                                    </div>
                                    <button disabled={pageCount === 1 ? true : false} className='paginationBtn' onClick={prevPage}>Previous</button>
                                    <button disabled={Math.floor((count + pageLimit - 1) / pageLimit )=== pageCount ? true : false} className='paginationBtn' onClick={nextPage}>Next</button>
                                </div>
                                : ''}
                    </div>
                </div>
            </div>
            <AddVehicle closeModal={_closeModal} data={data} callBack={callBack} returnFunction={returnFunction} />
        </div>
    )
}
export default VehicleSettings;