import $ from 'jquery'
import Plotly from 'plotly.js/dist/plotly';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

const VehicleLoads = (props) => {
  const points = props && props.coordinates ? props.coordinates : ''
  const pointLists = useSelector((state) => state.getplotRed.plotLists)
  const [data, setData] = useState([])
  const [overlay, setOverlay] = useState(true)
  const [activeInd, setActiveInd] = useState(0)

  useEffect(() => {
    setActiveInd(0)
    setOverlay(true)
    setData(points)
    if (points && points[0] && points[0].lines?.length) {
      let vertices = points && points[0].fig && points[0].fig.data ? points[0].fig.data : ''
      let obj1 = [
        {
          hoverinfo: "skip",
          line: { color: "blue", width: 2 },
          mode: "lines",
          x: points && points[0].frame[0] && points[0].frame[0].frame_x ? points[0].frame[0].frame_x : '',
          y: points && points[0].frame[0] && points[0].frame[0].frame_y ? points[0].frame[0].frame_y : '',
          z: points && points[0].frame[0] && points[0].frame[0].frame_z ? points[0].frame[0].frame_z : '',
          type: "scatter3d",
        }
      ]
      let obj2 = [
        {
          hoverinfo: "skip",
          line: { color: "black", width: 2 },
          mode: "lines",
          x: points && points[0].lines[0] && points[0].lines[0].x ? points[0].lines[0].x : '',
          y: points && points[0].lines[0] && points[0].lines[0].y ? points[0].lines[0].y : '',
          z: points && points[0].lines[0] && points[0].lines[0].z ? points[0].lines[0].z : '',
          type: "scatter3d",
        },
      ]
      if (document.getElementById("plotteDIv")) {
        Plotly.react(
          "plotteDIv",
          obj1,
          {
            margin: { b: 0, l: 0, r: 0, t: 0 },
            scene: {
              aspectmode: "manual",
              aspectratio: { x: 4, y: 1, z: 1 },
              camera: { eye: { x: 2.5, y: 2.5, z: 2.5 } },
            },
            title: {
              text: ` ( ${points && points[activeInd].vehicleDetail && points[activeInd].vehicleDetail.vehicleType ? points[activeInd].vehicleDetail.vehicleType : ''})`,
              // text: `${points && points[activeInd].vehicleDetail && points[activeInd].vehicleDetail.transporter ? points[activeInd].vehicleDetail.transporter : ''} - ( ${points && points[activeInd].vehicleDetail && points[activeInd].vehicleDetail.vehicleType ? points[activeInd].vehicleDetail.vehicleType : ''})`,
              x: 0.5,

              xanchor: "top",
              y: 0.9,
              yanchor: "top",
            },
            showlegend: false,


          }
          // {
          //     responsive: false,
          //     scrollZoom: true,
          //     showTips: false,
          //     logging: true,
          //     showLink: true
          // }
        )
        Plotly.addTraces("plotteDIv", [...vertices])
        Plotly.addTraces("plotteDIv", obj2[0]).then(function () {
          setOverlay(false)
        })
      }
    }
  }, [points])

  const clickk = () => {
    let cylinder = [{

      i: [0, 1, 41, 42, 0, 2, 42, 43, 0, 3, 43, 44, 0, 4, 44, 45, 0, 5,
        45, 46, 0, 6, 46, 47, 0, 7, 47, 48, 0, 8, 48, 49, 0, 9, 49, 50,
        0, 10, 50, 51, 0, 11, 51, 52, 0, 12, 52, 53, 0, 13, 53, 54, 0, 14,
        54, 55, 0, 15, 55, 56, 0, 16, 56, 57, 0, 17, 57, 58, 0, 18, 58, 59,
        0, 19, 59, 60, 0, 20, 60, 61, 0, 21, 61, 62, 0, 22, 62, 63, 0, 23,
        63, 64, 0, 24, 64, 65, 0, 25, 65, 66, 0, 26, 66, 67, 0, 27, 67, 68,
        0, 28, 68, 69, 0, 29, 69, 70, 0, 30, 70, 71, 0, 31, 71, 72, 0, 32,
        72, 73, 0, 33, 73, 74, 0, 34, 74, 75, 0, 35, 75, 76, 0, 36, 76, 77,
        0, 37, 77, 78, 0, 38, 78, 79, 0, 39, 79, 80, 0, 40, 80, 41],
      j: [2, 2, 2, 81, 3, 3, 3, 81, 4, 4, 4, 81, 5, 5, 5, 81, 6, 6,
        6, 81, 7, 7, 7, 81, 8, 8, 8, 81, 9, 9, 9, 81, 10, 10, 10, 81,
        11, 11, 11, 81, 12, 12, 12, 81, 13, 13, 13, 81, 14, 14, 14, 81, 15, 15,
        15, 81, 16, 16, 16, 81, 17, 17, 17, 81, 18, 18, 18, 81, 19, 19, 19, 81,
        20, 20, 20, 81, 21, 21, 21, 81, 22, 22, 22, 81, 23, 23, 23, 81, 24, 24,
        24, 81, 25, 25, 25, 81, 26, 26, 26, 81, 27, 27, 27, 81, 28, 28, 28, 81,
        29, 29, 29, 81, 30, 30, 30, 81, 31, 31, 31, 81, 32, 32, 32, 81, 33, 33,
        33, 81, 34, 34, 34, 81, 35, 35, 35, 81, 36, 36, 36, 81, 37, 37, 37, 81,
        38, 38, 38, 81, 39, 39, 39, 81, 40, 40, 40, 81, 1, 1, 1, 81],
      k: [1, 41, 42, 41, 2, 42, 43, 42, 3, 43, 44, 43, 4, 44, 45, 44, 5, 45,
        46, 45, 6, 46, 47, 46, 7, 47, 48, 47, 8, 48, 49, 48, 9, 49, 50, 49,
        10, 50, 51, 50, 11, 51, 52, 51, 12, 52, 53, 52, 13, 53, 54, 53, 14, 54,
        55, 54, 15, 55, 56, 55, 16, 56, 57, 56, 17, 57, 58, 57, 18, 58, 59, 58,
        19, 59, 60, 59, 20, 60, 61, 60, 21, 61, 62, 61, 22, 62, 63, 62, 23, 63,
        64, 63, 24, 64, 65, 64, 25, 65, 66, 65, 26, 66, 67, 66, 27, 67, 68, 67,
        28, 68, 69, 68, 29, 69, 70, 69, 30, 70, 71, 70, 31, 71, 72, 71, 32, 72,
        73, 72, 33, 73, 74, 73, 34, 74, 75, 74, 35, 75, 76, 75, 36, 76, 77, 76,
        37, 77, 78, 77, 38, 78, 79, 78, 39, 79, 80, 79, 40, 80, 41, 80],
      type: 'mesh3d',
      x: [60., 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 60.],
      y: [60., 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [60.0, 60.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570826, 119.26130043570826],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [78.54101966249684, 78.54101966249684],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130207, 113.46039145130207],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249685, 108.54101966249685],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119285, 102.42640687119285],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754839, 95.26711513754839],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [113.46039145130206, 113.46039145130206],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249685, 78.54101966249685],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241386, 69.38606790241386],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [60.00000000000001, 60.00000000000001],
      y: [120.0, 120.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758615, 50.61393209758615],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.458980337503164, 41.458980337503164],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.760570015627195, 32.760570015627195],
      y: [113.46039145130207, 113.46039145130207],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.73288486245162, 24.73288486245162],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.573593128807154, 17.573593128807154],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.458980337503156, 11.458980337503156],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.5396085486979345, 6.5396085486979345],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907886, 2.9366090222907886],
      y: [78.54101966249685, 78.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917427, 0.7386995642917427],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.0, 0.0],
      y: [60.00000000000001, 60.00000000000001],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917356, 0.7386995642917356],
      y: [50.61393209758616, 50.61393209758616],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907815, 2.9366090222907815],
      y: [41.458980337503164, 41.458980337503164],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.539608548697927, 6.539608548697927],
      y: [32.760570015627195, 32.760570015627195],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.45898033750315, 11.45898033750315],
      y: [24.73288486245162, 24.73288486245162],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.57359312880714, 17.57359312880714],
      y: [17.573593128807154, 17.573593128807154],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.732884862451606, 24.732884862451606],
      y: [11.458980337503156, 11.458980337503156],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.76057001562718, 32.76057001562718],
      y: [6.5396085486979345, 6.5396085486979345],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.45898033750315, 41.45898033750315],
      y: [2.9366090222907886, 2.9366090222907886],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758614, 50.61393209758614],
      y: [0.7386995642917427, 0.7386995642917427],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [59.999999999999986, 59.999999999999986],
      y: [0.0, 0.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241384, 69.38606790241384],
      y: [0.7386995642917356, 0.7386995642917356],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249684, 78.54101966249684],
      y: [2.9366090222907815, 2.9366090222907815],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [6.53960854869792, 6.53960854869792],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754837, 95.26711513754837],
      y: [11.45898033750315, 11.45898033750315],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119284, 102.42640687119284],
      y: [17.57359312880714, 17.57359312880714],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249684, 108.54101966249684],
      y: [24.7328848624516, 24.7328848624516],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130206, 113.46039145130206],
      y: [32.76057001562718, 32.76057001562718],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [41.45898033750314, 41.45898033750314],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570825, 119.26130043570825],
      y: [50.61393209758613, 50.61393209758613],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [59.999999999999986, 59.999999999999986],
      z: [0.0, 120.0]
    },
    {
      i: [0, 1, 41, 42, 0, 2, 42, 43, 0, 3, 43, 44, 0, 4, 44, 45, 0, 5,
        45, 46, 0, 6, 46, 47, 0, 7, 47, 48, 0, 8, 48, 49, 0, 9, 49, 50,
        0, 10, 50, 51, 0, 11, 51, 52, 0, 12, 52, 53, 0, 13, 53, 54, 0, 14,
        54, 55, 0, 15, 55, 56, 0, 16, 56, 57, 0, 17, 57, 58, 0, 18, 58, 59,
        0, 19, 59, 60, 0, 20, 60, 61, 0, 21, 61, 62, 0, 22, 62, 63, 0, 23,
        63, 64, 0, 24, 64, 65, 0, 25, 65, 66, 0, 26, 66, 67, 0, 27, 67, 68,
        0, 28, 68, 69, 0, 29, 69, 70, 0, 30, 70, 71, 0, 31, 71, 72, 0, 32,
        72, 73, 0, 33, 73, 74, 0, 34, 74, 75, 0, 35, 75, 76, 0, 36, 76, 77,
        0, 37, 77, 78, 0, 38, 78, 79, 0, 39, 79, 80, 0, 40, 80, 41],
      j: [2, 2, 2, 81, 3, 3, 3, 81, 4, 4, 4, 81, 5, 5, 5, 81, 6, 6,
        6, 81, 7, 7, 7, 81, 8, 8, 8, 81, 9, 9, 9, 81, 10, 10, 10, 81,
        11, 11, 11, 81, 12, 12, 12, 81, 13, 13, 13, 81, 14, 14, 14, 81, 15, 15,
        15, 81, 16, 16, 16, 81, 17, 17, 17, 81, 18, 18, 18, 81, 19, 19, 19, 81,
        20, 20, 20, 81, 21, 21, 21, 81, 22, 22, 22, 81, 23, 23, 23, 81, 24, 24,
        24, 81, 25, 25, 25, 81, 26, 26, 26, 81, 27, 27, 27, 81, 28, 28, 28, 81,
        29, 29, 29, 81, 30, 30, 30, 81, 31, 31, 31, 81, 32, 32, 32, 81, 33, 33,
        33, 81, 34, 34, 34, 81, 35, 35, 35, 81, 36, 36, 36, 81, 37, 37, 37, 81,
        38, 38, 38, 81, 39, 39, 39, 81, 40, 40, 40, 81, 1, 1, 1, 81],
      k: [1, 41, 42, 41, 2, 42, 43, 42, 3, 43, 44, 43, 4, 44, 45, 44, 5, 45,
        46, 45, 6, 46, 47, 46, 7, 47, 48, 47, 8, 48, 49, 48, 9, 49, 50, 49,
        10, 50, 51, 50, 11, 51, 52, 51, 12, 52, 53, 52, 13, 53, 54, 53, 14, 54,
        55, 54, 15, 55, 56, 55, 16, 56, 57, 56, 17, 57, 58, 57, 18, 58, 59, 58,
        19, 59, 60, 59, 20, 60, 61, 60, 21, 61, 62, 61, 22, 62, 63, 62, 23, 63,
        64, 63, 24, 64, 65, 64, 25, 65, 66, 65, 26, 66, 67, 66, 27, 67, 68, 67,
        28, 68, 69, 68, 29, 69, 70, 69, 30, 70, 71, 70, 31, 71, 72, 71, 32, 72,
        73, 72, 33, 73, 74, 73, 34, 74, 75, 74, 35, 75, 76, 75, 36, 76, 77, 76,
        37, 77, 78, 77, 38, 78, 79, 78, 39, 79, 80, 79, 40, 80, 41, 80],
      type: 'mesh3d',
      x: [60., 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 60.],
      y: [60., 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [60.0, 60.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570826, 119.26130043570826],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [78.54101966249684, 78.54101966249684],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130207, 113.46039145130207],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249685, 108.54101966249685],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119285, 102.42640687119285],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754839, 95.26711513754839],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [113.46039145130206, 113.46039145130206],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249685, 78.54101966249685],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241386, 69.38606790241386],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [60.00000000000001, 60.00000000000001],
      y: [120.0, 120.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758615, 50.61393209758615],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.458980337503164, 41.458980337503164],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.760570015627195, 32.760570015627195],
      y: [113.46039145130207, 113.46039145130207],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.73288486245162, 24.73288486245162],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.573593128807154, 17.573593128807154],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.458980337503156, 11.458980337503156],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.5396085486979345, 6.5396085486979345],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907886, 2.9366090222907886],
      y: [78.54101966249685, 78.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917427, 0.7386995642917427],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.0, 0.0],
      y: [60.00000000000001, 60.00000000000001],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917356, 0.7386995642917356],
      y: [50.61393209758616, 50.61393209758616],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907815, 2.9366090222907815],
      y: [41.458980337503164, 41.458980337503164],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.539608548697927, 6.539608548697927],
      y: [32.760570015627195, 32.760570015627195],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.45898033750315, 11.45898033750315],
      y: [24.73288486245162, 24.73288486245162],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.57359312880714, 17.57359312880714],
      y: [17.573593128807154, 17.573593128807154],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.732884862451606, 24.732884862451606],
      y: [11.458980337503156, 11.458980337503156],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.76057001562718, 32.76057001562718],
      y: [6.5396085486979345, 6.5396085486979345],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.45898033750315, 41.45898033750315],
      y: [2.9366090222907886, 2.9366090222907886],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758614, 50.61393209758614],
      y: [0.7386995642917427, 0.7386995642917427],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [59.999999999999986, 59.999999999999986],
      y: [0.0, 0.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241384, 69.38606790241384],
      y: [0.7386995642917356, 0.7386995642917356],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249684, 78.54101966249684],
      y: [2.9366090222907815, 2.9366090222907815],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [6.53960854869792, 6.53960854869792],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754837, 95.26711513754837],
      y: [11.45898033750315, 11.45898033750315],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119284, 102.42640687119284],
      y: [17.57359312880714, 17.57359312880714],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249684, 108.54101966249684],
      y: [24.7328848624516, 24.7328848624516],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130206, 113.46039145130206],
      y: [32.76057001562718, 32.76057001562718],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [41.45898033750314, 41.45898033750314],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570825, 119.26130043570825],
      y: [50.61393209758613, 50.61393209758613],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [59.999999999999986, 59.999999999999986],
      z: [0.0, 120.0]
    },
    {
      i: [0, 1, 41, 42, 0, 2, 42, 43, 0, 3, 43, 44, 0, 4, 44, 45, 0, 5,
        45, 46, 0, 6, 46, 47, 0, 7, 47, 48, 0, 8, 48, 49, 0, 9, 49, 50,
        0, 10, 50, 51, 0, 11, 51, 52, 0, 12, 52, 53, 0, 13, 53, 54, 0, 14,
        54, 55, 0, 15, 55, 56, 0, 16, 56, 57, 0, 17, 57, 58, 0, 18, 58, 59,
        0, 19, 59, 60, 0, 20, 60, 61, 0, 21, 61, 62, 0, 22, 62, 63, 0, 23,
        63, 64, 0, 24, 64, 65, 0, 25, 65, 66, 0, 26, 66, 67, 0, 27, 67, 68,
        0, 28, 68, 69, 0, 29, 69, 70, 0, 30, 70, 71, 0, 31, 71, 72, 0, 32,
        72, 73, 0, 33, 73, 74, 0, 34, 74, 75, 0, 35, 75, 76, 0, 36, 76, 77,
        0, 37, 77, 78, 0, 38, 78, 79, 0, 39, 79, 80, 0, 40, 80, 41],
      j: [2, 2, 2, 81, 3, 3, 3, 81, 4, 4, 4, 81, 5, 5, 5, 81, 6, 6,
        6, 81, 7, 7, 7, 81, 8, 8, 8, 81, 9, 9, 9, 81, 10, 10, 10, 81,
        11, 11, 11, 81, 12, 12, 12, 81, 13, 13, 13, 81, 14, 14, 14, 81, 15, 15,
        15, 81, 16, 16, 16, 81, 17, 17, 17, 81, 18, 18, 18, 81, 19, 19, 19, 81,
        20, 20, 20, 81, 21, 21, 21, 81, 22, 22, 22, 81, 23, 23, 23, 81, 24, 24,
        24, 81, 25, 25, 25, 81, 26, 26, 26, 81, 27, 27, 27, 81, 28, 28, 28, 81,
        29, 29, 29, 81, 30, 30, 30, 81, 31, 31, 31, 81, 32, 32, 32, 81, 33, 33,
        33, 81, 34, 34, 34, 81, 35, 35, 35, 81, 36, 36, 36, 81, 37, 37, 37, 81,
        38, 38, 38, 81, 39, 39, 39, 81, 40, 40, 40, 81, 1, 1, 1, 81],
      k: [1, 41, 42, 41, 2, 42, 43, 42, 3, 43, 44, 43, 4, 44, 45, 44, 5, 45,
        46, 45, 6, 46, 47, 46, 7, 47, 48, 47, 8, 48, 49, 48, 9, 49, 50, 49,
        10, 50, 51, 50, 11, 51, 52, 51, 12, 52, 53, 52, 13, 53, 54, 53, 14, 54,
        55, 54, 15, 55, 56, 55, 16, 56, 57, 56, 17, 57, 58, 57, 18, 58, 59, 58,
        19, 59, 60, 59, 20, 60, 61, 60, 21, 61, 62, 61, 22, 62, 63, 62, 23, 63,
        64, 63, 24, 64, 65, 64, 25, 65, 66, 65, 26, 66, 67, 66, 27, 67, 68, 67,
        28, 68, 69, 68, 29, 69, 70, 69, 30, 70, 71, 70, 31, 71, 72, 71, 32, 72,
        73, 72, 33, 73, 74, 73, 34, 74, 75, 74, 35, 75, 76, 75, 36, 76, 77, 76,
        37, 77, 78, 77, 38, 78, 79, 78, 39, 79, 80, 79, 40, 80, 41, 80],
      type: 'mesh3d',
      x: [60., 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 60.],
      y: [60., 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [60.0, 60.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570826, 119.26130043570826],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [78.54101966249684, 78.54101966249684],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130207, 113.46039145130207],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249685, 108.54101966249685],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119285, 102.42640687119285],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754839, 95.26711513754839],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [113.46039145130206, 113.46039145130206],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249685, 78.54101966249685],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241386, 69.38606790241386],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [60.00000000000001, 60.00000000000001],
      y: [120.0, 120.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758615, 50.61393209758615],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.458980337503164, 41.458980337503164],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.760570015627195, 32.760570015627195],
      y: [113.46039145130207, 113.46039145130207],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.73288486245162, 24.73288486245162],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.573593128807154, 17.573593128807154],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.458980337503156, 11.458980337503156],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.5396085486979345, 6.5396085486979345],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907886, 2.9366090222907886],
      y: [78.54101966249685, 78.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917427, 0.7386995642917427],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.0, 0.0],
      y: [60.00000000000001, 60.00000000000001],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917356, 0.7386995642917356],
      y: [50.61393209758616, 50.61393209758616],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907815, 2.9366090222907815],
      y: [41.458980337503164, 41.458980337503164],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.539608548697927, 6.539608548697927],
      y: [32.760570015627195, 32.760570015627195],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.45898033750315, 11.45898033750315],
      y: [24.73288486245162, 24.73288486245162],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.57359312880714, 17.57359312880714],
      y: [17.573593128807154, 17.573593128807154],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.732884862451606, 24.732884862451606],
      y: [11.458980337503156, 11.458980337503156],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.76057001562718, 32.76057001562718],
      y: [6.5396085486979345, 6.5396085486979345],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.45898033750315, 41.45898033750315],
      y: [2.9366090222907886, 2.9366090222907886],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758614, 50.61393209758614],
      y: [0.7386995642917427, 0.7386995642917427],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [59.999999999999986, 59.999999999999986],
      y: [0.0, 0.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241384, 69.38606790241384],
      y: [0.7386995642917356, 0.7386995642917356],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249684, 78.54101966249684],
      y: [2.9366090222907815, 2.9366090222907815],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [6.53960854869792, 6.53960854869792],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754837, 95.26711513754837],
      y: [11.45898033750315, 11.45898033750315],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119284, 102.42640687119284],
      y: [17.57359312880714, 17.57359312880714],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249684, 108.54101966249684],
      y: [24.7328848624516, 24.7328848624516],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130206, 113.46039145130206],
      y: [32.76057001562718, 32.76057001562718],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [41.45898033750314, 41.45898033750314],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570825, 119.26130043570825],
      y: [50.61393209758613, 50.61393209758613],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [59.999999999999986, 59.999999999999986],
      z: [0.0, 120.0]
    },
    {
      i: [0, 1, 41, 42, 0, 2, 42, 43, 0, 3, 43, 44, 0, 4, 44, 45, 0, 5,
        45, 46, 0, 6, 46, 47, 0, 7, 47, 48, 0, 8, 48, 49, 0, 9, 49, 50,
        0, 10, 50, 51, 0, 11, 51, 52, 0, 12, 52, 53, 0, 13, 53, 54, 0, 14,
        54, 55, 0, 15, 55, 56, 0, 16, 56, 57, 0, 17, 57, 58, 0, 18, 58, 59,
        0, 19, 59, 60, 0, 20, 60, 61, 0, 21, 61, 62, 0, 22, 62, 63, 0, 23,
        63, 64, 0, 24, 64, 65, 0, 25, 65, 66, 0, 26, 66, 67, 0, 27, 67, 68,
        0, 28, 68, 69, 0, 29, 69, 70, 0, 30, 70, 71, 0, 31, 71, 72, 0, 32,
        72, 73, 0, 33, 73, 74, 0, 34, 74, 75, 0, 35, 75, 76, 0, 36, 76, 77,
        0, 37, 77, 78, 0, 38, 78, 79, 0, 39, 79, 80, 0, 40, 80, 41],
      j: [2, 2, 2, 81, 3, 3, 3, 81, 4, 4, 4, 81, 5, 5, 5, 81, 6, 6,
        6, 81, 7, 7, 7, 81, 8, 8, 8, 81, 9, 9, 9, 81, 10, 10, 10, 81,
        11, 11, 11, 81, 12, 12, 12, 81, 13, 13, 13, 81, 14, 14, 14, 81, 15, 15,
        15, 81, 16, 16, 16, 81, 17, 17, 17, 81, 18, 18, 18, 81, 19, 19, 19, 81,
        20, 20, 20, 81, 21, 21, 21, 81, 22, 22, 22, 81, 23, 23, 23, 81, 24, 24,
        24, 81, 25, 25, 25, 81, 26, 26, 26, 81, 27, 27, 27, 81, 28, 28, 28, 81,
        29, 29, 29, 81, 30, 30, 30, 81, 31, 31, 31, 81, 32, 32, 32, 81, 33, 33,
        33, 81, 34, 34, 34, 81, 35, 35, 35, 81, 36, 36, 36, 81, 37, 37, 37, 81,
        38, 38, 38, 81, 39, 39, 39, 81, 40, 40, 40, 81, 1, 1, 1, 81],
      k: [1, 41, 42, 41, 2, 42, 43, 42, 3, 43, 44, 43, 4, 44, 45, 44, 5, 45,
        46, 45, 6, 46, 47, 46, 7, 47, 48, 47, 8, 48, 49, 48, 9, 49, 50, 49,
        10, 50, 51, 50, 11, 51, 52, 51, 12, 52, 53, 52, 13, 53, 54, 53, 14, 54,
        55, 54, 15, 55, 56, 55, 16, 56, 57, 56, 17, 57, 58, 57, 18, 58, 59, 58,
        19, 59, 60, 59, 20, 60, 61, 60, 21, 61, 62, 61, 22, 62, 63, 62, 23, 63,
        64, 63, 24, 64, 65, 64, 25, 65, 66, 65, 26, 66, 67, 66, 27, 67, 68, 67,
        28, 68, 69, 68, 29, 69, 70, 69, 30, 70, 71, 70, 31, 71, 72, 71, 32, 72,
        73, 72, 33, 73, 74, 73, 34, 74, 75, 74, 35, 75, 76, 75, 36, 76, 77, 76,
        37, 77, 78, 77, 38, 78, 79, 78, 39, 79, 80, 79, 40, 80, 41, 80],
      type: 'mesh3d',
      x: [60., 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 60.],
      y: [60., 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [60.0, 60.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570826, 119.26130043570826],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [78.54101966249684, 78.54101966249684],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130207, 113.46039145130207],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249685, 108.54101966249685],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119285, 102.42640687119285],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754839, 95.26711513754839],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [113.46039145130206, 113.46039145130206],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249685, 78.54101966249685],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241386, 69.38606790241386],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [60.00000000000001, 60.00000000000001],
      y: [120.0, 120.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758615, 50.61393209758615],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.458980337503164, 41.458980337503164],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.760570015627195, 32.760570015627195],
      y: [113.46039145130207, 113.46039145130207],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.73288486245162, 24.73288486245162],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.573593128807154, 17.573593128807154],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.458980337503156, 11.458980337503156],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.5396085486979345, 6.5396085486979345],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907886, 2.9366090222907886],
      y: [78.54101966249685, 78.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917427, 0.7386995642917427],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.0, 0.0],
      y: [60.00000000000001, 60.00000000000001],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917356, 0.7386995642917356],
      y: [50.61393209758616, 50.61393209758616],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907815, 2.9366090222907815],
      y: [41.458980337503164, 41.458980337503164],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.539608548697927, 6.539608548697927],
      y: [32.760570015627195, 32.760570015627195],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.45898033750315, 11.45898033750315],
      y: [24.73288486245162, 24.73288486245162],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.57359312880714, 17.57359312880714],
      y: [17.573593128807154, 17.573593128807154],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.732884862451606, 24.732884862451606],
      y: [11.458980337503156, 11.458980337503156],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.76057001562718, 32.76057001562718],
      y: [6.5396085486979345, 6.5396085486979345],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.45898033750315, 41.45898033750315],
      y: [2.9366090222907886, 2.9366090222907886],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758614, 50.61393209758614],
      y: [0.7386995642917427, 0.7386995642917427],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [59.999999999999986, 59.999999999999986],
      y: [0.0, 0.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241384, 69.38606790241384],
      y: [0.7386995642917356, 0.7386995642917356],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249684, 78.54101966249684],
      y: [2.9366090222907815, 2.9366090222907815],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [6.53960854869792, 6.53960854869792],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754837, 95.26711513754837],
      y: [11.45898033750315, 11.45898033750315],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119284, 102.42640687119284],
      y: [17.57359312880714, 17.57359312880714],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249684, 108.54101966249684],
      y: [24.7328848624516, 24.7328848624516],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130206, 113.46039145130206],
      y: [32.76057001562718, 32.76057001562718],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [41.45898033750314, 41.45898033750314],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570825, 119.26130043570825],
      y: [50.61393209758613, 50.61393209758613],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [59.999999999999986, 59.999999999999986],
      z: [0.0, 120.0]
    },
    {
      i: [0, 1, 41, 42, 0, 2, 42, 43, 0, 3, 43, 44, 0, 4, 44, 45, 0, 5,
        45, 46, 0, 6, 46, 47, 0, 7, 47, 48, 0, 8, 48, 49, 0, 9, 49, 50,
        0, 10, 50, 51, 0, 11, 51, 52, 0, 12, 52, 53, 0, 13, 53, 54, 0, 14,
        54, 55, 0, 15, 55, 56, 0, 16, 56, 57, 0, 17, 57, 58, 0, 18, 58, 59,
        0, 19, 59, 60, 0, 20, 60, 61, 0, 21, 61, 62, 0, 22, 62, 63, 0, 23,
        63, 64, 0, 24, 64, 65, 0, 25, 65, 66, 0, 26, 66, 67, 0, 27, 67, 68,
        0, 28, 68, 69, 0, 29, 69, 70, 0, 30, 70, 71, 0, 31, 71, 72, 0, 32,
        72, 73, 0, 33, 73, 74, 0, 34, 74, 75, 0, 35, 75, 76, 0, 36, 76, 77,
        0, 37, 77, 78, 0, 38, 78, 79, 0, 39, 79, 80, 0, 40, 80, 41],
      j: [2, 2, 2, 81, 3, 3, 3, 81, 4, 4, 4, 81, 5, 5, 5, 81, 6, 6,
        6, 81, 7, 7, 7, 81, 8, 8, 8, 81, 9, 9, 9, 81, 10, 10, 10, 81,
        11, 11, 11, 81, 12, 12, 12, 81, 13, 13, 13, 81, 14, 14, 14, 81, 15, 15,
        15, 81, 16, 16, 16, 81, 17, 17, 17, 81, 18, 18, 18, 81, 19, 19, 19, 81,
        20, 20, 20, 81, 21, 21, 21, 81, 22, 22, 22, 81, 23, 23, 23, 81, 24, 24,
        24, 81, 25, 25, 25, 81, 26, 26, 26, 81, 27, 27, 27, 81, 28, 28, 28, 81,
        29, 29, 29, 81, 30, 30, 30, 81, 31, 31, 31, 81, 32, 32, 32, 81, 33, 33,
        33, 81, 34, 34, 34, 81, 35, 35, 35, 81, 36, 36, 36, 81, 37, 37, 37, 81,
        38, 38, 38, 81, 39, 39, 39, 81, 40, 40, 40, 81, 1, 1, 1, 81],
      k: [1, 41, 42, 41, 2, 42, 43, 42, 3, 43, 44, 43, 4, 44, 45, 44, 5, 45,
        46, 45, 6, 46, 47, 46, 7, 47, 48, 47, 8, 48, 49, 48, 9, 49, 50, 49,
        10, 50, 51, 50, 11, 51, 52, 51, 12, 52, 53, 52, 13, 53, 54, 53, 14, 54,
        55, 54, 15, 55, 56, 55, 16, 56, 57, 56, 17, 57, 58, 57, 18, 58, 59, 58,
        19, 59, 60, 59, 20, 60, 61, 60, 21, 61, 62, 61, 22, 62, 63, 62, 23, 63,
        64, 63, 24, 64, 65, 64, 25, 65, 66, 65, 26, 66, 67, 66, 27, 67, 68, 67,
        28, 68, 69, 68, 29, 69, 70, 69, 30, 70, 71, 70, 31, 71, 72, 71, 32, 72,
        73, 72, 33, 73, 74, 73, 34, 74, 75, 74, 35, 75, 76, 75, 36, 76, 77, 76,
        37, 77, 78, 77, 38, 78, 79, 78, 39, 79, 80, 79, 40, 80, 41, 80],
      type: 'mesh3d',
      x: [60., 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 60.],
      y: [60., 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [60.0, 60.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570826, 119.26130043570826],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [78.54101966249684, 78.54101966249684],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130207, 113.46039145130207],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249685, 108.54101966249685],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119285, 102.42640687119285],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754839, 95.26711513754839],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [113.46039145130206, 113.46039145130206],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249685, 78.54101966249685],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241386, 69.38606790241386],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [60.00000000000001, 60.00000000000001],
      y: [120.0, 120.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758615, 50.61393209758615],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.458980337503164, 41.458980337503164],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.760570015627195, 32.760570015627195],
      y: [113.46039145130207, 113.46039145130207],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.73288486245162, 24.73288486245162],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.573593128807154, 17.573593128807154],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.458980337503156, 11.458980337503156],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.5396085486979345, 6.5396085486979345],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907886, 2.9366090222907886],
      y: [78.54101966249685, 78.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917427, 0.7386995642917427],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.0, 0.0],
      y: [60.00000000000001, 60.00000000000001],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917356, 0.7386995642917356],
      y: [50.61393209758616, 50.61393209758616],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907815, 2.9366090222907815],
      y: [41.458980337503164, 41.458980337503164],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.539608548697927, 6.539608548697927],
      y: [32.760570015627195, 32.760570015627195],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.45898033750315, 11.45898033750315],
      y: [24.73288486245162, 24.73288486245162],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.57359312880714, 17.57359312880714],
      y: [17.573593128807154, 17.573593128807154],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.732884862451606, 24.732884862451606],
      y: [11.458980337503156, 11.458980337503156],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.76057001562718, 32.76057001562718],
      y: [6.5396085486979345, 6.5396085486979345],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.45898033750315, 41.45898033750315],
      y: [2.9366090222907886, 2.9366090222907886],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758614, 50.61393209758614],
      y: [0.7386995642917427, 0.7386995642917427],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [59.999999999999986, 59.999999999999986],
      y: [0.0, 0.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241384, 69.38606790241384],
      y: [0.7386995642917356, 0.7386995642917356],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249684, 78.54101966249684],
      y: [2.9366090222907815, 2.9366090222907815],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [6.53960854869792, 6.53960854869792],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754837, 95.26711513754837],
      y: [11.45898033750315, 11.45898033750315],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119284, 102.42640687119284],
      y: [17.57359312880714, 17.57359312880714],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249684, 108.54101966249684],
      y: [24.7328848624516, 24.7328848624516],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130206, 113.46039145130206],
      y: [32.76057001562718, 32.76057001562718],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [41.45898033750314, 41.45898033750314],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570825, 119.26130043570825],
      y: [50.61393209758613, 50.61393209758613],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [59.999999999999986, 59.999999999999986],
      z: [0.0, 120.0]
    },
    {
      i: [0, 1, 41, 42, 0, 2, 42, 43, 0, 3, 43, 44, 0, 4, 44, 45, 0, 5,
        45, 46, 0, 6, 46, 47, 0, 7, 47, 48, 0, 8, 48, 49, 0, 9, 49, 50,
        0, 10, 50, 51, 0, 11, 51, 52, 0, 12, 52, 53, 0, 13, 53, 54, 0, 14,
        54, 55, 0, 15, 55, 56, 0, 16, 56, 57, 0, 17, 57, 58, 0, 18, 58, 59,
        0, 19, 59, 60, 0, 20, 60, 61, 0, 21, 61, 62, 0, 22, 62, 63, 0, 23,
        63, 64, 0, 24, 64, 65, 0, 25, 65, 66, 0, 26, 66, 67, 0, 27, 67, 68,
        0, 28, 68, 69, 0, 29, 69, 70, 0, 30, 70, 71, 0, 31, 71, 72, 0, 32,
        72, 73, 0, 33, 73, 74, 0, 34, 74, 75, 0, 35, 75, 76, 0, 36, 76, 77,
        0, 37, 77, 78, 0, 38, 78, 79, 0, 39, 79, 80, 0, 40, 80, 41],
      j: [2, 2, 2, 81, 3, 3, 3, 81, 4, 4, 4, 81, 5, 5, 5, 81, 6, 6,
        6, 81, 7, 7, 7, 81, 8, 8, 8, 81, 9, 9, 9, 81, 10, 10, 10, 81,
        11, 11, 11, 81, 12, 12, 12, 81, 13, 13, 13, 81, 14, 14, 14, 81, 15, 15,
        15, 81, 16, 16, 16, 81, 17, 17, 17, 81, 18, 18, 18, 81, 19, 19, 19, 81,
        20, 20, 20, 81, 21, 21, 21, 81, 22, 22, 22, 81, 23, 23, 23, 81, 24, 24,
        24, 81, 25, 25, 25, 81, 26, 26, 26, 81, 27, 27, 27, 81, 28, 28, 28, 81,
        29, 29, 29, 81, 30, 30, 30, 81, 31, 31, 31, 81, 32, 32, 32, 81, 33, 33,
        33, 81, 34, 34, 34, 81, 35, 35, 35, 81, 36, 36, 36, 81, 37, 37, 37, 81,
        38, 38, 38, 81, 39, 39, 39, 81, 40, 40, 40, 81, 1, 1, 1, 81],
      k: [1, 41, 42, 41, 2, 42, 43, 42, 3, 43, 44, 43, 4, 44, 45, 44, 5, 45,
        46, 45, 6, 46, 47, 46, 7, 47, 48, 47, 8, 48, 49, 48, 9, 49, 50, 49,
        10, 50, 51, 50, 11, 51, 52, 51, 12, 52, 53, 52, 13, 53, 54, 53, 14, 54,
        55, 54, 15, 55, 56, 55, 16, 56, 57, 56, 17, 57, 58, 57, 18, 58, 59, 58,
        19, 59, 60, 59, 20, 60, 61, 60, 21, 61, 62, 61, 22, 62, 63, 62, 23, 63,
        64, 63, 24, 64, 65, 64, 25, 65, 66, 65, 26, 66, 67, 66, 27, 67, 68, 67,
        28, 68, 69, 68, 29, 69, 70, 69, 30, 70, 71, 70, 31, 71, 72, 71, 32, 72,
        73, 72, 33, 73, 74, 73, 34, 74, 75, 74, 35, 75, 76, 75, 36, 76, 77, 76,
        37, 77, 78, 77, 38, 78, 79, 78, 39, 79, 80, 79, 40, 80, 41, 80],
      type: 'mesh3d',
      x: [60., 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 60.],
      y: [60., 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120.],
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [60.0, 60.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570826, 119.26130043570826],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [78.54101966249684, 78.54101966249684],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130207, 113.46039145130207],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249685, 108.54101966249685],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119285, 102.42640687119285],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754839, 95.26711513754839],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [113.46039145130206, 113.46039145130206],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249685, 78.54101966249685],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241386, 69.38606790241386],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [60.00000000000001, 60.00000000000001],
      y: [120.0, 120.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758615, 50.61393209758615],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.458980337503164, 41.458980337503164],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.760570015627195, 32.760570015627195],
      y: [113.46039145130207, 113.46039145130207],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.73288486245162, 24.73288486245162],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.573593128807154, 17.573593128807154],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.458980337503156, 11.458980337503156],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.5396085486979345, 6.5396085486979345],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907886, 2.9366090222907886],
      y: [78.54101966249685, 78.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917427, 0.7386995642917427],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.0, 0.0],
      y: [60.00000000000001, 60.00000000000001],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917356, 0.7386995642917356],
      y: [50.61393209758616, 50.61393209758616],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907815, 2.9366090222907815],
      y: [41.458980337503164, 41.458980337503164],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.539608548697927, 6.539608548697927],
      y: [32.760570015627195, 32.760570015627195],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.45898033750315, 11.45898033750315],
      y: [24.73288486245162, 24.73288486245162],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.57359312880714, 17.57359312880714],
      y: [17.573593128807154, 17.573593128807154],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.732884862451606, 24.732884862451606],
      y: [11.458980337503156, 11.458980337503156],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.76057001562718, 32.76057001562718],
      y: [6.5396085486979345, 6.5396085486979345],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.45898033750315, 41.45898033750315],
      y: [2.9366090222907886, 2.9366090222907886],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758614, 50.61393209758614],
      y: [0.7386995642917427, 0.7386995642917427],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [59.999999999999986, 59.999999999999986],
      y: [0.0, 0.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241384, 69.38606790241384],
      y: [0.7386995642917356, 0.7386995642917356],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249684, 78.54101966249684],
      y: [2.9366090222907815, 2.9366090222907815],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [6.53960854869792, 6.53960854869792],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754837, 95.26711513754837],
      y: [11.45898033750315, 11.45898033750315],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119284, 102.42640687119284],
      y: [17.57359312880714, 17.57359312880714],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249684, 108.54101966249684],
      y: [24.7328848624516, 24.7328848624516],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130206, 113.46039145130206],
      y: [32.76057001562718, 32.76057001562718],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [41.45898033750314, 41.45898033750314],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570825, 119.26130043570825],
      y: [50.61393209758613, 50.61393209758613],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [59.999999999999986, 59.999999999999986],
      z: [0.0, 120.0]
    },
    {
      i: [0, 1, 41, 42, 0, 2, 42, 43, 0, 3, 43, 44, 0, 4, 44, 45, 0, 5,
        45, 46, 0, 6, 46, 47, 0, 7, 47, 48, 0, 8, 48, 49, 0, 9, 49, 50,
        0, 10, 50, 51, 0, 11, 51, 52, 0, 12, 52, 53, 0, 13, 53, 54, 0, 14,
        54, 55, 0, 15, 55, 56, 0, 16, 56, 57, 0, 17, 57, 58, 0, 18, 58, 59,
        0, 19, 59, 60, 0, 20, 60, 61, 0, 21, 61, 62, 0, 22, 62, 63, 0, 23,
        63, 64, 0, 24, 64, 65, 0, 25, 65, 66, 0, 26, 66, 67, 0, 27, 67, 68,
        0, 28, 68, 69, 0, 29, 69, 70, 0, 30, 70, 71, 0, 31, 71, 72, 0, 32,
        72, 73, 0, 33, 73, 74, 0, 34, 74, 75, 0, 35, 75, 76, 0, 36, 76, 77,
        0, 37, 77, 78, 0, 38, 78, 79, 0, 39, 79, 80, 0, 40, 80, 41],
      j: [2, 2, 2, 81, 3, 3, 3, 81, 4, 4, 4, 81, 5, 5, 5, 81, 6, 6,
        6, 81, 7, 7, 7, 81, 8, 8, 8, 81, 9, 9, 9, 81, 10, 10, 10, 81,
        11, 11, 11, 81, 12, 12, 12, 81, 13, 13, 13, 81, 14, 14, 14, 81, 15, 15,
        15, 81, 16, 16, 16, 81, 17, 17, 17, 81, 18, 18, 18, 81, 19, 19, 19, 81,
        20, 20, 20, 81, 21, 21, 21, 81, 22, 22, 22, 81, 23, 23, 23, 81, 24, 24,
        24, 81, 25, 25, 25, 81, 26, 26, 26, 81, 27, 27, 27, 81, 28, 28, 28, 81,
        29, 29, 29, 81, 30, 30, 30, 81, 31, 31, 31, 81, 32, 32, 32, 81, 33, 33,
        33, 81, 34, 34, 34, 81, 35, 35, 35, 81, 36, 36, 36, 81, 37, 37, 37, 81,
        38, 38, 38, 81, 39, 39, 39, 81, 40, 40, 40, 81, 1, 1, 1, 81],
      k: [1, 41, 42, 41, 2, 42, 43, 42, 3, 43, 44, 43, 4, 44, 45, 44, 5, 45,
        46, 45, 6, 46, 47, 46, 7, 47, 48, 47, 8, 48, 49, 48, 9, 49, 50, 49,
        10, 50, 51, 50, 11, 51, 52, 51, 12, 52, 53, 52, 13, 53, 54, 53, 14, 54,
        55, 54, 15, 55, 56, 55, 16, 56, 57, 56, 17, 57, 58, 57, 18, 58, 59, 58,
        19, 59, 60, 59, 20, 60, 61, 60, 21, 61, 62, 61, 22, 62, 63, 62, 23, 63,
        64, 63, 24, 64, 65, 64, 25, 65, 66, 65, 26, 66, 67, 66, 27, 67, 68, 67,
        28, 68, 69, 68, 29, 69, 70, 69, 30, 70, 71, 70, 31, 71, 72, 71, 32, 72,
        73, 72, 33, 73, 74, 73, 34, 74, 75, 74, 35, 75, 76, 75, 36, 76, 77, 76,
        37, 77, 78, 77, 38, 78, 79, 78, 39, 79, 80, 79, 40, 80, 41, 80],
      type: 'mesh3d',
      x: [60., 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 60.],
      y: [60., 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [60.0, 60.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570826, 119.26130043570826],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [78.54101966249684, 78.54101966249684],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130207, 113.46039145130207],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249685, 108.54101966249685],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119285, 102.42640687119285],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754839, 95.26711513754839],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [113.46039145130206, 113.46039145130206],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249685, 78.54101966249685],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241386, 69.38606790241386],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [60.00000000000001, 60.00000000000001],
      y: [120.0, 120.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758615, 50.61393209758615],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.458980337503164, 41.458980337503164],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.760570015627195, 32.760570015627195],
      y: [113.46039145130207, 113.46039145130207],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.73288486245162, 24.73288486245162],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.573593128807154, 17.573593128807154],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.458980337503156, 11.458980337503156],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.5396085486979345, 6.5396085486979345],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907886, 2.9366090222907886],
      y: [78.54101966249685, 78.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917427, 0.7386995642917427],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.0, 0.0],
      y: [60.00000000000001, 60.00000000000001],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917356, 0.7386995642917356],
      y: [50.61393209758616, 50.61393209758616],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907815, 2.9366090222907815],
      y: [41.458980337503164, 41.458980337503164],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.539608548697927, 6.539608548697927],
      y: [32.760570015627195, 32.760570015627195],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.45898033750315, 11.45898033750315],
      y: [24.73288486245162, 24.73288486245162],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.57359312880714, 17.57359312880714],
      y: [17.573593128807154, 17.573593128807154],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.732884862451606, 24.732884862451606],
      y: [11.458980337503156, 11.458980337503156],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.76057001562718, 32.76057001562718],
      y: [6.5396085486979345, 6.5396085486979345],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.45898033750315, 41.45898033750315],
      y: [2.9366090222907886, 2.9366090222907886],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758614, 50.61393209758614],
      y: [0.7386995642917427, 0.7386995642917427],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [59.999999999999986, 59.999999999999986],
      y: [0.0, 0.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241384, 69.38606790241384],
      y: [0.7386995642917356, 0.7386995642917356],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249684, 78.54101966249684],
      y: [2.9366090222907815, 2.9366090222907815],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [6.53960854869792, 6.53960854869792],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754837, 95.26711513754837],
      y: [11.45898033750315, 11.45898033750315],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119284, 102.42640687119284],
      y: [17.57359312880714, 17.57359312880714],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249684, 108.54101966249684],
      y: [24.7328848624516, 24.7328848624516],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130206, 113.46039145130206],
      y: [32.76057001562718, 32.76057001562718],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [41.45898033750314, 41.45898033750314],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570825, 119.26130043570825],
      y: [50.61393209758613, 50.61393209758613],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [59.999999999999986, 59.999999999999986],
      z: [0.0, 120.0]
    },
    {
      i: [0, 1, 41, 42, 0, 2, 42, 43, 0, 3, 43, 44, 0, 4, 44, 45, 0, 5,
        45, 46, 0, 6, 46, 47, 0, 7, 47, 48, 0, 8, 48, 49, 0, 9, 49, 50,
        0, 10, 50, 51, 0, 11, 51, 52, 0, 12, 52, 53, 0, 13, 53, 54, 0, 14,
        54, 55, 0, 15, 55, 56, 0, 16, 56, 57, 0, 17, 57, 58, 0, 18, 58, 59,
        0, 19, 59, 60, 0, 20, 60, 61, 0, 21, 61, 62, 0, 22, 62, 63, 0, 23,
        63, 64, 0, 24, 64, 65, 0, 25, 65, 66, 0, 26, 66, 67, 0, 27, 67, 68,
        0, 28, 68, 69, 0, 29, 69, 70, 0, 30, 70, 71, 0, 31, 71, 72, 0, 32,
        72, 73, 0, 33, 73, 74, 0, 34, 74, 75, 0, 35, 75, 76, 0, 36, 76, 77,
        0, 37, 77, 78, 0, 38, 78, 79, 0, 39, 79, 80, 0, 40, 80, 41],
      j: [2, 2, 2, 81, 3, 3, 3, 81, 4, 4, 4, 81, 5, 5, 5, 81, 6, 6,
        6, 81, 7, 7, 7, 81, 8, 8, 8, 81, 9, 9, 9, 81, 10, 10, 10, 81,
        11, 11, 11, 81, 12, 12, 12, 81, 13, 13, 13, 81, 14, 14, 14, 81, 15, 15,
        15, 81, 16, 16, 16, 81, 17, 17, 17, 81, 18, 18, 18, 81, 19, 19, 19, 81,
        20, 20, 20, 81, 21, 21, 21, 81, 22, 22, 22, 81, 23, 23, 23, 81, 24, 24,
        24, 81, 25, 25, 25, 81, 26, 26, 26, 81, 27, 27, 27, 81, 28, 28, 28, 81,
        29, 29, 29, 81, 30, 30, 30, 81, 31, 31, 31, 81, 32, 32, 32, 81, 33, 33,
        33, 81, 34, 34, 34, 81, 35, 35, 35, 81, 36, 36, 36, 81, 37, 37, 37, 81,
        38, 38, 38, 81, 39, 39, 39, 81, 40, 40, 40, 81, 1, 1, 1, 81],
      k: [1, 41, 42, 41, 2, 42, 43, 42, 3, 43, 44, 43, 4, 44, 45, 44, 5, 45,
        46, 45, 6, 46, 47, 46, 7, 47, 48, 47, 8, 48, 49, 48, 9, 49, 50, 49,
        10, 50, 51, 50, 11, 51, 52, 51, 12, 52, 53, 52, 13, 53, 54, 53, 14, 54,
        55, 54, 15, 55, 56, 55, 16, 56, 57, 56, 17, 57, 58, 57, 18, 58, 59, 58,
        19, 59, 60, 59, 20, 60, 61, 60, 21, 61, 62, 61, 22, 62, 63, 62, 23, 63,
        64, 63, 24, 64, 65, 64, 25, 65, 66, 65, 26, 66, 67, 66, 27, 67, 68, 67,
        28, 68, 69, 68, 29, 69, 70, 69, 30, 70, 71, 70, 31, 71, 72, 71, 32, 72,
        73, 72, 33, 73, 74, 73, 34, 74, 75, 74, 35, 75, 76, 75, 36, 76, 77, 76,
        37, 77, 78, 77, 38, 78, 79, 78, 39, 79, 80, 79, 40, 80, 41, 80],
      type: 'mesh3d',
      x: [60., 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 60.],
      y: [60., 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [60.0, 60.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570826, 119.26130043570826],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [78.54101966249684, 78.54101966249684],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130207, 113.46039145130207],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249685, 108.54101966249685],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119285, 102.42640687119285],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754839, 95.26711513754839],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [113.46039145130206, 113.46039145130206],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249685, 78.54101966249685],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241386, 69.38606790241386],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [60.00000000000001, 60.00000000000001],
      y: [120.0, 120.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758615, 50.61393209758615],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.458980337503164, 41.458980337503164],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.760570015627195, 32.760570015627195],
      y: [113.46039145130207, 113.46039145130207],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.73288486245162, 24.73288486245162],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.573593128807154, 17.573593128807154],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.458980337503156, 11.458980337503156],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.5396085486979345, 6.5396085486979345],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907886, 2.9366090222907886],
      y: [78.54101966249685, 78.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917427, 0.7386995642917427],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.0, 0.0],
      y: [60.00000000000001, 60.00000000000001],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917356, 0.7386995642917356],
      y: [50.61393209758616, 50.61393209758616],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907815, 2.9366090222907815],
      y: [41.458980337503164, 41.458980337503164],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.539608548697927, 6.539608548697927],
      y: [32.760570015627195, 32.760570015627195],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.45898033750315, 11.45898033750315],
      y: [24.73288486245162, 24.73288486245162],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.57359312880714, 17.57359312880714],
      y: [17.573593128807154, 17.573593128807154],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.732884862451606, 24.732884862451606],
      y: [11.458980337503156, 11.458980337503156],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.76057001562718, 32.76057001562718],
      y: [6.5396085486979345, 6.5396085486979345],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.45898033750315, 41.45898033750315],
      y: [2.9366090222907886, 2.9366090222907886],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758614, 50.61393209758614],
      y: [0.7386995642917427, 0.7386995642917427],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [59.999999999999986, 59.999999999999986],
      y: [0.0, 0.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241384, 69.38606790241384],
      y: [0.7386995642917356, 0.7386995642917356],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249684, 78.54101966249684],
      y: [2.9366090222907815, 2.9366090222907815],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [6.53960854869792, 6.53960854869792],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754837, 95.26711513754837],
      y: [11.45898033750315, 11.45898033750315],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119284, 102.42640687119284],
      y: [17.57359312880714, 17.57359312880714],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249684, 108.54101966249684],
      y: [24.7328848624516, 24.7328848624516],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130206, 113.46039145130206],
      y: [32.76057001562718, 32.76057001562718],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [41.45898033750314, 41.45898033750314],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570825, 119.26130043570825],
      y: [50.61393209758613, 50.61393209758613],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [59.999999999999986, 59.999999999999986],
      z: [0.0, 120.0]
    },
    {
      i: [0, 1, 41, 42, 0, 2, 42, 43, 0, 3, 43, 44, 0, 4, 44, 45, 0, 5,
        45, 46, 0, 6, 46, 47, 0, 7, 47, 48, 0, 8, 48, 49, 0, 9, 49, 50,
        0, 10, 50, 51, 0, 11, 51, 52, 0, 12, 52, 53, 0, 13, 53, 54, 0, 14,
        54, 55, 0, 15, 55, 56, 0, 16, 56, 57, 0, 17, 57, 58, 0, 18, 58, 59,
        0, 19, 59, 60, 0, 20, 60, 61, 0, 21, 61, 62, 0, 22, 62, 63, 0, 23,
        63, 64, 0, 24, 64, 65, 0, 25, 65, 66, 0, 26, 66, 67, 0, 27, 67, 68,
        0, 28, 68, 69, 0, 29, 69, 70, 0, 30, 70, 71, 0, 31, 71, 72, 0, 32,
        72, 73, 0, 33, 73, 74, 0, 34, 74, 75, 0, 35, 75, 76, 0, 36, 76, 77,
        0, 37, 77, 78, 0, 38, 78, 79, 0, 39, 79, 80, 0, 40, 80, 41],
      j: [2, 2, 2, 81, 3, 3, 3, 81, 4, 4, 4, 81, 5, 5, 5, 81, 6, 6,
        6, 81, 7, 7, 7, 81, 8, 8, 8, 81, 9, 9, 9, 81, 10, 10, 10, 81,
        11, 11, 11, 81, 12, 12, 12, 81, 13, 13, 13, 81, 14, 14, 14, 81, 15, 15,
        15, 81, 16, 16, 16, 81, 17, 17, 17, 81, 18, 18, 18, 81, 19, 19, 19, 81,
        20, 20, 20, 81, 21, 21, 21, 81, 22, 22, 22, 81, 23, 23, 23, 81, 24, 24,
        24, 81, 25, 25, 25, 81, 26, 26, 26, 81, 27, 27, 27, 81, 28, 28, 28, 81,
        29, 29, 29, 81, 30, 30, 30, 81, 31, 31, 31, 81, 32, 32, 32, 81, 33, 33,
        33, 81, 34, 34, 34, 81, 35, 35, 35, 81, 36, 36, 36, 81, 37, 37, 37, 81,
        38, 38, 38, 81, 39, 39, 39, 81, 40, 40, 40, 81, 1, 1, 1, 81],
      k: [1, 41, 42, 41, 2, 42, 43, 42, 3, 43, 44, 43, 4, 44, 45, 44, 5, 45,
        46, 45, 6, 46, 47, 46, 7, 47, 48, 47, 8, 48, 49, 48, 9, 49, 50, 49,
        10, 50, 51, 50, 11, 51, 52, 51, 12, 52, 53, 52, 13, 53, 54, 53, 14, 54,
        55, 54, 15, 55, 56, 55, 16, 56, 57, 56, 17, 57, 58, 57, 18, 58, 59, 58,
        19, 59, 60, 59, 20, 60, 61, 60, 21, 61, 62, 61, 22, 62, 63, 62, 23, 63,
        64, 63, 24, 64, 65, 64, 25, 65, 66, 65, 26, 66, 67, 66, 27, 67, 68, 67,
        28, 68, 69, 68, 29, 69, 70, 69, 30, 70, 71, 70, 31, 71, 72, 71, 32, 72,
        73, 72, 33, 73, 74, 73, 34, 74, 75, 74, 35, 75, 76, 75, 36, 76, 77, 76,
        37, 77, 78, 77, 38, 78, 79, 78, 39, 79, 80, 79, 40, 80, 41, 80],
      type: 'mesh3d',
      x: [60., 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 60.],
      y: [60., 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [60.0, 60.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570826, 119.26130043570826],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [78.54101966249684, 78.54101966249684],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130207, 113.46039145130207],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249685, 108.54101966249685],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119285, 102.42640687119285],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754839, 95.26711513754839],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [113.46039145130206, 113.46039145130206],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249685, 78.54101966249685],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241386, 69.38606790241386],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [60.00000000000001, 60.00000000000001],
      y: [120.0, 120.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758615, 50.61393209758615],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.458980337503164, 41.458980337503164],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.760570015627195, 32.760570015627195],
      y: [113.46039145130207, 113.46039145130207],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.73288486245162, 24.73288486245162],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.573593128807154, 17.573593128807154],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.458980337503156, 11.458980337503156],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.5396085486979345, 6.5396085486979345],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907886, 2.9366090222907886],
      y: [78.54101966249685, 78.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917427, 0.7386995642917427],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.0, 0.0],
      y: [60.00000000000001, 60.00000000000001],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917356, 0.7386995642917356],
      y: [50.61393209758616, 50.61393209758616],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907815, 2.9366090222907815],
      y: [41.458980337503164, 41.458980337503164],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.539608548697927, 6.539608548697927],
      y: [32.760570015627195, 32.760570015627195],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.45898033750315, 11.45898033750315],
      y: [24.73288486245162, 24.73288486245162],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.57359312880714, 17.57359312880714],
      y: [17.573593128807154, 17.573593128807154],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.732884862451606, 24.732884862451606],
      y: [11.458980337503156, 11.458980337503156],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.76057001562718, 32.76057001562718],
      y: [6.5396085486979345, 6.5396085486979345],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.45898033750315, 41.45898033750315],
      y: [2.9366090222907886, 2.9366090222907886],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758614, 50.61393209758614],
      y: [0.7386995642917427, 0.7386995642917427],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [59.999999999999986, 59.999999999999986],
      y: [0.0, 0.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241384, 69.38606790241384],
      y: [0.7386995642917356, 0.7386995642917356],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249684, 78.54101966249684],
      y: [2.9366090222907815, 2.9366090222907815],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [6.53960854869792, 6.53960854869792],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754837, 95.26711513754837],
      y: [11.45898033750315, 11.45898033750315],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119284, 102.42640687119284],
      y: [17.57359312880714, 17.57359312880714],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249684, 108.54101966249684],
      y: [24.7328848624516, 24.7328848624516],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130206, 113.46039145130206],
      y: [32.76057001562718, 32.76057001562718],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [41.45898033750314, 41.45898033750314],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570825, 119.26130043570825],
      y: [50.61393209758613, 50.61393209758613],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [59.999999999999986, 59.999999999999986],
      z: [0.0, 120.0]
    },
    {
      i: [0, 1, 41, 42, 0, 2, 42, 43, 0, 3, 43, 44, 0, 4, 44, 45, 0, 5,
        45, 46, 0, 6, 46, 47, 0, 7, 47, 48, 0, 8, 48, 49, 0, 9, 49, 50,
        0, 10, 50, 51, 0, 11, 51, 52, 0, 12, 52, 53, 0, 13, 53, 54, 0, 14,
        54, 55, 0, 15, 55, 56, 0, 16, 56, 57, 0, 17, 57, 58, 0, 18, 58, 59,
        0, 19, 59, 60, 0, 20, 60, 61, 0, 21, 61, 62, 0, 22, 62, 63, 0, 23,
        63, 64, 0, 24, 64, 65, 0, 25, 65, 66, 0, 26, 66, 67, 0, 27, 67, 68,
        0, 28, 68, 69, 0, 29, 69, 70, 0, 30, 70, 71, 0, 31, 71, 72, 0, 32,
        72, 73, 0, 33, 73, 74, 0, 34, 74, 75, 0, 35, 75, 76, 0, 36, 76, 77,
        0, 37, 77, 78, 0, 38, 78, 79, 0, 39, 79, 80, 0, 40, 80, 41],
      j: [2, 2, 2, 81, 3, 3, 3, 81, 4, 4, 4, 81, 5, 5, 5, 81, 6, 6,
        6, 81, 7, 7, 7, 81, 8, 8, 8, 81, 9, 9, 9, 81, 10, 10, 10, 81,
        11, 11, 11, 81, 12, 12, 12, 81, 13, 13, 13, 81, 14, 14, 14, 81, 15, 15,
        15, 81, 16, 16, 16, 81, 17, 17, 17, 81, 18, 18, 18, 81, 19, 19, 19, 81,
        20, 20, 20, 81, 21, 21, 21, 81, 22, 22, 22, 81, 23, 23, 23, 81, 24, 24,
        24, 81, 25, 25, 25, 81, 26, 26, 26, 81, 27, 27, 27, 81, 28, 28, 28, 81,
        29, 29, 29, 81, 30, 30, 30, 81, 31, 31, 31, 81, 32, 32, 32, 81, 33, 33,
        33, 81, 34, 34, 34, 81, 35, 35, 35, 81, 36, 36, 36, 81, 37, 37, 37, 81,
        38, 38, 38, 81, 39, 39, 39, 81, 40, 40, 40, 81, 1, 1, 1, 81],
      k: [1, 41, 42, 41, 2, 42, 43, 42, 3, 43, 44, 43, 4, 44, 45, 44, 5, 45,
        46, 45, 6, 46, 47, 46, 7, 47, 48, 47, 8, 48, 49, 48, 9, 49, 50, 49,
        10, 50, 51, 50, 11, 51, 52, 51, 12, 52, 53, 52, 13, 53, 54, 53, 14, 54,
        55, 54, 15, 55, 56, 55, 16, 56, 57, 56, 17, 57, 58, 57, 18, 58, 59, 58,
        19, 59, 60, 59, 20, 60, 61, 60, 21, 61, 62, 61, 22, 62, 63, 62, 23, 63,
        64, 63, 24, 64, 65, 64, 25, 65, 66, 65, 26, 66, 67, 66, 27, 67, 68, 67,
        28, 68, 69, 68, 29, 69, 70, 69, 30, 70, 71, 70, 31, 71, 72, 71, 32, 72,
        73, 72, 33, 73, 74, 73, 34, 74, 75, 74, 35, 75, 76, 75, 36, 76, 77, 76,
        37, 77, 78, 77, 38, 78, 79, 78, 39, 79, 80, 79, 40, 80, 41, 80],
      type: 'mesh3d',
      x: [60., 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 60.],
      y: [60., 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60., 69.3860679, 78.54101966, 87.23942998,
        95.26711514, 102.42640687, 108.54101966, 113.46039145, 117.06339098,
        119.26130044, 120., 119.26130044, 117.06339098, 113.46039145,
        108.54101966, 102.42640687, 95.26711514, 87.23942998, 78.54101966,
        69.3860679, 60., 50.6139321, 41.45898034, 32.76057002,
        24.73288486, 17.57359313, 11.45898034, 6.53960855, 2.93660902,
        0.73869956, 0., 0.73869956, 2.93660902, 6.53960855,
        11.45898034, 17.57359313, 24.73288486, 32.76057002, 41.45898034,
        50.6139321, 60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0., 0.,
        0., 0., 0., 0., 0.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120.],
      y: [60., 69.3860679, 78.54101966, 87.23942998, 95.26711514,
        102.42640687, 108.54101966, 113.46039145, 117.06339098, 119.26130044,
        120., 119.26130044, 117.06339098, 113.46039145, 108.54101966,
        102.42640687, 95.26711514, 87.23942998, 78.54101966, 69.3860679,
        60., 50.6139321, 41.45898034, 32.76057002, 24.73288486,
        17.57359313, 11.45898034, 6.53960855, 2.93660902, 0.73869956,
        0., 0.73869956, 2.93660902, 6.53960855, 11.45898034,
        17.57359313, 24.73288486, 32.76057002, 41.45898034, 50.6139321,
        60.],
      z: [120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120., 120.,
        120., 120., 120., 120., 120.]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [60.0, 60.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570826, 119.26130043570826],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [78.54101966249684, 78.54101966249684],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130207, 113.46039145130207],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249685, 108.54101966249685],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119285, 102.42640687119285],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754839, 95.26711513754839],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [113.46039145130206, 113.46039145130206],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249685, 78.54101966249685],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241386, 69.38606790241386],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [60.00000000000001, 60.00000000000001],
      y: [120.0, 120.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758615, 50.61393209758615],
      y: [119.26130043570826, 119.26130043570826],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.458980337503164, 41.458980337503164],
      y: [117.06339097770922, 117.06339097770922],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.760570015627195, 32.760570015627195],
      y: [113.46039145130207, 113.46039145130207],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.73288486245162, 24.73288486245162],
      y: [108.54101966249685, 108.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.573593128807154, 17.573593128807154],
      y: [102.42640687119285, 102.42640687119285],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.458980337503156, 11.458980337503156],
      y: [95.26711513754839, 95.26711513754839],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.5396085486979345, 6.5396085486979345],
      y: [87.2394299843728, 87.2394299843728],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907886, 2.9366090222907886],
      y: [78.54101966249685, 78.54101966249685],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917427, 0.7386995642917427],
      y: [69.38606790241386, 69.38606790241386],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.0, 0.0],
      y: [60.00000000000001, 60.00000000000001],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [0.7386995642917356, 0.7386995642917356],
      y: [50.61393209758616, 50.61393209758616],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [2.9366090222907815, 2.9366090222907815],
      y: [41.458980337503164, 41.458980337503164],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [6.539608548697927, 6.539608548697927],
      y: [32.760570015627195, 32.760570015627195],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [11.45898033750315, 11.45898033750315],
      y: [24.73288486245162, 24.73288486245162],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [17.57359312880714, 17.57359312880714],
      y: [17.573593128807154, 17.573593128807154],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [24.732884862451606, 24.732884862451606],
      y: [11.458980337503156, 11.458980337503156],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [32.76057001562718, 32.76057001562718],
      y: [6.5396085486979345, 6.5396085486979345],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [41.45898033750315, 41.45898033750315],
      y: [2.9366090222907886, 2.9366090222907886],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [50.61393209758614, 50.61393209758614],
      y: [0.7386995642917427, 0.7386995642917427],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [59.999999999999986, 59.999999999999986],
      y: [0.0, 0.0],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [69.38606790241384, 69.38606790241384],
      y: [0.7386995642917356, 0.7386995642917356],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [78.54101966249684, 78.54101966249684],
      y: [2.9366090222907815, 2.9366090222907815],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [87.2394299843728, 87.2394299843728],
      y: [6.53960854869792, 6.53960854869792],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [95.26711513754837, 95.26711513754837],
      y: [11.45898033750315, 11.45898033750315],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [102.42640687119284, 102.42640687119284],
      y: [17.57359312880714, 17.57359312880714],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [108.54101966249684, 108.54101966249684],
      y: [24.7328848624516, 24.7328848624516],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [113.46039145130206, 113.46039145130206],
      y: [32.76057001562718, 32.76057001562718],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [117.06339097770922, 117.06339097770922],
      y: [41.45898033750314, 41.45898033750314],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [119.26130043570825, 119.26130043570825],
      y: [50.61393209758613, 50.61393209758613],
      z: [0.0, 120.0]
    },
    {
      line: { color: '#202020', 'width': 3 },
      mode: 'lines',
      showlegend: false,
      type: 'scatter3d',
      x: [120.0, 120.0],
      y: [59.999999999999986, 59.999999999999986],
      z: [0.0, 120.0]
    },
    {
      'hoverinfo': 'skip',
      line: { color: 'blue', 'width': 2 },
      mode: 'lines',
      type: 'scatter3d',
      x: [0, 1202.4, 1202.4, 0, 0, null, 0, 1202.4, 1202.4, 0, 0, null,
        0, 0, null, 1202.4, 1202.4, null, 1202.4, 1202.4, null, 0, 0],
      y: [0, 0, 235, 235, 0, null, 0, 0, 235, 235, 0, null, 0, 0, null,
        0, 0, null, 235, 235, null, 235, 235],
      z: [0, 0, 0, 0, 0, null, 269.7, 269.7, 269.7, 269.7, 269.7, null,
        0, 269.7, null, 0, 269.7, null, 0, 269.7, null, 0, 269.7]
    }]


    if (document.getElementById("plotteDIv")) {
      Plotly.react(
        "plotteDIv",
        cylinder,
        {
          margin: { b: 0, l: 0, r: 0, t: 0 },
          scene: {
            aspectmode: "manual",
            aspectratio: { x: 4, y: 1, z: 1 },
            camera: { eye: { x: 2.5, y: 2.5, z: 2.5 } },
          },
          title: {
            // text: `${points && points[activeInd].vehicleDetail && points[activeInd].vehicleDetail.transporter ? points[activeInd].vehicleDetail.transporter : ''} - ( ${points && points[activeInd].vehicleDetail && points[activeInd].vehicleDetail.vehicleType ? points[activeInd].vehicleDetail.vehicleType : '' })`,
            x: 0.5,

            xanchor: "top",
            y: 0.9,
            yanchor: "top",
          },
          showlegend: false,


        }

      )

    }



  }

  useEffect(() => {
    if (!overlay) {
      $(".overlayModalFixed").addClass("overlayNone");
    }
  }, [overlay])

  const coordsPlot = (index) => {
    // Plotly.purge('plotteDIv');
    setActiveInd(index)
    setOverlay(true)
    setTimeout(() => {
      if (pointLists && pointLists[index] && pointLists[index].lines?.length) {
        let vertices = pointLists && pointLists[index].fig && pointLists[index].fig.data ? pointLists[index].fig.data : ''
        let obj1 = [
          {
            hoverinfo: "skip",
            line: { color: "blue", width: 2 },
            mode: "lines",
            x: pointLists && pointLists[index].frame[0] && pointLists[index].frame[0].frame_x ? pointLists[index].frame[0].frame_x : '',
            y: pointLists && pointLists[index].frame[0] && pointLists[index].frame[0].frame_y ? pointLists[index].frame[0].frame_y : '',
            z: pointLists && pointLists[index].frame[0] && pointLists[index].frame[0].frame_z ? pointLists[index].frame[0].frame_z : '',
            type: "scatter3d",
          }
        ]
        let obj2 = [
          {
            hoverinfo: "skip",
            line: { color: "black", width: 2 },
            mode: "lines",
            x: pointLists && pointLists[index].lines[0] && pointLists[index].lines[0].x ? pointLists[index].lines[0].x : '',
            y: pointLists && pointLists[index].lines[0] && pointLists[index].lines[0].y ? pointLists[index].lines[0].y : '',
            z: pointLists && pointLists[index].lines[0] && pointLists[index].lines[0].z ? pointLists[index].lines[0].z : '',
            type: "scatter3d",
          },
        ]

        Plotly.react(
          "plotteDIv",
          obj1,
          {
            margin: { b: 0, l: 0, r: 0, t: 0 },
            scene: {
              aspectmode: "manual",
              aspectratio: { x: 4, y: 1, z: 1 },
              camera: { eye: { x: 2.5, y: 2.5, z: 2.5 } },
            },
            title: {
              text: `( ${pointLists && pointLists[activeInd].vehicleDetail && pointLists[activeInd].vehicleDetail.vehicleType ? pointLists[activeInd].vehicleDetail.vehicleType : ''})`,
              // text: `${pointLists && pointLists[activeInd].vehicleDetail && pointLists[activeInd].vehicleDetail.transporter ? pointLists[activeInd].vehicleDetail.transporter : ''} - ( ${pointLists && pointLists[activeInd].vehicleDetail && pointLists[activeInd].vehicleDetail.vehicleType ? pointLists[activeInd].vehicleDetail.vehicleType : ''})`,
              x: 0.5,
              xanchor: "top",
              y: 0.9,
              yanchor: "top",
            },
            showlegend: false
          }
        )
        Plotly.addTraces("plotteDIv", [...vertices])
        Plotly.addTraces("plotteDIv", obj2[0]).then(function () {
          setOverlay(false)
        })
      }
    }, 100)
  }
  const slideBox = () => {
    if ($(".overContainer").hasClass("open")) {
      $(".overContainer").removeClass("open");
      $(".overContainer").addClass("close");
    }
    else {
      $(".overContainer").removeClass("close");
      $(".overContainer").addClass("open");
    }
  }
  const _closeModal = () => {
    Plotly.purge('plotteDIv');
    setData([])
    setActiveInd(0)
    setOverlay(true)
    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
    $(".custom-modal").removeClass("custom-modal-is-visible")
  }
  useEffect(() => {
    setData(pointLists)
  }, [pointLists])

  const coordsPlot2 = (index) => {
    let vertices = pointLists && pointLists[0].fig && pointLists[0].fig.data ? pointLists[0].fig.data : ''
    let obj1 = [
      {
        hoverinfo: "skip",
        line: { color: "blue", width: 2 },
        mode: "lines",
        x: pointLists && pointLists[0].frame[0] && pointLists[0].frame[0].frame_x ? pointLists[0].frame[0].frame_x : '',
        y: pointLists && pointLists[0].frame[0] && pointLists[0].frame[0].frame_y ? pointLists[0].frame[0].frame_y : '',
        z: pointLists && pointLists[0].frame[0] && pointLists[0].frame[0].frame_z ? pointLists[0].frame[0].frame_z : '',
        type: "scatter3d",
      }
    ]
    let obj2 = [
      {
        hoverinfo: "skip",
        line: { color: "black", width: 2 },
        mode: "lines",
        x: pointLists && pointLists[0].lines[0] && pointLists[0].lines[0].x ? pointLists[0].lines[0].x : '',
        y: pointLists && pointLists[0].lines[0] && pointLists[0].lines[0].y ? pointLists[0].lines[0].y : '',
        z: pointLists && pointLists[0].lines[0] && pointLists[0].lines[0].z ? pointLists[0].lines[0].z : '',
        type: "scatter3d",
      },
    ]
    if (document.getElementById("plotteDIv")) {
      Plotly.react(
        "plotteDIv",
        obj1,
        {
          margin: { b: 0, l: 0, r: 0, t: 0 },
          scene: {
            aspectmode: "manual",
            aspectratio: { x: 4, y: 1, z: 1 },
            camera: { eye: { x: 2.5, y: 2.5, z: 2.5 } },
          },
          title: {
            // text: `${points && points[activeInd].vehicleDetail && points[activeInd].vehicleDetail.transporter ? points[activeInd].vehicleDetail.transporter : ''} - ( ${points && points[activeInd].vehicleDetail && points[activeInd].vehicleDetail.vehicleType ? points[activeInd].vehicleDetail.vehicleType : '' })`,
            x: 0.5,

            xanchor: "top",
            y: 0.9,
            yanchor: "top",
          },
          showlegend: false,


        }
        // {
        //     responsive: false,
        //     scrollZoom: true,
        //     showTips: false,
        //     logging: true,
        //     showLink: true
        // }
      )
      Plotly.addTraces("plotteDIv", [...vertices])
      Plotly.addTraces("plotteDIv", obj2[0]).then(function () {
        setOverlay(false)
      })
    }
  }



  return (
    <>
      <section id="VehicleLoads" className="custom-modal toggle_width" style={{ width: "77%", }}>
        <div className="custom-modal-header">
          <h1>Load Distribution</h1>
          <span className="ct-close" onClick={_closeModal}></span>
        </div>
        <div className="custom-modal-body" style={{ overflow: 'auto' }}>

          <div className='vehTypeOuter'>
            <div className='vehTypeLeft'>
              <div className='vehTyleHeader'>
                <span>Vehicle Types</span>
                {/* <button onClick={coordsPlot2}>PLOT</button> */}
              </div> 

              <div className='vehTypeLists'>
                {pointLists && pointLists.map((item, index) => {
                  return (
                    <>
                      <div key={index} className={activeInd === index ? 'vehicleType' : 'vehicleTypeInactive'} onClick={() => { coordsPlot(index); }} >
                        {item && item.vehicleDetail && item.vehicleDetail.transporter ? item.vehicleDetail.transporter : '-'} 
                       
                      </div>
                      {item.recommended !== '0' ?
                      index === item.totalquantity -1 ?
                        <span className='recomendedText'>{`${item.recommended}` ? `Recommended +${item.recommended} more trucks.` :''}</span>
                        : ''
                      :''}
                    </>
                  )
                })}
              </div>

            </div>
            <div className='vehTypeRight'>
              {overlay ?
                <div className='overlayModal overlayModalFixed '>
                  <div className='overlayContent'>
                    <span className='routePreview' style={{ color: '#000', fontSize: '0.8rem', fontWeight: '800', opacity: '1', zIndex: '30000' }}>Arranging your Order...</span>
                    <i className="fa fa-spinner fa-spin loadingSpinner" style={{ color: '#000', fontSize: '1rem', fontWeight: '800' }}></i>
                  </div>
                </div>
                : ""}



              <div>
                <div id="plotteDIv" className="plotly-graph-div1" ></div>
                <div className='overContainer'>
                  {/* <div onClick={slideBox} className='clip'><span className='roundCircleNew'></span></div> */}
                  <div className='containerRow containerRowHeader' >
                    <div className='boxColor colorblack'>Color</div>
                    <div className='boxName colorblack '>Box Name</div>
                    <div className='boxQty colorblack'>Qty</div>
                  </div>
                  {data && data.length !== 0 ?
                    data && data[activeInd] && data[activeInd].boxDetails ? data[activeInd].boxDetails.map((item, ind) => {
                      return (
                        <div className='containerRow'>
                          <div className='boxColor'><div className='colorDiv' style={{ background: item.color }} ></div></div>
                          <div className='boxName' style={{ color: item.color }} >{item.name}</div>
                          <div className='boxQty' style={{ color: item.color }}>{item.quantity}</div>
                        </div>
                      )
                    }) : ''
                    : ''}



                  <div className='containerRow' style={{ border: 'none', height: '46px' }}>
                    <div className='boxName bn50'>Space Utilized -</div>
                    {data && data.length !== 0 ?
                      data && data[activeInd] && data[activeInd].vehicleDetail && data[activeInd].vehicleDetail ?
                        <div className='boxQty'>
                          <span className='contSpan newcontSpan'>
                            {data && data[activeInd] && data[activeInd].vehicleDetail && data[activeInd].vehicleDetail.spaceUtilization ? data[activeInd].vehicleDetail.spaceUtilization : '-'} %
                          </span>
                        </div>
                        : ''
                      : ''}
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default VehicleLoads;