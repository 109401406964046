import { USERS , USERS_SHOW , USERS_HIDE , USERS_MSSG , USERS_COUNT} from './types'
import { post , logoutOnExpiry } from './../../../services/requests'

export const getUsers = (data) => {
    return (dispatch) => {
        dispatch(showLoader())
        post('user/getUsers', {...data  })  
            .then(response => {
                const status = response.success
                const usersList = response.data
                const count = response.count
                const message = response && response.message ? response.message : ''
                if(status === true && usersList !== undefined){
                    dispatch(getUsersListing(usersList))
                    dispatch(getCount(count))
                    dispatch(hideLoader())
                    dispatch(userMessage(''))
                }
                else{
                    dispatch(getUsersListing())
                    dispatch(hideLoader())
                    dispatch(userMessage(message))
                }
            })
            .catch(error => {
                dispatch(hideLoader())
                dispatch(userMessage('Some Error Occured.'))
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }
}


export const getUsersListing = (usersList) => {
    return {
        type: USERS,
        payload: usersList
    }
}
export const showLoader = () =>{
    return{
        type : USERS_SHOW,
    }
}
export const hideLoader = () =>{
    return{
        type : USERS_HIDE,
    }
}
export const userMessage = (message) =>{
    return{
        type : USERS_MSSG,
        payload : message
    }
}
export const getCount = (count) =>{
    return{
        type : USERS_COUNT,
        payload : count
    }
}