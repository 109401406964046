import { CASH_COUNT , CASH_LIST , CASH_SHOW , CASH_HIDE , CASH_MESSAGE } from "../actions/types";

const initialState={
    cashListing: [],
    loading: true,
    message: '',
    count :''
} 

const cashMemoListingRed =(state=initialState,{type,payload})=>{
    switch(type){
        case CASH_LIST : return{
            ...state,
            cashListing : payload
        }
        case CASH_SHOW : return{ 
            ...state,
            loading:true 
        }
        case CASH_HIDE : return{ 
            ...state,
            loading:false 
        }
        case CASH_MESSAGE : return{ 
            ...state,
            message: payload 
        }
        case CASH_COUNT : return{ 
            ...state,
            count: payload 
        }
        default: return state;  
    }
}
export default cashMemoListingRed ;