import { selectInit } from './../../../services/functions'
import { useEffect, useState } from 'react'
import $ from 'jquery'
import { getAddress, getRequestList } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import Select from 'react-select';
import { logoutOnExpiry , url } from './../../../services/requests'
import { colourStyles } from './../../../services/functions'

const AddRequest = (props) => {  
    const data = props && props.info && props.info.data ? props.info.data : ''
    const status = props && props.info && props.info.update ? true : false
    const [updateObj, setUpdateObj] = useState({})
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.addressListRed.addressListing)
    const pageNumber = props && props.info && props.info.pagination && props.info.pagination.pageNumber ? props.info.pagination.pageNumber : ''
    const entries = props && props.info && props.info.pagination && props.info.pagination.entries ? props.info.pagination.entries : ''
    const [slotTime, setSlotTime] = useState({
        startSlotTime: 'select',
        endSlotTime: 'select'
    })
    const [shapeToggle, setShapeToggle] = useState({ shapeType: "select" })
    const [filteredSlots, setFilteredslots] = useState([])
    const [updateSlot, setUpdateSlot] = useState([])
    const [requestState, setReqState] = useState({
        address: {
            userId: "",
            address: "select",
            userName: "",
            geofenceId: "",
            status: "",
            base: "lynkit",
            latitude: "",
            longitude: ""
        },
        quantity: "",
        unit: "",
        date: "",
        // requestType: "select"
    })
    const [contactDetail, setContactDetails] = useState({
        personName: '',
        mobileNo: '',
        email: '',
        company: ''
    })
    const [dimensions, setDimensions] = useState({
        length: '',
        width: '',
        height: '',
        radius: '',
        volume: ''
    })
    if (props && props.info && props.info.update === true) {
        if ($(".reqBody").hasClass("hide")) {
            $(".reqBody").removeClass("hide");
            $(".reqBody").addClass("open");
        }
        else {
            // $(".reqBody").removeClass("open");
            // $(".reqBody").addClass("hide");

        }
    }
    const _onChange = (e) => {
        setReqState({ ...requestState, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        dispatch(getAddress({
            searchType: '',
            searchValue: ''
        }))
    }, [dispatch])

    useEffect(() => {
        setTimeout(() => {
            selectInit(_handleDrop)
        }, 100);
        let dummy = { ...updateObj }
        dummy.address = data && data.address && data.address.address ? data.address.address : ''
        dummy.mobile = data && data.address && data.address.mobile ? data.address.mobile : ''
        dummy.latitude = data && data.address && data.address.latitude ? data.address.latitude : ''
        dummy.longitude = data && data.address && data.address.longitude ? data.address.longitude : ''
        dummy.quantity = data.quantity
        dummy.userName = data && data.address && data.address.userName ? data.address.userName : ''
        // dummy.person = data && data.address && data.address.userName ? data.address.userName : ''
        dummy.unit = data && data.unit ? data.unit  : ''
        dummy.date = data.date
        dummy.startSlotTime = data && data.startSlotTime ? data.startSlotTime.toString() : ''
        dummy.endSlotTime = data && data.endSlotTime ? data.endSlotTime.toString() : ''

        dummy.personName = data && data.contactDetail && data.contactDetail.personName ? data.contactDetail.personName : ''
        dummy.mobileNo = data && data.contactDetail && data.contactDetail.mobileNo ? data.contactDetail.mobileNo : ''
        dummy.email = data && data.contactDetail && data.contactDetail.email ? data.contactDetail.email : ''
        dummy.company = data && data.contactDetail && data.contactDetail.company ? data.contactDetail.company : ''
        // dummy.requestType = data && data.requestType ? data.requestType : ''
        dummy.shapeType = data && data.shapeType ? data.shapeType : ''
        dummy.length = data && data.length ? data.length : ''
        dummy.width = data && data.width ? data.width : ''
        dummy.height = data && data.height ? data.height : ''
        dummy.radius = data && data.radius ? data.radius : ''
        dummy.volume = data && data.volume ? data.volume : ''
        shapeToggle.shapeType = data && data.shapeType ? data.shapeType : 'select'

        let num = Number(data.startSlotTime)
        for (let i = num + 1; i <= 24; i++) {
            updateSlot.push({
                label: i <= 9 ? `0` + i : i, value: i
            })
        }
        setUpdateObj(dummy)
    }, [data])

    const _handleDrop = (e) => {
        const id = e.target.value
        let temp = { ...requestState }
        temp.address.userId = listing[id]._id
        temp.address.address = listing[id].address
        temp.address.userName = listing[id].userName
        temp.address.geofenceId = listing[id].geofenceId
        temp.address.status = listing[id].status
        temp.address.latitude = listing[id].latitude
        temp.address.longitude = listing[id].longitude
        setReqState(temp)

        contactDetail.personName = listing[id].contactDetails.personName
        contactDetail.mobileNo = listing[id].contactDetails.mobileNo
        contactDetail.email = listing[id].contactDetails.email
        contactDetail.company = listing[id].company
    }  
    const addRequest = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        let dummyRequest = {...requestState}
        dummyRequest.unit =  localStorage.getItem('unit')
        let concated = { ...dummyRequest, ...slotTime, contactDetail, ...dimensions, ...shapeToggle }
        if (requestState.address.address === '' && requestState.address.address === 'select') {
                window.$.fn.show_notification({ message: `Address is mandatory.`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
        }
        else if(slotTime.startSlotTime === 'select' &&  slotTime.endSlotTime === 'select'){
            window.$.fn.show_notification({ message: `Time Slot is mandatory.`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else { 
            axios.post(`${url}/request/addRequest`, { ...concated }, { headers: config.headers })
                .then(response => {
                    const status = response.data.success
                    const message = response.data.message
                    if (status === true) {
                        setReqState({
                            address: {
                                userId: "",
                                address: "select",
                                userName: "",
                                geofenceId: "",
                                status: "",
                                base: "lynkit",
                                latitude: "",
                                longitude: ""
                            },
                            quantity: "",
                            unit: "",
                            date: "",
                        })
                        setSlotTime({
                            startSlotTime: 'select',
                            endSlotTime: 'select'
                        })
                        setContactDetails({
                            personName: '',
                            mobileNo: '',
                            email: '',
                            company: ''
                        })
                        setDimensions({
                            length: '',
                            width: '',
                            height: '',
                            radius: '',
                            volume: ''
                        })
                        shapeToggle.shapeType = 'Select'
                        dispatch(getRequestList({
                            page: 1,
                            limit: 10,
                            searchType: '',
                            searchValue: ''
                        }))

                        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                        $(".custom-modal").removeClass("custom-modal-is-visible")
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        }
    
    }
    const _onUpdate = (e, item) => {
        setUpdateObj({ ...updateObj, [e.target.name]: e.target.value })
    }
    const editRequest = () => {
        let finalObj = {
            _id: data._id,
            updateDetails: {
                address: {
                    userId: updateObj.userId,
                    address: updateObj.address,
                    userName: updateObj.userName,
                    geofenceId: updateObj.geofenceId,
                    status: updateObj.status,
                    base: "lynkit",
                    latitude: updateObj.latitude,
                    longitude: updateObj.longitude
                },
                quantity: Number(updateObj.quantity),
                unit: updateObj.unit,
                date: updateObj.date,
                startSlotTime: updateObj.startSlotTime,
                endSlotTime: updateObj.endSlotTime,
                // requestType: updateObj.requestType,
                shapeType: updateObj.shapeType,
                length: updateObj.length,
                width: updateObj.width,
                height: updateObj.height,
                radius: updateObj.radius,
                volume: updateObj.volume,
                contactDetail: {
                    personName: updateObj.personName,
                    mobileNo: updateObj.mobileNo,
                    email: updateObj.email,
                    company: updateObj.company
                }
            }
        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post(`${url}/request/editRequest`, { ...finalObj }, { headers: config.headers })
            .then(response => {
                dispatch(getRequestList({
                    page: pageNumber,
                    limit: entries,
                    searchType: '',
                    searchValue: ''
                }))
                const status = response.data.success
                const message = response.data.message
                if (status === true) {
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                    // window.location.reload()
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }
    const empty = () => {
        setReqState({
            address: {
                userId: "",
                address: "select",
                userName: "",
                geofenceId: "",
                status: "",
                base: "lynkit",
                latitude: "",
                longitude: ""
            },
            quantity: "",
            unit: "",
            date: "",
            // requestType: "select"
        })
        setContactDetails(
            {
                personName: '',
                mobileNo: '',
                email: '',
                company: ''
            }
        )
        setSlotTime({
            startSlotTime: 'select',
            endSlotTime: 'select'
        })
        setShapeToggle({
            shapeType: 'select'
        })
        setDimensions({
            length: '',
            width: '',
            height: '',
            radius: '',
            volume: ''
        })
    }

    const options = [
        { value: 'select', label: 'Select' },
        { value: 'Pickup', label: 'Pickup' },
        { value: 'DropOff', label: 'DropOff' }
    ];
    const requests = [];
    listing && listing.map((item) => {
        requests.push({
            value: item._id,
            label: item.address,
            userId: item._id,
            address: item.address,
            userName: item.userName,
            geofenceId: item.geofenceId,
            status: item.status,
            latitude: item.latitude,
            longitude: item.longitude,
            personName: item.contactDetails.personName,
            mobileNo: item.contactDetails.mobileNo,
            email: item.contactDetails.email,
            company: item.company
        });
    });
    const handleChange = (item) => {
        let temp = { ...requestState }
        temp.address.userId = item._id
        temp.address.address = item.address
        temp.address.userName = item.userName
        temp.address.geofenceId = item.geofenceId
        temp.address.status = item.status
        temp.address.latitude = item.latitude
        temp.address.longitude = item.longitude
        setReqState(temp)

        let local = { ...contactDetail }
        local.personName = item.personName
        local.mobileNo = item.mobileNo
        local.email = item.email
        local.company = item.company
        setContactDetails(local)


    }
    const _onUpdateDrop = (item) => {
        let temp = { ...updateObj }
        temp.address = item.address
        temp.userId = item._id
        temp.userName = item.userName
        temp.geofenceId = item.geofenceId
        temp.status = item.status
        temp.latitude = item.latitude
        temp.longitude = item.longitude
        temp.personName = item.personName
        temp.mobileNo = item.mobileNo
        temp.email = item.email
        temp.company = item.company
        setUpdateObj(temp)
    }
    const _onUpdatstart = (item) => {
        updateSlot.length = 0

        let temp = { ...updateObj }
        temp.startSlotTime = item.label.toString()
        setUpdateObj(temp)  

        let num = Number(item.label)
        for (let i = num + 1; i <= 24; i++) {
            updateSlot.push({
                label: i <= 9 ? `0` + i : i.toString(), value: i
            })
        }
    }
    const _onUpdatend = (item) => {
        let temp = { ...updateObj }
        temp.endSlotTime = item.label.toString()
        setUpdateObj(temp)
    }
    // const _handleReqType = (item) => {
    //     if (item.label === 'DropOff') {
    //         let local = { ...requestState }
    //         local.requestType = 'dropoff'
    //         setReqState(local)
    //     }
    //     else {
    //         let local = { ...requestState }
    //         local.requestType = 'pickup'
    //         setReqState(local)
    //     }
    // }
    const handleStartChange = (item) => {
        filteredSlots.length = 0
        let local = { ...slotTime }
        local.startSlotTime = item.label
        setSlotTime(local)

        let num = Number(item.label)
        for (let i = num + 1; i <= 24; i++) {
            filteredSlots.push({
                label: i <= 9 ? `0` + i : i.toString(), value: i
            })
        }
    }
    const handleEndChange = (item) => {
        let local = { ...slotTime }
        local.endSlotTime = item.label
        setSlotTime(local)
    }
    const timeSlots = [
        { label: '00', value: '00' },
        { label: '01', value: '01' },
        { label: '02', value: '02' },
        { label: '03', value: '03' },
        { label: '04', value: '04' },
        { label: '05', value: '05' },
        { label: '06', value: '06' },
        { label: '07', value: '07' },
        { label: '08', value: '08' },
        { label: '09', value: '09' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
        { label: '16', value: '16' },
        { label: '17', value: '17' },
        { label: '18', value: '18' },
        { label: '19', value: '19' },
        { label: '20', value: '20' },
        { label: '21', value: '21' },
        { label: '22', value: '22' },
        { label: '23', value: '23' },
        { label: '24', value: '24' },
    ]
    const shapes = [
        { value: 'Box', label: 'Box' },
        { value: 'Cylinder', label: 'Cylinder' }
    ];
    const _handleShapeType = (item) => {
        let local = { ...shapeToggle }
        if (item.label === 'Box') {
            local.shapeType = 'Box'
            dimensions.volume = ''
            setShapeToggle(local)
        }
        else if (item.label === 'Cylinder') {
            local.shapeType = 'Cylinder'
            setShapeToggle(local)
            dimensions.volume = ''
        }
    }
    const _updateShapeType = (item) => {
        let temp = { ...updateObj }
        // let local = { ...shapeToggle }
        if (item.label === 'Box') {
            updateObj.shapeType = 'Box'
            temp.shapeType = 'Box'
            dimensions.volume = ''
            // setUpdateObj(local)
            setShapeToggle(temp)
        }
        else if (item.label === 'Cylinder') {
            updateObj.shapeType = 'Cylinder'
            temp.shapeType = 'Cylinder'
            // setUpdateObj(local)
            dimensions.volume = ''
            setShapeToggle(temp)
        }
    }
    const _handleDimensions = (e) => {
        if (shapeToggle.shapeType === 'Box') {
            dimensions.radius = ''
            setDimensions({ ...dimensions, [e.target.name]: e.target.value })
        }
        else if (shapeToggle.shapeType === 'Cylinder') {
            dimensions.length = ''
            dimensions.width = ''
            setDimensions({ ...dimensions, [e.target.name]: e.target.value })
        }

    }
    const _handleUpdatedDimensions = (e) => {
        if (shapeToggle.shapeType === 'Box') {
            dimensions.radius = ''
            setUpdateObj({ ...updateObj, [e.target.name]: e.target.value })
        }
        else if (shapeToggle.shapeType === 'Cylinder') {
            dimensions.length = ''
            dimensions.width = ''
            setUpdateObj({ ...updateObj, [e.target.name]: e.target.value })
        }

    }
    const calculation = () => {
        if (status !== true) {
            let temp = { ...dimensions }
            if (shapeToggle.shapeType === 'Box') {
                let res = Math.ceil(dimensions.length * dimensions.width * dimensions.height)
                temp.volume = res
                setDimensions(temp)
            }
            else if (shapeToggle.shapeType === 'Cylinder') {
                let res = Math.ceil(3.145 * dimensions.radius * dimensions.radius * dimensions.height)
                temp.volume = res
                setDimensions(temp)
            }
        }
        else {
            let local = { ...updateObj }
            if (shapeToggle.shapeType === 'Box') {
                let res = Math.ceil(Number(updateObj.length) * Number(updateObj.width) * Number(updateObj.height))
                local.volume = res
                setUpdateObj(local)
            }
            else if (shapeToggle.shapeType === 'Cylinder') {
                let res = Math.ceil(3.145 * Number(updateObj.radius) * Number(updateObj.radius) * Number(updateObj.height))
                local.volume = res
                setUpdateObj(local)
            }
        }
    }
    // const _handleUpdateReq = (item) => {
    //     let temp = { ...updateObj }
    //     temp.requestType = item.label
    //     setUpdateObj(temp)
    // }

    return (
        <section id="AddRequest" className="custom-modal toggle_width" style={{ width: "60%" }}>
            <div className="custom-modal-header">
                <h1>{status !== true ? 'Add Request' : 'Edit Request'}</h1>
                <span className="ct-close" onClick={() => { props.closeModal(); props.callBack(); empty() }}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '75vh' }}>
                <div className='requestBody'>
                    <div className='reqHeader'>
                        <div className='custom20'>
                            <span className='reqHeadSpan'>Select Address <span className='asterick'> * </span> :</span>
                        </div>
                        <div className='custom40 custom40Div'>
                            {status !== true ?
                                <Select
                                    onChange={(value) => handleChange(value)}
                                    options={requests}
                                    styles={colourStyles}
                                    value={{ label: requestState.address.address, value: requestState.address.address }}
                                />
                                :
                                <Select
                                    onChange={(value) => _onUpdateDrop(value)}
                                    options={requests}
                                    styles={colourStyles}
                                    value={{ label: updateObj.address, value: updateObj.address }}
                                />
                            }
                        </div>
                    </div>
                    {contactDetail.mobileNo && contactDetail.email && contactDetail.personName || updateObj.mobileNo && updateObj.email && updateObj.personName !== '' ?
                        <div className='reqBody open' style={{ marginTop: '20px' }}>
                            <div className='customRow'>
                                <div className='custom47'>
                                    <div className='custom40'>
                                        <span className='custSpan'>Customer Name :</span>
                                    </div>
                                    <div className='custom60'>
                                        <input className='custInp'
                                            value={status !== true ? contactDetail && contactDetail.personName ? contactDetail.personName : '' : updateObj.personName} disabled={true} />
                                    </div>
                                </div>
                                <div className='custom47'>
                                    <div className='custom40'>
                                        <span className='custSpan'>Mobile No :</span>
                                    </div>
                                    <div className='custom60'>
                                        <input className='custInp'
                                            value={status !== true ? contactDetail && contactDetail.mobileNo ? contactDetail.mobileNo : '' : updateObj.mobileNo}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='customRow'>
                                <div className='custom47'>
                                    <div className='custom40'>
                                        <span className='custSpan'>Email Id :</span>
                                    </div>
                                    <div className='custom60'>
                                        <input className='custInp'
                                            value={status !== true ? contactDetail && contactDetail.email ? contactDetail.email : '' : updateObj.email}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='custom47'>
                                    <div className='custom40'>
                                        <span className='custSpan'>Latitude :</span>
                                    </div>
                                    <div className='custom60'>
                                        <input className='custInp'
                                            value={status !== true ? requestState && requestState.address.latitude ? requestState.address.latitude : '' : updateObj.latitude}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='customRow'>
                                <div className='custom47'>
                                    <div className='custom40'>
                                        <span className='custSpan'>Longitude :</span>
                                    </div>
                                    <div className='custom60'>
                                        <input className='custInp'
                                            value={status !== true ? requestState && requestState.address.longitude ? requestState.address.longitude : '' : updateObj.longitude}
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className='custom47'>

                                </div>
                            </div>
                        </div>
                        : ''}
                    <div className='reqbod3' style={{ marginTop: '20px', borderLeft: '4px solid #727376' }}>
                        <div className='customRow'>
                            <div className='custom47'>
                                <div className='custom40'>
                                    <span className='custSpan'>Unit <span className='asterick'> * </span> :</span>
                                </div>
                                {status !== true ?
                                <div className='custom60'>
                                    <input placeholder='Enter Unit' value={localStorage.getItem('unit')} className='custInput' disabled={true} />
                                </div>
                                :
                                <div className='custom60'>
                                    <input placeholder='Enter Unit' value={updateObj.unit} className='custInput' disabled={true} />
                                </div>
                                }
                            </div>
                            <div className='custom47'>
                                <div className='custom40'>
                                    <span className='custSpan'>Date <span className='asterick'> * </span> :</span>
                                </div>
                                <div className='custom60'>
                                    {status !== true ?
                                        <input className='custInput' type='date'
                                            name='date'
                                            value={requestState.date}
                                            // : moment(updateObj.date).format("DD-MM-YYYY")}
                                            onChange={(e) => _onChange(e)}
                                            min={new Date().toISOString().split('T')[0]}
                                        />
                                        :
                                        <span className='driverSpan2 calenderInpCont' style={{ color: 'black', fontSize: '0.75rem' }}>
                                            {moment(updateObj.date).format('DD/MM/YYYY')}
                                            <div className='hubUploadIcon' style={{ border: 'none' }}>
                                                <i className="fa fa-calendar updateCalender"></i>
                                                <input className='hideCalend' min={new Date().toISOString().split('T')[0]} name='date' value={updateObj.date} onChange={(e) => _onUpdate(e)} type="date" />
                                            </div>
                                        </span>
                                    }
                                </div>

                            </div>
                        </div>
                        <div className='reqbod3' style={{ width:'100%',background:'none' }}>
                        <div className='customRow'>
                            <div className='custom47'>
                                <div className='custom40'>
                                    <span className='custSpan'>Time Slot <span className='asterick'> * </span> :</span>
                                </div>
                                <div className='custom60 newReq reqNew'>
                                    {status !== true ?
                                        <Select
                                            onChange={(value) => handleStartChange(value)}
                                            options={timeSlots}
                                            styles={colourStyles}
                                            value={{ label: slotTime.startSlotTime, value: slotTime.startSlotTime }}
                                        />
                                        :
                                        <Select
                                            onChange={(value) => _onUpdatstart(value)}
                                            options={timeSlots}
                                            styles={colourStyles}
                                            value={{ label: updateObj.startSlotTime, value: updateObj.startSlotTime }}
                                        />
                                    }

                                    {status !== true ?
                                        <Select
                                            onChange={(value) => handleEndChange(value)}
                                            options={filteredSlots}
                                            styles={colourStyles}
                                            value={{ label: slotTime.endSlotTime, value: slotTime.endSlotTime }}
                                        />
                                        :
                                        <Select
                                            onChange={(value) => _onUpdatend(value)}
                                            options={updateSlot}
                                            styles={colourStyles}
                                            value={{ label: updateObj.endSlotTime, value: updateObj.endSlotTime }}
                                        />
                                    }
                                </div>
                            </div>
                            <div className='custom47'>
                              
                            </div>
                           
                        </div>
                    </div>
                    </div>
                    <div className='reqbod3' style={{ marginTop: '20px', borderLeft: '4px solid #727376' }}>
                        <div className='customRow'>
                            <div className='custom47 alignVet'>
                                <span className='custSpan' style={{ marginRight: '23px' }}>Shape Type :</span>
                                {status !== true ?
                                    <Select
                                        onChange={(value) => _handleShapeType(value)}
                                        options={shapes}
                                        styles={colourStyles}
                                        value={{ label: shapeToggle.shapeType, value: shapeToggle.shapeType }}
                                    />
                                    :
                                    <Select
                                        onChange={(value) => _updateShapeType(value)}
                                        options={shapes}
                                        styles={colourStyles}
                                        value={{ label: updateObj.shapeType, value: updateObj.shapeType }}
                                    />
                                }
                            </div>
                        </div>

                        {shapeToggle.shapeType === 'Box' ?
                            <div className='customRow'>
                                <div className='custom47'>
                                    <div className='custom40'>
                                        <span className='custSpan'>Length (cm) <span className='asterick'> * </span>:</span>
                                    </div>
                                    <div className='custom60'>
                                        <input type='number' name='length'
                                            value={status !== true ? dimensions && dimensions.length ? dimensions.length : '-' : updateObj.length}

                                            onChange={status !== true ? (e) => _handleDimensions(e) : (e) => _handleUpdatedDimensions(e)}
                                            onKeyUp={() => calculation()}
                                            placeholder='Enter Length' className='custInput' />
                                    </div>
                                </div>
                                <div className='custom47'>
                                    <div className='custom40'>
                                        <span className='custSpan'>Width (cm) <span className='asterick'> * </span>:</span>
                                    </div>
                                    <div className='custom60'>
                                        <input name='width'
                                            value={status !== true ? dimensions && dimensions.width ? dimensions.width : '-' : updateObj.width}

                                            onChange={status !== true ? (e) => _handleDimensions(e) : (e) => _handleUpdatedDimensions(e)}
                                            onKeyUp={() => calculation()}
                                            type='number' placeholder='Enter Width' className='custInput' />
                                    </div>
                                </div>
                            </div>
                            : ""}
                        {shapeToggle.shapeType === 'Cylinder' || shapeToggle.shapeType === 'Box' ?
                            <div className='customRow'>
                                {shapeToggle.shapeType === 'Cylinder' || shapeToggle.shapeType === 'Box' ?
                                    <div className='custom47'>
                                        <div className='custom40'>
                                            <span className='custSpan'>Height (cm) <span className='asterick'> * </span>:</span>
                                        </div>
                                        <div className='custom60'>
                                            <input name='height'
                                                value={status !== true ? dimensions && dimensions.height ? dimensions.height : '-' : updateObj.height}
                                                onChange={status !== true ? (e) => _handleDimensions(e) : (e) => _handleUpdatedDimensions(e)}
                                                onKeyUp={() => calculation()} type='number' placeholder='Enter Height' className='custInput' />
                                        </div>

                                    </div>
                                    : ""}
                                {shapeToggle.shapeType === 'Box' ?
                                    <div className='custom47'>
                                        <div className='custom40'>
                                            <span className='custSpan'>Quantity <span className='asterick'> * </span> :</span>
                                        </div>
                                        <div className='custom60'>
                                            <input placeholder='Enter Quantity' name='quantity' type='number'
                                                value={status !== true ? requestState.quantity : updateObj.quantity}
                                                onChange={(e) => status !== true ? _onChange(e) : _onUpdate(e)} className='custInput' />
                                        </div>
                                    </div>
                                    : ''}
                                {shapeToggle.shapeType === 'Cylinder' ?
                                    <div className='custom47'>
                                        <div className='custom40'>
                                            <span className='custSpan'>Radius (cm) <span className='asterick'> * </span>:</span>
                                        </div>
                                        <div className='custom60'>
                                            <input name='radius'
                                                value={status !== true ? dimensions && dimensions.radius ? dimensions.radius : '-' : updateObj.radius}
                                                onChange={status !== true ? (e) => _handleDimensions(e) : (e) => _handleUpdatedDimensions(e)}
                                                onKeyUp={() => calculation()} type='number' placeholder='Enter Radius' className='custInput' />
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                            : ""}
                        {shapeToggle.shapeType === 'Cylinder' ?
                            <div className='customRow'>
                                <div className='custom47'>
                                    <div className='custom40'>
                                        <span className='custSpan'>Pi (cm):</span>
                                    </div>
                                    <div className='custom60'>
                                        <input type='number' placeholder='3.1415' className='custInput' disabled />
                                    </div>
                                </div>
                                {shapeToggle.shapeType === 'Cylinder' ?
                                    <div className='custom47'>
                                        <div className='custom40'>
                                            <span className='custSpan'>Quantity <span className='asterick'> * </span>:</span>
                                        </div>
                                        <div className='custom60'>
                                            <input placeholder='Enter Quantity' name='quantity' type='number'
                                                value={status !== true ? requestState.quantity : updateObj.quantity}
                                                onChange={(e) => status !== true ? _onChange(e) : _onUpdate(e)} className='custInput' />
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                            : ""}
                        <div className='customRow'>
                            <div className='custom100'>
                                <span className='custSpanVol'>Volume  :</span>
                                {status !== true ?
                                    <>
                                        <span className='calVol'>{dimensions.volume}</span><span style={{ marginLeft: '6px', color: '#727376 ! important' }}>cm<sup>3</sup></span>
                                    </>
                                    :
                                    <>
                                        <span className='calVol'>{updateObj.volume}</span><span style={{ marginLeft: '6px', color: '#727376 ! important' }}>cm<sup>3</sup></span>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='customBtnElem'>
                        {status !== true ?
                            <button className='customBtn' onClick={()=>{addRequest();  props.returnFun();}}>Add Request</button>
                            :
                            <button className='customBtn' onClick={()=>{editRequest();  props.returnFun();}}>Edit Request</button>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddRequest;

