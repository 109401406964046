import React, { useEffect, useState, useRef } from 'react'
import bgImage from './../../static/images/bg1.jpg'
import yellowBg from './../../static/images/yellowBg.jpg'
import yellowBgMobile from './../../static/images/bggg.png'
import logo from './../../static/images/logo.png'
import lynkit from './../../static/images/lynkit.png'
import bharatNew from './../../static/images/bharatNew.png'
import greybg from './../../static/images/greybg.png'
import './../../static/css/login.css'
import history from './../../services/history';
import axios from 'axios'
import jwt_decode from "jwt-decode";
import crypto from 'crypto-js';
import ReCAPTCHA from "react-google-recaptcha";
import { url } from './../../services/requests'


const Login = (props) => {
    const captchaRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [userType, setUserType] = useState('distributor')
    const [eye, setEye] = useState(false)
    const [passwordType, setPasswordType] = useState(true)
    const [userLoginType, setUserLoginType] = useState('Distributor Id')
    const [userPlaceType, setUserPlaceType] = useState('Enter Distributor Id..')
    const [changePass, setChangePass] = useState(false)
    const [forgotPass, setForgotPass] = useState(false)
    const [id, setId] = useState('')
    const [idLoader, setIdLoader] = useState(false)
    const [PassLoader, setPassLoader] = useState(false)
    const [passShow, setPassShow] = useState('password')
    const [timer, setTimer] = useState(60)
    const [timerStatus, setTimerStatus] = useState(false)
    const [timerNew, setTimerNew] = useState(60)
    const [timerStatusNew, setTimerStatusNew] = useState(false)
    // const [readState, setRead] = useState(false)
    const [userNameType, setUserNameType] = useState('Distributor Id')
    const [loginData, setLoginData] = useState({
        code: '',
        password: '',
        time: '',
        userType: "distributor",
        captchaValue: ""
    })
    const [ssnValues, setValue] = useState({
        ssn1: "",
        ssn2: "",
        ssn3: "",
        ssn4: "",
        ssn5: "",
        ssn6: ""
    });
    const [otpdata, setOtpData] = useState({
        userId: "",
        otp: 0,
        newpassword: "",
        time: ""
    })
    const [otpVal, setOtpVal] = useState({
        ss1: "",
        ss2: "",
        ss3: "",
        ss4: "",
        ss5: "",
        ss6: ""
    });
    const [readOnlyState, setReadOnlyState] = useState(false)
    const [otpShow, setOtpShow] = useState(false)
    const [otpLoader, setOtpLoader] = useState(false)
    const [verifyLoader, setVerifyLoader] = useState(false)

    const [driverData, setDriverData] = useState({
        mobile: '',
        otp: ''
    })

    const _onChange = (e, item) => {
        if (item === 'driver') {
            setDriverData({ ...driverData, [e.target.name]: e.target.value })
        }
        else {
            setLoginData({ ...loginData, [e.target.name]: e.target.value })
        }
    }
    const _setPassType = () => {
        if (passwordType === false) {
            setPasswordType(true)
        }
        else {
            setPasswordType(false)
        }
    }
    const _onLogin = () => {
        if (loginData.code === '') {
            window.$.fn.show_notification({ message: `${userPlaceType}`, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if (loginData.password === '') {
            // let timerInterval
            window.$.fn.show_notification({ message: "Please Enter Password.", title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })

        }
        else if (loginData.captchaValue === '') {
            window.$.fn.show_notification({ message: "Captcha Error..", title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else {
            let dateTime = new Date().getTime();
            let encryptedBase64Key = `ven/hrtvdi+6442+G23${dateTime}`;
            let parsedBase64Key = crypto.enc.Base64.parse(encryptedBase64Key);
            let encryptedSharingLink = crypto.AES.encrypt(`${loginData.password}`, parsedBase64Key, {
                mode: crypto.mode.ECB,
                padding: crypto.pad.Pkcs7
            });
            let temp = { ...loginData }
            temp.password = encryptedSharingLink.toString();
            // temp.password = loginData.password
            temp.time = dateTime
            setLoading(true)
            axios.post(`${url}/user/login`, { ...temp })
                .then(response => {
                    var token = response && response.data && response.data.token ? response.data.token : ''
                    var email = response && response.data && response.data.user && response.data.user.email ? response.data.user.email : ''
                    var user = response && response.data && response.data.user && response.data.user.userType ? response.data.user.userType : ''
                    var id = response && response.data && response.data.user && response.data.user.id ? response.data.user.id : ''
                    var message = response && response.data && response.data.message ? response.data.message : ''
                    var status = response && response.data && response.data.success ? response.data.success : ''
                    if (status === true) {
                        setLoading(false)
                        localStorage.setItem("secretkey", token)
                        localStorage.setItem("email", email)
                        localStorage.setItem("userType", user)
                        localStorage.setItem("id", id)
                        // var decoded = jwt_decode(token);
                        setTimeout(() => {
                            history.push('/dashboard')
                        }, 500)
                    }
                    else {
                        setLoading(false)
                        window.$.fn.show_notification({ message: `${message}`, autohide: true, showInput: false, title: 'Alert!!' }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    setLoading(false)
                    console.log('error', error);
                    window.$.fn.show_notification({ message: "Some Error Occured.", autohide: true, showInput: false, title: 'Alert!!' }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })

                })
        }
    }
    const userGroupFunction = (index) => {
        if (index === 1) {
            setOtpShow(false)
            setDriverData({
                mobile: '',
                otp: ''
            })
            setReadOnlyState(false)
            setUserLoginType('Distributor Id')
            setUserType('distributor')
            setUserPlaceType("Enter Distributor Id.")
            loginData.code = ''
            loginData.password = ''
            loginData.userType = 'distributor'
            setUserNameType('Distributor Id')
        }
        else if (index === 2) {
            setReadOnlyState(false)
            setUserLoginType('Email Id')
            setUserType('driver')
            setUserPlaceType("Enter Email Id.")
            loginData.code = ''
            loginData.password = ''
            loginData.userType = 'driver'
        }
        // else if (index === 3) {
        //     setRead(false)
        //     setUserLoginType('Email Id')
        //     setUserType('admin')
        //     loginData.userType = 'admin'
        // }
        else if (index === 3) {
            setReadOnlyState(false)
            setUserLoginType('Email Id')
            setUserType('plant')
            loginData.userType = 'plant'
            setUserNameType('Plant Id')
            setUserPlaceType("Enter Plant Id..")
        }
    }
    const forgotPassword = () => {
        setChangePass(!changePass)
    }
    const closeForgot = () => {
        setChangePass(!changePass)

    }
    const closeMe = () => {
        setChangePass(!changePass)
        setForgotPass(!forgotPass)
    }
    const otpTogglePassType = () => {
        if (passShow === 'password') {
            setPassShow('text')
        }
        else {
            setPassShow('password')
        }
    }
    const verify = () => {
        let data = { userId: id }
        setIdLoader(true)
        axios.post(`${url}/user/verify`, { ...data })
            .then(response => {
                let status = response.data.success
                let mssg = response.data.message
                if (status === true) {
                    setForgotPass(!forgotPass)
                    setIdLoader(false)
                    setTimer(60)
                    setTimerStatus(true)
                }
                else {
                    setIdLoader(false)
                    window.$.fn.show_notification({ message: `${mssg}`, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                setIdLoader(false)
            })
    }
    const sendOtp = () => {
        const combineOtp = `${ssnValues.ssn1}` + `${ssnValues.ssn2}` + `${ssnValues.ssn3}` + `${ssnValues.ssn4}` + `${ssnValues.ssn5}` + `${ssnValues.ssn6}`
        let temp = { ...otpdata }
        temp.otp = Number(combineOtp)
        temp.userId = id
        setPassLoader(true)

        if (temp.otp === 0) {
            setPassLoader(false)
            window.$.fn.show_notification({ message: 'Please Enter OTP.', title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else {
            if (temp.newpassword.match(/[a-z]/g) && temp.newpassword.length >= 8 && temp.newpassword.match(/[A-Z]/g) && temp.newpassword.match(/[0-9]/g) && temp.newpassword.match(/[!@#$%^&*]/g)) {
                let dateTime = new Date().getTime();
                let encryptedBase64Key = `ven/hrtvdi+6442+G23${dateTime}`;
                let parsedBase64Key = crypto.enc.Base64.parse(encryptedBase64Key);
                let encryptedSharingLink = crypto.AES.encrypt(`${otpdata.newpassword}`, parsedBase64Key, {
                    mode: crypto.mode.ECB,
                    padding: crypto.pad.Pkcs7
                });
                temp.newpassword = encryptedSharingLink.toString();
                temp.time = dateTime
                axios.post(`${url}/user/forgotPassword`, { ...temp })
                    .then(response => {
                        let status = response.data.success
                        let mssg = response.data.message
                        if (status === true) {
                            setPassLoader(false)
                            setForgotPass(!forgotPass)
                            setChangePass(!changePass)
                            setId('')
                            setValue({
                                ssn1: "",
                                ssn2: "",
                                ssn3: "",
                                ssn4: "",
                                ssn5: "",
                                ssn6: ""
                            })
                            setOtpData({
                                userId: "",
                                otp: 0,
                                newpassword: ""
                            })
                            window.$.fn.show_notification({ message: 'Password changed successfully.', title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                        else {
                            setPassLoader(false)
                            window.$.fn.show_notification({ message: `${mssg}`, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        setPassLoader(false)
                    })
            }
            else {
                setPassLoader(false)
                window.$.fn.show_notification({ message: 'Password should contain min 8 letters with 1 lower case , 1 upper case , 1 number and 1 special character.', title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }

        }

    }
    function handle_next_input(event) {
        var otp_inputs = document.querySelectorAll(".otp__digit__white")
        var mykey = "0123456789".split("")
        otp_inputs.forEach((_) => {
            _.addEventListener("keyup", handle_next_input)
        })

        let current = event.target
        let index = parseInt(current.classList[1].split("__")[2])
        current.value = event.key

        if (event.keyCode == 8 && index > 1) {
            current.previousElementSibling.focus()
        }
        if (index < 6 && mykey.indexOf("" + event.key + "") != -1) {
            var next = current.nextElementSibling;
            next.focus()
        }
        var _finalKey = ""
        for (let { value } of otp_inputs) {
            _finalKey += value
        }
        var splitOtp = _finalKey.split("")
        let temp = { ...otpVal }
        temp.ss1 = splitOtp[0]
        temp.ss2 = splitOtp[1]
        temp.ss3 = splitOtp[2]
        temp.ss4 = splitOtp[3]
        temp.ss5 = splitOtp[4]
        temp.ss6 = splitOtp[5]
        setOtpVal(temp)
    }
    function handleFogotOTP(event) {
        var otp_inputs = document.querySelectorAll(".otp__digit__grey")
        var mykey = "0123456789".split("")
        otp_inputs.forEach((_) => {
            _.addEventListener("keyup", handleFogotOTP)
        })

        let current = event.target
        let index = parseInt(current.classList[1].split("__")[2])
        current.value = event.key

        if (event.keyCode == 8 && index > 1) {
            current.previousElementSibling.focus()
        }
        if (index < 6 && mykey.indexOf("" + event.key + "") != -1) {
            var next = current.nextElementSibling;
            next.focus()
        }
        var _finalKey = ""
        for (let { value } of otp_inputs) {
            _finalKey += value
        }
        var splitOtp = _finalKey.split("")
        let temp = { ...ssnValues }
        temp.ssn1 = splitOtp[0]
        temp.ssn2 = splitOtp[1]
        temp.ssn3 = splitOtp[2]
        temp.ssn4 = splitOtp[3]
        temp.ssn5 = splitOtp[4]
        temp.ssn6 = splitOtp[5]
        setValue(temp)
    }
    const _handleChange = (e) => {
        setOtpData({ ...otpdata, [e.target.name]: e.target.value })
    }
    const resendEmail = () => {
        let data = { userId: id }
        axios.post(`${url}/user/verify`, { ...data })
            .then(response => {
                setTimer(60)
                let status = response.data.success
                let mssg = response.data.message
                if (status === true) {
                }
                else {
                    window.$.fn.show_notification({ message: `${mssg}`, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
            })
    }
    useEffect(() => {
        if (loginData.password.length !== 0) {
            setEye(true)
        }
    }, [loginData])

    useEffect(() => {
        if (timer <= 0) {
            setTimerStatus(false)
        }
        if (timerStatus === true) {
            if (timer !== 0) {
                setInterval(() => {
                    setTimer(timer => timer - 1)
                }, 1000)
            }
        }
    }, [timerStatus])

    useEffect(() => {
        if (timerNew <= 0) {
            setTimerStatusNew(false)
        }
        if (timerStatusNew === true) {
            if (timerNew !== 0) {
                setInterval(() => {
                    setTimerNew(timerNew => timerNew - 1)
                }, 1000)
            }
        }
    }, [timerStatusNew])

    const onCaptchaChange = (value) => {
        let temp = { ...loginData }
        temp.captchaValue = value
        setLoginData(temp)
    }
    const sendOTP = () => {
        if (driverData.mobile === '') {
            window.$.fn.show_notification({ message: 'Please Enter Mobile Number', title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else {
            setOtpLoader(true)
            let temp = {
                mobile: driverData.mobile,
                type: 'web'
            }
            axios.post(`${url}/user/loginOtp`, { ...temp })
                .then(response => {
                    let status = response.data.success
                    let message = response.data.message
                    if (status === true) {
                        setTimerStatusNew(true)
                        setTimerNew(60)
                        setOtpShow(true)
                        setOtpLoader(false)
                        window.$.fn.show_notification({ message: `${message}`, title: 'Success!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                    else {
                        setOtpShow(false)
                        setOtpLoader(false)
                        window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                    // setTimeout(() => {
                    //     history.push('/dashboard')
                    // }, 500)
                })
                .catch(error => {
                    setOtpShow(false)
                    setOtpLoader(false)
                    console.log('error', error);
                    window.$.fn.show_notification({ message: "Some Error Occured.", title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                })
        }
    }
    const resendDriverOtp = () => {
        let temp = {
            mobile: driverData.mobile,
            type: 'web'
        }
        axios.post(`${url}/user/loginOtp`, { ...temp })
            .then(response => {
                let status = response.data.success
                let message = response.data.message
                if (status === true) {
                    setTimerNew(60)
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
            })
    }
    const verifyOTP = () => {
        const combineOtp = `${otpVal.ss1}` + `${otpVal.ss2}` + `${otpVal.ss3}` + `${otpVal.ss4}` + `${otpVal.ss5}` + `${otpVal.ss6}`
        let temp = { ...driverData }
        temp.otp = Number(combineOtp)
        setVerifyLoader(true)
        axios.post(`${url}/user/verifyOtp`, { ...temp })
            .then(response => {
                let status = response.data.success
                let message = response.data.message
                if (status === true) {
                    setVerifyLoader(false)
                    var token = response && response.data && response.data.token ? response.data.token : ''
                    var email = response && response.data && response.data.user && response.data.user.email ? response.data.user.email : ''
                    var user = response && response.data && response.data.user && response.data.user.userType ? response.data.user.userType : ''
                    var id = response && response.data && response.data.user && response.data.user.id ? response.data.user.id : ''
                    localStorage.setItem("secretkey", token)
                    localStorage.setItem("email", email)
                    localStorage.setItem("userType", user)
                    localStorage.setItem("id", id)
                    setTimeout(() => {
                        history.push('/dashboard')
                    }, 500)
                }
                else {
                    setVerifyLoader(false)
                    window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }

            })
            .catch(error => {
                setVerifyLoader(false)
                console.log('error', error);
                window.$.fn.show_notification({ message: "Some Error Occured.", title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            })
    }

    return (
        <div className='loginDiv'>
            <img src={yellowBg} alt='' className='bgImage' />
            <img src={yellowBgMobile} alt='' className='bgImage logoHidee' />
            <div className='loginInner'>
                <div className='loginLeft'>
                    <div className='logLogo '>
                        {/* <img className='logoSize1' src={logo} alt="" /> */}
                        <img className='logoSize2' src={bharatNew} alt="" />
                    </div>
                </div>
                <div className={userType === 'distributor'  || userType === 'plant' ? 'loginDiv1' : 'loginDiv2'}>
                    <div>
                        <img className='logoSize2 logoHidee' src={bharatNew} alt="" />
                    </div>
                    <div className='log-parts '>
                        <span className='log_head'>Login</span>
                    </div>
                    {userType === 'distributor' || userType === 'plant' ?
                        <>
                            <div className='log-parts '>
                                <div className='loginToggleBtn'>
                                    <div className='userTypeDiv'>
                                        <div className={userType === 'distributor' ? 'userTypesActive' : 'userTypes'} onClick={() => userGroupFunction(1)}>
                                            <span className={userType === 'distributor' ? 'userTypeActiveSpan' : 'userTypeSpan'}>Distributor</span>
                                        </div>
                                        <div className={userType === 'driver' ? 'userTypesActive' : 'userTypes'} onClick={() => userGroupFunction(2)}>
                                            <span className={userType === 'driver' ? 'userTypeActiveSpan' : 'userTypeSpan'}>Delivery Man</span>
                                        </div>
                                       <div className={userType === 'plant' ? 'userTypesActive' : 'userTypes'} onClick={() => userGroupFunction(3)}>
                                            <span className={userType === 'plant' ? 'userTypeActiveSpan' : 'userTypeSpan'}>Plant</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='log-parts1 '>
                                <span>{userNameType}</span>
                                <input className='myCustInp' name='code' value={loginData.code} onChange={(e) => _onChange(e)} type='text' placeholder={userPlaceType} />
                            </div>
                            <div className='log-parts1 '>
                                <span>Password</span>
                                <input className='myCustInp' type={passwordType === true ? 'password' : "text"} name='password' value={loginData.password} onChange={(e) => _onChange(e)} placeholder="Enter your Password.." />
                                {passwordType === true ?
                                    <i className="fa fa-eye-slash eyeIcon" onClick={_setPassType} ></i>
                                    :
                                    <i className="fa fa-eye eyeIcon" onClick={_setPassType} ></i>
                                }
                            </div>
                            <div className='log-parts1 '>
                                <ReCAPTCHA 
                                    sitekey="6LeGrzwoAAAAALfNuPbQmP8CIKPLMIoUour3lHV0" // for production
                                    // sitekey="6LcINfwkAAAAAETp1-bjlQeZl4oihF43YqY2LKh_"  // for vapt
                                    onChange={onCaptchaChange}
                                    ref={captchaRef}
                                />
                            </div> 
                            <div className='log-parts2'>
                                <button className='btnloginBtn' onClick={() => _onLogin()}>Login</button>
                                <button className='btnforgotBtn' onClick={() => forgotPassword()}>Forgot Password ?</button>
                            </div>
                            <div className='loadSpinnder'>
                                {loading === true ?
                                    <i className="fa fa-spinner fa-spin loadingSpinner"></i>
                                    : ''}
                            </div>
                        </>
                        :
                        <>
                            <div className='log-parts '>
                                <div className='loginToggleBtn'>
                                    <div className='userTypeDiv'>
                                        <div className={userType === 'distributor' ? 'userTypesActive' : 'userTypes'} onClick={() => userGroupFunction(1)}>
                                            <span className={userType === 'distributor' ? 'userTypeActiveSpan' : 'userTypeSpan'}>Distributor</span>
                                        </div>
                                        <div className={userType === 'driver' ? 'userTypesActive' : 'userTypes'} onClick={() => userGroupFunction(2)}>
                                            <span className={userType === 'driver' ? 'userTypeActiveSpan' : 'userTypeSpan'}>Delivery Man</span>
                                        </div>
                                        <div className={userType === 'plants' ? 'userTypesActive' : 'userTypes'} onClick={() => userGroupFunction(3)}>
                                    <span className={userType === 'plants' ? 'userTypeActiveSpan' : 'userTypeSpan'}>Plant</span>
                                </div>
                                    </div>
                                </div>
                            </div>
                            <div className='log-parts1 '>
                                <span>Mobile Number</span>
                                <input className='myCustInp' readOnly={readOnlyState} name='mobile' type='number' value={driverData.mobile} onChange={(e) => _onChange(e, 'driver')} placeholder='Enter Mobile Number' />
                            </div>
                            {otpShow === true ?
                                <>
                                    <div className='log-parts-OTP '>
                                        <span>Enter OTP</span>
                                    </div>
                                    <div className=' log-parts1New' >
                                        <input type="number" onChange={(event) => handle_next_input(event)} className="otp__digit__white  otp__field__1" />
                                        <input type="number" onChange={(event) => handle_next_input(event)} className="otp__digit__white  otp__field__2" />
                                        <input type="number" onChange={(event) => handle_next_input(event)} className="otp__digit__white  otp__field__3" />
                                        <input type="number" onChange={(event) => handle_next_input(event)} className="otp__digit__white  otp__field__4" />
                                        <input type="number" onChange={(event) => handle_next_input(event)} className="otp__digit__white  otp__field__5" />
                                        <input type="number" onChange={(event) => handle_next_input(event)} className="otp__digit__white  otp__field__6" />
                                    </div>
                                </>
                                : ''}

                            <div className='log-parts2'>

                                {otpShow !== true ?
                                    <>
                                        <button className='btnloginBtn' onClick={() => sendOTP()}>Send OTP</button>
                                        {otpLoader === true ?
                                            <div className='loadSpinnder' style={{ width: '10%' }}>
                                                <i className="fa fa-spinner fa-spin passpin "></i>
                                            </div>
                                            : ''}
                                    </>
                                    :
                                    <>
                                        <button className='btnloginBtn' onClick={() => verifyOTP()}>Verify OTP</button>
                                        <span className='resend'>Didn't receive it yet?
                                            <button id="resend-styleNew" disabled={timerNew >= 0 ? true : false} onClick={resendDriverOtp}> Resend OTP</button>
                                            {timerNew >= 0 ?
                                                <span>in ({timerNew}) seconds</span>
                                                : ''
                                            }
                                        </span>
                                        {verifyLoader === true ?
                                            <div className='loadSpinnder' style={{ width: '10%' }}>
                                                <i className="fa fa-spinner fa-spin passpin "></i>
                                            </div>
                                            : ''}
                                    </>
                                }

                            </div>
                            <div className='loadSpinnder'>
                                {loading === true ?
                                    <i className="fa fa-spinner fa-spin loadingSpinner"></i>
                                    : ''}
                            </div>
                        </>
                    }

                </div>
            </div>
            {changePass && (
                <div className='recover-moadal'>
                    <div className='recover-overlay' ></div>
                    <div className='forgotPass forgotPassNew'>
                        <i className="fa fa-close ddrclosea" onClick={() => closeForgot()} />
                        <div className='forgotPassInner'>
                            <div className='fMe alignCent'>
                                <span className='fmeBold'>Recover Password</span>
                            </div>
                            <div className='fMe alignCent'>
                                <span className='fmeSoft'>Please Enter Registered Email Id / Mobile Number.</span>

                            </div>
                            <div className='fMe'>
                                <div className='fme100'>
                                    <input className='fmeInp' onChange={(e) => setId(e.target.value)} type="email" placeholder='Enter Email Id / Mobile Number .' />
                                </div>
                            </div>
                            <div className='fMe alignCent alignVertCent'>
                                <button className='fBtn' disabled={id === '' ? true : false} onClick={verify}>Send OTP</button>
                                {idLoader ?
                                    <i className="fa fa-spinner fa-spin passpin "></i>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {forgotPass && (
                <div className='recover-moadal' >
                    <div className='forgotPass' style={{ height: '435px' }}>
                        <i className="fa fa-close ddrclosea" onClick={() => closeMe()} />
                        <div className='forgotPassInner'>
                            <div className='fMe alignCent'>
                                <span className='fmeBold'>Recover Password</span>

                            </div>
                            <div className='fMe alignCent'>
                                <span className='fmeSoft'>Please Enter OTP.</span>
                            </div>
                            <div className='fMe'>
                                <div className='fme100'>
                                    <input className='fmeInp' value={id} readOnly type="email" placeholder='Enter Email Id or Distributor Id.' />
                                </div>

                            </div>

                            <div className='fMe'>
                                <div className='fme100'>
                                    <input className='fmeInp' name='newpassword' value={otpdata.newpassword} onChange={(e) => _handleChange(e)} type={passShow} placeholder='Enter New Password.' />
                                    {passShow === 'password' ?
                                        <i className="fa fa-eye-slash iconEye otpEye" onClick={otpTogglePassType}></i>
                                        :
                                        <i onClick={otpTogglePassType} className="fa fa-eye iconEye otpEye" ></i>
                                    }
                                </div>
                            </div>
                            <div className='fMe fmeIncHgt' >
                                <input type="number" onChange={(event) => handleFogotOTP(event)} className="otp__digit__grey  otp__field__1" />
                                <input type="number" onChange={(event) => handleFogotOTP(event)} className="otp__digit__grey  otp__field__2" />
                                <input type="number" onChange={(event) => handleFogotOTP(event)} className="otp__digit__grey  otp__field__3" />
                                <input type="number" onChange={(event) => handleFogotOTP(event)} className="otp__digit__grey  otp__field__4" />
                                <input type="number" onChange={(event) => handleFogotOTP(event)} className="otp__digit__grey  otp__field__5" />
                                <input type="number" onChange={(event) => handleFogotOTP(event)} className="otp__digit__grey  otp__field__6" />
                            </div>
                            <div className='fMe alignCent alignVertCent'>
                                <button className='fBtn' onClick={sendOtp} disabled={otpdata.newpassword === '' ? true : false}>Change Password</button>
                                <span className='resend'>Didn't receive it yet?
                                    <button id="resend-style" disabled={timer >= 0 ? true : false} onClick={resendEmail}> Resend OTP</button>
                                    {timer >= 0 ?
                                        <span>in ({timer}) seconds</span>
                                        : ''
                                    }
                                </span>
                                {PassLoader ?
                                    <i className="fa fa-spinner fa-spin passpin "></i>
                                    : ''}
                            </div>

                        </div>

                    </div>

                </div>
            )}
        </div>

    )
}
export default Login;