import { DISTRIBUTORACTIVITY_COUNT, DISTRIBUTORACTIVITY_DATA, DISTRIBUTORACTIVITY_SHOW, DISTRIBUTORACTIVITY_HIDE , DISTRIBUTORACTIVITY_MSSG } from './types';
import { post , logoutOnExpiry} from './../../../services/requests';

export const getDistributorActivityData = (object) => {
    return (dispatch) => {
        dispatch(showLoader())
        post('distributor/getDistributorActivity', {...object })
            .then(response => {
                const Info = response && response.data ? response.data : ''
                const status = response && response.success ? response.success : ''
                const mssg = response && response.message ? response.message : ''
                const count = response.count
                if (status === true && Info !== undefined) {
                    // console.log('feedbackInfo in action', feedbackInfo)   
                    dispatch(getDistributorActivityInfo(Info))
                    dispatch(hideLoader()) 
                    dispatch(showMessage(mssg))
                    dispatch(getCount(count))
                }
                else{
                    dispatch(getDistributorActivityInfo())
                    dispatch(hideLoader()) 
                    dispatch(showMessage(mssg))
                }
            })
            .catch(error => { 
                console.log('error', error);
                dispatch(hideLoader()) 
                dispatch(showMessage('Some Error Occured.'))
                if (error?.error?.status === 401) {
                    logoutOnExpiry()
                }
            })
    }
}

export const getDistributorActivityInfo = (feedbackInfo) => {
    return {
        type: DISTRIBUTORACTIVITY_DATA,
        payload: feedbackInfo
    }
}
export const showLoader = () => {
    return {
        type: DISTRIBUTORACTIVITY_SHOW,
    }
}
export const hideLoader = () => {
    return {
        type: DISTRIBUTORACTIVITY_HIDE,
    }
}
export const showMessage = (message) =>{
    return{
        type : DISTRIBUTORACTIVITY_MSSG,
        payload : message
    }
}

export const getCount = (count) =>{
    return{
        type : DISTRIBUTORACTIVITY_COUNT,
        payload : count
    }
}