import React, { useEffect, useState } from 'react'
import AddAddress from './Modal/AddAddress'
import AddressRoute from './Modal/AddressRoute'
import Geofence from './Modal/Geofence'
import $ from 'jquery'
import axios from 'axios'
import { getAddress } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { selectInit } from './../../services/functions'
import { logoutOnExpiry, url } from './../../services/requests'
import Select from 'react-select';
import { colourStyles } from './../../services/functions'
import {Table} from 'react-bootstrap'
const AddressManag = () => {
    const dispatch = useDispatch()
    const [geo, setGeo] = useState({})
    const [info, setInfo] = useState({
        data: '',
        update: '',
        pagination: { pageNumber:'', entries:''}
    })
    const [pageCount, setPageCount] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [searchObj, setSearchObject] = useState({
        page: pageCount,
        limit: pageLimit,
        searchType: '',
        searchValue: ''
    })
    const listing = useSelector((state) => state.addressListRed.addressListing)
    const loader = useSelector((state) => state.addressListRed.loading)
    const message = useSelector((state) => state.addressListRed.message)
    const count = useSelector((state) => state.addressListRed.count)

    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const deleteAddress = (id) => {
        window.$.fn.show_notification({ message: "Delete this Address ?", title: 'Are you Sure..', type: 'confirm', autohide: true, showInput: false }, (result) => {
            if (result.type === 'confirm') {
                const token = localStorage.getItem('secretkey')
                const config = {
                    headers: { Authorization: token }
                };
                axios.post(`${url}/route/deleteAddress`, { _id: id }, { headers: config.headers })
                    .then(response => {
                        // const addressRes = response.data
                        const status = response.data.success
                        const message = response.data.message
                        if (status === true) {
                            setPageCount(1)
                            dispatch(getAddress({
                                page: pageCount,
                                limit: pageLimit,
                                searchType: '',
                                searchValue: ''
                            }))
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }

                    })
                    .catch(error => {
                        console.log('error', error);
                        if (error.error.status === 401) {
                            logoutOnExpiry()
                        }
                    })
            }
        })
    }
    const shareData = (item, index) => {
        let concate = { lat: listing[index].latitude, lng: listing[index].longitude, geo: item }
        setGeo(concate)
    }
    const shareToModal = (item) => {
        let concated = { ...info }
        concated.data = item
        concated.update = true
        concated.pagination.pageNumber = pageCount
        concated.pagination.entries = pageLimit
        setInfo(concated)
    }
    const callBack = () => {
        let concated = { ...info }
        concated.data = ''
        concated.update = false
        concated.pagination.entries = ''
        concated.pagination.pageNumber = ''
        setInfo(concated)
    }
    const returnFun = () => {
        // setPageCount(1)
    }
    useEffect(() => {
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 50)
        dispatch(getAddress({
            page: pageCount,
            limit: pageLimit,
            searchType: '',
            searchValue: ''
        }))
    }, [dispatch, pageCount])

    const _searchDrop = (id, value) => {
        let temp = { ...searchObj }
        temp.searchType = id
        setSearchObject(temp)

    }
    const _handleChange = (e) => {
        setSearchObject({ ...searchObj, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(getAddress({
            page: '',
            limit: pageLimit,
            searchType: searchObj.searchType,
            searchValue: searchObj.searchValue
        }))
    }
    const nextPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount += 1);
        });
    }
    const prevPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount -= 1);
        });
    }
    const entries = [
        {label: 10, value : 10},
        {label: 20, value : 20},
        {label: 30, value : 30},
        {label: 40, value : 40},
        {label: 50, value : 50}
    ]
    
    const handleChange = (item) => {
        let count = item.value
        setPageCount(1)
        setPageLimit(count)
        dispatch(getAddress({
            page: 1,
            limit: item.value,
            searchType: '',
            searchValue: ''
        }))
    }
    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className='cust-col-5'>
                    <h1 className="title">
                        Address Management
                    </h1>
                </div>
                <div className='cust-col-5 flex-jc-end'>
                    <h1 className="title font-bold orange_color">
                    </h1>
                </div>
            </div>
            <hr className='bgg' />
            <div className="cust-container">
                <div class="cust-row">
                    <div class="cust-col-10">
                        <div className='bdaOuter'>
                            <div className='searcBox alignCen'>
                                <div className='cust-col-4-new'>
                                    <div className="input-group">
                                        <div className="input-group-prepend" style={{ width: '30%', zIndex: '10', marginRight: '15px' }}>
                                            <select className="select2-single" defaultValue="select" id="vehicleSearch">
                                                <option value="select" disabled>Select</option>
                                                <option value="address">Address</option>
                                                <option value="company">Company Name</option>
                                                <option value="contactDetails.mobileNo">Mobile Number</option>
                                                <option value="contactDetails.personName">Contact Person Name</option>
                                            </select>
                                        </div>
                                        <input type="text" name='searchValue' value={searchObj.searchValue} onChange={(e) => _handleChange(e)} className="form-control" placeholder="Search here..." style={{ zIndex: '10' }} />
                                        <div className="input-group-append" onClick={() => triggerSearch()}>
                                            <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='cust-col-2'>
                                </div>
                            </div>
                            <div className='mapView' >
                                <button className='myMapBtnGrey ' onClick={() => { _handleClick('AddAddressModal') }}> Add Address</button>
                                {/* <button className='myMapBtnGrey' onClick={() => { _handleClick('AddressRouteModal') }}> Create Route</button> */}
                            </div>
                        </div>
                        {loader === false ?
                            listing && listing.length !== 0 ?
                                <div className="generic-table-wrapper mt20">
                                    <Table className="generic-table">
                                        <thead className="bg-accent">
                                            <tr>
                                                <th>S. No</th>
                                                <th>Company Name</th>
                                                <th>Address</th>
                                                <th>Location</th>
                                                <th>Contact Details</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            {listing && listing.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ color: '#007BC9' }}>
                                                            {((pageCount - 1) * (pageLimit)) + index + 1}
                                                        </td>
                                                        <td>{item.company ? item.company : '-'}</td>
                                                        <td>{item.address ? item.address : '-'}</td>
                                                        <td style={{textDecoration:'underline', textUnderlineOffset:'4px',color:'#0079c9', cursor:'pointer'}} onClick={() => { _handleClick('GeofenceModal'); shareData(item, index) }}>{item.latitude} ,  {item.longitude}</td>

                                                        <td>{item.contactDetails && item.contactDetails.personName ? item.contactDetails.personName : '-'} , {item.contactDetails && item.contactDetails.mobileNo ? item.contactDetails.mobileNo : '-'} , {item.contactDetails && item.contactDetails.email ? item.contactDetails.email : '-'}</td>
                                                       
                                                        <td><p></p>
                                                            <>
                                                            <i className="fa fa-pencil-square-o editIcon" onClick={() => { _handleClick('AddAddressModal'); shareToModal(item) }} style={{ marginRight: '10px' }} ></i>
                                                        <i className="fa fa-trash trashIcon" onClick={() => deleteAddress(item._id)}></i>
                                                            </>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                                :
                                <div className='noData'>
                                    <p>{message}</p>
                                </div>
                            :
                            <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
                            </div>

                        }
                        {listing && listing.length !== 0 ?
                                <div className='paginationDiv'>
                                    <div className='entries'>
                                        <span className='entrieSpan'>Show Entries :</span>
                                        <Select
                                            onChange={(value) => handleChange(value)}
                                            options={entries}
                                            styles={colourStyles}
                                        />
                                    </div>
                                    <button disabled={pageCount === 1 ? true : false} className='paginationBtn' onClick={prevPage}>Previous</button>
                                    <button disabled={Math.floor((count + pageLimit - 1) / pageLimit )=== pageCount ? true : false} className='paginationBtn' onClick={nextPage}>Next</button>
                                </div>
                                : ''}
                    </div>
                </div>
            </div>
            <AddAddress closeModal={_closeModal} info={info} callBack={callBack} returnFun={returnFun} />
            <AddressRoute closeModal={_closeModal} listing={listing} />
            <Geofence closeModal={_closeModal} geo={geo} />
        </div>
    )
}
export default AddressManag;