import {  AREA_LIST , AREA_SHOW , AREA_HIDE , AREA_MSSG } from "../actions/types";

const initialState={
    areaLists: [],
    loading: true, 
    message : ""
} 

const getAreasRed =(state=initialState,{type,payload})=>{
    switch(type){
        case AREA_LIST : return{
            ...state,
            areaLists : payload
        }
        case AREA_SHOW : return{ 
            ...state,
            loading: true
        }
        case AREA_HIDE : return{ 
            ...state,
            loading:false
        }  
        case AREA_MSSG : return{ 
            ...state,
            message:payload
        }
        default: return state;  
    }
}
export default getAreasRed ;