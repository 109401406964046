import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { getSolutions, getPlotPoints } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import $ from 'jquery'
import VehicleLoads from '../Modal/VehLoad'
// import Plotlyy from '../Modal/Plotly'

const Solutions = (props) => {
    const solList = useSelector((state) => state.getSolRed.solsListing)
    const solListLoader = useSelector((state) => state.getSolRed.loading)
    const pointLists = useSelector((state) => state.getplotRed.plotLists)
    const [coordinates, setCoordinates] = useState({})

    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")

        // $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $("#SolModal").removeClass("custom-modal-is-visible")
    }
    const _handleCoords = () => {
        setCoordinates(pointLists)

        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#VehicleLoads`).addClass("custom-modal-is-visible")

        // $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $("#SolutionTable").removeClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#SolModal`).addClass("custom-modal-is-visible")

        // $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $("#SolutionTable").removeClass("custom-modal-is-visible")
    }

    return (
        <>
            <section id="SolutionTable" className="custom-modal toggle_width" style={{ width: "72%", }}>
                <div className="custom-modal-header">
                    <h1>Solutions</h1>
                    <span className="ct-close" onClick={_closeModal}></span>
                </div>

                <div className="custom-modal-body" style={{ maxHeight: '78vh', overflow: 'auto' }}>
                    {solList && solList !== undefined ? 
                    Object.entries(solList).map(obj => {
                        return (
                            <div className='solutionsOuter'>
                                <div className='solutionsInner'>
                                    <div className='solutionHeader'>
                                        <span className='solHeaderSpan'>Solution based on {obj[0] === 'price' ? 'Price' : '' ||
                                            obj[0] === 'price_wastage' ? 'Price and Wastage' : '' || obj[0] === 'wastage' ? 'Wastage' : ''} -:</span>
                                    </div>
                                    <div className='solutionTable'>
                                        <div className='solTableHead'>
                                            <div className='solCol sol10'>S No.</div>
                                            <div className='solCol sol40'>Vehicle Type</div>
                                            <div className='solCol sol16'>Quantity</div>
                                            <div className='solCol sol16'>Wastage </div>
                                            <div className='solCol sol16'>Price / KM</div>
                                            <div className='solCol sol16' style={{ borderRight: 'none' }}>Action</div>
                                        </div>
                                        {JSON.parse(obj[1]).map((item, ind) => {
                                            return (
                                                <div className='solTableBody'>
                                                    <div className='solCol sol10 solTabSpan'>{ind + 1}</div>
                                                    <div className='solCol sol40 solTabSpan'>{(item.combinations.join(", "))} </div>
                                                    <div className='solCol sol16 solTabSpan'>{item.combinations.length}</div>
                                                    <div className='solCol sol16 solTabSpan'>{item.wastage} (cm<sup>3</sup>)</div>
                                                    <div className='solCol sol16 solTabSpan'>{Array.isArray(item.price) ? (item.price.join(", ")) : item.price}</div>
                                                    {JSON.parse(obj[1]).length - 1 === ind ?
                                                        <div className='solCol sol16  solAbs' style={{ borderRight: 'none' }}>
                                                            <div className={JSON.parse(obj[1]).length === 1 ? 'hideBorderDiv1' : 'hideBorderDiv2'}>
                                                                <button className='loadDistBtn'
                                                                    disabled={pointLists && pointLists.length === 0 || pointLists === undefined ? true : false}
                                                                    onClick={() => { _handleClick('VehicleLoads'); _handleCoords() }}>
                                                                    View Load Distribution
                                                                    {pointLists && pointLists.length === 0 || pointLists === undefined ? 
                                                                    <div className='warningBoxx'>
                                                                        <span>No data Found</span>
                                                                    </div>
                                                                    :''}
                                                                </button>



                                                                {pointLists && pointLists.length === 0 ?
                                                                    <i className="fa fa-spinner fa-spin loadingSpinnerdown" style={{ color: '#007BC9', fontSize: '1rem', fontWeight: '800' }}></i>
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='solCol sol16 solTabSpan' style={{ borderRight: 'none', borderBottom: '1px solid #fff' }}></div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>


                            </div>

                        )
                    })
                    :
                    <div className='notFoundddd'>
                    <span>Solution not found.</span>
                    </div>
                    }
                </div>

            </section>

            <VehicleLoads coordinates={coordinates} />
        </>


    )
}

export default Solutions;