import React, { useEffect, useState } from 'react';
import AddBankDetailModal from './Modal/AddBankDetail'
import { useDispatch, useSelector } from 'react-redux'
import jwt_decode from "jwt-decode";
import user from './../../static/images/dummy-user.png'
import ProfileLocation from './Modal/ProfileLocation'
import $ from 'jquery'
import { getUsers } from '../../state/redux/actions/index';

const Profile = () => {
    const dispatch = useDispatch()
    const [sharedData, setSharedData] = useState({})
    const [token, setToken] = useState({})
    const [type, setType] = useState('')
    const listing = useSelector((state) => state.getUserRed.usersList)
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const shareData = (item) => {
        setSharedData(item)
    }
    useEffect(() => {
        var token = localStorage.getItem('secretkey')
        var decoded = jwt_decode(token);
        var userType = decoded && decoded.token ? decoded.token.userType : ''
        var searcValue = userType === 'distributor' ? decoded.token.code : ''
        setType(userType)
        if (userType === 'distributor') {
            dispatch(getUsers({
                searchType: "code",
                searchValue: searcValue,
                userType: 'distributor',
                type: 'distributor',
            }))
        }
        else {
            setToken(decoded.token)
        }


    }, [])

    return (
        <>
            <div className="contentpanel">
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-5'>
                        <h1 className="title">
                            Profile Details
                        </h1>
                    </div>
                    <div className='cust-col-5 flex-jc-end'>
                        <h1 className="title font-bold orange_color">
                        </h1>
                    </div>
                </div>
                <hr className='bgg' />
                <div className="cust-container">
                    <div className="cust-row">
                        <div className="cust-col-10">

                            <div className='bdaList'>
                                <div className='profileElem'>
                                    <div className='topProfile'>
                                        <div className='profilePic'>
                                            <div className='img-container'>
                                                <img className='imageDim' src={user} alt='sd' />
                                            </div>
                                        </div>
                                        <div className='profileInfo'>
                                            <div className='lt'>
                                                <div className="ltTop">
                                                    <span className="ltAbs">User Details</span>
                                                </div>
                                                <>
                                                    <div className="ltBot" style={{ height: 'auto' }}>
                                                        <div className="ltRow"><div className="lt40">
                                                            <span className="ltspan">User Name :</span>
                                                        </div>
                                                            <div className="lt60">
                                                                {type === 'distributor' ?
                                                                    <input className="ltInp1 nobd" readOnly value={listing && listing[0] ? listing[0].name : '-'} />
                                                                    :
                                                                    <input className="ltInp1 nobd" readOnly value={token ? token.name : '-'} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="ltRow"><div className="lt40">
                                                            <span className="ltspan">Mobile Number :</span>
                                                        </div>
                                                            <div className="lt60">
                                                                {type === 'distributor' ?
                                                                    <input className="ltInp1 nobd" readOnly value={listing && listing[0] ? listing[0].mobile : '-'} />
                                                                    :
                                                                    <input className="ltInp1 nobd" readOnly value={token ? token.mobile : '-'} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="ltRow"><div className="lt40">
                                                            <span className="ltspan">Type :</span>
                                                        </div>
                                                            <div className="lt60">
                                                                {type === 'distributor' ?
                                                                    <input className="ltInp1 nobd" readOnly value={`${listing && listing[0] && listing[0].userType ? listing[0].userType.toUpperCase() : ''}` + ` ` +   ` ,` + ` ` +`${listing && listing[0] && listing[0].code ? listing[0].code: ''}`} />
                                                                    :
                                                                    <input className="ltInp1 nobd" readOnly value={token && token.userType  ? token.userType.toUpperCase() === 'DRIVER' ? 'DELIVERY MAN' : localStorage.getItem('userType').toLocaleUpperCase() : ''} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ltBot" style={{ height: 'auto' }}>
                                                        <div className="ltRow"><div className="lt40">
                                                            <span className="ltspan">State :</span>
                                                        </div>
                                                            <div className="lt60">
                                                            {type === 'distributor' ?
                                                                <input className="ltInp1 nobd" readOnly value={listing && listing[0] ? listing[0].state : '-'} />
                                                                :
                                                                <input className="ltInp1 nobd" readOnly value={token ? token.state : ''} />
                                                            }
                                                            </div>
                                                        </div>
                                                        <div className="ltRow"><div className="lt40">
                                                            <span className="ltspan">City :</span>
                                                        </div>
                                                            <div className="lt60">
                                                            {type === 'distributor' ?
                                                                <input className="ltInp1 nobd" readOnly value={listing && listing[0] ? listing[0].city : '-'} />
                                                                :
                                                                <input className="ltInp1 nobd" readOnly value={token ? token.city : ''} />
                                                            }
                                                            </div>
                                                        </div>

                                                        {type === 'distributor' ?
                                                            <>
                                                                <div className="ltRow"><div className="lt40">
                                                                    <span className="ltspan">Location :</span>
                                                                </div>
                                                                    <div className="lt60">
                                                                        <input className="ltInp1 nobd ltnNoUnderline" readOnly value={`${listing && listing[0] && listing[0].latitude ? listing[0].latitude.toFixed(8) : '-'} , ${listing && listing[0] && listing[0].longitude ? listing[0].longitude.toFixed(8) : '-'}`} />
                                                                        <i onClick={() => { _handleClick('ProfileLocation'); shareData(listing) }} className="fa fa-map-marker lMarker" ></i>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : 
                                                            <div className="ltRow"><div className="lt40">
                                                                    {/* <span className="ltspan">Location :</span> */}
                                                                </div>
                                                                    <div className="lt60">
                                                                        {/* <input className="ltInp1 nobd ltnNoUnderline" readOnly value={`${listing && listing[0] && listing[0].latitude ? listing[0].latitude.toFixed(8) : '-'} , ${listing && listing[0] && listing[0].longitude ? listing[0].longitude.toFixed(8) : '-'}`} />
                                                                        <i onClick={() => { _handleClick('ProfileLocation'); shareData(listing) }} className="fa fa-map-marker lMarker" ></i> */}
                                                                    </div>
                                                                </div>
                                                            }

                                                    </div>
                                                    {/* <div className="ltBot" style={{ height: '100px' }}>
                                                        <div className="ltRow"><div className="lt40">
                                                            <span className="ltspan">Longitude :</span>
                                                        </div>
                                                            <div className="lt60">
                                                                <input className="ltInp1 nobd" value={profileData[0].longitude} /></div>
                                                        </div>
                                                        <div className="ltRow"><div className="lt40">

                                                        </div>


                                                        </div>
                                                        <div className="ltRow"><div className="lt40">

                                                        </div>


                                                        </div>
                                                    </div> */}

                                                </>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <ProfileLocation closeModal={_closeModal} sharedData={sharedData} />
            </div>
        </>
    );
};

export default Profile;