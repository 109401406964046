import { DIST_ADMIN_LISTING , DIST_ADMIN_LOADER , DIST_HIDE_ADMIN_LOADER , DIST_MSSG, DIST_COUNT} from "../actions/types";



const initialState={
    distributorListing: [],
    loading: true,
    message: '',
    count :''
} 

const distributorListingReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case DIST_ADMIN_LISTING : return{
            ...state,
            distributorListing : payload
        }
        case DIST_ADMIN_LOADER : return{ 
            ...state,
            loading:true 
        }
        case DIST_HIDE_ADMIN_LOADER : return{ 
            ...state,
            loading:false 
        }
        case DIST_MSSG : return{ 
            ...state,
            message: payload 
        }
        case DIST_COUNT : return{ 
            ...state,
            count: payload 
        }
        default: return state;  
    }
}
export default distributorListingReducer ;