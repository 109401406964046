import $ from 'jquery'
import { useState } from 'react';
import axios from 'axios'
import { getTrips } from './../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { logoutOnExpiry , url } from './../../../services/requests'

const HandleInputOutput = (props) => {
    const dispatch = useDispatch()
    let id = props.otpData.tripId
    const [pageCount, setPageCount] = useState(1)
    const [count, setCount] = useState(10)
    const [date, setDate] = useState('')
    const [ssnValues, setValue] = useState({
        ssn1: "",
        ssn2: "",
        ssn3: "",
        ssn4: "",
        ssn5: "",
        ssn6: ""
    });
    const _handleOtp = (e) => {
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        if (value.length >= maxLength) {
            if (parseInt(fieldIndex, 10) < 6) {
                const nextSibling = document.querySelector(
                    `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
                );
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }
        setValue({ ...ssnValues, [`ssn${fieldIndex}`]: value });
    }
    const toggleTrip = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
         if (props.otpData.type === 'reached') {
            axios.post(`${url}/trip/editTrip`, { tripId: id, type: 'reached_waypoint' }, { headers: config.headers })
                .then(response => {
                    const status = response.data.success
                    const message = response.data.message
                    ssnValues.ssn1 = ''
                    ssnValues.ssn2 = ''
                    ssnValues.ssn3 = ''
                    ssnValues.ssn4 = ''
                    ssnValues.ssn5 = ''
                    ssnValues.ssn6 = ''
                    if (status === true) {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Success!!',  autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                        dispatch(getTrips({
                            page: pageCount,
                            limit: count,
                            date: date
                        }))
                        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                        $(".custom-modal").removeClass("custom-modal-is-visible")
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        }
        else if (props.otpData.type === 'stop') {
            axios.post(`${url}/trip/editTrip`, { tripId: id, type: 'end_trip' }, { headers: config.headers })
                .then(response => {
                    const status = response.data.success
                    const message = response.data.message
                    ssnValues.ssn1 = ''
                    ssnValues.ssn2 = ''
                    ssnValues.ssn3 = ''
                    ssnValues.ssn4 = ''
                    ssnValues.ssn5 = ''
                    ssnValues.ssn6 = ''
                    if (status === true) {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Success!!',  autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                        dispatch(getTrips({
                            page: pageCount,
                            limit: count,
                            date: date
                        }))
                        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                        $(".custom-modal").removeClass("custom-modal-is-visible")
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        }
     
    }
    const _closeModal = () => {
        ssnValues.ssn1 = ''
        ssnValues.ssn2 = ''
        ssnValues.ssn3 = ''
        ssnValues.ssn4 = ''
        ssnValues.ssn5 = ''
        ssnValues.ssn6 = ''
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    return (
        <>
            <section id="VerfiyOtp" className="custom-modal toggle_width" style={{ width: "30%", }}>
                <div className="custom-modal-header">
                    <h1>Verify OTP</h1>
                    <span className="ct-close" onClick={_closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{ height: '37vh', overflow: 'auto' }}>

                    <div className='otpModule'>
                        <div className='otpHeader'>
                            <span>Enter 6 digit OTP received at your Email...</span>
                        </div>
                        <div className='otp-input'>
                            <div className='input-1'>
                                <input name='ssn-1' value={ssnValues.ssn1} onChange={(e) => _handleOtp(e)} maxLength={1} className='otp-field' />
                            </div>
                            <div className='input-2'>
                                <input name='ssn-2' value={ssnValues.ssn2} onChange={(e) => _handleOtp(e)} maxLength={1} className='otp-field' />
                            </div>
                            <div className='input-3'>
                                <input name='ssn-3' value={ssnValues.ssn3} onChange={(e) => _handleOtp(e)} maxLength={1} className='otp-field' />
                            </div>
                            <div className='input-4'>
                                <input name='ssn-4' value={ssnValues.ssn4} onChange={(e) => _handleOtp(e)} maxLength={1} className='otp-field' />
                            </div>
                            <div className='input-3'>
                                <input name='ssn-5' value={ssnValues.ssn5} onChange={(e) => _handleOtp(e)} maxLength={1} className='otp-field' />
                            </div>
                            <div className='input-4'>
                                <input name='ssn-6' value={ssnValues.ssn6} onChange={(e) => _handleOtp(e)} maxLength={1} className='otp-field' />
                            </div>
                        </div>
                    </div>

                    <div className='customBtnElem'>
                        <button className='customBtn' onClick={() => toggleTrip()}>Verify OTP</button>
                    </div>

                </div>
            </section>
        </>
    )
}

export default HandleInputOutput;