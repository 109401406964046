import { SLOT_LISTING , SHOW_SLOT_LOADER , HIDE_SLOT_LOADER, SLOT_MESSAGE, SLOT_COUNT } from './types'
import { post , logoutOnExpiry} from './../../../services/requests'
// import axios from 'axios'

export const getSlotList = (object) => {
        return (dispatch) => {
            dispatch(showLoader())
            post('setting/getTimeSettings', {...object })
                .then(response => {
                    const slotList = response.data
                    const status = response.success
                    const count = response.count
                    const message = response && response.message ? response.message : ''
                    if(status === true && slotList !== undefined){
                        dispatch(getSlot(slotList))
                        dispatch(getCount(count))
                        dispatch(hideLoader())
                        dispatch(showMessage(''))
                    }
                    else{
                        dispatch(getSlot())
                        dispatch(hideLoader())
                        dispatch(showMessage(message))
                    }
                    
                })
                .catch(error => {
                    dispatch(hideLoader())
                    dispatch(showMessage('Some Error Occured.'))
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                    console.log('error', error);
                })
        } 
    }


export const getSlot = (slotList) => {
    return {
        type: SLOT_LISTING,
        payload: slotList
    }
}
export const showLoader = () =>{
    return{
        type : SHOW_SLOT_LOADER,
    }
}
export const hideLoader = () =>{
    return{
        type : HIDE_SLOT_LOADER,
    }
}
export const showMessage = (message) =>{
    return{
        type : SLOT_MESSAGE,
        payload: message
    }
}
export const getCount = (count) =>{
    return{
        type : SLOT_COUNT,
        payload: count
    }
}