import React, {useEffect, useState} from 'react';
import pure from './../../static/images/icon/sidebar/updated/pure.jpg'
const CustomerData = () => {

    const [starState, setStarState] =useState({
        star1 : "",
        star2 : "",
        star3 : "",
        star4 : "",
        star5 : ""
    })

    const setRating = (index) => {
        let temp= {...starState}
        if(index === '1'){
            temp.star1 = '1'

            temp.star2 = ''
            temp.star3 = ''
            temp.star4 = ''
            temp.star5 = ''
            setStarState(temp)
        }
        else if(index === '2'){
            temp.star1 = '1'
            temp.star2 = '2'
            temp.star3 = ''
            temp.star4 = ''
            temp.star5 = ''
            setStarState(temp)
        }
        else if(index === '3'){
            temp.star1 = '1'
            temp.star2 = '2'
            temp.star3 = '3'
            temp.star4 = ''
            temp.star5 = ''
            setStarState(temp)
        }
        else if(index === '4'){
            temp.star1 = '1'
            temp.star2 = '2'
            temp.star3 = '3'
            temp.star4 = '4'
            temp.star5 = ''
            setStarState(temp)
        }
        else if(index === '5'){
            temp.star1 = '1'
            temp.star2 = '2'
            temp.star3 = '3'
            temp.star4 = '4'
            temp.star5 = '5'
            setStarState(temp)
        }
        
    
        // temp.
    }

    return (
        <>
            <div className='customerDetailsDiv'>
                <div className='customerHeader'>
                    <span className='detailSpan'>Details</span>
                    <img className='pfs' src={pure} alt='' />
                </div>
                <div className='customerBody'>
                    <div className='customerDetails'>
                        <div className='customerRow'>
                            <div className='customerHead'>
                                <span>Name :</span>
                            </div>
                            <div className='customerValue'>
                                <span>Lakshay Chaudhary</span>
                            </div>
                        </div>
                        <div className='customerRow'>
                            <div className='customerHead'>
                                <span>Address :</span>
                            </div>
                            <div className='customerValue'>
                                <span>G-21 Opposite SS market , Sarjoini Nagar, New Delhi, 250001</span>
                            </div>
                        </div>
                        <div className='customerRow'>
                            <div className='customerHead'>
                                <span>Quantity :</span>
                            </div>
                            <div className='customerValue'>
                                <span>1</span>
                            </div>
                        </div>
                        <div className='customerRow'>
                            <div className='customerHead'>
                                <span>Time of Delivery :</span>
                            </div>
                            <div className='customerValue'>
                                <span>02:30 PM</span>
                            </div>
                        </div>
                        {/* <div className='customerRow'>
                            <div className='customerHead'>
                                <span>Lat , Lng :</span>
                            </div>
                            <div className='customerValue'>
                                <span>27.86578 , 77.876865</span>
                            </div>
                        </div> */}
                        <div className='customerRow'>
                            <div className='customerHead'>
                                <span>Deliver Person :</span>
                            </div>
                            <div className='customerValue'>
                                <span>Ganesh , 9876543212</span>
                            </div>
                        </div>
                        <div className='feedbackDiv'>
                            <div className='feedbackHeader'>
                                <span>Rate Your Delivery Exprience :</span>
                            </div>
                            <div className='feedBackBody'>
                                <div className='feedbackStar'>
                                    <i className={starState.star1 === '1' ? "fa fa-star myStarActive" : "fa fa-star myStar"} onClick={()=>setRating('1')} ></i>
                                    <i className={starState.star2 === '2' ? "fa fa-star myStarActive" : "fa fa-star myStar"} onClick={()=>setRating('2')}></i>
                                    <i className={starState.star3 === '3' ? "fa fa-star myStarActive" : "fa fa-star myStar"} onClick={()=>setRating('3')}></i>
                                    <i className={starState.star4 === '4' ? "fa fa-star myStarActive" : "fa fa-star myStar"} onClick={()=>setRating('4')}></i>
                                    <i className={starState.star5 === '5' ? "fa fa-star myStarActive" : "fa fa-star myStar"} onClick={()=>setRating('5')}></i>
                                </div>
                            </div>
                            <div className='feedbackComment'>
                                <textarea id="w3review" rows="4" cols="40"></textarea>
                            </div>
                            <div className='feedbackSubmit'>
                                <button className='feedbackBtn'>Submit</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>

    );
};

export default CustomerData;