import React, { useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-drag-and-drop'
import Select from 'react-select';
import axios from 'axios';
import { getSettings } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { logoutOnExpiry , url } from './../../services/requests'
import { colourStyles } from '../../services/functions'

const EstimatedTripDuration = () => {
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.getSetRed.settingslisting)
    const [draggableItem, setDraggableItem] = useState([
        {
            label: 'Simple Text',
            type: 'input',
            dragType: 'simpletext',
            key: ''
        },
    ])
    const [delivery, setDelivery] = useState([])
    const [getSettingsState, setGetSettings] = useState([])
    const onDrop = (item) => {
        if (item.simpletext === 'simpletext') {
            setDelivery([...delivery, {
                label: 'Simple Text',
                type: 'input',
                dragType: 'simpletext',
                key: '',
                value: '',
                unit: ''
            }])
        }
    }
    const removeElement = (index) => {
        let local = [...delivery]
        local.splice(index, 1)
        setDelivery(local)
    }
    const _handleChange = (e, index) => {
        let temp = [...delivery]
        temp[index].key = e.target.value
        setDelivery(temp)

    }
  
    const options = [
        { label: 'select', value: 'select' },
        { label: 'Minutes', value: 'minutes' },
        { label: 'Hours', value: 'hours' },
        { label: 'Days', value: 'days' }

    ]
    const handleOptions = (item, index) => {
        let temp = [...getSettingsState]
        temp[index].unit = item.label
        setGetSettings(temp)
    }
    const addSettings = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        
        axios.post(`${url}/setting/addSetting`, { delivery }, { headers: config.headers })
            .then(response => {
                const status = response.data.success
                const message = response.data.message
                if (status === true) {
                    setDelivery([])
                    dispatch(getSettings())
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }
    const SaveSettings = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post(`${url}/setting/addSetting`, { delivery:getSettingsState }, { headers: config.headers })
            .then(response => {
                const status = response.data.success
                const message = response.data.message
                if (status === true) {
                    // setDelivery([])
                    // dispatch(getSettings())
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }
    useEffect(() => {
        dispatch(getSettings())
    }, [])
    useEffect(() => {
        if (listing && listing.length !== 0) {
            setGetSettings(listing[0].delivery)
        }
    }, [listing])

    const _onChange = (e, index) => {
        let temp = [...getSettingsState]
        temp[index].value = Number(e.target.value)
        setGetSettings(temp)
    }

    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className='cust-col-5'>
                    <h1 className="title">
                        Estimated Time Duration
                    </h1>
                </div>
                <div className='cust-col-5 flex-jc-end'>
                    <h1 className="title font-bold orange_color">
                    </h1>
                </div>
            </div>
            <hr className='bgg' />
            <div className="cust-container">
                <div class="cust-row">
                    <div class="cust-col-10">
                        {/* <div className='bdaOuter'>
                        <div className='searcBox alignCen' >
                            <div className='cust-col-4-new'>
                                <div className="input-group">
                                    <div className="input-group-prepend" style={{ width: '30%', zIndex: '10', marginRight:'15px' }}>
                                        <select className="select2-single" defaultValue="select" id="vehicleSearch">
                                            <option value="select">Select</option>
                                            <option value="vehicleNo">Vehicle Number</option>
                                        </select>
                                    </div>
                                    <input type="text" name='searchValue' value={searchObj.searchValue} onChange={(e) => _handleChange(e)} className="form-control" placeholder="Search here..." style={{ zIndex: '10' }} />
                                    <div className="input-group-append" onClick={() => triggerSearch()}>
                                        <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='cust-col-2'>
                            </div>
                        </div>
                        <div className='mapView'>
                        </div>
                    </div> */}
                        <div className='bdaList'>
                            <div className='etd'>
                                <div className='etdCreation'>
                                    <div className='etdHeading'>
                                        <span>Estimated Time Duration</span>
                                    </div>
                                    <div className='etdDynamic'>
                                        <div className='etdLeft'>
                                            <div className='etdLeftInner'>
                                                <div className='etdDropZone'>
                                                    <Droppable
                                                        className='etdDropZoneNew'
                                                        types={['simpletext']}
                                                        onDrop={(data) => onDrop(data)}>
                                                        {delivery.length > 0 ?
                                                            delivery && delivery.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='inputDiv'>
                                                                        <i className="fa fa-minus etdMinus" onClick={() => removeElement(index)}></i>
                                                                        <input name='key' value={item.key} onChange={(e) => _handleChange(e, index)} className='inputDivInp' type='text' placeholder='Enter text here' />
                                                                    </div>
                                                                )
                                                            }) :
                                                            <span className='dropHere'>Drop here..</span>
                                                        }
                                                    </Droppable>
                                                </div>

                                            </div>
                                            <div className='saveEtd'>
                                                <button className='etdSaveBtn' onClick={() => addSettings()}>Add</button>
                                            </div>
                                        </div>
                                        <div className='etdRight'>
                                            <div className='etdRightHeader'>
                                                Draggable Field
                                            </div>
                                            <div className='etdRightBody'>
                                                {draggableItem.map((item, index) => {
                                                    return (
                                                        <Draggable key={index} className='etdDraggable' type={item.dragType} data={[item.dragType]}>
                                                            <div className='etdRightColumn'>
                                                                <div className='etdDragField'>{item.label}</div>
                                                            </div>
                                                        </Draggable>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='etdDisplay'>
                                    <div className='etdDisplayHeader'>
                                        <span>ETD</span>
                                    </div>
                                    {getSettingsState && getSettingsState.length !== 0 ?
                                        <div className='etdTimeClauses'>
                                            {getSettingsState && getSettingsState.map((item, index) => {
                                                return (
                                                    <div key={index} className='etdLine'>
                                                        <div className='etdClause'>
                                                            <div className='getEtd'>
                                                                <span>{item.key}</span>
                                                            </div>
                                                            <div className='etdValueDiv'>
                                                                <div className='etd50'>
                                                                    <input value={item.value} onChange={(e) => _onChange(e, index)} placeholder='write here..' className='etdDInput' type='number' />
                                                                </div>
                                                                <div className='etd50'>
                                                                    <Select
                                                                        onChange={(value) => handleOptions(value, index)}
                                                                        options={options}
                                                                        styles={colourStyles}
                                                                        value={item.unit !== '' ? {label: item.unit , value: item.unit} : {label: 'select' , value: 'select'}}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='etdEdit'>
                                                            <i className="fa fa-edit etdEditIcon"></i>
                                                            <i className="fa fa-trash etdDeleteIcon" ></i>
                                                        </div>
                                                    </div>

                                                )
                                            })}
                                        </div>
                                        :
                                        <div className='noDataDiv'>
                                            <span>No Data Found...</span>
                                        </div>
                                    }

                                    {getSettingsState && getSettingsState.length !== 0 ?
                                    <div className='etdSave'>
                                        <button onClick={()=>SaveSettings()}>Save</button>
                                    </div>
                                    :""}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default EstimatedTripDuration;