import { OPTI_ROUTE } from "../actions/types";

const initialState={
    optiRoutes: [],
} 

const optimisedRed =(state=initialState,{type,payload})=>{
    switch(type){
        case OPTI_ROUTE : return{
            ...state,
            optiRoutes : payload
        }
        default: return state;  
    }
}
export default optimisedRed ; 