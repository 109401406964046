import { GET_SOLUTIONS, SOL_SHOW, SOL_HIDE } from './types'
import { post, logoutOnExpiry } from './../../../services/requests'


export const getSolutions = (data) => {
    return (dispatch) => {
        dispatch(showSolLoader())
        post('load/getSolutions', { ...data })
            .then(response => {
                let status = response.success
                if (status === true) {
                    const resLists = response.data.solutions
                    dispatch(getSolutionsLists(resLists))
                    dispatch(hideSolLoader())
                }
                else{
                    dispatch(getSolutionsLists())
                    dispatch(hideSolLoader())
                }
            })
            .catch(error => {
                dispatch(hideSolLoader())
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }
}


export const getSolutionsLists = (resLists) => {
    return {
        type: GET_SOLUTIONS,
        payload: resLists
    }
}
export const showSolLoader = () => {
    return {
        type: SOL_SHOW,
        payload: true
    }
}
export const hideSolLoader = () => {
    return {
        type: SOL_HIDE,
        payload: false
    }
}