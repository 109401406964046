import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import $ from 'jquery'
import history from './../../../services/history';

const Feasible = (props) => {

    const data = props && props.dataCluster && props.dataCluster && props.dataCluster ? props.dataCluster.infeasible : []
    const feasibleData = props && props.dataCluster && props.dataCluster ? props.dataCluster : []
    const [inFeasibleState, setInFeasibleState] = useState([])
    const [feasibleState, setFeasibleState] = useState([])
    useEffect(() => {
        if (data.length !== 0) {
            setInFeasibleState(data)
            setFeasibleState(feasibleData)
        }
    }, [data])

    const _closeModal = () => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $('#CreateTripModal').addClass("custom-modal-is-visible")

        // $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $("#FeasibleModal").removeClass("custom-modal-is-visible")
    }
    const continuePlot = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        history.push({
            pathname: '/dashboard/trip/preview',
            state: { clusterArr: feasibleState, previewPayload: feasibleState }
        })
    }

    return (
        <>
            <section id="FeasibleModal" className="custom-modal toggle_width" style={{ width: "71%", }}>
                <div className="custom-modal-header">
                    <h1>Infeasible Requests</h1>
                    <span className="ct-close" onClick={_closeModal}></span>
                </div>

                <div className="custom-modal-body" style={{ maxHeight: '70vh', overflow: 'auto' }}>
                    {inFeasibleState && inFeasibleState.length !== 0 ?
                        <div className='infeasibleOuter'>
                            <div className='infeasibleHeader'>
                                <span className='infeasibleText'>Total Infeasible Requests - <span className='infeasibleNum'><b>{inFeasibleState && inFeasibleState.length}</b></span></span>
                            </div>
                            {inFeasibleState && inFeasibleState.map((item, index) => {
                                return (

                                    <div key={index} className='infeasibleBody'>
                                        <div className='infeasibleReq'>
                                            <span className='feasibleSno'>{index + 1}</span>
                                            <div className='infeasibleReqHead'>
                                                <span className='infeasibleBlack'>Address :</span>
                                                <span className='infeasibleOrange'>{item.address}</span>
                                            </div>
                                            <div className='infeasibleReqHead infeasibleFlex'>
                                                <div className='infeasibleReqHead33 infeasibleFlex'>
                                                    <span className='infeasibleTime'>Time Slot :</span>
                                                    <span className='infeasibleInp'>{item.startSlotTime} - {item.endSlotTime}</span>
                                                </div>
                                                <div className='infeasibleReqHead33 infeasibleFlex'>
                                                    <span className='infeasibleTime'>Quantity :</span>
                                                    <span className='infeasibleInp'>{item.quantity}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='feasibleReason'>
                                            <span className='requestFailText'>Infeasible Location due to Extra Quantity.</span>
                                        </div>


                                    </div>



                                )
                            })}
                            <div className='feasibleTail'>
                                <button className='feasibleBtn' onClick={_closeModal}>Edit</button>
                                <button className='feasibleBtn' onClick={() => continuePlot()}>Continue with Feasible Data</button>
                            </div>
                        </div>
                        :
                        <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ color: 'red', fontSize: '1.2rem' }}>No Data found..</span>
                        </div>
                    }
                </div>
            </section>

        </>


    )
}

export default Feasible;