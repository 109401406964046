import { SETTINGS , SETTINGS_SHOW, SETTINGS_HIDE, SETTINGS_MSSG} from "../actions/types";

const initialState={
    settingslisting: [],
    loading: true,
    message : ""
} 

const getSetRed =(state=initialState,{type,payload})=>{
    switch(type){
        case SETTINGS : return{
            ...state,
            settingslisting : payload
        }
        case SETTINGS_SHOW : return{ 
            ...state,
            loading: true
        }
        case SETTINGS_HIDE : return{ 
            ...state,
            loading:false
        }
        case SETTINGS_MSSG : return{ 
            ...state,
            message: payload
        }
        default: return state;  
    }
}
export default getSetRed ;