/* eslint-disable */

import { useEffect, useState } from 'react'
import axios from 'axios';
import { logoutOnExpiry , url } from './../../../services/requests'

const ProfileLocation = (props) => {
    const data = props && props.sharedData[0] ? props.sharedData[0] : {}
    const lat = props && props.sharedData[0] ? props.sharedData[0].latitude : ''
    const lng = props && props.sharedData[0] ? props.sharedData[0].longitude : ''
    const mid = { lat: lat, lng: lng }
    const [distState, setDistState] = useState({
        _id: '',
        latitude: '',
        longitude: '',
        userType: 'distributor'
    })

    useEffect(() => {
        const myLatLng = { lat: mid.lat, lng: mid.lng };
        const map = new google.maps.Map(document.getElementById("mapDimensions"), {
            zoom: 17,
            center: myLatLng,
        });

        var marker = new google.maps.Marker({
            position: myLatLng,
            map,
            draggable: true,
            title: "",
        });
        var circle = new google.maps.Circle({
            map: map,
            radius: 100,
            strokeOpacity: 0.8,
            strokeWeight: 0.7,
            fillColor: "#add8e6",
            fillOpacity: 0.45,
            zIndex: 1,
            setDraggable: false
        });
        var dummyMarker = new google.maps.Marker({
            position: new google.maps.LatLng(mid.lat, mid.lng),
            visible: false,
            map: map,
        });
        circle.bindTo('center', dummyMarker, 'position');
        google.maps.event.addListener(marker, "dragend", function (event) {
            var lat = event.latLng.lat();
            var lng = event.latLng.lng();
            let temp ={...distState}
            temp.latitude = lat
            temp.longitude = lng
            setDistState(temp)

        });
    }, [props])

    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const update = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        let temp = { ...distState }
        temp._id = data ? data._id : ''
        axios.post(`${url}/user/editUser`, { ...temp }, { headers: config.headers })
            .then(response => {
                let status = response.data.success
                let message = response.data.message
                if(status === true){
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.location.reload()
                }
                else{
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }


    return (
        <section id="ProfileLocation" className="custom-modal toggle_width" style={{ width: "60%" }}>
            <div className="custom-modal-header">
                <h1>Location</h1>
                <span className="ct-close" onClick={_closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ maxHeight: '70vh', height: '70vh' }} >
                <div className='mapOuter' style={{ height: '100%' }}>
                    {distState.latitude !== '' && distState.longitude !== '' ?
                    <button className='updateLocations' onClick={update}>Update</button>
                    :''}
                    <div id='mapDimensions'>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProfileLocation;