import { CYLINDER_DATA, CYLINDER_SHOW, CYLINDER_HIDE , CYLINDER_MSSG } from './types'
import { post , logoutOnExpiry} from './../../../services/requests'

export const cylinderData = (object) => {
    return (dispatch) => {
        dispatch(showLoader())
        post('/cylinder/qRDeliveryDetails', {...object })
            .then(response => {
                const cylinderInfo = response && response.data ? response.data : ''
                const status = response && response.success ? response.success : ''
                const mssg = response && response.message ? response.message : ''
                if (status === true && cylinderInfo !== undefined) {
                    dispatch(getCylinderInfo(cylinderInfo))
                    dispatch(hideLoader()) 
                }
                else{
                    dispatch(getCylinderInfo())
                    dispatch(hideLoader()) 
                    dispatch(showMessage(mssg))
                }
            })
            .catch(error => { 
                console.log('error', error);
                dispatch(hideLoader()) 
                dispatch(showMessage('Some Error Occured.'))
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }
}


export const getCylinderInfo = (cylinderInfo) => {
    return {
        type: CYLINDER_DATA,
        payload: cylinderInfo
    }
}
export const showLoader = () => {
    return {
        type: CYLINDER_SHOW,
    }
}
export const hideLoader = () => {
    return {
        type: CYLINDER_HIDE,
    }
}
export const showMessage = (message) =>{
    return{
        type : CYLINDER_MSSG,
        payload : message
    }
}