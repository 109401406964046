import React, { useState, useEffect } from 'react';
import axios from 'axios'
// import FileViewer from 'react-file-viewer';
// import { Document, Page } from 'react-pdf';
import { url } from './../../services/requests'

const CashMemo = () => {
    const [base, setBase] = useState('')
    const [mssg, setMssg] = useState('')
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const getFileName = query.get('fileKey')
        let finalObj = { fileName: getFileName }
        setLoader(true)
        
        axios.post(`${url}/general/getImageToBlob`, { ...finalObj })
            .then(response => {
                setLoader(false)
                let status = response.data.success
                if (status === true) {
                    let base64 = response.data.data
                    // let blob = b64toBlob(base64, 'application/pdf');
                    // let ur = URL.createObjectURL(blob);
                    // let addString = `data:application/pdf;${base64}`
                    setBase(base64)
                }
                else {
                    setMssg('No Result Found...')
                }

            })
            .catch(error => {
                setLoader(false)
                console.log('error', error);
            })
    }, [])

    // const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    //     const byteCharacters = atob(b64Data);
    //     const byteArrays = [];

    //     for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    //         const slice = byteCharacters.slice(offset, offset + sliceSize);

    //         const byteNumbers = new Array(slice.length);
    //         for (let i = 0; i < slice.length; i++) {
    //             byteNumbers[i] = slice.charCodeAt(i);
    //         }

    //         const byteArray = new Uint8Array(byteNumbers);
    //         byteArrays.push(byteArray);
    //     }

    //     const blob = new Blob(byteArrays, { type: contentType });
    //     return blob;
    // }
    // const openPdf = () => {
    //     window.open(base)
    // }
    // function downloadPDF(pdf) {
    //     const linkSource = `data:application/pdf;base64,${pdf}`;
    //     const downloadLink = document.createElement("a");
    //     const fileName = "file.pdf";

    //     downloadLink.href = linkSource;
    //     downloadLink.download = fileName;
    //     downloadLink.click();
    // }
    // }
    return (
        <>
            <div className='cashMemo'>
                {!loader ?
                    base !== '' ?
                        <embed className='embedTag' src={`data:application/pdf;base64,${base}`} />
                        :
                        <div className='noData'>
                            <p style={{ fontWeight: '900' }}>{mssg}</p>
                        </div>
                    :
                    <div className='loaderDiv'>
                        <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
                    </div>
                }
            </div>
        </>
    )
}
export default CashMemo;