import { selectInit } from './../../../services/functions'
import { useEffect, useState } from 'react'
import $ from 'jquery'
import { getAddress } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { getVehiclesList } from './../../../state/redux/actions/index';
import moment from 'moment'
import Select from 'react-select';
import { logoutOnExpiry, url } from './../../../services/requests'
import { colourStyles } from './../../../services/functions'

const AddVehicle = (props) => {
    const dispatch = useDispatch()
    const sharedData = props && props.data && props.data.detail ? props.data.detail : ''
    const status = props && props.data && props.data.update ? props.data.update : ''
    const pageNumber = props && props.data && props.data.pagination && props.data.pagination.pageNumber ? props.data.pagination.pageNumber : ''
    const entries = props && props.data && props.data.pagination && props.data.pagination.entries ? props.data.pagination.entries : ''

    const [vehicleState, setVehicleState] = useState({
        vehicleNo: "",
        vehicleType: "select",
        capacityInKg: "",
        capacityInNumber: "",
        // startTime: "",
        // endTime: "",
        length: "",
        width: "",
        height: "",
        freightRate: "",
        params: "Quantity",
        quantity: ""
    })
    const [storeData, setStoreData] = useState({
        vehicleNo: "",
        vehicleType: "",
        capacityInKg: "",
        capacityInNumber: "",
        // startTime: "",
        // endTime: "",
        length: "",
        width: "",
        height: "",
        freightRate: ""
    })

    const _onChange = (e, item) => {
        if (item === 'capaNum') {
            setVehicleState({ ...vehicleState, [e.target.name]: parseFloat(e.target.value) })
        }
        else if (item === 'capaKg') {
            setVehicleState({ ...vehicleState, [e.target.name]: parseFloat(e.target.value) })
        }
        else if (item === 'time') {
            setVehicleState({ ...vehicleState, [e.target.name]: parseFloat(e.target.value) })
        }
        else if (item === 'qty') {
            setVehicleState({ ...vehicleState, [e.target.name]: e.target.value})
        }
        else if (item === 'vehicleNo') {
            var specialChars = /[^a-zA-Z0-9 ]/g;
            var len = e.target.value.length
            if (e.target.value.match(specialChars) || len >= 11) {
            }
            else {
                setVehicleState({ ...vehicleState, [e.target.name]: e.target.value })
            }
        }
        else {
            setVehicleState({ ...vehicleState, [e.target.name]: e.target.value })
        }
    }
    const calculation = () => {
        if (status !== true) {
            let temp = { ...vehicleState }
            let res = Math.ceil(temp.length * temp.width * temp.height)
            temp.capacityInKg = res
            setVehicleState(temp)
        }
        else {
            let dummy = { ...storeData }
            let res = Math.ceil(dummy.length * dummy.width * dummy.height)
            dummy.capacityInKg = res
            setStoreData(dummy)
        }
    }
   
    const addVehicle = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        const concate = { ...vehicleState }
        if (concate.vehicleNo === '') {
            window.$.fn.show_notification({ message: 'Vehicle Number required.', title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if (concate.vehicleType === 'select') {
            window.$.fn.show_notification({ message: 'Select Vehicle Type.', title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if (concate.params === "Quantity") {
            if (concate.capacityInNumber === '') {
                window.$.fn.show_notification({ message: 'Enter Quantity.', title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }
            else {
                axios.post(`${url}/vehicle/addVehicle`, { ...concate }, { headers: config.headers })
                    .then(response => {
                        const status = response.data.success
                        const message = response.data.message
                        dispatch(getVehiclesList({
                            page: 1,
                            limit: 10,
                            searchType: '',
                            searchValue: ''
                        }))
                        if (status === true) {
                            $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                            $(".custom-modal").removeClass("custom-modal-is-visible")
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                            setVehicleState({
                                vehicleNo: "",
                                vehicleType: "select",
                                capacityInKg: "",
                                capacityInNumber: "",
                                length: "",
                                width: "",
                                height: "",
                                params: "",
                                freightRate: "",
                                quantity: ""
                            })
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                        if (error.error.status === 401) {
                            logoutOnExpiry()
                        }
                    })
            }
        }
        else if (concate.params === "Volume") {
            if (concate.length === '') {
                window.$.fn.show_notification({ message: 'Enter Length.', title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }
            else if (concate.width === '') {
                window.$.fn.show_notification({ message: 'Enter width.', title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }
            else if (concate.height === '') {
                window.$.fn.show_notification({ message: 'Enter height.', title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }
            else if (concate.freightRate === '') {
                window.$.fn.show_notification({ message: 'Enter freightRate.', title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }
            else {
                axios.post(`${url}/vehicle/addVehicle`, { ...concate }, { headers: config.headers })
                    .then(response => {
                        const status = response.data.success
                        const message = response.data.message
                        dispatch(getVehiclesList({
                            page: 1,
                            limit: 10,
                            searchType: '',
                            searchValue: ''
                        }))
                        if (status === true) {
                            $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                            $(".custom-modal").removeClass("custom-modal-is-visible")
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                            setVehicleState({
                                vehicleNo: "",
                                vehicleType: "select",
                                capacityInKg: "",
                                capacityInNumber: "",
                                length: "",
                                width: "",
                                height: "",
                                params: "",
                                freightRate: "",
                                quantity: ""
                            })
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                        if (error.error.status === 401) {
                            logoutOnExpiry()
                        }
                    })
            }
        }


    }
    useEffect(() => {
        let dummy = { ...storeData }
        dummy.vehicleNo = sharedData.vehicleNo
        dummy.vehicleType = sharedData.vehicleType
        dummy.capacityInKg = sharedData.capacityInKg
        dummy.capacityInNumber = sharedData.capacityInNumber
        // dummy.startTime = sharedData.startTime
        // dummy.endTime = sharedData.endTime
        dummy.params = sharedData && sharedData.capacityInNumber !== null ? "Quantity" : "Volume"
        dummy.length = sharedData.length
        dummy.width = sharedData.width
        dummy.height = sharedData.height
        dummy.freightRate = sharedData.freightRate
        setStoreData(dummy)
        setTimeout(() => {
            selectInit()
        }, 100);

        let temp = {...vehicleState}
        temp.params = sharedData && sharedData.capacityInNumber !== null ? "Quantity" : "Volume"
        setVehicleState(temp)
    }, [sharedData])
    // update work 

    const _onUpdate = (e, item) => {
        if (item === 'num') {
            setStoreData({ ...storeData, [e.target.name]: parseFloat(e.target.value) })
        }
        else if (item === 'time') {
            setStoreData({ ...storeData, [e.target.name]: parseFloat(e.target.value) })
        }
        else if (item === 'qty') {
            setStoreData({ ...storeData, [e.target.name]: e.target.value })
        }
        else if (item === 'vehicleNo') {
            var specialChars = /[^a-zA-Z0-9 ]/g;
            var len = e.target.value.length
            if (e.target.value.match(specialChars) || len >= 11) {
            }
            else {
                setStoreData({ ...storeData, [e.target.name]: e.target.value })
            }
        }
        else {
            setStoreData({ ...storeData, [e.target.name]: e.target.value })
        }
    }
    const vehicleTypes = [
        // { label: 'select', value: 'select' },
        { label: ' CYCLE or TWO-WHEELER ', value: 'CYCLE or TWO-WHEELER ' },
        { label: 'LPMV', value: 'LPMV' },
        { label: 'HPMV', value: 'HPMV' },
    ]
    const dummy = [
        // { label: 'select', value: 'select' },
        { label: 'Volume ', value: 'Volume' },
        { label: 'Quantity ', value: 'Quantity' },
    ]
    const handleDropChange = (item, val) => {
        let temp = { ...vehicleState }
        if (val === 'vehicleType') {
            temp.vehicleType = item.value
            setVehicleState(temp)
        }
        else if (val === 'dummy') {
            temp.params = item.value
            setVehicleState(temp)
        }
    }
    const handleUpdateDropChange = (item, val) => {
        let temp = { ...storeData }
        if (val === 'vehicleType') {
            temp.vehicleType = item.value
            setStoreData(temp)
        }
        // else if (val === 'dummy') {
        //     temp.params = item.value
        //     setStoreData(temp)
        // }
    }
    const updateVehicle = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        let dataObj = { updateDetails: storeData }
        const concate = { ...dataObj, _id: sharedData._id }
        if (dataObj.updateDetails.vehicleNo === '') {
            window.$.fn.show_notification({ message: 'Enter Vehicle Number.', title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if(dataObj.updateDetails.vehicleType === 'select'){
            window.$.fn.show_notification({ message: 'Select Vehicle Type..', title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else{
            axios.post(`${url}/vehicle/editVehicle`, { ...concate }, { headers: config.headers })
            .then(response => {
                const status = response.data.success
                const message = response.data.message
                if (status === true) {
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.$.fn.show_notification({ message: `${message}`, title: 'Success!!',  autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                    dispatch(getVehiclesList({
                        page: pageNumber,
                        limit: entries,
                        searchType: '',
                        searchValue: ''
                    }))
                    setVehicleState({
                        vehicleNo: "",
                        vehicleType: "",
                        capacityInKg: "",
                        capacityInNumber: "",
                        // startTime: "",
                        // endTime: "",
                        length: "",
                        width: "",
                        height: "",
                        freightRate: ""
                    })
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
        }
       

    }
    const _closeModal = () => {
        setVehicleState({
            vehicleNo: "",
            vehicleType: "select",
            capacityInKg: "",
            capacityInNumber: "",
            // startTime: "", 
            // endTime: "",
            length: "",
            width: "",
            height: "",
            freightRate: "",
            params: "Quantity",
            quantity: ""
        })
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $("#AddVehicle").removeClass("custom-modal-is-visible")
    }


    return (
        <section id="AddVehicle" className="custom-modal toggle_width" style={{ width: "60%" }}>
            <div className="custom-modal-header">
                <h1>{status !== true ? 'Add Vehicle' : 'Edit Vehicle'}</h1>
                <span className="ct-close" onClick={() => { _closeModal(); props.callBack() }}></span>
            </div>

            <div className="custom-modal-body" >
                <div className='requestBody'>
                    <div className='reqbod3'>
                        <div className='customRow addBg'>
                            <div className='custom47'>
                                <div className='custom40'>
                                    <span className='custSpan'>Vehicle Number<span className='asterick'> * </span></span>
                                </div>
                                <div className='custom60'>
                                    <input name='vehicleNo'
                                        value={status !== true ? vehicleState.vehicleNo : storeData.vehicleNo}
                                        onChange={(e) => status !== true ? _onChange(e, 'vehicleNo') : _onUpdate(e, 'vehicleNo')}
                                        className='custInput brdrbg'
                                        placeholder='Enter Vehicle Number'
                                    />
                                </div>
                            </div>
                            <div className='custom47'>
                                <div className='custom40'>
                                    <span className='custSpan'>Vehicle Type<span className='asterick'> * </span></span>
                                </div>
                                <div className='custom60'>
                                {status !== true ?
                                        <Select
                                            onChange={(value) => handleDropChange(value, 'vehicleType')}
                                            options={vehicleTypes}
                                            styles={colourStyles}
                                            value={{ label: vehicleState.vehicleType, value: vehicleState.vehicleType }}
                                        />
                                        :
                                        <Select
                                            onChange={(value) => handleUpdateDropChange(value, 'vehicleType')}
                                            options={vehicleTypes}
                                            styles={colourStyles}
                                            value={{ label: storeData.vehicleType, value: storeData.vehicleType }}
                                        />
                                }
                                    {/* <input name='vehicleType'
                                        value={status !== true ? vehicleState.vehicleType : storeData.vehicleType}
                                        onChange={(e) => status !== true ? _onChange(e) : _onUpdate(e)}
                                        className='custInput brdrbg' placeholder='Enter Vehicle type'
                                    /> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className='customRow addBg'>
                            <div className='custom47'>
                                <div className='custom40'>
                                    <span className='custSpan'>Vehicle Start Time <span className='asterick'> * </span> :</span>
                                </div>
                                <div className='custom60'>
                                    <input className='custInput brdrbg'
                                        type='time' name='startTime'
                                        placeholder='Enter Vehicle Start Time'
                                        value={status !== true ? moment(vehicleState.startTime, "h:mm:ss A").format("HH:mm:ss") : moment(storeData.startTime, "h:mm:ss A").format("HH:mm:ss")}
                                        onChange={(e) => status !== true ? _onChange(e, 'time') : _onUpdate(e, 'time')}

                                    />
                                </div>
                            </div>
                            <div className='custom47'>
                                <div className='custom40'>
                                    <span className='custSpan'>Vehicle End Time <span className='asterick'> * </span> :</span>
                                </div>
                                <div className='custom60'>
                                    <input className='custInput brdrbg'
                                        type='time' name='endTime'
                                        placeholder='Enter Vehicle Capacity'
                                        value={status !== true ? moment(vehicleState.endTime, "h:mm:ss A").format("HH:mm:ss") : moment(storeData.endTime, "h:mm:ss A").format("HH:mm:ss")}
                                        onChange={(e) => status !== true ? _onChange(e, 'time') : _onUpdate(e, 'time')}
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div className='customRow addBg alignCenter' style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <div className='custom47' style={{alignItems:'center',justifyContent:'center'}}>
                                <div className='custom40' style={{width:'auto'}}>
                                    <span className='custSpan'>Capacity<span className='asterick'> * </span></span>
                                </div>
                                <div className='custom60' style={{width:'40%'}}>
                                    <Select
                                        onChange={(value) => handleDropChange(value, 'dummy')}
                                        options={dummy}
                                        styles={colourStyles}
                                        value={{ label: vehicleState.params, value: vehicleState.params }}
                                    />
                                </div>
                            </div>
                        </div>
                        {vehicleState.params === 'Quantity' ?
                            <div className='customRow addBg'  >
                                <div className='custom47' >
                                    <div className='custom40'>
                                        <span className='custSpan'>Quantity ( Number )<span className='asterick'> * </span></span>
                                    </div>
                                    <div className='custom60'>
                                        <input className='custInput brdrbg'
                                            type='number' name='capacityInNumber'
                                            placeholder='Enter Quantity'
                                            value={status !== true ? vehicleState.capacityInNumber : storeData.capacityInNumber}
                                            onChange={(e) => status !== true ? _onChange(e, 'qty') : _onUpdate(e,'qty')}
                                        />
                                    </div>
                                </div>
                                <div className='custom47'>

                                </div>
                            </div>
                            : ""}
                        {vehicleState.params === 'Volume' ?
                            <div className='customRow addBg' style={{ marginBottom: '9px'}}>
                                <div className='custom47'>
                                    <div className='custom40'>
                                        <span className='custSpan'>Length( cm )<span className='asterick'>* </span> </span>
                                    </div>
                                    <div className='custom60'>
                                        <input className='custInput brdrbg'
                                            type='number' name='length'
                                            placeholder='Enter Length'
                                            value={status !== true ? vehicleState.length : storeData.length}
                                            onChange={(e) => status !== true ? _onChange(e) : _onUpdate(e)}
                                            onKeyUp={() => calculation()}
                                        />
                                    </div>
                                </div>
                                <div className='custom47'>
                                    <div className='custom40'>
                                        <span className='custSpan'>Width( cm )<span className='asterick'>* </span></span>
                                    </div>
                                    <div className='custom60'>
                                        <input className='custInput brdrbg'
                                            type='number' name='width'
                                            placeholder='Enter Width'
                                            value={status !== true ? vehicleState.width : storeData.width}
                                            onChange={(e) => status !== true ? _onChange(e) : _onUpdate(e)}
                                            onKeyUp={() => calculation()}
                                        />
                                    </div>
                                </div>
                            </div>
                            : ''}
                        {vehicleState.params === 'Volume' ?
                            <div className='customRow addBg' style={{    marginBottom: '9px'}}>
                                <div className='custom47'>
                                    <div className='custom40'>
                                        <span className='custSpan'>Height( cm )<span className='asterick'>* </span></span>
                                    </div>
                                    <div className='custom60'>
                                        <input className='custInput brdrbg'
                                            type='number' name='height'
                                            placeholder='Enter Height'
                                            value={status !== true ? vehicleState.height : storeData.height}
                                            onChange={(e) => status !== true ? _onChange(e) : _onUpdate(e)}
                                            onKeyUp={() => calculation()}
                                        />
                                    </div>
                                </div>
                                <div className='custom47'>
                                    <div className='custom40'>
                                        <span className='custSpan'>Freight Rate / KM </span>
                                    </div>
                                    <div className='custom60'>
                                        <input className='custInput brdrbg'
                                            type='number' name='freightRate'
                                            placeholder='Enter Freight Rate'
                                            value={status !== true ? vehicleState.freightRate : storeData.freightRate}
                                            onChange={(e) => status !== true ? _onChange(e) : _onUpdate(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            : ''}
                        {vehicleState.params === 'Volume' ?
                            <div className='customRow addBg alignCent' style={{display:'flex', justifyContent:'center', alignItems:'center'}} >
                                <div className='custom47' style={{alignItems:'center',justifyContent:'center'}}>
                                    <div className='custom40' style={{width:'auto'}}>
                                        <span className='custSpan'>Volume ( cm <sup>3</sup> ) -</span>
                                    </div>
                                    <div className='custom60 vehVolelm' style={{width:'25%', marginLeft:'12px'}}>
                                        <span className='vehVol'>{status !== true ? vehicleState.capacityInKg : storeData.capacityInKg} cm <sup>3</sup></span>
                                    </div>
                                </div>



                            </div>
                            : ''}
                    </div>
                    <div className='customBtnElem '>
                        {status !== true ?
                            <button className='customBtn' onClick={() => { addVehicle(); props.returnFunction(); }}>Add Vehicle</button>
                            :
                            <button className='customBtn' onClick={() => { updateVehicle(); props.returnFunction(); }}>Update Vehicle</button>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddVehicle;