import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Sidebar from "../../views/Dashboard/Sidebar"
import Header from "../../views/Dashboard/Header"
import CustomBreadcrumb from "./../../views/Dashboard/CustomBreadcrumb"
import DashboardView from "../../views/Dashboard/DashboardView"
import './../../static/css/dashboard.css'
import logo from './../../static/images/logo3.png'
import $ from 'jquery'
import history from './../../services/history'
import BDA from '../../views/Dashboard/BDAmanagement';
import BDAviewDetail from '../../views/Dashboard/BDAviewDetail';
import BdaMap from '../../views/Dashboard/BdaMap';
import BdaRoutes from '../../views/Dashboard/BdaRoutes';
import RequestDist from '../../views/Dashboard/RequestDist';
import Calendar from '../../views/Dashboard/Calendar';
import DistributorMapView from '../../views/Dashboard/DistributorMapView';
import CreateRoute from '../../views/Dashboard/CreateRoute';
import RouteMap from '../../views/Dashboard/RouteMap';
import AddressManag from '../../views/Dashboard/AddressMana';
import Request from '../../views/Dashboard/Request';
import VehicleSettings from '../../views/Dashboard/VehicleSettings';
import TripSettings from '../../views/Dashboard/TripSettings';
import TripMana from '../../views/Dashboard/TripMana';
import TripMap from '../../views/Dashboard/TripMap';
import ProductSettings from '../../views/Dashboard/ProductSettings';
import Preview from '../../views/Dashboard/Preview';
import Tracking from '../../views/Dashboard/Tracking';
import SlotSettings from '../../views/Dashboard/SlotSettings'
import LoadOptimizer from '../../views/Dashboard/LoadOptimizer';
import EstimatedTripDuration from '../../views/Dashboard/EstimatedTripDuration';
import UserManagement from '../../views/Dashboard/UserManagement';
import Customize from '../../views/Dashboard/Customize';
import CustomerData from '../../views/Dashboard/CustomerData';
import DriverSettings from '../../views/Dashboard/DriverSettings';
import Profile from '../../views/Dashboard/Profile';
import CashMemo from '../../views/Dashboard/CashMemo';
import AreaManagement from '../../views/Dashboard/AreaManagement';
import CustomerManagement from '../../views/Dashboard/CustomerManagement';
import CounterManagement from '../../views/Dashboard/CounterManagment';
import CylinderMapping from '../../views/Dashboard/CylinderMapping';
import Footer from '../../views/Landing/Footer';
import jwt_decode from "jwt-decode";
import CashMemoC from '../../views/Dashboard/CashMemoC';
import PlantDashboard from '../../views/Dashboard/PlantDashboard';
import Analytics from '../../views/Dashboard/Analytics';

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this._logout = this._logout.bind(this)
    this.userType = localStorage.getItem("userType") === 'driver' ? 'driver' : localStorage.getItem("userType") === 'distributor' ? 'distributor' : localStorage.getItem("userType") === 'admin' ? 'admin' : ""
    this.permissions = jwt_decode(localStorage.getItem("secretkey")).token?.permissions
  }



  componentDidMount() {
    $("[data-toggle='dropdown']").on("click", function (e) {
      e.stopPropagation();
      let target = $(this).data('target')
      $(target).parent().toggleClass('open')
    });

    setTimeout(() => {
      $(".custom-modal-overlay").height("100vh");
    }, 1000);

    $("[data-toggle!='dropdown']").on("click", function () {
      $(".btn-group,.media").removeClass('open')
    })

    $("#menuToggle").on("click", function () {
      $(".sidebar").toggleClass('open')
    })
    $("#myBar").on("click", function () {
      $(".sidebar.open").toggleClass('mobile')
    })

    // myBar
  }

  _logout() {
    localStorage.removeItem('secretkey')
    localStorage.removeItem('token')
    localStorage.removeItem('userType')
    history.push("/")
  }


  render() {
    return (
      <div>
        <Header handleClick={this._logout} image={logo} />
        <div className="main-content">
          {/* {localStorage.getItem("userType") !== 'plant' ?
            <Sidebar image={logo} {...this.props} />
            : ''} */}
          <Sidebar image={logo} {...this.props} />
          <div className="content-body">
            <CustomBreadcrumb {...this.props} />
            <Switch>
              {/* <Route path={this.props.match.url+"/analytics"} exact={true} render={(props)=><Analytics {...props}/>}/>  BdaMap*/}
              {/* <Route path={`${this.props.match.url}/bda/bda-details`} exact={true} render={(props) => <BDAviewDetail {...props} />} /> */}
              {/* <Route path={`${this.props.match.url}/bda/Map`} exact={true} render={(props) => <BdaMap {...props} />} /> */}
              {/* <Route path={`${this.props.match.url}/bda/create-route`} exact={true} render={(props) => <CreateRoute {...props} />} /> */}
              {/* <Route path={`${this.props.match.url}/map`} exact={true} render={(props) => <RouteMap {...props} />} /> */}
              {/* <Route path={`${this.props.match.url}/bda/routes`} exact={true} render={(props) => <BdaRoutes {...props} />} /> */}
              {/* <Route path={`${this.props.match.url}/bda/distributor-request`} exact={true} render={(props)=><RequestDist {...props} />}/> */}
              {/* <Route path={`${this.props.match.url}/bda/distributor-indent-request`} exact={true} render={(props) => <Calendar {...props} />} /> */}
              {/* <Route path={`${this.props.match.url}/bda/distributor-map-view`} exact={true} render={(props) => <DistributorMapView {...props} />} /> */}
              {/* <Route path={`${this.props.match.url}/distributor-management`} exact={true} render={(props) => <BDA {...props} />} /> */}
              {/* <Route path={`${this.props.match.url}/settings/trip-settings`} exact={true} render={(props) => <TripSettings {...props} type="trip-settings" />} /> */}
              {/* <Route path={`${this.props.match.url}/customization`} exact={true} render={(props) => <Customize {...props} type="estimatedTrip" />} /> */}
              {/* <Route path={`${this.props.match.url}/settings/trip-counter-management`} exact={true} render={(props) => <CounterManagement {...props} type="counterManagement" />} /> */}
              {localStorage.getItem("userType") !== "admin" ?
                <Route path={localStorage.getItem('userType') === 'plant' ? `${this.props.match.url}/trip-management` : `${this.props.match.url}`} exact={true} render={(props) => localStorage.getItem('userType') === 'plant' ? <PlantDashboard {...props} /> : <TripMana {...props} />} />
                :
                <Route path={`${this.props.match.url}`} exact={true} render={(props) => <UserManagement {...props} type="userManagement" />} />
              }

              {localStorage.getItem('userType') === 'plant' && <Route path={localStorage.getItem('userType') === 'plant' ? `${this.props.match.url}` : `${this.props.match.url}/analytics`} exact={true} render={(props) => <Analytics {...props} />} />}

              {/*{localStorage.getItem("userType") !== "admin" ?
                <Route path={`${this.props.match.url}`} exact={true} render={(props) => <TripMana {...props} />} />
                :
                <Route path={`${this.props.match.url}`} exact={true} render={(props) => <UserManagement {...props} type="userManagement" />} />
              } */}
              {this.permissions?.addresssManagement ?
                <Route path={`${this.props.match.url}/address-management`} exact={true} render={(props) => <AddressManag {...props} />} />
                : null}
              {this.permissions?.request ?
                <Route path={`${this.props.match.url}/request`} exact={true} render={(props) => <Request {...props} />} />
                : null}

              {this.permissions?.tripManagement ?
                <Route path={`${this.props.match.url}/trip-management`} exact={true} render={(props) => <TripMana {...props} />} />
                : ''}

              <Route path={`${this.props.match.url}/trip-map`} exact={true} render={(props) => <TripMap {...props} type="trip-map" />} />

              {this.permissions?.tripPreview ?
                <Route path={`${this.props.match.url}/trip/preview`} exact={true} render={(props) => <Preview {...props} type="trip-map" />} />
                : null}
              {this.permissions?.setting ?
                <Route path={`${this.props.match.url}/settings`} exact={true} render={(props) => <VehicleSettings {...props} type="settings" />} />
                : null}
              {this.permissions?.vehicleSettings ?
                <Route path={`${this.props.match.url}/settings/vehicle-settings`} exact={true} render={(props) => <VehicleSettings {...props} type="vehicle-settings" />} />
                : null}
              {this.permissions?.productsettings ?
                <Route path={`${this.props.match.url}/settings/product-settings`} exact={true} render={(props) => <ProductSettings {...props} type="product-settings" />} />
                : null}
              {this.permissions?.slotSettings ?
                <Route path={`${this.props.match.url}/settings/slot-settings`} exact={true} render={(props) => <SlotSettings {...props} type="slot-settings" />} />
                : null}
              {this.permissions?.estimatedTimeDuration ?
                <Route path={`${this.props.match.url}/settings/estimated-trip-duration`} exact={true} render={(props) => <EstimatedTripDuration {...props} type="estimatedTrip" />} />
                : null}

              {this.permissions?.driverSettings ?
                <Route path={`${this.props.match.url}/settings/delivery-man-settings`} exact={true} render={(props) => <DriverSettings {...props} type="CustomerData" />} />
                : null}
              {this.permissions?.areaManagement ?
                <Route path={`${this.props.match.url}/area-management`} exact={true} render={(props) => <AreaManagement {...props} type="areaManagement" />} />
                : null}
              {this.permissions?.consumerManagement ?
                <Route path={`${this.props.match.url}/consumer-management`} exact={true} render={(props) => <CustomerManagement {...props} type="customerManagement" />} />
                : null}
              {/* {this.permissions.allTripsView ?
                <Route path={`${this.props.match.url}/all-trips`} exact={true} render={(props) => <AllTrips {...props} type="AllTrips" />} />
                : null} */}
              <Route path={`${this.props.match.url}/cash-memo`} exact={true} render={(props) => <CashMemoC {...props} type="tracking" />} />

              <Route path={`${this.props.match.url}/tracking`} exact={true} render={(props) => <Tracking {...props} type="tracking" />} />
              <Route path={`${this.props.match.url}/cashmemo`} exact={true} render={(props) => <CashMemo {...props} type="cashmemo" />} />
              <Route path={`${this.props.match.url}/load-optimizer`} exact={true} render={(props) => <LoadOptimizer {...props} type="tracking" />} />
              <Route path={`${this.props.match.url}/customer`} exact={true} render={(props) => <CustomerData {...props} type="CustomerData" />} />
              <Route path={`${this.props.match.url}/user-profile`} exact={true} render={(props) => <Profile {...props} type="CustomerData" />} />
              <Route path={`${this.props.match.url}/v`} exact={true} render={(props) => <CylinderMapping {...props} type="CylinderMapping" />} />

              <Redirect to='/dashboard' />
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Dashboard;