import { REQUEST_LIST ,SHOW_REQ_LOADER, HIDE_REQ_LOADER ,REQUEST_MSSG ,REQUEST_COUNT } from "../actions/types";

const initialState={
    requestListing: [],
    loading: true,
    message: "",
    count :''
} 

const getRequestRed =(state=initialState,{type,payload})=>{
    switch(type){
        case REQUEST_LIST : return{
            ...state,
            requestListing : payload
        }
        case SHOW_REQ_LOADER : return{ 
            ...state,
            loading: true
        }
        case HIDE_REQ_LOADER : return{ 
            ...state,
            loading:false
        }  
        case REQUEST_MSSG : return{ 
            ...state,
            message:payload
        }
        case REQUEST_COUNT : return{ 
            ...state,
            count:payload
        }
        default: return state;  
    }
}
export default getRequestRed ;