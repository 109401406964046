import { FEEDBACK_DATA, FEEDBACK_SHOW, FEEDBACK_HIDE , FEEDBACK_MSSG } from './types'
import { post , logoutOnExpiry} from './../../../services/requests';


export const getfeedbackData = (object) => {
    return (dispatch) => {
        dispatch(showLoader())
        post('user/getFeedbackSettingDetails', {...object })
            .then(response => {
                const feedbackInfo = response && response.data ? response.data : ''
                const status = response && response.success ? response.success : ''
                const mssg = response && response.message ? response.message : ''
                if (status === true && feedbackInfo !== undefined) {
                    // console.log('feedbackInfo in action', feedbackInfo)   
                    dispatch(getFeedbackInfo(feedbackInfo))
                    dispatch(hideLoader()) 
                    dispatch(showMessage(mssg))
                }
                else{
                    dispatch(getFeedbackInfo())
                    dispatch(hideLoader()) 
                    dispatch(showMessage(mssg))
                }
            })
            .catch(error => { 
                console.log('error', error);
                dispatch(hideLoader()) 
                dispatch(showMessage('Some Error Occured.'))
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }
}

export const getFeedbackInfo = (feedbackInfo) => {
    return {
        type: FEEDBACK_DATA,
        payload: feedbackInfo
    }
}
export const showLoader = () => {
    return {
        type: FEEDBACK_SHOW,
    }
}
export const hideLoader = () => {
    return {
        type: FEEDBACK_HIDE,
    }
}
export const showMessage = (message) =>{
    return{
        type : FEEDBACK_MSSG,
        payload : message
    }
}