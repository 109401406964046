import { CASH_COUNT , CASH_LIST , CASH_SHOW , CASH_HIDE , CASH_MESSAGE } from './types'
import { post , logoutOnExpiry } from './../../../services/requests'
 
export const getCashMemoListing = (object) => {
        return (dispatch) => {
            dispatch(showLoader())
            post('user/getCashMemo', {...object })
                .then(response => {
                    const cashlists = response.data
                    const status = response.success
                    const message = response.message
                    const count = response.count
                    if(status === true && cashlists !== undefined){
                        dispatch(getMemos(cashlists))
                        dispatch(hideLoader())
                        dispatch(getCount(count))
                        dispatch(showMessage(''))
                    }
                    else{
                        dispatch(getMemos())
                        dispatch(hideLoader())
                        dispatch(showMessage(message))
                    }
                    
                })
                .catch(error => {
                    dispatch(hideLoader())
                    dispatch(showMessage('Some Error occured'))
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        } 
    }


export const getMemos = (cashlists) => {
    return {
        type: CASH_LIST,
        payload: cashlists
    }
}
export const showLoader = () =>{
    return{
        type : CASH_SHOW,
    }
}
export const hideLoader = () =>{
    return{
        type : CASH_HIDE,
    }
}
export const showMessage = (message) =>{
    return{
        type : CASH_MESSAGE,
        payload : message
    }
}
export const getCount = (count) =>{
    return{
        type : CASH_COUNT,
        payload : count
    }
}