// import React from 'react';
// import { Switch, Route } from 'react-router-dom';
// import Landing from '../components/App';
// import Dashboard from '../components/Dashboard';

// import React from 'react';
// import { Switch } from 'react-router-dom';
// import Route from './Route';
// import Landing from '../components/App';
// import Dashboard from '../components/Dashboard';

// export default function Routes() {
//   return (
//     <>
//       <Switch>
//         <Route path="/" exact component={Landing} />
//         <Route path="/dashboard" component={Dashboard} isPrivate />
//         {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
//         <Route component={Landing} />
//       </Switch>
//     </>
//   );
// }

import React from "react";
import { Switch, Route , Redirect } from "react-router-dom";
import Landing from "../components/App";
import Dashboard from "../components/Dashboard";
import PrivateRoute from "./Route";
import FeedBack from "../views/Dashboard/FeedBack/FeedBack";

export default function Routes() {
  return (
    <Switch>
      <Route path="/feedback/:feedbackId" exact component={FeedBack} />
      <PrivateRoute path="/" exact component={Landing} />
      <PrivateRoute path="/dashboard" component={Dashboard} isPrivate />
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Redirect to="/" />

    </Switch>
  );
}