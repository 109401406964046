import { CONSTRAINTS ,CONSTRAINTS_OUTPUT } from "../actions/types";

const initialState={
    constrainstInputList: [],
    constrainstOutputList: []
} 

const getConstraintsRed =(state=initialState,{type,payload})=>{
    switch(type){
        case CONSTRAINTS : return{
            ...state, 
            constrainstInputList : payload
        }
        case CONSTRAINTS_OUTPUT : return{
            ...state,
            constrainstOutputList : payload
        }
        default: return state;  
    }
}
export default getConstraintsRed ;