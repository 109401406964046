import { BDA_LISTING, SHOW_LOADER , HIDE_LOADER } from "../actions/types";

const initialState={
    bdaListing: [],
    loading: true
} 

const bdaListingRed =(state=initialState,{type,payload})=>{
    switch(type){
        case BDA_LISTING : return{
            ...state,
            bdaListing : payload
        }
        case SHOW_LOADER : return{ 
            ...state,
            loading:true
        }
        case HIDE_LOADER : return{ 
            ...state,
            loading:false
        }
        default: return state;  
    }
}
export default bdaListingRed ;