import { GET_ORDERS } from "../actions/types";

const initialState={
    ordersListing: [],
} 

const getOrdersRed =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_ORDERS : return{
            ...state,
            ordersListing : payload
        }
        default: return state;  
    }
}
export default getOrdersRed ;