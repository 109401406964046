import React, { useEffect, useState } from 'react';
import history from '../../services/history'
import { getSlotList } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import AddSlot from './Modal/AddSlot';
import $ from 'jquery'
import { selectInit } from './../../services/functions'
import axios from 'axios'
import { logoutOnExpiry, url } from './../../services/requests'
import Select from 'react-select';
import { colourStyles } from './../../services/functions'

const VehicleSettings = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState({
        detail: '',
        update: '',
        pagination: { pageNumber: '', entries: '' }
    })
    const [pageCount, setPageCount] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [searchObj, setSearchObject] = useState({
        page: pageCount,
        limit: pageLimit,
        searchType: '',
        searchValue: ''
    })
    const listing = useSelector((state) => state.getSlotRed.slotListing)
    const loader = useSelector((state) => state.getSlotRed.loading)
    const message = useSelector((state) => state.getSlotRed.message)
    const count = useSelector((state) => state.getSlotRed.count)

    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const _shareData = (item) => {
        let concated = { ...data }
        concated.detail = item
        concated.update = true
        concated.pagination.pageNumber = pageCount
        concated.pagination.entries = pageLimit
        setData(concated)
    }
    const callBack = () => {
        let concated = { ...data }
        concated.detail = ''
        concated.update = false
        concated.pagination.entries = ''
        concated.pagination.pageNumber = ''
        setData(concated)
    }
    const nextPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount += 1);
        });
    }
    const prevPage = () => {
        setPageCount(function (pageCount) {
            return (pageCount -= 1);
        });
    }
    useEffect(() => {
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 50)
        dispatch(getSlotList({
            page: pageCount,
            limit: pageLimit,
            searchType: '',
            searchValue: ''
        }))
    }, [dispatch, pageCount])

    const _searchDrop = (id, value) => {
        let temp = { ...searchObj }
        temp.searchType = id
        setSearchObject(temp)

    }
    const _handleChange = (e) => {
        setSearchObject({ ...searchObj, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(getSlotList({
            page: pageCount,
            limit: pageLimit,
            searchType: searchObj.searchType,
            searchValue: searchObj.searchValue
        }))
    }
    const enableDisable = (id) => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post(`${url}/setting/editSlotTime`, { _id: id }, { headers: config.headers })
            .then(response => {
                const status = response.data.success
                const message = response.data.message
                if (status === true) {
                    dispatch(getSlotList({
                        page: pageCount,
                        limit: pageLimit,
                        searchType: '',
                        searchValue: ''
                    }))
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                    // window.location.reload()
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }

    const deleteSlot = (id) => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        window.$.fn.show_notification({ message: 'Delete this Time Slot ?', title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
            if (result.type === 'confirm') {
                axios.post(`${url}/setting/deleteTime`, { _id: id }, { headers: config.headers })
                    .then(response => {
                        const status = response.data.success
                        const message = response.data.message
                        if (status === true) {
                            setPageCount(1)
                            dispatch(getSlotList({
                                page: pageCount,
                                limit: pageLimit,
                                searchType: '',
                                searchValue: ''
                            }))
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                            // window.location.reload()
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                        if (error.error.status === 401) {
                            logoutOnExpiry()
                        }
                    })

            }
        })
    }
    const returnFun = () => {
        // setPageCount(1)
    }
    const entries = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: 40, value: 40 },
        { label: 50, value: 50 }
    ]
    const handleChange = (item) => {
        let count = item.value
        setPageCount(1)
        setPageLimit(count)
        dispatch(getSlotList({
            page: pageCount,
            limit: item.value,
            searchType: '',
            searchValue: ''
        }))
    }

    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className='cust-col-5'>
                    <h1 className="title">
                        Time Slot Settings
                    </h1>
                </div>
                <div className='cust-col-5 flex-jc-end'>
                    <h1 className="title font-bold orange_color">
                    </h1>
                </div>
            </div>
            <hr className='bgg' />
            <div className="cust-container">
                <div class="cust-row">
                    <div class="cust-col-10">
                        <div className='bdaOuter'>
                            <div className='searcBox alignCen' >
                                {/* <div className='cust-col-4-new'>
                                    <div className="input-group">
                                        <div className="input-group-prepend" style={{ width: '30%', zIndex: '10', marginRight:'15px' }}>
                                            <select className="select2-single" defaultValue="select" id="vehicleSearch">
                                                <option value="select">Select</option>
                                                <option value="vehicleNo">Vehicle Number</option>
                                            </select>
                                        </div>
                                        <input type="text" name='searchValue' value={searchObj.searchValue} onChange={(e) => _handleChange(e)} className="form-control" placeholder="Search here..." style={{ zIndex: '10' }} />
                                        <div className="input-group-append" onClick={() => triggerSearch()}>
                                            <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='cust-col-2'>
                                </div>
                            </div>
                            <div className='mapView'>
                                <button className='myMapBtnGrey' onClick={() => { _handleClick('AddSlot') }}> Add Slot</button>
                            </div>
                        </div>
                        <div className='bdaList'>
                            {loader === false ?
                                listing && listing.length !== 0 ?
                                    <div className='bdaTableNew'>
                                        <div className='bdaHeader'>
                                            <div className='bdaH00 wid5'>
                                                <span>S NO.</span>
                                            </div>
                                            <div className='bdaH2 wid30'>
                                                <span>Start Slot</span>
                                            </div>
                                            <div className='bdaH2 wid30'>
                                                <span>End Slot</span>
                                            </div>
                                            <div className='bdaH2 wid30'>
                                                <span>Status</span>
                                            </div>
                                            <div className='bdaH00'>
                                                <span>Action</span>
                                            </div>
                                        </div>
                                        {listing && listing.map((item, index) => {
                                            return (
                                                <div key={index} className='bdaContent'>
                                                    <div className='bdaH00 wid5 no_top' style={{ height: 'auto' }}>
                                                        <span className='bdaSpan orangeColor'>{index + 1}</span>
                                                    </div>
                                                    <div className='bdaH2 wid30 no_top modifyHgt'>
                                                        <span className='bdaSpan'>{item.startTime ? item.startTime : '-'}</span>
                                                    </div>
                                                    <div className='bdaH2 wid30 no_top modifyHgt'>
                                                        <span className='bdaSpan'>{item.endTime ? item.endTime : '-'}</span>
                                                    </div>
                                                    <div className='bdaH2 wid30 no_top modifyHgt'>
                                                        <span className='bdaSpan'>{item.status ? <div style={{ color: "green" }}>Enabled</div> : <div style={{ color: "red" }}>Disabled</div>}</span>
                                                    </div>
                                                    <div className='bdaH00 no_top modifyHgt'>
                                                        <i className="fa fa-pencil-square-o editIcon" style={{ marginRight: '10px' }} onClick={() => { _handleClick('AddSlot'); _shareData(item) }}></i>
                                                        <i className="fa fa-trash trashIcon" onClick={() => { deleteSlot(item._id) }}></i>
                                                        {item.status ? <i class="fa fa-ban slotStatusDisableIcon" onClick={() => { enableDisable(item._id) }}></i> : <i class="fa fa-check slotStatusEnableIcon" onClick={() => { enableDisable(item._id) }}></i>}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    :

                                    <div className='noData'>
                                        <p>{message}</p>
                                    </div>
                                :
                                <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                    <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
                                </div>

                            }
                            {listing && listing.length !== 0 ?
                                <div className='paginationDiv'>
                                    <div className='entries'>
                                        <span className='entrieSpan'>Show Entries :</span>
                                        <Select
                                            onChange={(value) => handleChange(value)}
                                            options={entries}
                                            styles={colourStyles}
                                        />
                                    </div>
                                    <button disabled={pageCount === 1 ? true : false} className='paginationBtn' onClick={prevPage}>Previous</button>
                                    <button disabled={Math.floor((count + pageLimit - 1) / pageLimit) === pageCount ? true : false} className='paginationBtn' onClick={nextPage}>Next</button>
                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
            </div>
            <AddSlot closeModal={_closeModal} data={data} callBack={callBack} returnFun={returnFun} />
        </div>
    )
}
export default VehicleSettings;