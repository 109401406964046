import React, { useState, useEffect } from 'react';
import history from '../../../services/history'
import $ from 'jquery'
import Select from 'react-select';
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers } from '../../../state/redux/actions/index';
import { logoutOnExpiry, url } from './../../../services/requests'
import { colourStyles } from './../../../services/functions'

const AddUser = (props) => {
    const dispatch = useDispatch()
    const [userState, setUserState] = useState(
        {
            name: '',
            latitude: "",
            longitude: "",
            email: "",
            mobile: "",
            company: "",
            userType: "",
            address: "",
            code: "",
            status: false
        }
    )
    const _handleChange = (e) => {
        setUserState({ ...userState, [e.target.name]: e.target.value })
    }
  
    const options = [
        { value: 'select', label: 'Select' },
        { value: 'distributor', label: 'Distributor' },
        { value: 'driver', label: 'Driver' }
    ]
    const handleChange = (item) => {
        if (item.value === 'distributor') {
            let temp = { ...userState }
            temp.userType = item.value
            setUserState(temp)
        }
        else {
            let temp = { ...userState }
            temp.userType = item.value
            temp.code = ''
            setUserState(temp)
        }
    }
    const submitUser = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        if(userState.userType !== ''){  
            axios.post(`${url}/user/addUser`, { ...userState }, { headers: config.headers })
                .then(response => {
                    const status = response.data.success
                    const message = response.data.message
                    dispatch(getUsers({
                        page: 1,
                        limit: 10,
                        searchType: "",
                        searchValue: "",
                        userType: "distributor",
                        type: 'admin'
                    }))
                    if (status === true) {
                        setUserState({
                            name: '',
                            latitude: "",
                            longitude: "",
                            email: "",
                            mobile: "",
                            company: "",
                            userType: "",
                            code: "",
                        })
                        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                        $(".custom-modal").removeClass("custom-modal-is-visible")
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        }
        else{
            window.$.fn.show_notification({ message: 'Please choose User Type..', title: 'Message!!',  autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })  
        }
    }
    return (
        <section id="AddUser" className="custom-modal" style={{ width: '60%' }}>
            <div className="custom-modal-header">
                <h1 >Create User</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ maxHeight: '70vh' }}>
                <div className='latlngInpElem'>
                    <div className='user100'>
                        <div className='user332'>
                            <span className='userSpan userSpanBlack'>User Type :</span>
                            <Select
                                onChange={(value) => handleChange(value)}
                                options={options}
                                styles={colourStyles}
                            />
                        </div>
                    </div>
                    <div className='user100'>
                        <div className='user33'>
                            <span className='userSpan'>Name :</span>
                            <input name='name' value={userState.name} onChange={(e) => _handleChange(e)} className='userInp' type='text' placeholder='Enter your Name' />
                        </div>
                        <div className='user33'>
                            <span className='userSpan'>Mobile Number :</span>
                            <input name='mobile' value={userState.mobile} onChange={(e) => _handleChange(e)} className='userInp' type='number' placeholder='Enter Mobile Number' />
                        </div>
                        <div className='user33'>
                            <span className='userSpan'>Email :</span>
                            <input name='email' value={userState.email} onChange={(e) => _handleChange(e)} className='userInp' type='email' placeholder='Enter Email Address' />
                        </div>
                    </div>
                    <div className='user100'>
                        <div className='user33'>
                            <span className='userSpan'>Company Name :</span>
                            <input name='company' value={userState.company} onChange={(e) => _handleChange(e)} className='userInp' type='text' placeholder='Enter Company Name' />
                        </div>
                        <div className='user33'>
                            <span className='userSpan'>Latitude :</span>
                            <input name='latitude' value={userState.latitude} onChange={(e) => _handleChange(e)} className='userInp' type='number' placeholder='Enter Latitude' />
                        </div>
                        <div className='user33'>
                            <span className='userSpan'>Longitude :</span>
                            <input name='longitude' value={userState.longitude} onChange={(e) => _handleChange(e)} className='userInp' type='number' placeholder='Enter Longitude' />
                        </div>
                    </div>
                    {userState.userType === 'distributor'  ?
                        <div className='user100'>
                            <div className='user33'>
                                <span className='userSpan'>Distributor ID :</span>
                                <input name='code' value={userState.code} onChange={(e) => _handleChange(e)} className='userInp' type='number' placeholder='Enter Distributor ID' />
                            </div>
                            <div className='user33'>
                                <span className='userSpan'>Address :</span>
                                <input name='address' value={userState.address} onChange={(e) => _handleChange(e)} className='userInp' type='text' placeholder='Enter Address' />
                            </div>
                        </div>
                        : ''}
                </div>
                <div className='createRouteBtn'>
                    <button onClick={() => submitUser()}>Create User</button>
                </div>
            </div>
        </section>
    )
}

export default AddUser;