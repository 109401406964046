import { GET_VEHICLES } from "../actions/types";

const initialState={
    vehiclesListing: [],
    loading: false
} 

const getVehiclesRed =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_VEHICLES : return{
            ...state,
            vehiclesListing : payload
        }
        // case HIDE_SINGLE_DRIVER : return{ 
        //     ...state,
        //     loading:false
        // }
        default: return state;  
    }
}
export default getVehiclesRed ;